import React from 'react';
import PropTypes from 'prop-types';

import './accordion.component.scss';

const AccordionComponent = ({ title, onClick, expand, children }) => (
  <div className="accordion">
    <h2 onClick={() => onClick(title)}>
      {title}
      <span
        title="Open or close"
        className={expand === true ? 'icon-arrow-down accordion__icon-arrow-down--upside-down' : 'icon-arrow-down'}></span>
    </h2>
    <div className={expand === true ? 'accordion__content accordion__content--active' : 'accordion__content'}>
      {children}
    </div>
  </div>
);

AccordionComponent.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  expand: PropTypes.bool,
};

export default AccordionComponent;
