import { Children, cloneElement, useEffect, useState } from 'react';

import './accordion-collection.component.scss';

const AccordionCollectionComponent = ({ children, singleExpandMode }) => {
  // store array of bool values for which accordions are expanded (this is an array in same order as the children prop)
  const [expanded, setExpanded] = useState(Children.toArray().map(() => false));

  useEffect(() => {
    setExpanded(Children.toArray().map(() => false));
  }, [children, singleExpandMode]);

  // Toggle the expand flag on the clicked accordion
  const handleClick = (e) => {
    // Clone for immutability
    const newExpanded = [...expanded];
    // If we can only have one accordion expanded, set all expanded values to false
    singleExpandMode === true && newExpanded.fill(false);
    // Now, toggle the one we clicked on
    newExpanded[e] = !expanded[e];

    setExpanded(newExpanded);
  };

  return (
    <div className="accordion-collection">
      {Children.toArray(children).map((child, index) =>
        cloneElement(child, { onClick: () => handleClick(index), expand: expanded[index] })
      )}
    </div>
  );
};

export default AccordionCollectionComponent;
