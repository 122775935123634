import React, { useMemo } from 'react';
import { object, shape, string } from 'prop-types';

import * as constants from '../../constants/bin-collections.constants';
import { number } from 'prop-types';

const BinCollectionComponent = ({ collection, ...props }) => {
  const collectionStyle = constants.collectionClassNames[collection.service];

  // Calculate if we need to add an additional class to the container div, based on the number of containers
  const columnClass = useMemo(() => {
    switch (collection.serviceCount) {
      case 0:
      case 1:
        return '';
      case 2:
        return 'col-sm-6';
      default:
        return 'col-md-4';
    }
  }, [collection.serviceCount]);

  return (
    <div className={`col-xs-12 ${columnClass}`}>
      <div className={`bin-collections-lookup__services__service ${collectionStyle}`} {...props}>
         <div className="bin-collections-lookup__services__service__header">
          <h2>{constants.collectionTypeTitles[collection.service]} collection</h2>
          {collection.nextCollection && (
            ( collection.nextCollection.isSame(collection.lastCollected) ? (
              <h3>Not applicable; no subscription</h3>
          ) : (  <h3>Your next collection will be on {collection.nextCollection.format('dddd D MMMM YYYY')}</h3>))
          )}
        </div>
        <div className="bin-collections-lookup__services__service__body">
          <dl>
            <dt>Waste type</dt>
            <dd>{collection.wasteType}</dd>

            <dt>What's collected</dt>
            <dd>{collection.binDescription}</dd>

            <dt>Previous collection</dt>
            <dd>
              {collection.lastCollected ? collection.lastCollected.format('dddd D MMMM YYYY') : 'No data available'}
            </dd>

            <dt>Next collection</dt>
           <dd>
              {collection.nextCollection ? (
                  collection.lastCollected && collection.nextCollection.isSame(collection.lastCollected) ? 
                  'Not applicable; no subscription' : 
                  collection.nextCollection.format('dddd D MMMM YYYY')
              ) : 'No data available'}
          </dd>
            {collection.frequency !== '' && (
              <>
                <dt>Collection frequency</dt>
                <dd>{collection.frequency}</dd>
              </>
            )}
            {collection.wasteCollectedFrom && (
              <>
                <dt>Waste collected from</dt>
                <dd>{collection.wasteCollectedFrom}</dd>
              </>
            )}
            {collection.collectedBy === 'St Nicholas' && (
              <>
                <dt>Waste collected by</dt>
                <dd>
                  <a href=" https://stnicks.org.uk/make-a-difference/our-recycling-service/" target="_blank">
                    St Nicks (opens in new tab)
                  </a>
                </dd>
              </>
            )}
          </dl>
        </div>
      </div>
    </div>
  );
};

BinCollectionComponent.propTypes = {
  collection: shape({
    service: string,
    wasteType: string,
    lastCollected: object,
    nextCollection: object,
    frequency: string,
    wasteCollectedFrom: string,
    serviceCount: number,
  }).isRequired,
};

export default BinCollectionComponent;
