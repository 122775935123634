import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { clientMyAccountUrl as urls } from '../common';
import MyReportsIndexContainer from './containers/index/MyReportsIndex.container';
import ReportDetailsContainer from './containers/details/report-details.container';
const MyReportsRouter = () => {
  return (
    <Routes>
      <Route path={urls.myReports.details + '/:referenceNumber/:product/*'} element={<ReportDetailsContainer />} />
      <Route path="/" element={<MyReportsIndexContainer />} />
    </Routes>
  );
};

export default MyReportsRouter;
