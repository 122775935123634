import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { fetchAddressArrayAsync } from '../../services/address.service';
import { getWardFromPoint } from 'common/components/map-components/esri-location.service';
import { ErrorLabelHandlerComponent, isValidPostcode } from 'common';

import {
  TextInputComponent,
  ButtonComponent,
  ButtonClasses,
  DropdownInputComponent,
  InputErrorComponent,
} from 'cyc-react-ui-components';

import './address-lookup.container.scss';

const AddressLookupContainer = ({
  className,
  onAddressChange,
  onAddressSearch,
  onPostcodeChange,
  onValidationError,
  ...props
}) => {
  const [postcode, setPostcode] = useState(props.postcode || '');
  const [addresses, setAddresses] = useState(props.addresses ? [props.addresses] : []);
  const [uprn, setUprn] = useState(props.uprn || '');
  const [loading, setLoading] = useState(false);

  const classes = useMemo(() => (className === undefined ? 'cyc-address-lookup' : `cyc-address-lookup ${className}`), [
    className,
  ]);

  const handlePostcodeChange = (e) => {
    onPostcodeChange && onPostcodeChange(e.target.value);
    setPostcode(e.target.value);
    handleAddressReset();
  };

  const handlePostcodeKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearchClick();
      e.preventDefault();
    }
  };

  const handleAddressReset = () => {
    if (addresses.length > 0) {
      setUprn('');
      setAddresses([]);

      onAddressChange && onAddressChange('');
    }
  };

  const handleSearchClick = () => {
    handleAddressReset();
    const trimmedPostcode = postcode.trim();

    if (trimmedPostcode === '') {
      onValidationError && onValidationError('REQUIRED');
    } else if (isValidPostcode(trimmedPostcode) === false) {
      onValidationError && onValidationError('INVALID');
    } else {
      setLoading(true);
      setPostcode(trimmedPostcode);

      fetchAddressArrayAsync(trimmedPostcode)
        .then((data) => {
          setLoading(false);
          setAddresses(data.results);

          onAddressSearch && onAddressSearch(data);
        })
        .catch(() => setLoading(false));
    }
  };

  const handleAddressChangeAsync = async (e) => {
    const address = addresses.find((x) => x.uprn.toString() === e.target.value);

    if (address !== undefined) {
      let outOfYork = false;

      if (address.ward === null) {
        const ward = await getWardFromPoint(address.easting, address.northing);

        if (ward === '') {
          outOfYork = true;
        }
      }

      setUprn(address.uprn);

      onAddressChange && onAddressChange(address, outOfYork);
    }
  };

  return (
    <div className={classes}>
      <ErrorLabelHandlerComponent text="Postcode" id="postcode" touched={props.touched} errors={props.errors} />
      <TextInputComponent
        type="text"
        id="postcode"
        value={postcode}
        onChange={handlePostcodeChange}
        onKeyPress={handlePostcodeKeyPress}
        error={props.errors?.postcode && props.touched?.postcode}
      />
      {props.errors?.postcode && props.touched?.postcode === true && (
        <InputErrorComponent>{props.errors?.postcode}</InputErrorComponent>
      )}
      <ButtonComponent
        id="addressSearch"
        type="button"
        className={ButtonClasses.secondary}
        onClick={handleSearchClick}
        disabled={loading}>
        Find address
      </ButtonComponent>

      {loading ||
        (loading === false && addresses.length > 0 && (
          <React.Fragment>
            <ErrorLabelHandlerComponent text="Address" id="address" touched={props.touched} errors={props.errors} />
            <br />
            <DropdownInputComponent
              disabled={loading}
              id="address"
              onChange={handleAddressChangeAsync}
              value={uprn}
              defaultValue={loading ? 'Loading...' : 'Please select'}
              options={addresses.map((address) => ({
                text: address.shortAddress,
                value: address.uprn,
              }))}
            />
            {props.errors?.address && props.touched?.address === true && (
              <InputErrorComponent>{props.errors?.address}</InputErrorComponent>
            )}
          </React.Fragment>
        ))}
    </div>
  );
};

AddressLookupContainer.propTypes = {
  address: PropTypes.string,
  errors: PropTypes.object,
  onAddressChange: PropTypes.func,
  onAddressSearch: PropTypes.func,
  onPostcodeChange: PropTypes.func,
  postcode: PropTypes.string,
  source: PropTypes.string,
  touched: PropTypes.object,
};

export default AddressLookupContainer;
