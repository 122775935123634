import React from 'react';
import AccordionInternalComponent from './accordion-internal/accordion-internal.component';

const MyCouncilTaxAccordion = () => {
  return (
    <AccordionInternalComponent
      callToActionLink={{
        linkText: 'Pay council tax',
        linkUrl: 'https://www.york.gov.uk/PayingYourBill',
        isExternal: true,
      }}
      linksToRender={[
        {
          linkText: 'Set up or amend Direct Debit',
          linkUrl: 'https://www.york.gov.uk/paymentplan',
          isExternal: true,
        },
        {
          linkText: 'Discount for students',
          linkUrl: 'https://www.york.gov.uk/StudentDiscount',
          isExternal: true,
        },
        {
          linkText: 'Single occupancy discount',
          linkUrl: 'https://www.york.gov.uk/SingleOccupancy',
          isExternal: true,
        },
        { linkText: 'Move into York', linkUrl: 'https://www.york.gov.uk/movein', isExternal: true },
        { linkText: 'Move within York', linkUrl: 'https://www.york.gov.uk/movewithin', isExternal: true },
        { linkText: 'Move out of York', linkUrl: 'https://www.york.gov.uk/moveout', isExternal: true },
        { linkText: 'Request a bill', linkUrl: 'https://www.york.gov.uk/copybill', isExternal: true },
        {
          linkText: 'Other council tax information',
          linkUrl: 'https://www.york.gov.uk/counciltax',
          isExternal: true,
        },
      ]}
    />
  );
};

export default MyCouncilTaxAccordion;
