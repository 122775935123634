import React, { useState, useEffect } from 'react';
import { getContactDetailAsync, updateContactPhoneAsync } from '../../services/account.service';
import ChangePhonenumberComponent from '../components/change-phone-number.component';

import {
  NetworkLoaderComponent,
  DocumentTitleComponent,
  PageHeaderComponent,
  MyAccountPageComponent,
  clientMyAccountUrl,
} from '../../../common';
import WithAuthentication from 'common/components/with-authentication/with-authentication.component';
import { useNavigate } from 'react-router-dom';

const ChangePhonenumberContainer = () => {
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberPromise, setPhoneNumberPromise] = useState();

  useEffect(() => {
    const phoneNumberPromise = getContactDetailAsync(false);
    setPhoneNumberPromise(phoneNumberPromise);

    phoneNumberPromise
      .then((response) => {
        setPhoneNumber(response.data.phoneNumber);
      })
      .catch(() => {});
  }, []);

  return (
    <WithAuthentication>
      <PageHeaderComponent text="Change telephone number" icon="icon-website" />
      <MyAccountPageComponent>
        <DocumentTitleComponent title="My account - change telephone number">
          {phoneNumberPromise !== undefined && (
            <NetworkLoaderComponent request={phoneNumberPromise}>
              <ChangePhonenumberComponent
                phoneNumber={phoneNumber}
                onSubmit={(newPhoneNumber) => updateContactPhoneAsync(newPhoneNumber)}
                onSuccessfulChange={() =>
                  navigate(clientMyAccountUrl.account.root + clientMyAccountUrl.account.contactDetails)
                }
              />
            </NetworkLoaderComponent>
          )}
        </DocumentTitleComponent>
      </MyAccountPageComponent>
    </WithAuthentication>
  );
};

export default ChangePhonenumberContainer;
