import React, { useState, useEffect } from 'react';
import { FeatureFlagsService } from 'cyc-configuration-service';
import { getPrimaryAddressAsync } from '../../services/account.service';
import {
  DocumentTitleComponent,
  PageHeaderComponent,
  MyAccountPageComponent,
  RefuseAndRecycling,
  isEnabled,
  AccordionComponent,
  AccordionCollectionComponent,
  ParkingServices,
} from '../../../common';

import MyParkingServicesAccordion from '../../components/my-parking-services-accordion.component';
import MyDetailsAccordion from '../../components/my-details-accordion.component';
import MyReportsAccordion from '../../components/my-reports-accordion.component';
import MyRefuseAndRecyclingAccordion from '../../components/my-refuse-and-recycling-accordion.component';
import MyCouncilTaxAccordion from '../../components/my-council-tax-accordion.component';
import MyBenefitsAccordion from '../../components/my-benefits-accordion.component';
import WithAuthentication from 'common/components/with-authentication/with-authentication.component';

const HomePageContainer = () => {
  const [visible, setVisible] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const result = await getPrimaryAddressAsync();
      const outOfYork = result.data.outofYork;

      setVisible({
        refuse: outOfYork === false && isEnabled(FeatureFlagsService.getFeatureFlagByName(RefuseAndRecycling)) === true,
        councilTax: outOfYork === false,
        benefits: outOfYork === false,
        parking: isEnabled(FeatureFlagsService.getFeatureFlagByName(ParkingServices)) === true,
      });
    };

    fetchData();
  }, []);

  return (
    <WithAuthentication>
      <DocumentTitleComponent title="My Account - Dashboard">
        <PageHeaderComponent text="My Account" icon="icon-website" />
        <MyAccountPageComponent>
          <p> Welcome to My Account, where you can access our online services whenever you want to.</p>
          <p> We're currently improving My Account so more council services will be made available soon.</p>
          <AccordionCollectionComponent singleExpandMode={true}>
            <AccordionComponent title="My reports/report a problem">
              <MyReportsAccordion />
            </AccordionComponent>

            <AccordionComponent title="My details">
              <MyDetailsAccordion />
            </AccordionComponent>

            {visible.refuse === true && (
              <AccordionComponent title="My refuse and recycling">
                <MyRefuseAndRecyclingAccordion />
              </AccordionComponent>
            )}

            {visible.councilTax === true && (
              <AccordionComponent title="My council tax">
                <MyCouncilTaxAccordion />
              </AccordionComponent>
            )}

            {visible.benefits === true && (
              <AccordionComponent title="My benefits">
                <MyBenefitsAccordion />
              </AccordionComponent>
            )}

            {visible.parking === true && (
              <AccordionComponent title="My parking services">
                <MyParkingServicesAccordion />
              </AccordionComponent>
            )}
          </AccordionCollectionComponent>

          <div className="row">
            <div className="col">
              <h2>Also see</h2>
              <ul>
                <li>
                  <a href="https://www.york.gov.uk/myaccounthelp">My Account help</a>
                </li>
                <li>
                  <a href="https://www.york.gov.uk/privacy">Information about your privacy</a>
                </li>
                <li>
                  <a href="https://www.york.gov.uk/terms">Legal terms and conditions</a>
                </li>
              </ul>
            </div>
            <div className="col">
              <h2>Get in touch</h2>
              <p>
                There are lots of ways you can <a href="https://www.york.gov.uk/ContactUs">contact us</a>.
              </p>
            </div>
          </div>
        </MyAccountPageComponent>
      </DocumentTitleComponent>
    </WithAuthentication>
  );
};

export default HomePageContainer;
