export const AppName = 'Waste collection calendar';
export const ServiceDescription =
  'Check your waste collection dates and the types of waste we collect at your address.';

export const collectionClassNames = {
  REFUSE: 'bin-collections-lookup__services__service--refuse',
  RECYCLING: 'bin-collections-lookup__services__service--recycling',
  GARDEN: 'bin-collections-lookup__services__service--garden',
};

export const collectionTypeTitles = {
  REFUSE: 'Household waste',
  RECYCLING: 'Recycling',
  GARDEN: 'Garden waste',
};

export const webaspxDays = {
  mon: 'Monday',
  tue: 'Tuesday',
  wed: 'Wednesday',
  thu: 'Thursday',
  fri: 'Friday',
  sat: 'Saturday',
  sun: 'Sunday',
};
