import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FaRegEdit } from 'react-icons/fa';
import { DataArrayTableComponent, TableClasses } from 'cyc-react-ui-components';

import './summary-table.component.scss';

const SummaryTableComponent = ({ headerOne, headerTwo, linkHeader, rowData }) => (
  <DataArrayTableComponent
    headers={[headerOne, headerTwo, linkHeader]}
    data={rowData.map((data, key) => ({
      col1: data.columnOne,
      col2: data.columnTwo,
      col3:
        data.link !== undefined ? (
          <Link
            key={key}
            to={data.link}
            onClick={data.onChangeClicked}
            title={`Change '${data.columnOne}'`}
            data-testid={`${data.columnOne} - change`}>
            <FaRegEdit className="edit-icon" />
          </Link>
        ) : (
          data.onChangeClicked && (
            <FaRegEdit
              key={key}
              title={`Change '${data.columnOne}'`}
              onClick={data.onChangeClicked}
              className="edit-icon"
              data-testid={`${data.columnOne} - change`}
            />
          )
        ),
    }))}
    className={TableClasses.restyle + ' sentence-case'}
  />
);

SummaryTableComponent.propTypes = {
  headerOne: PropTypes.string,
  headerTwo: PropTypes.string,
  linkHeader: PropTypes.string,
  rowData: PropTypes.arrayOf(
    PropTypes.shape({
      columnOne: PropTypes.string,
      columnTwo: PropTypes.any,
      link: PropTypes.string,
      onChangeClicked: PropTypes.func,
      columnOneClassName: PropTypes.string,
      columnTwoClassName: PropTypes.string,
      linkClassName: PropTypes.string,
    })
  ),
};

export default SummaryTableComponent;
