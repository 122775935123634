import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { FeatureFlagsService } from 'cyc-configuration-service';

import {
  incidentFormRoot,
  GraffitiForm,
  RoadAndPavementProblemsForm,
  WaterAndDrainageProblemsForm,
  isEnabled,
  StreetFurnitureForm,
  DogAndLitterBinsForm,
  RoadAndStreetSignForm,
  VegetationForm,
} from '../common';
import StreetLightingApp from './street-lighting/street-lighting.app';
import FlyTippingApp from './fly-tipping/fly-tipping.app';
import StreetCleaningApp from './street-cleaning/street-cleaning.app';
import SaltBinProblemsApp from './salt-bins-problems/salt-bin-problems.app';
import RoadAndPavementProblemsApp from './road-and-pavement-problems/road-and-pavement-problems.app';
import GraffitiApp from './graffiti/graffiti.app';
import WaterAndDrainageProblemsApp from './water-and-drainage-problems/water-and-drainage-problems.app';
import { NotFoundComponent } from '../common';
import DogAndLitterBins from './dog-and-litter-bins/dog-and-litter-bins.app';
import StreetFurnitureApp from './street-furniture/street-furniture.app';
import RoadAndStreetSignApp from './road-and-street-sign/road-and-street-sign.app';
import VegetationApp from './vegetation/vegetation.app';

// Routes are currently hard coded because this is the only place they are used without the /form/ on the front
const IncidentFormRouter = () => (
  <Routes>
    <Route path="street-lighting/*" element={<StreetLightingApp />} />

    <Route path="fly-tipping/*" element={<FlyTippingApp />} />

    {isEnabled(FeatureFlagsService.getFeatureFlagByName(GraffitiForm)) ? (
      <Route path="graffiti/*" element={<GraffitiApp />} />
    ) : undefined}

    <Route path="street-cleaning/*" element={<StreetCleaningApp />} />

    {isEnabled(FeatureFlagsService.getFeatureFlagByName(RoadAndPavementProblemsForm)) ? (
      <Route path="road-and-pavement-problems/*" element={<RoadAndPavementProblemsApp />} />
    ) : undefined}

    {isEnabled(FeatureFlagsService.getFeatureFlagByName(WaterAndDrainageProblemsForm)) ? (
      <Route path="water-and-drainage-problems/*" element={<WaterAndDrainageProblemsApp />} />
    ) : undefined}

    <Route path="salt-bin-problems/*" element={<SaltBinProblemsApp />} />

    {isEnabled(FeatureFlagsService.getFeatureFlagByName(DogAndLitterBinsForm)) ? (
      <Route path="dog-and-litter-bins/*" element={<DogAndLitterBins />} />
    ) : undefined}

    {isEnabled(FeatureFlagsService.getFeatureFlagByName(StreetFurnitureForm)) ? (
      <Route path="street-furniture/*" element={<StreetFurnitureApp />} />
    ) : undefined}

    {isEnabled(FeatureFlagsService.getFeatureFlagByName(RoadAndStreetSignForm)) ? (
      <Route path="/road-and-street-sign/*" element={<RoadAndStreetSignApp />} />
    ) : undefined}

    {isEnabled(FeatureFlagsService.getFeatureFlagByName(VegetationForm)) ? (
      <Route path="vegetation/*" element={<VegetationApp />} />
    ) : undefined}

    <Route path="*" element={<NotFoundComponent />} />
  </Routes>
);

export default IncidentFormRouter;
