import React from 'react';

const TermsAndConditionsComponent = () => (
  <React.Fragment>
    <h2>My Account terms and conditions</h2>
    <p>
      We'll use the information you provide in accordance with the Data Protection Act 1998, to validate who you are and
      provide you with specific online services through My Account.
    </p>

    <p>
      We may share your information across our internal departments or with external partners, agencies and contractors
      delivering services on our behalf.
    </p>

    <p>
      We may be required or permitted to disclose your personal information without your explicit consent. For example,
      when we have a legal obligation to prevent and detect crime or fraud.
    </p>

    <p>
      We don't pass your information to third parties for marketing, sales or any other commercial purposes without your
      prior explicit consent.
    </p>

    <p>We'll only keep your personal information for as long as we need it, then we will destroy it securely.</p>

    <p>
      Please see our <a href="https://www.york.gov.uk/privacy">privacy statement.</a>
    </p>

    <h2>Using your details</h2>

    <p>If you agree, we'll use your contact details to send you relevant information about our services. </p>
  </React.Fragment>
);

export { TermsAndConditionsComponent };
