import React from 'react';
import { Link } from 'react-router-dom';
import { withFormik, Form } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { ConfigurationService } from 'cyc-configuration-service';
import {
  ErrorBoxComponent,
  ErrorLabelHandlerComponent,
  equalTo,
  clientMyAccountUrl as urls,
  NetworkCatchErrorComponent,
  msgConstants,
  PASSWORD_COMPLICATED_REGEX,
  apiMyAccountUrl,
} from '../../../../common';
import {
  TextInputComponent,
  InputErrorComponent,
  SubmitButtonAxiosComponent,
  ButtonClasses,
} from 'cyc-react-ui-components';

Yup.addMethod(Yup.string, 'equalTo', equalTo);

// The actual form to implement
const resetPasswordForm = ({ values, errors, touched, handleSubmit, setFieldValue }) => (
  <div className="placeholderCss">
    <NetworkCatchErrorComponent didCatchError={values.submitError} customMessage={values.submitErrorMessage} />
    {values.passwordChangeError ? (
      <div className="cyc-input-errors-box">
        <h2 className="cyc-input-errors">Invalid password reset request</h2>
        <p>The link you followed to reset your password has either expired or been previously used</p>
        <p>
          You can request a new link <Link to={urls.account.password.forgot}>here</Link>.
        </p>
      </div>
    ) : (
      <React.Fragment />
    )}
    {values.passwordChangeSuccessful ? (
      <div>
        <h2>Your password has been updated</h2>
        <br />
        <Link to={urls.signIn} className="button-cyc-primary float-right">
          Sign in
        </Link>
      </div>
    ) : (
      <React.Fragment />
    )}
    {values.passwordChangeError === false && values.passwordChangeSuccessful === false ? (
      <div>
        <Form noValidate>
          {Object.keys(errors).length > 0 && touched.password && touched.confirmPassword ? (
            <ErrorBoxComponent errors={errors}></ErrorBoxComponent>
          ) : null}
          <div className="row">
            <div className="col form-group">
              <ErrorLabelHandlerComponent touched={touched} errors={errors} text="New password" id="password" />
              <TextInputComponent
                type="password"
                name="password"
                id="password"
                error={errors.password && touched.password}
                value={values.password}
                onChange={(e) => setFieldValue('password', e.target.value)}
              />
              {errors.password && touched.password && <InputErrorComponent>{errors.password}</InputErrorComponent>}
            </div>
          </div>
          <div className="row">
            <div className="col  form-group">
              <ErrorLabelHandlerComponent
                touched={touched}
                errors={errors}
                text="Confirm password"
                id="confirmPassword"
              />
              <TextInputComponent
                type="password"
                name="confirmPassword"
                id="confirmPassword"
                error={errors.confirmPassword && touched.confirmPassword}
                value={values.confirmPassword}
                onChange={(e) => setFieldValue('confirmPassword', e.target.value)}
              />
              {errors.confirmPassword && touched.confirmPassword && (
                <InputErrorComponent>{errors.confirmPassword}</InputErrorComponent>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <SubmitButtonAxiosComponent
                type="submit"
                className={ButtonClasses.primary + ' float-right'}
                url={`${ConfigurationService.store.apiUrl}${apiMyAccountUrl.ACCOUNT_RESET_PASSWORD}`}
                onClick={handleSubmit}
              />
            </div>
          </div>
        </Form>
      </div>
    ) : (
      <React.Fragment />
    )}
  </div>
);

// This is what is used anywhere in the app
const ResetPasswordFormComponent = withFormik({
  mapPropsToValues({ userId, code, password, confirmPassword, onSubmit }) {
    return {
      userId: userId || '',
      code: code || '',
      password: password || '',
      confirmPassword: confirmPassword || '',
      onSubmit: onSubmit,
      passwordChangeError: false,
      passwordChangeErrorMessage: '',
      passwordChangeSuccessful: false,
    };
  },
  validationSchema: Yup.object().shape({
    password: Yup.string()
      .min(8, 'New password must be at least 8 characters')
      .required('Password is required')
      .matches(PASSWORD_COMPLICATED_REGEX, msgConstants.PASSWORD_COMPLICATED_MESSAGE),
    confirmPassword: Yup.string()
      .equalTo(Yup.ref('password'), 'Password must match')
      .required('Confirm password is required'),
  }),
  handleSubmit(values, { setFieldValue, setSubmitting, resetForm }) {
    const form = Object.assign(
      {},
      {
        userId: values.userId,
        code: values.code,
        password: values.password,
        confirmPassword: values.confirmPassword,
      }
    );
    const resetFormValues = () => {
      resetForm();
      setFieldValue('password', '');
      setFieldValue('confirmPassword', '');
      setSubmitting(false);
    };
    values
      .onSubmit(form)
      .then((response) => {
        if (response.status === 200) {
          setFieldValue('passwordChangeSuccessful', true);
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          resetFormValues();
          setFieldValue('passwordChangeError', true);
        } else if (err.response.status === 500) {
          resetFormValues();
          setFieldValue('submitError', true);
          setFieldValue('submitErrorMessage', msgConstants.GENERIC_API_ERROR);
        }
      });
  },
})(resetPasswordForm);

export default ResetPasswordFormComponent;

ResetPasswordFormComponent.propTypes = {
  userId: PropTypes.string,
  code: PropTypes.string,
  onSubmit: PropTypes.func,
};
