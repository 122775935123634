import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { LoggedInUpdatesComponent, NotLoggedInUpdatesComponent } from '../../common';
import { useNavigate } from 'react-router-dom';
import { RedirectWithWindowConfirmV6 } from 'common/helpers/react-router-helpers/RedirectWithWindowConfirm';

const ConfirmationPage = (props) => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="page">
      <h2>Confirmation</h2>
      <div className="row">
        <p>
          Thank you for submitting your request. Your reference is: <b>{props.referenceNumber}</b>
        </p>
      </div>

      <h3>Get updates</h3>
      {props.userLoggedIn() ? (
        <LoggedInUpdatesComponent goToMyAccount={props.goToMyAccount} />
      ) : (
        <NotLoggedInUpdatesComponent
          onSignInClicked={props.onSignInClicked}
          onRegisterClicked={props.onRegisterClicked}
        />
      )}

      {props.responseTimes ? (
        <>
          <h2>Response times</h2>
          {props.responseTimes.map((response, index) => {
            return (
              <div className="row" key={index}>
                <p>
                  See our <a href={response.url}>{response.text}</a>.
                </p>
              </div>
            );
          })}
        </>
      ) : null}

      <p>
        <button className="in-text-button" onClick={() => RedirectWithWindowConfirmV6(navigate, props.restartFormUrl)}>
          Report another problem
        </button>
      </p>
    </div>
  );
};

export default ConfirmationPage;

ConfirmationPage.propTypes = {
  scrollToWindowTop: PropTypes.func,
  referenceNumber: PropTypes.string,
  onSignInClicked: PropTypes.func,
  onRegisterClicked: PropTypes.func,
  goToMyAccount: PropTypes.func,
  userLoggedIn: PropTypes.func.isRequired,
  restartFormUrl: PropTypes.string.isRequired,
};
