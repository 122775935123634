import { PASSWORD_COMPLICATED_REGEX, POSTCODE_REGEX } from '../constants/validation.constants';

// Checks if the value is a number and is the has 11 digits
export const isValidTelephoneNumber = (phoneNumber) => {
  // Return false if input is not a number or string
  if (phoneNumber === undefined || phoneNumber === null) return false;

  // Convert to string to preserve leading 0 and remove white space
  const numberToString = phoneNumber.toString().replace(/\s/g, '');

  // Return false if there is anything but numbers in there
  if (numberToString.match(/^[0-9]+$/)) {
    // Keep the number as string to preserve leading 0
    return numberToString.replace(/\s/g, '').match(/\d/g).length === 11;
  } else {
    return false;
  }
};

// Checks to make sure that the password is complicated enough
// Allow empty value means it will return true if it's empty
export const IsValidComplexPassword = (value, allowEmptyValue = false) => {
  // Allow empty as this is resolved through formik
  if (allowEmptyValue === true && (value === '' || value === undefined)) {
    return true;
  }

  const doesItMatch = value.match(PASSWORD_COMPLICATED_REGEX);
  return doesItMatch !== null;
};

export const isValidPostcode = (value, allowEmptyValue = false) => {
  if (value === '' || value === undefined) {
    return allowEmptyValue;
  }

  if (value.trim().match(POSTCODE_REGEX) !== null) {
    return true;
  }
  return false;
};
