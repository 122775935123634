import React from 'react';
import { withFormik, Form } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { ConfigurationService } from 'cyc-configuration-service';
import { ErrorBoxComponent, ErrorLabelHandlerComponent, apiMyAccountUrl } from '../../../../common';
import {
  TextInputComponent,
  InputErrorComponent,
  SubmitButtonAxiosComponent,
  ButtonClasses,
} from 'cyc-react-ui-components';

// The actual form to implement
const forgotPasswordForm = ({ errors, touched, handleSubmit, submitCount, setFieldValue, values }) => (
  <div className="placeholderCss forgotPasswordForm">
    <Form noValidate>
      {Object.keys(errors).length > 0 && touched.email && submitCount > 0 ? (
        <ErrorBoxComponent errors={errors}></ErrorBoxComponent>
      ) : null}
      <ErrorLabelHandlerComponent
        touched={touched}
        errors={errors}
        text="Email address"
        id="email"
        submitCount={submitCount}
      />
      <TextInputComponent
        type="email"
        name="email"
        id="email"
        autoComplete="off"
        value={values.email}
        onChange={(e) => setFieldValue('email', e.target.value)}
        error={errors.email && touched.email && submitCount > 0}
      />
      {errors.email && touched.email && submitCount > 0 && <InputErrorComponent>{errors.email}</InputErrorComponent>}
      <SubmitButtonAxiosComponent
        type="submit"
        className={ButtonClasses.primary + ' float-right'}
        url={`${ConfigurationService.store.apiUrl}${apiMyAccountUrl.ACCOUNT_FORGOT_PASSWORD}`}
        onClick={handleSubmit}
      />
    </Form>
  </div>
);

const ForgotPasswordFormComponent = withFormik({
  mapPropsToValues({ email, onSubmit }) {
    return {
      email: email || '',
      onSubmit: onSubmit || console.log('no submit method'),
    };
  },
  validationSchema: Yup.object().shape({
    email: Yup.string()
      .email("'Email address' - Error: this email address does not appear to be valid. Please re-enter.")
      .required("Please supply details for 'Email address'"),
  }),
  handleSubmit(values, { setSubmitting }) {
    values
      .onSubmit(values)
      .then(() => {
        setSubmitting(false);
      })
      .catch(() => {
        setSubmitting(false);
      });
  },
})(forgotPasswordForm);

ForgotPasswordFormComponent.propTypes = {
  email: PropTypes.string,
  onSubmit: PropTypes.func,
};

export default ForgotPasswordFormComponent;
