import React, { useState, useEffect } from 'react';

import { postEmailConfirmation } from '../../services/registration.service';
import { clientMyAccountUrl } from '../../../common';
import { ButtonComponent, ButtonClasses } from 'cyc-react-ui-components';
import { useNavigate, useSearchParams } from 'react-router-dom';

const RegisterEmailConfirmComponent = () => {
  const navigate = useNavigate();
  const [emailConfirmSuccess, setEmailConfirmSuccess] = useState(false);
  const [confirmationCompleted, setConfirmationCompleted] = useState(false);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    // Not using queryString on code as it reads the token incorrectly
    const userId = searchParams.get('userId');
    const code = window.location.search.split('code=')[1];

    postEmailConfirmation(userId, code)
      .then((data) => {
        setEmailConfirmSuccess(data.status === 200);
        setConfirmationCompleted(true);
      })
      .catch(() => {
        setEmailConfirmSuccess(false);
        setConfirmationCompleted(true);
      });
  }, []);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      {confirmationCompleted ? (
        <React.Fragment>
          {emailConfirmSuccess ? (
            <React.Fragment>
              <h1>My Account verification</h1>
              <p>Thank you for verifying your My Account email address. You can now sign in to My Account.</p>
              <ButtonComponent
                type="submit"
                className={ButtonClasses.primary + ' float-right'}
                onClick={() => navigate(clientMyAccountUrl.signIn)}>
                Sign in
              </ButtonComponent>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <h1>Email verification failed</h1>
              <p>Sorry, your email address was not properly verified.</p>
              <p>We've sent you a new verification email. Please try again using the link provided in the email.</p>
              <p>
                If you are still having trouble verifying your email,{' '}
                <a href="https://www.york.gov.uk/ContactUs">contact us</a>.
              </p>
            </React.Fragment>
          )}
        </React.Fragment>
      ) : (
        <h3>Please wait while we verify your email address...</h3>
      )}
    </>
  );
};

export { RegisterEmailConfirmComponent };
