export const msgConstants = {
  SIGNIN_INVALID:
    'Incorrect email or password, or your account has not been confirmed. Please try again or click Forgot password to reset it.',
  SIGNIN_FAIL: 'Something has gone wrong, please try again',
  SIGNIN_EMAIL_NOT_CONFIRM: 'You need to confirm your account in order to sign in, please check your email',
  ERROR_BOX_HEADER: 'Please check the required details are completed',
  NETWORK_ERROR: 'There has been a network error, please try again', // Error if the server cannot be reached
  GENERIC_API_ERROR: 'Whoops! There has been a problem, please try again.', // Error used if server fails to complete request,
  REPORT_FAIL: 'Unable to retrieve report details, please try again.',
  SELECT_ADDRESS_MESSAGE: 'Please select an address from the list.',
  NO_POSTCODE_MESSAGE: "Please supply details for 'Postcode'.",
  INVALID_POSTCODE_MESSAGE: "'Postcode' - Error: This does not appear to be a valid postcode. Please re-enter.",
  PASSWORD_COMPLICATED_MESSAGE:
    "'Password' - Error: Password must be at least 8 characters and contain at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character.",
  NEW_PASSWORD_COMPLICATED_MESSAGE:
    "'New password' - Error: Password must be at least 8 characters and contain at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character.",
  PASSWORD_SIMPLE_MESSAGE: "'Password' - Error: this password does not appear to be valid. Please re-enter.",
  CHARACTER_LIMIT_EXCEEDED_ERROR:
    'You have exceeded the maximum amount of characters. Please reduce the text and try again',
  PLEASE_SELECT_A_LOCATION: 'Please select a location on the map.',
};
