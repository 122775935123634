import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import PropTypes from 'prop-types';

import { ConfigurationService } from 'cyc-configuration-service';
import appInsights from 'cyc-application-insights-react';
import { CYCServices } from 'cyc-services';
import {
  InputLabelComponent,
  ButtonClasses,
  TextInputComponent,
  InputErrorComponent,
  SubmitButtonAxiosComponent,
} from 'cyc-react-ui-components';

import { clientMyAccountUrl, apiMyAccountUrl, ErrorBoxComponent, UserContext } from '../../common';
import sessionActivityService from '../../common/services/session-activity.service';

const SignInFormComponent = ({ onSuccessfulSignIn, registerLinkUrl, email, password }) => {
  const userContext = useContext(UserContext);
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required("Please supply details for 'Email address'."),
    password: Yup.string().required("Please supply details for 'Password'."),
  });

  const initialValues = {
    email: email || '',
    password: password || '',
    submitError: '',
  };

  const signInUrl = `${ConfigurationService.store.apiUrl}${apiMyAccountUrl.ACCOUNT_LOGIN}`;

  const handleSuccessfulSignIn = () => {
    const user = CYCServices.JWTAuth.getProfile();
    userContext.setStore('firstName', user.given_name);
    userContext.setStore('lastName', user.family_name);
    sessionActivityService.begin();

    // call additional successful sign in function if there is any, otherwise redirect to dashboard.
    if (onSuccessfulSignIn) {
      onSuccessfulSignIn();
    } else {
      navigate(clientMyAccountUrl.dashboard);
    }
  };

  const submitForm = async (values) => {
    values.submitError = '';

    try {
      const response = await CYCServices.JWTAuth.authenticateAsync(values.email, values.password, signInUrl);
      values.password = '';

      if (response.success === true) {
        handleSuccessfulSignIn();
      } else if (response.status === 401) {
        values.submitError = 'The email or password you entered is incorrect or your account has been disabled.';
      }
    } catch (ex) {
      values.password = '';

      if (ex.response && ex.response.status === 401) {
        values.submitError = 'The email or password you entered is incorrect or your account has been disabled.';
      } else {
        values.submitError = 'System error, please try again';
        console.error(ex);
      }

      appInsights.trackException({
        exception: new Error('Failed to submit sign in form'),
        properties: { email: values.email },
      });
    }
  };

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={submitForm}>
      {(props) => {
        const { values, errors, touched, setFieldValue, handleSubmit } = props;
        const emailHasError = errors.email && touched.email;
        const passwordHasError = errors.password && touched.password;
        return (
          <Form noValidate={true}>
            {Object.keys(errors).length > 0 && touched.email && touched.password && <ErrorBoxComponent />}
            {Object.keys(errors).length === 0 && values.submitError && (
              <ErrorBoxComponent jumpButton={false} text={values.submitError} />
            )}
            <div className="row">
              <div className="form-group">
                <p>Sign in with an existing My Account.</p>
              </div>
            </div>
            <div className="row">
              <div className="form-group w-100">
                <InputLabelComponent htmlFor="email" required={true} error={emailHasError}>
                  Email
                </InputLabelComponent>
                <TextInputComponent
                  id="email"
                  type="email"
                  name="email"
                  value={values.email}
                  onChange={(e) => setFieldValue('email', e.target.value)}
                  error={emailHasError}
                />
                {emailHasError && <InputErrorComponent>{errors.email}</InputErrorComponent>}
              </div>
            </div>
            <div className="row">
              <div className="form-group w-100">
                <InputLabelComponent htmlFor="password" required={true} error={passwordHasError}>
                  Password
                </InputLabelComponent>
                <TextInputComponent
                  id="password"
                  type="password"
                  name="password"
                  value={values.password}
                  onChange={(e) => setFieldValue('password', e.target.value)}
                  error={passwordHasError}
                />
                {passwordHasError && <InputErrorComponent>{errors.password}</InputErrorComponent>}
              </div>
            </div>
            <div className="row">
              <div className="form-group w-100">
                <SubmitButtonAxiosComponent
                  type="submit"
                  url={signInUrl}
                  className={ButtonClasses.primary + ' float-right'}
                  pending="Signing in"
                  title="Sign in"
                  done="Done"
                  failed="Sign in"
                  allowReSubmit={true}
                  onClick={handleSubmit}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <p>
                  <Link to={clientMyAccountUrl.account.root + clientMyAccountUrl.account.password.forgot}>
                    Forgot your password?
                  </Link>
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <h2>Register for My Account</h2>
                <p>
                  <Link to={registerLinkUrl}>Register for My Account</Link> to see the status/progress of all services
                  and reported problems by signing in online. My Account stores your information, making it quick and
                  easy to report problems, complete forms and make payments.
                </p>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

SignInFormComponent.propTypes = {
  onSuccessfulSignIn: PropTypes.func,
  registerLinkUrl: PropTypes.string.isRequired,
};

export default SignInFormComponent;
