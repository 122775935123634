import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { withFormik, Form } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import {
  ErrorBoxComponent,
  ErrorLabelHandlerComponent,
  isValidPostcode,
  isValidTelephoneNumber,
  msgConstants,
  apiMyAccountUrl,
} from '../../../common';

import { ConfigurationService } from 'cyc-configuration-service';

import AddressLookupContainer from '../../../common/containers/address-lookup/address-lookup.container';
import {
  InputErrorComponent,
  TextInputComponent,
  HelpTextComponent,
  ButtonComponent,
  ButtonClasses,
  SubmitButtonAxiosComponent,
} from 'cyc-react-ui-components';

const RegStepThreeForm = ({
  values,
  errors,
  touched,
  setFieldValue,
  setErrors,
  isSubmitting,
  setFieldTouched,
  submitCount,
  handleSubmit,
}) => {
  let errorBoxRef = React.createRef();
  const previousSubmitCount = React.useRef(0);
  const telephoneError = touched.telephone && errors.telephone && submitCount > 0;

  useEffect(() => {
    if (Object.keys(errors).length > 0 && submitCount > previousSubmitCount.current) {
      previousSubmitCount.current = submitCount;
      const errorBox = ReactDOM.findDOMNode(errorBoxRef.current);
      errorBox.scrollIntoView();
    }
  }, [errors, errorBoxRef, submitCount, previousSubmitCount]);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <Form noValidate>
      <div ref={errorBoxRef}></div>
      {Object.keys(errors).length > 0 && touched.postcode && touched.address && <ErrorBoxComponent />}
      <ErrorLabelHandlerComponent
        touched={touched}
        errors={errors}
        requiredInput={false}
        text="Telephone number"
        id="telephone"
        submitCount={submitCount}
      />
      <TextInputComponent
        type="tel"
        pattern="[0-9\s]*"
        name="telephone"
        id="telephone"
        error={telephoneError}
        value={values.telephone}
        onChange={(e) => setFieldValue('telephone', e.target.value)}
      />
      {telephoneError && <InputErrorComponent>{errors.telephone}</InputErrorComponent>}
      <HelpTextComponent>Help: Provide a mobile or home telephone number</HelpTextComponent>

      <AddressLookupContainer
        touched={touched}
        errors={errors}
        onPostcodeChange={(postcode) => {
          setFieldValue('postcode', postcode, false);
          setFieldTouched('postcode', false, false);
          setFieldTouched('address', false, false);
        }}
        onAddressChange={(address, outofYork) => {
          setFieldValue('uprn', address.uprn, true);
          setFieldValue('address', address.shortAddress, true);
          setFieldValue('outofYork', outofYork);
        }}
        onAddressSearch={(data) => {
          if (data.results && data.results.length === 0) {
            setFieldTouched('postcode', true, false);
            setFieldTouched('address', true, false);
            setErrors({ postcode: msgConstants.INVALID_POSTCODE_MESSAGE });
          } else {
            setFieldValue('addressSource', data.source);
            setFieldTouched('postcode', true, true);
            setFieldTouched('address', false, true);
          }
        }}
        onValidationError={(type) => {
          setFieldTouched('postcode', true, false);
          setFieldTouched('address', true, false);

          if (type === 'REQUIRED') {
            setErrors({ postcode: msgConstants.NO_POSTCODE_MESSAGE });
          }
          if (type === 'INVALID') {
            setErrors({ postcode: msgConstants.INVALID_POSTCODE_MESSAGE });
          }
        }}
      />
      <SubmitButtonAxiosComponent
        type="submit"
        className={ButtonClasses.primary + ' float-right'}
        url={`${ConfigurationService.store.apiUrl}${apiMyAccountUrl.REGISTER}`}
        onClick={handleSubmit}
        title="Register"
        pending="Registering"
        done="Registered"
      />
      <ButtonComponent type="button" onClick={values.goToPrevious}>
        Previous
      </ButtonComponent>
    </Form>
  );
};

// This is what is used anywhere in the app
const RegistrationStepThreeComponent = withFormik({
  mapPropsToValues({ telephone, postcode, address, uprn, onSubmitSuccess, goToPrevious }) {
    return {
      telephone: telephone || '',
      postcode: postcode || '',
      address: address || '',
      uprn: uprn || '',
      showAddressArray: address ? true : false,
      addressArray: [{ address: address }] || [],
      onSubmitSuccess: onSubmitSuccess || null,
      goToPrevious: goToPrevious || null,
    };
  },
  validationSchema: Yup.object({
    telephone: Yup.string().test(
      'phone',
      "'Telephone number' - Error: This does not appear to be a valid telephone number. Please re-enter.",
      (value) => (value ? isValidTelephoneNumber(value) : true)
    ),
    postcode: Yup.string()
      .required(msgConstants.NO_POSTCODE_MESSAGE)
      .test('postcode', msgConstants.INVALID_POSTCODE_MESSAGE, async (value) => {
        return await isValidPostcode(value);
      }),
  }),

  handleSubmit(values, { setSubmitting, setFieldError }) {
    if (values.address === '') {
      setFieldError('address', 'Please select an address from the list.');
      setSubmitting(false);
      return 0;
    }
    values.onSubmitSuccess(values).then((res) => {
      if (res === false) {
        return setSubmitting(false);
      }
    });
  },
})(RegStepThreeForm);

export { RegistrationStepThreeComponent, RegStepThreeForm };

RegistrationStepThreeComponent.propTypes = {
  telephone: PropTypes.string,
  postcode: PropTypes.string,
  address: PropTypes.string,
  onSubmitSuccess: PropTypes.func.isRequired,
  goToPrevious: PropTypes.func.isRequired,
};
