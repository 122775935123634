import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CYCServices } from 'cyc-services';
import { clientMyAccountUrl } from '../../constants/my-account-urls.constants';

const WithAuthentication = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const authenticated = CYCServices.JWTAuth.isAuthenticated();
    setIsAuthenticated(authenticated);
    if (authenticated === false) {
      navigate(clientMyAccountUrl.signIn);
    }
  }, []);

  if (isAuthenticated === true) return children;
  return <></>;
};

export default WithAuthentication;
