// This helper file is to have any methods for dom based work

// Scroll the provided dom node into view
export const scrollIntoView = (domNode) => {
  domNode.scrollIntoView();
};

export const scrollToWindowTop = () => {
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
};

// Scroll window to a hash found in url or to the top if not found
export const setInitialPosition = () => {
  const { hash } = window.location;
  if (hash) {
    // Push onto callback queue so it runs after the DOM is updated,
    // this is required when navigating from a different page so that
    // the element is rendered on the page before trying to getElementById.
    setTimeout(() => {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) element.scrollIntoView();
    }, 0);
  } else {
    window.scroll(0, 0);
  }
};

/**
 * Sets current url based on parameters. v6 refers to the react-router-dom version
 * @param {number} step - form step number to go to (required)
 * @param {object} formConfig - formConfig containing stepUrls (required)
 * @param {import("react-router-dom").NavigateFunction} navigate - navigate hook (useNavigate) used for changing URL
 * @param {string} id - id to pass to url as a hash (optional)
 * @param {boolean} isFormStarted - whether form has started (optional)
 * @param {function} setFormStarted - function to update the parent's formStarted state (required if isFormStarted set to true)
 */
export const changeUrlByStepNumberv6 = async (step, formConfig, navigate, id, isFormStarted, setFormStarted) => {
  if (isFormStarted) {
    if (!setFormStarted) throw new Error('setFormStarted is a required parameter when setting isFormStarted to true');
    await setFormStarted(true);
  }
  const url = id ? `${formConfig.stepUrls[step]}#${id}` : formConfig.stepUrls[step];
  navigate(url);
};
