import React, { useEffect, useReducer, useCallback, useMemo } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { ConfigurationService } from 'cyc-configuration-service';
import { FeatureFlagsService } from 'cyc-configuration-service';
import { CYCServices } from 'cyc-services';
import FormConfiguration from './form-config';
import { reducer, initialState, actions, fieldNames } from '../incident-form.reducer';
import {
  IncidentFormContainer,
  ContinueFormOrReRoute,
  apiMyAccountUrl,
  apiSettingName,
  clientMyAccountUrl,
  changeUrlByStepNumberv6,
  isEnabled,
  MissingAssetsFlag,
} from 'common';
import * as Services from 'common/services/incident.service';
import {
  incidentLayersOptionsBuilder,
  FieldsBuilder,
  popupTemplateBuilder,
  mapIncidentDetailsToAttributesBuilder,
} from '../helpers/incident-layer-options-builder.helper';
import { HeaderTextFromUrl } from '../helpers/header-text-from-url.helper';
import {
  getTOIDFromPoint,
  getPostcodeFromPointAsync,
  getUSRNFromPoint,
  getParishFromPointAsync,
  getStreetFromPoint,
  getWardFromPoint,
} from 'common/components/map-components/esri-location.service';

import EntryPage from '../components-shared/entry-page.component';
import LocationPageComponent from '../components-shared/location-page.component';
import ProblemPageComponent from './components/problem-page.component';
import SummaryPageComponent from '../components-shared/summary-page.component';
import ConfirmationPageComponent from '../components-shared/confirmation-page.component';
import SignInContainer from 'sign-in/containers/sign-in.container';
import RegistrationApp from '../../registration/registration.app';

const DogAndLitterBins = (props) => {
  let location = useLocation();
  let navigate = useNavigate();
  const formConfig = useMemo(() => new FormConfiguration(), []);

  const initState = useMemo(
    () => initialState({ pageHeaderText: formConfig.pageHeaderText }),
    [formConfig.pageHeaderText]
  );
  const [state, dispatch] = useReducer(reducer, initState);
  const getIncidentsUrl = `${ConfigurationService.getEnvironmentSettingByName(apiSettingName)}${
    apiMyAccountUrl.getIncidents.DOG_AND_LITTER_BINS
  }`;
  const possibleProblems = formConfig.categories;

  // Change the header text on certain steps
  useEffect(() => {
    const indexOfStep = formConfig.stepUrls.indexOf(location.pathname);
    const header = HeaderTextFromUrl({
      url: location.pathname,
      initialHeaderText: formConfig.pageHeaderText,
    });

    dispatch({
      type: actions.updateStateFields,
      payload: {
        [fieldNames.currentStep]: indexOfStep,
        [fieldNames.pageHeaderText]: header,
      },
    });
  }, [dispatch, location.pathname, formConfig.pageHeaderText, formConfig.stepUrls]);

  // ---- ENTRY PAGE ------
  const handleResetForm = useCallback(
    () =>
      dispatch({
        type: actions.resetForm,
        payload: initialState({ pageHeaderText: formConfig.pageHeaderText }),
      }),
    [dispatch, formConfig.pageHeaderText]
  );

  // ---- LOCATION PAGE ------
  const layerOptionsBuilder = () => {
    let fieldsBuilder = new FieldsBuilder();
    fieldsBuilder.addIncidentId();
    fieldsBuilder.addFaultType();
    fieldsBuilder.addIncidentReferenceNumber();
    fieldsBuilder.addCreatedTime();
    const inc = incidentLayersOptionsBuilder({
      mapIncidentDetailsToAttributes: mapIncidentDetailsToAttributesBuilder,
      getIncidentsUrl: getIncidentsUrl,
      mapIncidentLayerId: formConfig.mapIncidentLayerId,
      popupTemplate: popupTemplateBuilder({
        mapIncidentLayerTitle: formConfig.mapIncidentLayerTitle,
        tableDataArray: [
          { header: 'Fault type', data: 'faultType' },
          { header: 'Date created', data: 'createdTime' },
        ],
        showAlreadyReportedMessage: true,
      }),
      fields: fieldsBuilder.getBuilderResult(),
    });
    return inc;
  };

  const featureLayerOptionsBuilder = () => {
    return [
      {
        layerUrl: formConfig.dogAndLitterBinsFeatureLayerUrl,
        layerId: formConfig.dogAndLitterBinsFeatureLayerId,
        popupTemplate: popupTemplateBuilder({
          mapIncidentLayerTitle: 'Selected litter bin',
          tableDataArray: [
            { header: 'Bin Type', data: 'BINTYPE' },
            { header: 'Location', data: 'LOCATION' },
            { header: 'Road name', data: 'STREET' },
            { header: 'Ward', data: 'WARD' },
            { header: 'Item ID', data: 'ITEM_ID' }
          ],
        }),
      },
    ];
  };

  const handleFeatureClicked = async (e) => {
    const toid = getTOIDFromPoint(e.feature.geometry.x, e.feature.geometry.y);
    const street = await getStreetFromPoint(e.feature.geometry.x, e.feature.geometry.y);
    const ward = await getWardFromPoint(e.feature.geometry.x, e.feature.geometry.y);
    const postcode = getPostcodeFromPointAsync(e.feature.geometry.x, e.feature.geometry.y);
    const usrn = getUSRNFromPoint(e.feature.attributes.Street);
    const parish = getParishFromPointAsync(e.feature.geometry.x, e.feature.geometry.y);
    const [toidValue, postcodeValue, usrnValue, parishValue] = await Promise.all([toid, postcode, usrn, parish]);

    // Start Debug
    const itemId = e.feature.attributes.Item_Id;
    console.log('Feature ItemID: ', itemId);
    // End Debug

    // data from location service is more reliable so we try to use that where possible
    // if some of the information is not available, we fall back to the asset layer
    const locationObject = {
      easting: e.feature.geometry.x.toString(),
      northing: e.feature.geometry.y.toString(),
      street: street ? street : e.feature.attributes.Street,
      location: e.feature.attributes.Location,
      toid: toidValue,
      postcode: postcodeValue,
      usrn: usrnValue ? usrnValue.toString() : null,
      ward: ward ? ward : e.feature.attributes.Ward,
      parish: parishValue,
      assetId: e.feature.attributes.Item_Id,
      filter: `Item_Id=${e.feature.attributes.Item_Id}`,
    };
    // Note: Map Server now returns the bin type description in BinType property so match on bin type displayName.
    const binType = Object.values(formConfig.binTypes).find((bin) => bin.displayName.toLowerCase() === e.feature.attributes.BinType.toLowerCase());
    const binTypeId = binType && binType.id;
    dispatch({
      type: actions.updateFormDataFields,
      payload: {
        [fieldNames.binTypeId]: binTypeId,
      },
    });
    dispatch({ type: actions.updateLocationObject, payload: locationObject });
  };

  const getMissingAssetOptions = () => {
    if (isEnabled(FeatureFlagsService.getFeatureFlagByName(MissingAssetsFlag))) {
      return {
        checked: state.missingAssetChecked,
        label: formConfig.missingAssetCheckBoxLabel,
        handleSwitch: () => {
          dispatch({
            type: actions.updateStateFields,
            payload: { [fieldNames.missingAssetChecked]: !state.missingAssetChecked },
          });
        },
        onUploadFiles: (files) => dispatch({ type: actions.updateUploadFiles, payload: files }),
        onRemoveFile: (index) => dispatch({ type: actions.removeUploadedFile, payload: index }),
        uploadedFiles: state.formData.uploadedFiles,
        useUnadoptedLayer: false,
      };
    }
  };

  const locationSelectText = (
    <>
      Search the map by postcode, street name, or your current location, to view nearby dog waste and litter bins.
      <br />
      Use the <span aria-hidden="true" className="esri-icon esri-icon-locate"></span> icon to report a problem at your
      current location.
      <br />
      Select the dog waste and litter bin by clicking on the icon on the map.
      <br />
      Select a reported problem to view details.
    </>
  );

  // --- SUMMARY PAGE ---
  const buildTableData = () => {
    const formData = state.formData;
    const problem = possibleProblems.find((val) => val.id === formData.problemId);
    const binDescription =
      formData.binTypeId && Object.values(formConfig.binTypes).find((bin) => bin.id === formData.binTypeId).displayName;

    const locationColumn = {
      columnOne: 'Location',
      columnTwo:
        formData.locationObject && formData.locationObject.location && formData.locationObject.location.length > 0
          ? formData.locationObject.location
          : 'Selected on map',
      onChangeClicked: () => changeUrlByStepNumberv6(1, formConfig, navigate, 'location'),
    };

    const additionalLocationInformationColumn = {
      columnOne: 'Additional location information',
      columnTwo: formData.additionalInformation,
      onChangeClicked: () => changeUrlByStepNumberv6(1, formConfig, navigate, 'additionalInformation'),
    };

    const streetColumn = {
      columnOne: 'Street',
      columnTwo:
        formData.locationObject && formData.locationObject.streetName && formData.locationObject.streetName.length > 0
          ? formData.locationObject.streetName
          : 'Selected on map',
      onChangeClicked: () => changeUrlByStepNumberv6(1, formConfig, navigate, 'location'),
    };

    const binTypeColumn = {
      columnOne: 'Bin type',
      columnTwo: binDescription ? binDescription : 'Not available',
      onChangeClicked: () => changeUrlByStepNumberv6(1, formConfig, navigate, 'location'),
      columnTwoClassName: 'sentence-case',
    };

    const whatIsTheProblemColumn = {
      columnOne: 'What is the problem?',
      columnTwo: problem && problem.displayName ? problem.displayName : null,
      onChangeClicked: () => changeUrlByStepNumberv6(2, formConfig, navigate, 'whatIsTheProblem'),
      columnTwoClassName: 'sentence-case',
    };

    const uploadPhotoColumn = {
      columnOne: 'Upload a photo',
      columnTwo: formData.uploadedFiles ? (
        <ul className="uploaded-photos">
          {formData.uploadedFiles.map((file, index) => (
            <li key={index}>{file.fileName}</li>
          ))}
        </ul>
      ) : (
        <React.Fragment />
      ),
      onChangeClicked: () => changeUrlByStepNumberv6(2, formConfig, navigate, 'uploadPhoto'),
    };

    const tableData = state.missingAssetChecked
      ? [locationColumn, additionalLocationInformationColumn, whatIsTheProblemColumn, uploadPhotoColumn]
      : [
          locationColumn,
          additionalLocationInformationColumn,
          streetColumn,
          binTypeColumn,
          whatIsTheProblemColumn,
          uploadPhotoColumn,
        ];

    // Only show the "Offensive graffiti" response if there is a value
    if (formData.offensiveGraffiti !== undefined) {
      tableData.splice(state.missingAssetChecked ? 3 : 5, 0, {
        columnOne: 'Is the graffiti offensive?',
        columnTwo: formData.offensiveGraffiti === true ? 'Yes' : 'No',
        onChangeClicked: () => changeUrlByStepNumberv6(2, formConfig, navigate, 'isGraffitiOffensive'),
      });
    }

    return tableData;
  };

  const submitIncident = async () => {
    let form = { ...state.formData };
    form.productId = state.missingAssetChecked ? formConfig.missingAssetProductId : formConfig.productId;
    form.subject = formConfig.subject;

    const problemType = possibleProblems.find((problem) => problem.id === form.problemId);
    if (problemType.alternative) {
      form = { ...form, ...problemType.alternative };
    }

    // Create the incident
    try {
      const result = await Services.createIncident(form);
      if (result.status === 200) {
        dispatch({ type: actions.updateIncidentObject, payload: result.data });
        dispatch({
          type: actions.updateStateFields,
          payload: { [fieldNames.formStarted]: true, [fieldNames.formCompleted]: true },
        });
      }
    } catch {
      return false;
    }
  };

  // This code was breaking after router V6 changes
  // useEffect(() => {
  //   if (state.formCompleted) {
  //     changeUrlByStepNumberv6(4, formConfig, navigate);
  //   }
  // }, [state.formCompleted, navigate, formConfig]);

  // ----- CONFIRMATION PAGE -----
  const sendUpdateIncidentWithUser = async (email) => {
    await Services.updateIncidentWithUser(
      state.incident.referenceNumber,
      state.incident.secretReferenceNumber,
      state.incident.incidentId,
      email
    ).then((result) => {
      if (result.status === 200) {
        return true;
      } else {
        return false;
      }
    });
  };

  const handleUserRegistered = (userData) => {
    dispatch({ type: actions.updateStateFields, payload: { [fieldNames.userLoggedIn]: true } });
    // only update incident when user's email is not confirmed
    // save the incident into temp table while wait for the user to confirm his/her email.
    if (state.formCompleted && userData.isEmailConfirmed === false) {
      sendUpdateIncidentWithUser(userData.email);
    }
  };

  const handleSignInCallBack = () => {
    const isSignedIn = formConfig.userIsLoggedIn();
    if (isSignedIn) {
      sendUpdateIncidentWithUser('');
    }
    // after sign in succssfully, navigate back to incident creation confirmation page
    navigate(formConfig.stepUrls[formConfig.totalSteps]);
  };

  const handleProblemTypeChange = (value) => {
    const problemType = possibleProblems.find((problem) => problem.id === value);
    if (problemType.alternative === undefined) {
      dispatch({ type: actions.updateFormDataFields, payload: { [fieldNames.isAlternative]: false } });
      dispatch({ type: actions.updateFormDataFields, payload: { [fieldNames.offensiveGraffiti]: undefined } });
    } else {
      dispatch({ type: actions.updateFormDataFields, payload: { [fieldNames.isAlternative]: true } });
    }
    dispatch({ type: actions.updateFormDataFields, payload: { [fieldNames.problemId]: value } });
  };

  // --- render builders ----
  const formRouteInfo = () => {
    return {
      formStarted: state.formStarted,
      formCompleted: state.formCompleted,
      formStartUrl: formConfig.stepUrls[0],
      formEndUrl: formConfig.stepUrls[formConfig.totalSteps],
      location: location,
    };
  };

  const locationPageIncidentLayerOptions = layerOptionsBuilder();
  const featureLayerOptions = featureLayerOptionsBuilder();

  return (
    <IncidentFormContainer
      displayProgressBar={() => formConfig.shouldDisplayBasedOnStep(state.currentStep)}
      pageHeaderText={state.pageHeaderText}
      documentTitle={formConfig.documentTitle}
      totalSteps={formConfig.totalSteps}
      currentStep={state.currentStep}
      breadcrumbs={[{ url: clientMyAccountUrl.dogAndLitterBin.root, name: formConfig.pageHeaderText }]}>
      <Routes>
        <Route
          path="/"
          element={
            <EntryPage
              report={formConfig.whatToReportText}
              informationArray={formConfig.beforeYouBeginText}
              goToNextPage={() =>
                changeUrlByStepNumberv6(1, formConfig, navigate, false, true, () =>
                  dispatch({
                    type: actions.updateStateFields,
                    payload: { [fieldNames.formStarted]: true, [fieldNames.formCompleted]: false },
                  })
                )
              }
              onResetForm={handleResetForm}
            />
          }
        />
        <Route
          path={formConfig.baseUrl.step1}
          element={
            <ContinueFormOrReRoute {...formRouteInfo()}>
              <LocationPageComponent
                goToNext={() => changeUrlByStepNumberv6(2, formConfig, navigate)}
                goToPrevious={() => changeUrlByStepNumberv6(0, formConfig, navigate)}
                incidentLayerOptions={locationPageIncidentLayerOptions}
                featureLayerOptions={featureLayerOptions}
                handleFeatureClicked={handleFeatureClicked}
                locationObject={state.formData.locationObject}
                locationSelected={state.formData.locationSelected}
                clearSelectedPoint={() => {
                  dispatch({
                    type: actions.updateFormDataFields,
                    payload: {
                      [fieldNames.binTypeId]: undefined,
                    },
                  });
                  dispatch({ type: actions.resetLocation });
                }}
                disableFreeSelect={true}
                locationSelectText={locationSelectText}
                additionalInformation={state.formData.additionalInformation}
                onAdditionalInformationChange={(e) =>
                  dispatch({
                    type: actions.updateFormDataFields,
                    payload: { [fieldNames.additionalInformation]: e.target.value },
                  })
                }
                onMapSelected={(location) => dispatch({ type: actions.updateLocationObject, payload: location })}
                enablePointSelectionZoomLevels={formConfig.enablePointSelectionZoomLevels}
                missingAssetsOptions={getMissingAssetOptions()}
              />
            </ContinueFormOrReRoute>
          }
        />
        <Route
          path={formConfig.baseUrl.step2}
          element={
            <ContinueFormOrReRoute {...formRouteInfo()}>
              <ProblemPageComponent
                goToPrevious={() => changeUrlByStepNumberv6(1, formConfig, navigate)}
                goToNext={() => changeUrlByStepNumberv6(3, formConfig, navigate)}
                onUploadFiles={(files) => dispatch({ type: actions.updateUploadFiles, payload: files })}
                onRemoveFile={(index) => dispatch({ type: actions.removeUploadedFile, payload: index })}
                problemId={state.formData.problemId}
                onProblemTypeChange={handleProblemTypeChange}
                isGraffiti={state.formData.isAlternative}
                offensiveGraffiti={state.formData.offensiveGraffiti}
                onOffensiveGraffitiChange={(isOffensive) =>
                  dispatch({
                    type: actions.updateFormDataFields,
                    payload: { [fieldNames.offensiveGraffiti]: isOffensive },
                  })
                }
                uploadedFiles={state.formData.uploadedFiles}
                possibleProblems={possibleProblems}
              />
            </ContinueFormOrReRoute>
          }
        />
        <Route
          path={formConfig.baseUrl.step3}
          element={
            <ContinueFormOrReRoute {...formRouteInfo()}>
              <SummaryPageComponent
                goToProblemPage={() => changeUrlByStepNumberv6(2, formConfig, navigate)}
                submitIncident={submitIncident}
                tableData={buildTableData()}
              />
            </ContinueFormOrReRoute>
          }
        />
        <Route
          path={formConfig.baseUrl.confirmation}
          element={
            <ContinueFormOrReRoute {...formRouteInfo()}>
              <ConfirmationPageComponent
                referenceNumber={state.incident.referenceNumber}
                onSignInClicked={() => navigate(formConfig.baseUrl.root + clientMyAccountUrl.signIn)}
                onRegisterClicked={() => navigate(formConfig.baseUrl.root + clientMyAccountUrl.register)}
                userLoggedIn={() => CYCServices.JWTAuth.isAuthenticated()}
                goToMyAccount={() => navigate(clientMyAccountUrl.dashboard)}
                restartFormUrl={formConfig.baseUrl.root}
              />
            </ContinueFormOrReRoute>
          }
        />
        <Route
          path={clientMyAccountUrl.signIn}
          element={<SignInContainer onSuccessfulSignIn={handleSignInCallBack} />}
        />
        <Route
          path={`${clientMyAccountUrl.register}/*`}
          element={
            <RegistrationApp
              onSuccessfulRegistration={handleUserRegistered}
              extraSuccessfulContent={<p>Click 'Continue' to view the reference number for your reported problem.</p>}
              onContinueClicked={() => navigate(formConfig.baseUrl.root + formConfig.baseUrl.confirmation)}
            />
          }
        />
      </Routes>
    </IncidentFormContainer>
  );
};

export default DogAndLitterBins;
