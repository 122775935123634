import WithAuthentication from 'common/components/with-authentication/with-authentication.component';
import React from 'react';
import { Link } from 'react-router-dom';

import {
  PageHeaderComponent,
  MyAccountPageComponent,
  DocumentTitleComponent,
  clientMyAccountUrl,
} from '../../../common';

const ChangeNameConfirmationComponent = () => (
  <WithAuthentication>
    <DocumentTitleComponent title="My account - change name">
      <PageHeaderComponent text="Change of name confirmation" icon="icon-website" />
      <MyAccountPageComponent>
        <p>Thank you for submitting your change of name details.</p>

        <p>
          Back to <Link to={clientMyAccountUrl.dashboard}>My Account</Link>.
        </p>
      </MyAccountPageComponent>
    </DocumentTitleComponent>
  </WithAuthentication>
);

export default ChangeNameConfirmationComponent;
