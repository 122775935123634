import React from 'react';
import PropTypes from 'prop-types';

import './confirm-dialog.component.scss';
import { ButtonComponent, ButtonClasses } from 'cyc-react-ui-components';

/**
 * A simple confirm dialog wcomponent
 *
 * props
 * @param {function} onClose - Callback for when the dialog is closed
 * @param {bool} isOpen - Whether dialog is open
 * @param {string=} confirmText - overrides the default confirmation button text
 * @param {string=} cancelText - overrides the default cancel button text
 * @param {string} title - The title text for the dialog
 *
 */
const ConfirmDialog = (props) => {
  const { onClose, isOpen, confirmText, cancelText, title, children } = props;

  const handleConfirm = () => {
    onClose(true);
  };

  const handleCancel = () => {
    onClose(false);
  };

  return (
    <React.Fragment>
      {isOpen && (
        <div className="dialog-background">
          <div className="dialog">
            <div className="dialog__header">
              <h2>{title}</h2>
            </div>
            <div className="dialog__body">{children}</div>
            <div>
              <ButtonComponent onClick={handleConfirm} type="button" className={ButtonClasses.primary + ' float-right'}>
                {confirmText || 'Yes'}
              </ButtonComponent>
              <ButtonComponent onClick={handleCancel} type="button" className="float-left">
                {cancelText || 'No'}
              </ButtonComponent>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

ConfirmDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

export default ConfirmDialog;
