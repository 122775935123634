import React from 'react';
import { Link } from 'react-router-dom';

import { MyAccountPageComponent, PageHeaderComponent, clientMyAccountUrl as urls } from '../../common';

const TestLandingPage = () => {
  return (
    <React.Fragment>
      <PageHeaderComponent text="Test site landing page" />
      <MyAccountPageComponent>
        <p>This is the test site landing page for My Account.</p>
        <p>Use the links below to access the relevant pages for testing.</p>

        <h2>Online forms</h2>
        <ul>
          <li>
            <Link to={urls.streetLighting.root}>Report a street light problem</Link>
          </li>
          <li>
            <Link to={urls.streetCleaning.root}>Report a street cleaning problem</Link>
          </li>
          <li>
            <Link to={urls.flyTipping.root}>Report a fly-tipping problem</Link>
          </li>
          <li>
            <Link to={urls.graffiti.root}>Report a graffiti problem</Link>
          </li>
          <li>
            <Link to={urls.roadAndPavementProblems.root}>Report a road, pavement or path problem</Link>
          </li>
          <li>
            <Link to={urls.saltBinProblems.root}>Report a salt bin problem</Link>
          </li>
          <li>
            <Link to={urls.dogAndLitterBin.root}>Report a dog waste and litter bin problem</Link>
          </li>
          <li>
            <Link to={urls.waterAndDrainageProblems.root}>Report a water and drainage problem</Link>
          </li>
          <li>
            <Link to={urls.streetFurniture.root}>Report a street furniture problem</Link>
          </li>
          <li>
            <Link to={urls.roadAndStreetSign.root}>Report a road and street sign problem</Link>
          </li>
          <li>
            <Link to={urls.vegetation.root}>Report a vagetation problem</Link>
          </li>
        </ul>

        <h2>My Account</h2>
        <ul>
          <li>
            <Link to={urls.registration.root}>Register</Link>
          </li>
          <li>
            <Link to={urls.signIn}>Sign in</Link>
          </li>
          <li>
            <Link to={urls.dashboard}>Dashboard</Link>
          </li>
          <li>
            <Link to={urls.webchat.root}>Webchat</Link>
          </li>
        </ul>

        <h2>Waste</h2>
        <ul>
          <li>
            <Link to={urls.binCollections.root}>Waste collection calendar</Link>
          </li>
        </ul>
      </MyAccountPageComponent>
    </React.Fragment>
  );
};

TestLandingPage.propTypes = {};

export default TestLandingPage;
