import React from 'react';
import { Form, withFormik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CYCServices } from 'cyc-services';
import { ConfigurationService } from 'cyc-configuration-service';
import { ButtonComponent as Button, ButtonClasses, TableComponent, TableClasses } from 'cyc-react-ui-components';

import './web-chat-form-my-account.component.scss';
import { clientMyAccountUrl as urls, apiMyAccountUrl } from '../../../common';
import { FaRegEdit } from 'react-icons/fa';

const WebChatFormMyAccount = ({ isSubmitting, firstName, lastName, email }) => {
  const navigate = useNavigate();
  const signOut = () => {
    const signOutPath = `${ConfigurationService.store.apiUrl}${apiMyAccountUrl.ACCOUNT_LOGOUT}`;
    CYCServices.JWTAuth.signOut(signOutPath);
    navigate(urls.signIn);
  };

  return (
    <React.Fragment>
      <div className="row">
        <div className="col">
          <p>You're signed in with My Account</p>
          <TableComponent className={TableClasses.restyle}>
            <thead>
              <tr>
                <th>Field</th>
                <th>Value</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>First name</td>
                <td>{firstName}</td>
                <td></td>
              </tr>
              <tr>
                <td>Last name</td>
                <td>{lastName}</td>
                <td></td>
              </tr>
              <tr>
                <td>Email address</td>
                <td>{email}</td>
                <td>
                  <Link to={urls.account.root + urls.account.email.change} title="Change email address">
                    <FaRegEdit title={'Change email'} />
                  </Link>
                </td>
              </tr>
            </tbody>
          </TableComponent>
          <p>
            Not you?{' '}
            <button className="in-text-button" onClick={signOut}>
              Sign out
            </button>
          </p>
        </div>
      </div>
      <Form>
        <div className="row">
          <div className="col-12">
            <Button type="submit" className={ButtonClasses.primary + ' float-right'} disabled={isSubmitting}>
              Confirm
            </Button>
          </div>
        </div>
      </Form>
    </React.Fragment>
  );
};

WebChatFormMyAccount.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
};

export default withFormik({
  mapPropsToValues: (props) => props,
  handleSubmit(values, bag) {
    bag.props.onSubmit(values);
  },
})(WebChatFormMyAccount);
