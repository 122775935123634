import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';

import {
  InputErrorComponent,
  ButtonComponent as Button,
  ButtonClasses,
  RadiobuttonInputComponent,
} from 'cyc-react-ui-components';

import { ErrorBoxComponent, ErrorLabelHandlerComponent } from '../../../common';

const RegStepOneForm = ({
  values,
  errors,
  touched,
  handleSubmit,
  setFieldValue,
  isSubmitting,
  submitCount,
  isValid,
}) => {
  let errorBoxRef = React.createRef();
  const previousSubmitCount = React.useRef(0);

  useEffect(() => {
    if (Object.keys(errors).length > 0 && submitCount > previousSubmitCount.current) {
      previousSubmitCount.current = submitCount;
      const errorBox = ReactDOM.findDOMNode(errorBoxRef.current);
      errorBox.scrollIntoView();
    }
  }, [errors, errorBoxRef, submitCount, previousSubmitCount]);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <div ref={errorBoxRef}></div>
      {Object.keys(errors).length > 0 && submitCount > 0 && touched.shareContactDetails && <ErrorBoxComponent />}
      <ErrorLabelHandlerComponent
        tag="h3"
        touched={touched}
        errors={errors}
        text="Would you like to receive relevant information from City of York Council?"
        id="shareContactDetails"
      />

      <div id="shareContactDetails">
        <div>
          <RadiobuttonInputComponent
            name="shareContactDetails"
            value={values.shareContactDetails}
            options={[
              {
                label: 'Yes, I agree to my contact details being used for this purpose',
                value: true,
              },
              {
                label: 'No, I do not agree to my contact details being used for this purpose',
                value: false,
              },
            ]}
            onChange={(e) => setFieldValue('shareContactDetails', e)}
            error={errors.shareContactDetails && touched.shareContactDetails && submitCount > 0}
          />
          {errors.shareContactDetails && touched.shareContactDetails && submitCount > 0 && (
            <InputErrorComponent>{errors.shareContactDetails}</InputErrorComponent>
          )}
        </div>
      </div>

      <br />
      <p>Once registered, you can sign in to My Account to change your preferences at any time.</p>
      <br />

      <div className="row">
        <div className="col">
          <Button type="submit" className={ButtonClasses.primary + ' float-right'} disabled={isSubmitting}>
            Begin this form
          </Button>
        </div>
      </div>
    </form>
  );
};

const RegistrationStepOneComponent = withFormik({
  mapPropsToValues({ shareContactDetails, onSubmitSuccess }) {
    return {
      shareContactDetails: shareContactDetails,
      onSubmitSuccess: onSubmitSuccess,
    };
  },
  validationSchema: Yup.object().shape({
    shareContactDetails: Yup.bool()
      .nullable()
      .required(
        "Please supply details for 'Would you like to receive relevant information from City of York Council?'"
      ),
  }),
  async handleSubmit(values, { setSubmitting }) {
    setSubmitting(false);
    await values.onSubmitSuccess(values.shareContactDetails);
  },
})(RegStepOneForm);

RegistrationStepOneComponent.propTypes = {
  shareContactDetails: PropTypes.bool,
  onSubmitSuccess: PropTypes.func.isRequired,
};

export { RegistrationStepOneComponent, RegStepOneForm };
