import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import './page-header.component.scss';
import SiteBreadcrumbsComponent from '../route-handlers/site-breadcrumbs.component';

const PageHeaderComponent = ({ text, icon, breadcrumbs }) => {
  const passableCrumbs = useMemo(() => {
    if (breadcrumbs !== undefined) {
      return breadcrumbs;
    }
    // Check if the alternative is already My Account, ignore it as it already exists
    if (breadcrumbs === undefined && text === 'My Account') {
      return undefined;
    }
    return [{ url: '', name: text }];
  }, [breadcrumbs, text]);

  const Icon = ({ icon }) => (icon !== undefined ? <i className={icon + ' icon-large'} /> : null);

  return (
    <div className="page-header">
      <div className="flex-container">
        <div className="page-header__content">
          {/* Display icon to the left if there is one */}
          <Icon icon={icon} />

          {/* Main header content are */}
          {/* Title */}
          <div className="page-header__search">
            <h1 className="text-left" data-testid="PageHeaderComponentH1">
              {text}
            </h1>
            <SiteBreadcrumbsComponent breadcrumbs={passableCrumbs} />
          </div>
        </div>
      </div>
    </div>
  );
};

PageHeaderComponent.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.string,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      name: PropTypes.string.isRequired,
    })
  ),
};

export default PageHeaderComponent;
