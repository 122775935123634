import React, { useEffect, useCallback, useReducer, useMemo } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { CYCServices } from 'cyc-services';
import { ConfigurationService } from 'cyc-configuration-service';
import * as Services from '../../common/services/incident.service';
import {
  ContinueFormOrReRoute,
  IncidentFormContainer,
  clientMyAccountUrl,
  apiMyAccountUrl,
  apiSettingName,
  changeUrlByStepNumberv6,
} from '../../common';
import { FormConfiguration } from './form-config';
import { actions, fieldNames, initialState, reducer } from 'incident-forms/incident-form.reducer';

// Pages
import EntryPage from '../components-shared/entry-page.component';
import LocationPageComponent from '../components-shared/location-page.component';
import ProblemPage from './components/problem-page.component';
import SummaryPageComponent from '../components-shared/summary-page.component';
import ConfirmationPage from '../components-shared/confirmation-page.component';
import SignInPage from '../../sign-in/containers/sign-in.container';
import RegistrationApp from '../../registration/registration.app';
import { HeaderTextFromUrl } from 'incident-forms/helpers/header-text-from-url.helper';
import {
  FieldsBuilder,
  incidentLayersOptionsBuilder,
  popupTemplateBuilder,
  mapIncidentDetailsToAttributesBuilder,
} from 'incident-forms/helpers/incident-layer-options-builder.helper';

const SaltBinProblemsApp = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const formConfig = useMemo(() => new FormConfiguration(), []);
  const initState = useMemo(
    () => initialState({ pageHeaderText: formConfig.pageHeaderText }),
    [formConfig.pageHeaderText]
  );
  const [state, dispatch] = useReducer(reducer, initState);

  const possibleProblems = formConfig.getSaltBinsCategories();
  const getIncidentsUrl = `${ConfigurationService.getEnvironmentSettingByName(apiSettingName)}${
    apiMyAccountUrl.getIncidents.SALT_BIN_PROBLEMS
  }`;

  // This effect is for updating headers and step urls
  useEffect(() => {
    const indexOfStep = formConfig.stepUrls.indexOf(location.pathname);

    // Update the header based on the url.
    const header = HeaderTextFromUrl({
      url: location.pathname,
      initialHeaderText: formConfig.pageHeaderText,
    });
    dispatch({
      type: actions.updateStateFields,
      payload: { [fieldNames.currentStep]: indexOfStep, [fieldNames.pageHeaderText]: header },
    });

    // Change the step number based on the url.
    dispatch({ type: actions.updateStateFields, payload: { [fieldNames.currentStep]: indexOfStep } });
  }, [location.pathname, formConfig.pageHeaderText, formConfig.stepUrls]);

  // --- ENTRY PAGE ---
  const handleResetForm = useCallback(() => dispatch({ type: actions.resetForm, payload: initState }), [initState]);

  // ----- LOCATION PAGE -----
  const layerOptionsBuilder = () => {
    let fieldsBuilder = new FieldsBuilder();
    fieldsBuilder.addFaultType();
    fieldsBuilder.addCreatedTime('Created');
    const inc = incidentLayersOptionsBuilder({
      mapIncidentDetailsToAttributes: mapIncidentDetailsToAttributesBuilder,
      getIncidentsUrl: getIncidentsUrl,
      mapIncidentLayerId: formConfig.mapIncidentLayerId,
      popupTemplate: popupTemplateBuilder({
        mapIncidentLayerTitle: formConfig.mapIncidentLayerTitle,
        tableDataArray: [
          { header: 'Fault type', data: 'faultType' },
          { header: 'Created', data: 'createdTime' },
        ],
      }),
      fields: fieldsBuilder.builder,
    });
    return inc;
  };

  const clearSelectedFeature = () => {
    dispatch({ type: actions.resetLocation });
    dispatch({ type: actions.updateStateFields, payload: { [fieldNames.unitType]: null } });
  };

  // ----- PROBLEM PAGE -----

  // ----- SUMMARY PAGE ----

  const buildTableData = () => {
    const { formData } = state;
    const problem = possibleProblems && possibleProblems.find((problem) => problem.id === formData.problemId);
    return problem
      ? [
          {
            columnOne: 'Location',
            columnTwo:
              formData.locationObject.streetName && formData.locationObject.streetName.length > 0
                ? formData.locationObject.streetName
                : 'Selected on map',
            onChangeClicked: () => changeUrlByStepNumberv6(1, formConfig, navigate, 'location'),
          },
          {
            columnOne: 'Additional location information',
            columnTwo: formData.additionalInformation,
            onChangeClicked: () => changeUrlByStepNumberv6(1, formConfig, navigate, 'additionalInformation'),
          },
          {
            columnOne: 'What is the problem?',
            columnTwo: problem.displayName,
            onChangeClicked: () => changeUrlByStepNumberv6(2, formConfig, navigate, 'whatIsTheProblem'),
            columnTwoClassName: 'sentence-case',
          },
          {
            columnOne: 'Upload a photo',
            columnTwo: formData.uploadedFiles ? (
              <ul className="uploaded-photos">
                {formData.uploadedFiles.map((file, index) => (
                  <li key={index}>{file.fileName}</li>
                ))}
              </ul>
            ) : (
              <React.Fragment />
            ),
            onChangeClicked: () => changeUrlByStepNumberv6(2, formConfig, navigate, 'uploadPhoto'),
          },
        ]
      : [];
  };

  const submitIncident = async () => {
    const form = { ...state.formData };
    form.productId = formConfig.productId;
    form.subject = formConfig.subject;

    // Create the incident
    try {
      const result = await Services.createIncident(form);
      if (result.status === 200) {
        dispatch({ type: actions.updateIncidentObject, payload: result.data });
        dispatch({
          type: actions.updateStateFields,
          payload: { [fieldNames.formStarted]: true, [fieldNames.formCompleted]: true },
        });
      }
    } catch {
      return false;
    }
  };

  // ----- CONFIRMATION PAGE -----
  const sendUpdateIncidentWithUser = async (email) => {
    await Services.updateIncidentWithUser(
      state.incident.referenceNumber,
      state.incident.secretReferenceNumber,
      state.incident.incidentId,
      email
    ).then((result) => {
      return result.status === 200 ? true : false;
    });
  };

  const handleUserRegistered = (userData) => {
    dispatch({ type: actions.updateStateFields, payload: { [fieldNames.userLoggedIn]: true } });
    // only update incident when user's email is not confirmed
    // save the incident into temp table while wait for the user to confirm his/her email.
    if (state.formCompleted && userData.isEmailConfirmed === false) {
      sendUpdateIncidentWithUser(userData.email);
    }
  };

  const handleSignInCallBack = () => {
    const isSignedIn = formConfig.userIsLoggedIn();
    if (isSignedIn) {
      sendUpdateIncidentWithUser('');
    }
    // after sign in succssfully, navigate back to incident creation confirmation page
    navigate(formConfig.stepUrls[formConfig.totalSteps]);
  };

  // --- render builders ----
  const formRouteInfo = {
    formStarted: state.formStarted,
    formCompleted: state.formCompleted,
    formStartUrl: formConfig.stepUrls[0],
    formEndUrl: formConfig.stepUrls[formConfig.totalSteps],
    location: location,
  };

  return (
    <IncidentFormContainer
      displayProgressBar={() => formConfig.shouldDisplayBasedOnStep(state.currentStep)}
      pageHeaderText={state.pageHeaderText}
      documentTitle={formConfig.documentTitle}
      totalSteps={formConfig.totalSteps}
      currentStep={state.currentStep}
      breadcrumbs={[{ url: clientMyAccountUrl.saltBinProblems.root, name: formConfig.pageHeaderText }]}>
      <Routes>
        <Route
          path="/"
          element={
            <EntryPage
              report={{
                text: 'problems with a salt bin',
                url: 'https://www.york.gov.uk/saltbins',
              }}
              informationArray={[
                {
                  text: 'salt bin problems we cannot help with',
                  url: 'https://www.york.gov.uk/saltbins#problemswecannothelp',
                },
              ]}
              goToNextPage={() => {
                dispatch({
                  type: actions.updateStateFields,
                  payload: { [fieldNames.formStarted]: true, [fieldNames.formCompleted]: false },
                });
                changeUrlByStepNumberv6(1, formConfig, navigate);
              }}
              onResetForm={handleResetForm}
              additionalInformation={
                <p>
                  Do not use this form to report snow, ice, or frost on roads, pavements, or cycle tracks; we plan for
                  winter weather and maintain{' '}
                  <a href="https://www.york.gov.uk/gritting#grittingroutemap">gritting routes</a> between November and
                  April.
                </p>
              }
            />
          }
        />

        <Route
          path={formConfig.baseUrl.step1}
          element={
            <ContinueFormOrReRoute {...formRouteInfo}>
              <LocationPageComponent
                goToPrevious={() => changeUrlByStepNumberv6(0, formConfig, navigate)}
                goToNext={() => changeUrlByStepNumberv6(2, formConfig, navigate)}
                incidentLayerOptions={layerOptionsBuilder()}
                locationObject={state.formData.locationObject}
                locationSelected={state.formData.locationSelected}
                onMapSelected={(location) => dispatch({ type: actions.updateLocationObject, payload: location })}
                onAdditionalInformationChange={(e) =>
                  dispatch({
                    type: actions.updateFormDataFields,
                    payload: { [fieldNames.additionalInformation]: e.target.value },
                  })
                }
                additionalInformation={state.formData.additionalInformation}
                clearSelectedPoint={clearSelectedFeature}
                enablePointSelectionZoomLevels={formConfig.enablePointSelectionZoomLevels}
              />
            </ContinueFormOrReRoute>
          }
        />

        <Route
          path={formConfig.baseUrl.step2}
          element={
            <ContinueFormOrReRoute {...formRouteInfo}>
              <ProblemPage
                goToPrevious={() => changeUrlByStepNumberv6(1, formConfig, navigate)}
                goToNext={() => changeUrlByStepNumberv6(3, formConfig, navigate)}
                problemId={state.formData.problemId}
                onProblemTypeChange={(value) => {
                  dispatch({
                    type: actions.updateFormDataFields,
                    payload: { [fieldNames.problemId]: value },
                  });
                }}
                uploadedFiles={state.formData.uploadedFiles}
                onUploadFiles={(files) => dispatch({ type: actions.updateUploadFiles, payload: files })}
                onRemoveFile={(index) => dispatch({ type: actions.removeUploadedFile, payload: index })}
                possibleProblems={possibleProblems}
              />
            </ContinueFormOrReRoute>
          }
        />

        <Route
          path={formConfig.baseUrl.step3}
          element={
            <ContinueFormOrReRoute {...formRouteInfo}>
              <SummaryPageComponent
                goToProblemPage={() => changeUrlByStepNumberv6(2, formConfig, navigate)}
                submitIncident={submitIncident}
                tableData={buildTableData()}
              />
            </ContinueFormOrReRoute>
          }
        />

        <Route
          path={formConfig.baseUrl.confirmation}
          element={
            <ContinueFormOrReRoute {...formRouteInfo}>
              <ConfirmationPage
                referenceNumber={state.incident.referenceNumber}
                onSignInClicked={() => navigate(clientMyAccountUrl.saltBinProblems.root + clientMyAccountUrl.signIn)}
                onRegisterClicked={() =>
                  navigate(clientMyAccountUrl.saltBinProblems.root + clientMyAccountUrl.register)
                }
                userLoggedIn={() => CYCServices.JWTAuth.isAuthenticated()}
                goToMyAccount={() => navigate(clientMyAccountUrl.dashboard)}
                restartFormUrl={formConfig.stepUrls[0]}
              />
            </ContinueFormOrReRoute>
          }
        />

        <Route path={clientMyAccountUrl.signIn} element={<SignInPage onSuccessfulSignIn={handleSignInCallBack} />} />
        <Route
          path={`${clientMyAccountUrl.register}/*`}
          element={
            <RegistrationApp
              onSuccessfulRegistration={handleUserRegistered}
              extraSuccessfulContent={<p>Click 'Continue' to view the reference number for your reported problem.</p>}
              onContinueClicked={() => navigate(formConfig.baseUrl.root + formConfig.baseUrl.confirmation)}
            />
          }
        />
      </Routes>
    </IncidentFormContainer>
  );
};

export default SaltBinProblemsApp;
