import { CYCServices } from 'cyc-services';
import { ConfigurationService } from 'cyc-configuration-service';

import { clientMyAccountUrl, categoryIdsSettingName, processCategoryIdsSettingName } from '../../common';
import { mapConstants } from '../../common/constants/map.constants';

export default class FormConfiguration {
  baseUrl = clientMyAccountUrl.streetCleaning;
  beforeYouBeginText = [
    {
      text: 'which street cleaning problems we cannot help with',
      url: 'https://www.york.gov.uk/ReportStreetCleaning#problemswecannothelp',
    },
    {
      text: 'our street cleaning response times',
      url: 'https://www.york.gov.uk/ReportStreetCleaning#responsetimes',
    },
  ];
  documentTitle = 'My Account - Report a street cleaning problem';
  enablePointSelectionZoomLevels = mapConstants.enablePointSelectionZoomLevels;
  mapIncidentLayerId = 'streetCleaningIncidents';
  mapIncidentLayerTitle = 'Problem description';
  pageHeaderText = 'Report a street cleaning problem';
  productId = 94;
  stepUrls = [
    `${this.baseUrl.root}`,
    `${this.baseUrl.root + this.baseUrl.step1}`,
    `${this.baseUrl.root + this.baseUrl.step2}`,
    `${this.baseUrl.root + this.baseUrl.step3}`,
    `${this.baseUrl.root + this.baseUrl.confirmation}`,
  ];
  subject = 'Street cleaning';
  totalSteps = 4;
  userIsLoggedIn = CYCServices.JWTAuth.isAuthenticated;
  whatToReportText = {
    text: 'problems with street cleaning',
    url: 'https://www.york.gov.uk/ReportStreetCleaning',
  };

  // Values from configuration service
  streetCleaningCategories = {};

  constructor() {
    this.streetCleaningCategories = Object.values(
      ConfigurationService.getDrillDownSettingValue([
        categoryIdsSettingName,
        processCategoryIdsSettingName.streetCleaning,
      ])
    );
  }

  shouldDisplayBasedOnStep = (number) => number > 0 && number <= this.totalSteps;
}

export const testName = 'Street Cleaning';
