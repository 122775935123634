import React, { useState, useEffect } from 'react';

import { getContactDetailAsync } from '../../services/account.service';
import {
  NetworkLoaderComponent,
  DocumentTitleComponent,
  PageHeaderComponent,
  MyAccountPageComponent,
} from '../../../common';

import AccountDetailsComponent from '../components/account-details.component';
import '../account-details.scss';
import WithAuthentication from 'common/components/with-authentication/with-authentication.component';

const AccountDetailsContainer = () => {
  const [accountDetails, setAccountDetails] = useState({});
  const [accountDetailsPromise, setAccountDetailsPromise] = useState();
  useEffect(() => {
    const accountDetailsPromise = getContactDetailAsync(true);
    setAccountDetailsPromise(accountDetailsPromise);

    accountDetailsPromise.then((response) => setAccountDetails(response.data));
  }, []);

  return (
    <WithAuthentication>
      <PageHeaderComponent text="Contact details" icon="icon-website" />
      <MyAccountPageComponent>
        <DocumentTitleComponent title="My account - contact details">
          {accountDetailsPromise !== undefined && (
            <NetworkLoaderComponent request={accountDetailsPromise}>
              <AccountDetailsComponent results={accountDetails} />
            </NetworkLoaderComponent>
          )}
        </DocumentTitleComponent>
      </MyAccountPageComponent>
    </WithAuthentication>
  );
};

export default AccountDetailsContainer;
