import { ConfigurationService } from 'cyc-configuration-service';

export const categoryIdsSettingName = 'categoryIds';
export const roadDefectTypeIdsSettingName = 'roadDefectTypeIds';
export const lightingTypesSettingName = 'lightingTypes';
export const waterandDrainageTypesSettingName = 'waterAndDrainageTypes';
export const surfaceWaterQuestionsSettingName = 'surfaceWaterQuestions';
export const streetFurnitureSettingName = 'streetFurniture';
export const dogAndLitterBinTypesSettingName = 'binTypes';
export const graffitiSettingName = 'graffiti';
export const vegetationSettingName = 'vegetation';
export const vegetationTypesSettingName = 'vegetationTypes';
export const waterAndDrainageTypeSettingsNames = {
  gully: 'gully',
  drain: 'drain',
  ditch: 'ditch',
  manholeCover: 'manholeCover',
  surfaceWater: 'surfaceWater',
};

export const processCategoryIdsSettingName = {
  roadAndPavementProblems: 'roadAndPavementProblems',
  saltBins: 'saltBins',
  graffiti: 'graffiti',
  flyTipping: 'flyTipping',
  streetCleaning: 'streetCleaning',
  streetLighting: 'streetLighting',
  dogAndLitterBins: 'dogAndLitterBins',
  waterAndDrainage: 'waterAndDrainage',
  roadAndStreetSign: 'roadAndStreetSign',
};

export const categorySettingNames = {
  roadAndPavementProblems: {
    parkingOnVerge: 'parkingOnVerge',
    illegalCrossing: 'illegalCrossing',
    damagedOrRutted: 'damagedOrRutted',
    obstruction: 'obstruction',
    verge: 'verge',
    collapsed: 'collapsed',
    pothole: 'pothole',
    dippedOrCracking: 'dippedOrCracking',
    unevenFlag: 'unevenFlag',
    mud: 'mud',
    damaged: 'damaged',
    missing: 'missing',
    generalCondition: 'generalCondition',
  },
  saltBins: {
    empty: 'empty',
    brokenOrDamaged: 'brokenOrDamaged',
    litterInside: 'litterInside',
  },
  graffiti: {
    ancientMonument: 'ancientMonument',
    barWall: 'barWall',
    fenceOrBoundaryWall: 'fenceOrBoundaryWall',
    bridge: 'bridge',
    bollard: 'bollard',
    busShelterOrStop: 'busShelterOrStop',
    bin: 'bin',
    playEquipment: 'playEquipment',
    houseOrBuilding: 'houseOrBuilding',
    sign: 'sign',
    bench: 'bench',
    utilityBox: 'utilityBox',
  },
  flyTipping: {
    asbestos: 'asbestos',
    blackBags: 'blackBags',
    buildingOrConstructionDebris: 'buildingOrConstructionDebris',
    chemicalDrums: 'chemicalDrums',
    electricalEquipment: 'electricalEquipment',
    furniture: 'furniture',
    gardenWaste: 'gardenWaste',
    whiteGoods: 'whiteGoods',
    vehicleParts: 'vehicleParts',
    tyres: 'Tyres',
    other: 'other',
  },
  streetCleaning: {
    deadAnimal: 'deadAnimal',
    detritus: 'detritus',
    faeces: 'faeces',
    floodDebris: 'floodDebris',
    glass: 'glass',
    litter: 'litter',
    needles: 'needles',
    paint: 'paint',
    vomit: 'vomit',
  },
  streetLighting: {
    unlit: 'unlit',
    buzzing: 'buzzing',
    corrodedColumn: 'corrodedColumn',
    wiresExposed: 'wiresExposed',
    doorOff: 'doorOff',
    fallenDown: 'fallenDown',
    flickering: 'flickering',
    hangingLanternOrSign: 'hangingLanternOrSign',
    hitOrLeaning: 'hitOrLeaning',
    litDuringDaylight: 'litDuringDaylight',
    missingParts: 'missingParts',
    tooDimOrBright: 'tooDimOrBright',
    missingStreetLight: 'missingStreetLight',
  },
  dogAndLitterBins: {
    fallenOver: 'fallenOver',
    overflowing: 'overflowing',
    broken: 'broken',
  },
  streetFurniture: {
    damaged: 'damaged',
    hitOrLeaning: 'hitOrLeaning',
    missing: 'missing',
    missingBollardFlap: 'missingBollardFlap',
    missingRemovableBollard: 'missingRemovableBollard',
    damagedGlassPresent: 'damagedGlassPresent',
    damagedNoGlassNearby: 'damagedNoGlassNearby',
    requiresCleaning: 'requiresCleaning',
  },
};

export const categoriesToIgnoreInReports = [
  {
    settingName: categorySettingNames.roadAndPavementProblems.generalCondition,
    drillDownArray: [categoryIdsSettingName, processCategoryIdsSettingName.roadAndPavementProblems],
  },
];
