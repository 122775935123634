import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Form, withFormik } from 'formik';
import * as Yup from 'yup';
import { CheckboxInputComponent } from 'cyc-react-ui-components';
import { getUnadoptedRoadsFeatureOptions, isUnadoptedRoad } from '../helpers/unadopted-layer.helper';
import {
  ButtonComponent as Button,
  ButtonClasses,
  InputLabelComponent,
  InputErrorComponent,
  HelpTextComponent,
} from 'cyc-react-ui-components';

import {
  ADDITIONAL_INFORMATION_MAX_LENGTH,
  CycEsriMapComponent,
  ErrorBoxComponent,
  FileInputComponent,
  msgConstants,
  setInitialPosition,
} from 'common';
import { AdditionalInformationComponent } from '../../common/components/form-components/additional-information.component';
import './location-page.component.scss';

const LocationPage = (props) => {
  let errorBoxRef = React.createRef();
  const previousSubmitCount = React.useRef(0);
  let { errors, touched, isSubmitting } = props;

  useEffect(() => {
    if (
      !props.isSubmitting &&
      !props.isValid &&
      Object.keys(props.errors).length > 0 &&
      props.submitCount > previousSubmitCount.current
    ) {
      previousSubmitCount.current = props.submitCount;
      const errorBox = ReactDOM.findDOMNode(errorBoxRef.current);
      errorBox.scrollIntoView();
    }
  }, [props.isSubmitting, errorBoxRef, props.errors, props.isValid, props.submitCount]);

  useEffect(() => {
    setInitialPosition();
  }, []);

  const clearLocationSelected = () => {
    props.clearSelectedPoint();
    props.setFieldValue('locationSelected', false);
  };

  const handleIncidentSelected = (e) => {
    if (props.allowIncidentSelect === true) {
      props.setFieldValue('locationSelected', true);
    } else {
      clearLocationSelected();
    }
  };

  // handle ticking the missing asset box
  const handleMissingAssetsSwitch = () => {
    clearLocationSelected();
    props.missingAssetsOptions.handleSwitch && props.missingAssetsOptions.handleSwitch();
  };

  // Feature is clicked
  const handleFeatureClicked = async (e) => {
    // If unadopted road.
    if (isUnadoptedRoad(e) === true) {
      clearLocationSelected();
      return;
    }

    // Does javascript count undefined as false? explicit set below just in case
    const allowSelectIncident = props.allowIncidentSelect === undefined ? false : props.allowIncidentSelect;
    // if we do not allow selecting an incident and we can a prop to do a check on the asset
    // Then perform a check to see if any incidents exist
    // Then show an alert if there is an incident on it. Stop the user from progressing

    if (allowSelectIncident === false && props.incidentExistsCheck) {
      const incidentExists = await props.incidentExistsCheck(e);
      if (incidentExists) {
        alert(props.incidentExistsAlertMessage);
        clearLocationSelected();
        return;
      }
    }

    props.handleFeatureClicked(e);

    props.setFieldValue('locationSelected', true);
  };

  const handleMapClicked = (e) => {
    props.onMapSelected(e);
    props.setFieldValue('locationSelected', true);
  };

  const handleAdditionalInformationChange = (e) => {
    props.setFieldValue('additionalInformation', e.target.value);
    props.onAdditionalInformationChange && props.onAdditionalInformationChange(e);
  };

  const incidentLayersOptions = props.incidentLayerOptions
    ? [{ ...props.incidentLayerOptions[0], onIncidentSelected: handleIncidentSelected }]
    : undefined;

  let featureLayerOptions;

  // missing assets can only be selected on maps with features
  // if we have features, check if missing asset checkbox is selected
  // if it is, use unadopted feature layer only
  // otherwise use provided feature layers
  if (props.featureLayerOptions) {
    if (props.missingAssetsOptions && props.missingAssetsOptions.checked) {
      // check if unadopted layer should be used, not all processes have it implemented
      const useUnadoptedLayer =
        props.missingAssetsOptions.useUnadoptedLayer !== undefined
          ? props.missingAssetsOptions.useUnadoptedLayer
          : true;
      featureLayerOptions = useUnadoptedLayer ? [getUnadoptedRoadsFeatureOptions()] : undefined;
      // add feature click handler and rename popupTemplate option:
      if (featureLayerOptions) {
        featureLayerOptions[0].featurePopupTemplate = featureLayerOptions[0].popupTemplate;
        featureLayerOptions[0].onFeatureClicked = handleFeatureClicked;
      }
    } else {
      // add feature click handler and rename popupTemplate option:
      featureLayerOptions = props.featureLayerOptions.map((feature) => {
        return {
          layerUrl: feature.layerUrl,
          layerId: feature.layerId,
          onFeatureClicked: handleFeatureClicked,
          featurePopupTemplate: feature.popupTemplate,
        };
      });
    }
  }

  return (
    <div className="page">
      <Form noValidate>
        <div ref={errorBoxRef}></div>
        {Object.keys(errors).length > 0 && touched.locationSelected && <ErrorBoxComponent />}
        <InputLabelComponent error={errors.locationSelected && touched.locationSelected} required={true}>
          Location of problem
        </InputLabelComponent>

        <span id="locationSelected"></span>

        {props.locationSelectText && props.locationSelectText !== undefined ? (
          <HelpTextComponent>{props.locationSelectText}</HelpTextComponent>
        ) : (
          <HelpTextComponent>
            Enter a postcode or street name to search, or find the location on the map.
            <br />
            Select the location on the map. Be as accurate as you can to help us locate the problem.
            <br />
            Use the <span aria-hidden="true" className="esri-icon esri-icon-locate"></span> icon to report a problem at
            your current location.
            <br />
            Select a reported problem to view details.
            <br />
          </HelpTextComponent>
        )}
        <CycEsriMapComponent
          locationObject={props.locationObject}
          onMapClicked={handleMapClicked}
          incidentLayersOptions={incidentLayersOptions}
          featureLayersOptions={featureLayerOptions}
          disableFreeSelect={
            props.disableFreeSelect &&
            (!props.missingAssetsOptions || (props.missingAssetsOptions && !props.missingAssetsOptions.checked))
              ? true
              : false
          }
          enablePointSelectionAtZoomLevel={props.enablePointSelectionZoomLevels}
          getAllLayersDetailsOnClick={props.getAllLayersDetailsOnClick}
        />
        {errors.locationSelected && touched.locationSelected && (
          <InputErrorComponent>{errors.locationSelected}</InputErrorComponent>
        )}

        {props.missingAssetsOptions && (
          <CheckboxInputComponent
            id="missingAssetsCheckbox"
            checked={props.missingAssetsOptions.checked}
            label={props.missingAssetsOptions.label}
            onChange={handleMissingAssetsSwitch}
          />
        )}

        {props.extraInformationUnderMap && props.extraInformationUnderMap}
        {props.additionalInformation !== undefined && (
          <>
            <AdditionalInformationComponent
              showCounter={true}
              maximumCount={ADDITIONAL_INFORMATION_MAX_LENGTH}
              className="w-100"
              name="additionalInformation"
              placeholder="Additional location information"
              value={props.additionalInformation}
              onAdditionalInformationChange={handleAdditionalInformationChange}
              headerText={props.additionalInformationHeaderText || 'Additional location information'}
              helpText="Help: Details that will help us locate the problem. For example: nearest house number, road names, landmarks, restricted access."
              touched={touched}
              errors={errors}
              required={props.missingAssetsOptions && props.missingAssetsOptions.checked}
            />
            {errors.additionalInformation && touched.additionalInformation && (
              <InputErrorComponent>{errors.additionalInformation}</InputErrorComponent>
            )}
          </>
        )}

        {props.missingAssetsOptions?.showFileInputOnMapPage && props.missingAssetsOptions.checked && (
          <>
            <br />
            <h3 id="uploadPhoto" className="mt-2">
              Upload a photo
            </h3>
            <FileInputComponent
              onUploadFiles={(files) => props.missingAssetsOptions.onUploadFiles(files)}
              onRemoveFile={(index) => props.missingAssetsOptions.onUploadFiles(index)}
              uploadedFiles={props.missingAssetsOptions.uploadedFiles}
            />
          </>
        )}

        <Button onClick={props.goToPrevious} type="button">
          Previous
        </Button>
        {props.showNextButton === false ? (
          <></>
        ) : (
          <Button type="submit" disabled={isSubmitting} className={ButtonClasses.primary + ' float-right'}>
            Next
          </Button>
        )}
      </Form>
    </div>
  );
};

const LocationPageComponent = withFormik({
  mapPropsToValues({ locationSelected, additionalInformation }) {
    return {
      locationSelected,
      additionalInformation: additionalInformation ? additionalInformation : '',
    };
  },
  validationSchema: ({ locationSelectedValidationText, missingAssetsOptions }) => {
    const message = locationSelectedValidationText || 'Please select a location on the map.';
    const schema = {
      locationSelected: Yup.boolean().oneOf([true], message),
      additionalInformation: Yup.string().max(
        ADDITIONAL_INFORMATION_MAX_LENGTH,
        msgConstants.CHARACTER_LIMIT_EXCEEDED_ERROR
      ),
    };

    if (missingAssetsOptions && missingAssetsOptions.checked) {
      schema.additionalInformation = schema.additionalInformation.required(
        "Please supply details for 'Additional information'"
      );
      schema.locationSelected = Yup.boolean().oneOf([true], 'Please select a location on the map.');
    }
    return Yup.object().shape(schema);
  },
  handleSubmit(values, bag) {
    bag.props.goToNext();
  },
})(LocationPage);

LocationPageComponent.propTypes = {
  getAllLayersDetailsOnClick: PropTypes.func,
  allowIncidentSelect: PropTypes.bool,
  showNextButton: PropTypes.bool,
  locationSelected: PropTypes.bool.isRequired,
  locationObject: PropTypes.object,
  additionalInformation: PropTypes.string,
  goToNext: PropTypes.func.isRequired,
  goToPrevious: PropTypes.func.isRequired,
  onAdditionalInformationChange: (props, propName) => {
    if (props['additionalInformation'] !== undefined && props[propName] === undefined) {
      return new Error('onAdditionalInformationChange required');
    }
  },
  // additional information label override text
  additionalInformationHeaderText: PropTypes.string,
  clearSelectedPoint: PropTypes.func.isRequired,
  incidentExistsCheck: PropTypes.func,
  disableFreeSelect: PropTypes.bool,
  incidentExistsAlertMessage: (props, propName) => {
    if (props['incidentExistsCheck'] !== undefined && props[propName] === undefined) {
      return new Error('incidentExistsAlertMessage required');
    }
  },
  featureLayerOptions: PropTypes.array,
  incidentLayerOptions: PropTypes.array,
  onMapSelected: (props, propName) => {
    if (props['disableFreeSelect'] === false && props[propName] === undefined) {
      return new Error('onMapSelected required');
    }
  },
  extraInformationUnderMap: PropTypes.node,
  locationSelectedValidationText: PropTypes.string,
  locationSelectText: PropTypes.node,
  missingAssetsOptions: PropTypes.shape({
    // whether the options is currently selected
    checked: PropTypes.bool.isRequired,
    // label for the can't find asset checkbox
    label: PropTypes.string.isRequired,
    // callback for the checkbox
    handleSwitch: PropTypes.func.isRequired,
    // file input callbacks
    onUploadFiles: (props, propName) => CheckOtherPropIsTrue(props, 'showFileInputOnMapPage', propName),
    onRemoveFile: (props, propName) => CheckOtherPropIsTrue(props, 'showFileInputOnMapPage', propName),
    // file input content
    uploadedFiles: (props, propName) => CheckOtherPropIsTrue(props, 'showFileInputOnMapPage', propName),
    // whether to use unadopted feature layer - defaults to true
    useUnadoptedLayer: PropTypes.bool,
    // whether to show file input component on location page
    showFileInputOnMapPage: PropTypes.bool,
  }),
};

/**
 *
 * @param {PropType} props comes from PropTypes
 * @param {*} propToCheckAgainst Prop name to check against
 * @param {*} currentProp this property
 */
const CheckOtherPropIsTrue = (props, propToCheckAgainst, currentProp) => {
  if (
    props[propToCheckAgainst] !== undefined &&
    props[propToCheckAgainst] === true &&
    props[currentProp] === undefined
  ) {
    return new Error(currentProp + ' required');
  }
};

export default LocationPageComponent;
