import React from 'react';
import PropTypes from 'prop-types';
import { DocumentTitleComponent, PageHeaderComponent, ProgressBarComponent } from '../..';
import './incident-form.container.scss';
import MyAccountPageComponent from 'common/components/myaccount-page/my-account-page.component';

const IncidentFormContainer = ({
  documentTitle,
  pageHeaderText,
  totalSteps,
  currentStep,
  displayProgressBar,
  breadcrumbs,
  children,
}) => (
  <DocumentTitleComponent title={documentTitle}>
    <div className="App">
      <PageHeaderComponent text={pageHeaderText} breadcrumbs={breadcrumbs} />
      <MyAccountPageComponent>
        {displayProgressBar() === true ? (
          <ProgressBarComponent totalSteps={totalSteps} currentStep={currentStep} />
        ) : (
          <React.Fragment />
        )}
        <div>{children}</div>
      </MyAccountPageComponent>
    </div>
  </DocumentTitleComponent>
);

IncidentFormContainer.propTypes = {
  documentTitle: PropTypes.string.isRequired,
  pageHeaderText: PropTypes.string.isRequired,
  totalSteps: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
  displayProgressBar: PropTypes.func,
};

export default IncidentFormContainer;
