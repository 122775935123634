import { createContext } from 'react';
import { CYCServices } from 'cyc-services';

class UserContext {
  store = {
    firstName: undefined,
    lastName: undefined,
  };

  constructor() {
    if (CYCServices.JWTAuth.isAuthenticated()) {
      const user = CYCServices.JWTAuth.getProfile();
      this.store = {
        firstName: user.given_name,
        lastName: user.family_name,
      };
    }
  }

  setStore = (key, value) => {
    this.store[key] = value;
  };
}

export { UserContext };
export default createContext(new UserContext());
