import moment from 'moment';
import PropTypes from 'prop-types';
import './incidents-exist-display.component.style.scss';
import InfoIcon from '../../../resources/images/info_icon.svg';

const IncidentsExistDisplay = ({ headerText = '', footerText = '', incidentsList = [], categorys = {} }) => {
  const getDateWithCorrectFormat = (stringDate) => {
    return moment(stringDate).format('DD MMMM yyyy[, at] h.mma');
  };

  const getcategoryId = (categoryId) => {
    const getEntries = Object.entries(categorys);
    const findCategoryEntry = getEntries.find((x) => x[1].id == categoryId);
    if (findCategoryEntry) {
      return findCategoryEntry[1].displayName;
    }
    return 'Unknown';
  };

  const setCategoryAndDate = (categoryId, stringDate) => {
    return `${getcategoryId(categoryId)}, reported on ${getDateWithCorrectFormat(stringDate)}`;
  };

  if (incidentsList.length === 0) {
    return <></>;
  }

  return (
    <div id="incident-exists-display">
      <div>
        <img src={InfoIcon} alt="searchIcon" />
      </div>
      <div>
        <p id="incident-exists-display__header">Information Alert</p>
        <p>{headerText}</p>
        <ul>
          {incidentsList.map((incident, index) => (
            <li key={index}>{setCategoryAndDate(incident.categoryId, incident.createdTime)}</li>
          ))}
        </ul>
        <p>{footerText}</p>
      </div>
    </div>
  );
};

IncidentsExistDisplay.propTypes = {
  headerText: PropTypes.string.isRequired,
  footerText: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  incidentsList: PropTypes.arrayOf(
    PropTypes.shape({
      categoryId: PropTypes.number,
      createdTime: PropTypes.string,
    })
  ).isRequired,
  categorys: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default IncidentsExistDisplay;

export const generalHeader = (productName = '') => {
  return `We're already aware of a problem with the ${productName} you've selected; we've received reports about:`;
};

export const differentProblemFooter = (productName = '') => (
  <>
    Complete this form to report a <b>different problem </b>
    {`with this ${productName}.`}
  </>
);
export const workingToFixProblemFooter = (productName = '') => {
  return `We're working to fix problems that have already been reported; you can only report a different problem with this ${productName}.`;
};

export const cannotReportAnotherProblemFooter = (productName = '') => (
  <>
    We're working to fix problems that have already been reported; as there are no different problems that can be
    reported you <b>cannot report another problem</b> with this {productName} at this time.
  </>
);
