import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { PageHeaderComponent, MyAccountPageComponent, DocumentTitleComponent, clientMyAccountUrl } from 'common';

import * as constants from '../common/constants/bin-collections.constants';

import BinCollectionsLookupContainer from './containers/bin-collections-lookup.container';
import BinCalendarComponent from '../common/components/bin-collections/bin-calendar.component';

const BinCollectionsApp = () => {
  return (
    <>
      <PageHeaderComponent text={constants.AppName} icon="icon-waste-and-recycling" />
      <DocumentTitleComponent title={constants.AppName} />
      <MyAccountPageComponent className="bin-collections-lookup">
        <Routes>
          <Route path="/" element={<BinCollectionsLookupContainer />} />
          <Route path={`${clientMyAccountUrl.binCollections.calendar}/:uprn`} element={<BinCalendarComponent />} />
        </Routes>
      </MyAccountPageComponent>
    </>
  );
};

export default BinCollectionsApp;
