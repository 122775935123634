import React, { useEffect, useReducer, useCallback, useMemo } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { CYCServices } from 'cyc-services';
import { ConfigurationService } from 'cyc-configuration-service';
import { FeatureFlagsService } from 'cyc-configuration-service';

import {
  ContinueFormOrReRoute,
  IncidentFormContainer,
  IncidentService,
  clientMyAccountUrl,
  apiMyAccountUrl,
  changeUrlByStepNumberv6,
  isEnabled,
  UnadoptedLayerFlag,
} from '../../common';
import { FormConfiguration } from './form-config';
import { reducer, initialState, actions, fieldNames } from '../incident-form.reducer';
import { HeaderTextFromUrl } from '../helpers/header-text-from-url.helper';
import {
  incidentLayersOptionsBuilder,
  FieldsBuilder,
  popupTemplateBuilder,
  mapIncidentDetailsToAttributesBuilder,
} from '../helpers/incident-layer-options-builder.helper';
import { getUnadoptedRoadsFeatureOptions } from '../helpers/unadopted-layer.helper';

// Pages
import { ConfirmationPage, EntryPage, LocationPage, SummaryPage } from '../components-shared';

import ProblemPage from './components/problem-page.component';
import SignInPage from '../../sign-in/containers/sign-in.container';
import RegistrationApp from '../../registration/registration.app';

const RoadAndPavementProblemsApp = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const formConfig = useMemo(() => new FormConfiguration(), []);
  const initState = useMemo(
    () => initialState({ pageHeaderText: formConfig.pageHeaderText }),
    [formConfig.pageHeaderText]
  );
  const [state, dispatch] = useReducer(reducer, initialState(initState));
  const formData = state.formData;
  const getIncidentsUrl = `${ConfigurationService.store.apiUrl}${apiMyAccountUrl.getIncidents.ROAD_AND_PAVEMENT_PROBLEMS}`;
  const defectTypes = formConfig.defectTypes;
  const isCycleTrackSelected = formData.roadDefectTypeId === formConfig.cycleTrackId;

  // This effect is for updating headers and step urls
  useEffect(() => {
    const indexOfStep = formConfig.stepUrls.indexOf(location.pathname);

    // Update the header based on the url.
    const header = HeaderTextFromUrl({
      url: location.pathname,
      initialHeaderText: formConfig.pageHeaderText,
    });
    dispatch({
      type: actions.updateStateFields,
      payload: { [fieldNames.currentStep]: indexOfStep, [fieldNames.pageHeaderText]: header },
    });
  }, [location.pathname, formConfig.pageHeaderText, formConfig.stepUrls]);

  // --- ENTRY PAGE ---
  const handleResetForm = useCallback(() => dispatch({ type: actions.resetForm, payload: initState }), [initState]);

  // Build incident Layer options
  let fieldsBuilder = new FieldsBuilder();
  fieldsBuilder.addIncidentId();
  fieldsBuilder.addFaultType();
  fieldsBuilder.addIncidentReferenceNumber();
  fieldsBuilder.addCreatedTime();
  const incidentLayerOptions = incidentLayersOptionsBuilder({
    mapIncidentDetailsToAttributes: mapIncidentDetailsToAttributesBuilder,
    getIncidentsUrl: getIncidentsUrl,
    mapIncidentLayerId: formConfig.mapIncidentLayerId,
    popupTemplate: popupTemplateBuilder({
      mapIncidentLayerTitle: formConfig.mapIncidentLayerTitle,
      tableDataArray: [
        { header: 'Fault type', data: 'faultType' },
        { header: 'Date created', data: 'createdTime' },
      ],
      showAlreadyReportedMessage: true,
    }),
    fields: fieldsBuilder.getBuilderResult(),
  });

  // Build feature Layer options (currently only unadopted roads)
  const getFeatureLayerOptions = () => {
    if (isEnabled(FeatureFlagsService.getFeatureFlagByName(UnadoptedLayerFlag))) {
      return [getUnadoptedRoadsFeatureOptions()];
    }
  };

  const handleFeatureClicked = (e) => {
    dispatch({ type: actions.resetLocation });
  };

  // ----- SUMMARY PAGE ----
  const buildTableData = () => {
    const defectType = defectTypes.find((val) => val.id === formData.roadDefectTypeId);
    const problemWith = defectType && defectType.displayName;
    const problem =
      defectType && defectType.categories.find((problem) => problem.id === formData.problemId)?.displayName;

    const tableData = [
      {
        columnOne: 'Problem with',
        columnTwo: problemWith,
        onChangeClicked: () => changeUrlByStepNumberv6(1, formConfig, navigate, 'WhereIsTheProblem'),
        columnTwoClassName: 'sentence-case',
      },
      {
        columnOne: 'About the problem',
        columnTwo: problem,
        onChangeClicked: () => changeUrlByStepNumberv6(1, formConfig, navigate, 'WhatIsTheProblem'),
        columnTwoClassName: 'sentence-case',
      },
      {
        columnOne: 'Upload a photo',
        columnTwo: formData.uploadedFiles ? (
          <ul className="uploaded-photos">
            {formData.uploadedFiles.map((file, index) => (
              <li key={index}>{file.fileName}</li>
            ))}
          </ul>
        ) : (
          <React.Fragment />
        ),
        onChangeClicked: () => changeUrlByStepNumberv6(1, formConfig, navigate, 'UploadPhoto'),
      },
      {
        columnOne: 'Location',
        columnTwo:
          formData.locationObject && formData.locationObject.streetName && formData.locationObject.streetName.length > 0
            ? formData.locationObject.streetName
            : 'Selected on map',
        onChangeClicked: () => changeUrlByStepNumberv6(2, formConfig, navigate, 'location'),
      },
      {
        columnOne: 'Additional location information',
        columnTwo: formData.additionalInformation,
        onChangeClicked: () => changeUrlByStepNumberv6(2, formConfig, navigate, 'additionalInformation'),
      },
    ];

    return tableData;
  };

  const submitIncident = async () => {
    let form = { ...formData };
    form.productId = formConfig.productId;
    form.subject = formConfig.subject;
    // Create the incident
    try {
      const result = await IncidentService.createIncident(form);
      if (result.status === 200) {
        dispatch({ type: actions.updateIncidentObject, payload: result.data });
        dispatch({
          type: actions.updateStateFields,
          payload: { [fieldNames.formStarted]: true, [fieldNames.formCompleted]: true },
        });
      }
    } catch {
      return false;
    }
  };

  // ----- CONFIRMATION PAGE -----
  const sendUpdateIncidentWithUser = async (email) => {
    await IncidentService.updateIncidentWithUser(
      state.incident.referenceNumber,
      state.incident.secretReferenceNumber,
      state.incident.incidentId,
      email
    ).then((result) => {
      return result.status === 200;
    });
  };

  const handleUserRegistered = (userData) => {
    dispatch({ type: actions.updateStateFields, payload: { [fieldNames.userLoggedIn]: true } });
    // only update incident when user's email is not confirmed
    // save the incident into temp table while wait for the user to confirm his/her email.
    if (state.formCompleted && userData.isEmailConfirmed === false) {
      sendUpdateIncidentWithUser(userData.email);
    }
  };

  const handleSignInCallBack = () => {
    const isSignedIn = formConfig.userIsLoggedIn();
    if (isSignedIn) {
      sendUpdateIncidentWithUser('');
    }
    // after sign in succssfully, navigate back to incident creation confirmation page
    navigate(formConfig.stepUrls[formConfig.totalSteps]);
  };

  // --- render builders ----
  const formRouteInfo = {
    formStarted: state.formStarted,
    formCompleted: state.formCompleted,
    formStartUrl: formConfig.stepUrls[0],
    formEndUrl: formConfig.stepUrls[formConfig.totalSteps],
    location: location,
  };

  return (
    <IncidentFormContainer
      displayProgressBar={() => formConfig.shouldDisplayBasedOnStep(state.currentStep)}
      pageHeaderText={state.pageHeaderText}
      documentTitle={formConfig.documentTitle}
      totalSteps={formConfig.totalSteps}
      currentStep={state.currentStep}
      breadcrumbs={[{ url: clientMyAccountUrl.roadAndPavementProblems.root, name: formConfig.pageHeaderText }]}>
      <Routes>
        <Route
          path="/"
          element={
            <EntryPage
              report={{ text: 'road and pavement problems', url: 'https://www.york.gov.uk/ReportRoadsAndPavements' }}
              informationArray={[
                {
                  text: 'problems we can help with',
                  url: 'https://www.york.gov.uk/ReportRoadsAndPavements#problemswecanhelpwith',
                },
                {
                  text: 'our road problem response times',
                  url: 'https://www.york.gov.uk/ReportRoadsAndPavements#responsetimes',
                },
              ]}
              goToNextPage={() =>
                changeUrlByStepNumberv6(1, formConfig, navigate, false, true, () =>
                  dispatch({
                    type: actions.updateStateFields,
                    payload: { [fieldNames.formStarted]: true, [fieldNames.formCompleted]: false },
                  })
                )
              }
              onResetForm={handleResetForm}
            />
          }
        />

        <Route
          path={formConfig.baseUrl.step1}
          element={
            <ContinueFormOrReRoute {...formRouteInfo}>
              <ProblemPage
                goToNextPage={() => changeUrlByStepNumberv6(2, formConfig, navigate)}
                goToPreviousPage={() => changeUrlByStepNumberv6(0, formConfig, navigate)}
                problemWithId={formData.roadDefectTypeId}
                problemId={formData.problemId}
                onProblemWithChange={(value) =>
                  dispatch({
                    type: actions.updateFormDataFields,
                    payload: { [fieldNames.roadDefectTypeId]: value },
                  })
                }
                onProblemChange={(value) =>
                  dispatch({ type: actions.updateFormDataFields, payload: { [fieldNames.problemId]: value } })
                }
                uploadedFiles={formData.uploadedFiles}
                onUploadFiles={(files) => dispatch({ type: actions.updateUploadFiles, payload: files })}
                onRemoveFile={(index) => dispatch({ type: actions.removeUploadedFile, payload: index })}
                defectTypes={defectTypes}
              />
            </ContinueFormOrReRoute>
          }
        />

        <Route
          path={formConfig.baseUrl.step2}
          element={
            <ContinueFormOrReRoute {...formRouteInfo}>
              <LocationPage
                goToNext={() => changeUrlByStepNumberv6(3, formConfig, navigate)}
                goToPrevious={() => changeUrlByStepNumberv6(1, formConfig, navigate)}
                incidentLayerOptions={incidentLayerOptions}
                featureLayerOptions={isCycleTrackSelected ? undefined : getFeatureLayerOptions()}
                handleFeatureClicked={isCycleTrackSelected ? undefined : handleFeatureClicked}
                locationObject={formData.locationObject}
                locationSelected={formData.locationSelected}
                onMapSelected={(location) => dispatch({ type: actions.updateLocationObject, payload: location })}
                clearSelectedPoint={() => dispatch({ type: actions.resetLocation })}
                additionalInformation={formData.additionalInformation}
                onAdditionalInformationChange={(e) =>
                  dispatch({
                    type: actions.updateFormDataFields,
                    payload: { [fieldNames.additionalInformation]: e.target.value },
                  })
                }
                enablePointSelectionZoomLevels={formConfig.enablePointSelectionZoomLevels}
              />
            </ContinueFormOrReRoute>
          }
        />

        <Route
          path={formConfig.baseUrl.step3}
          element={
            <ContinueFormOrReRoute {...formRouteInfo}>
              <SummaryPage
                goToProblemPage={() => changeUrlByStepNumberv6(2, formConfig, navigate)}
                submitIncident={submitIncident}
                tableData={buildTableData()}
              />
            </ContinueFormOrReRoute>
          }
        />

        <Route
          path={formConfig.baseUrl.confirmation}
          element={
            <ContinueFormOrReRoute {...formRouteInfo}>
              <ConfirmationPage
                referenceNumber={state.incident.referenceNumber}
                onSignInClicked={() => navigate(formConfig.baseUrl.root + clientMyAccountUrl.signIn)}
                onRegisterClicked={() => navigate(formConfig.baseUrl.root + clientMyAccountUrl.register)}
                userLoggedIn={() => CYCServices.JWTAuth.isAuthenticated()}
                goToMyAccount={() => navigate(clientMyAccountUrl.dashboard)}
                restartFormUrl={formConfig.baseUrl.root}
                responseTimes={[
                  {
                    text: 'road or pavement response times',
                    url: 'https://www.york.gov.uk/ReportRoadsAndPavements#responsetimes',
                  },
                ]}
              />
            </ContinueFormOrReRoute>
          }
        />

        <Route path={clientMyAccountUrl.signIn} element={<SignInPage onSuccessfulSignIn={handleSignInCallBack} />} />
        <Route
          path={`${clientMyAccountUrl.register}/*`}
          element={
            <RegistrationApp
              onSuccessfulRegistration={handleUserRegistered}
              extraSuccessfulContent={<p>Click 'Continue' to view the reference number for your reported problem.</p>}
              onContinueClicked={() => navigate(formConfig.baseUrl.root + formConfig.baseUrl.confirmation)}
            />
          }
        />
      </Routes>
    </IncidentFormContainer>
  );
};

export default RoadAndPavementProblemsApp;
