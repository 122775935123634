import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { withFormik, Form } from 'formik';
import * as Yup from 'yup';
import {
  RadiobuttonInputComponent,
  ButtonComponent,
  ButtonClasses,
  InputErrorComponent,
} from 'cyc-react-ui-components';

import {
  ErrorBoxComponent,
  FileInputComponent,
  ErrorLabelHandlerComponent,
  OffensiveGraffitiInputComponent,
  setInitialPosition,
} from '../../../common';
import usePrevious from 'common/hooks/use-previous.hook';

const ProblemPage = ({
  isSubmitting,
  isValid,
  errors,
  values,
  touched,
  submitCount,
  setFieldValue,
  onProblemTypeChange,
  possibleProblems,
  isGraffiti,
  onOffensiveGraffitiChange,
  uploadedFiles,
  onUploadFiles,
  onRemoveFile,
  goToPrevious,
}) => {
  const previousIsSubmitting = usePrevious(isSubmitting);
  const errorBox = useRef();

  useEffect(() => {
    setInitialPosition();
  }, []);

  useEffect(() => {
    if (previousIsSubmitting !== undefined && isSubmitting === true && isValid === false) {
      ReactDOM.findDOMNode(errorBox.current).scrollIntoView();
    }
  }, [isSubmitting]);

  return (
    <div className="page">
      <Form>
        <div ref={errorBox}></div>
        {Object.keys(errors).length > 0 && touched.problemId && <ErrorBoxComponent errors={errors}></ErrorBoxComponent>}

        <h2>About the problem</h2>
        <p>Tell us what needs cleaning up.</p>
        <div>
          <span id="whatIsTheProblem">
            <ErrorLabelHandlerComponent
              touched={touched}
              errors={errors}
              text="What is the problem"
              id="problemId"
              submitCount={submitCount}
            />
            <RadiobuttonInputComponent
              name="problemId"
              value={values.problemId}
              onChange={(e) => {
                setFieldValue('problemId', e);
                setFieldValue('offensiveGraffiti', undefined);
                onProblemTypeChange(e);
              }}
              options={possibleProblems.map((problem) => ({
                value: problem.id,
                label: problem.displayName,
              }))}
              error={errors.problemId && touched.problemId}
            />
            {errors.problemId && touched.problemId && <InputErrorComponent>{errors.problemId}</InputErrorComponent>}
          </span>
        </div>
        {isGraffiti === true ? (
          <OffensiveGraffitiInputComponent
            offensiveGraffiti={values.offensiveGraffiti}
            onOffensiveGraffitiChange={(value) => {
              setFieldValue('offensiveGraffiti', value);
              onOffensiveGraffitiChange && onOffensiveGraffitiChange(value);
            }}
            errors={errors}
            touched={touched}
            submitCount={submitCount}
          />
        ) : (
          <React.Fragment />
        )}

        <h2 className="mt-3" id="uploadPhoto">
          Upload a photo
        </h2>

        <FileInputComponent
          uploadedFiles={uploadedFiles}
          onUploadFiles={onUploadFiles}
          onRemoveFile={onRemoveFile}
        />

        <ButtonComponent type="button" onClick={goToPrevious}>
          Previous
        </ButtonComponent>
        <ButtonComponent type="submit" disabled={isSubmitting} className={ButtonClasses.primary + ' float-right'}>
          Next
        </ButtonComponent>
      </Form>
    </div>
  );
};

const ProblemPageComponent = withFormik({
  mapPropsToValues({ problemId, goToNext, offensiveGraffiti }) {
    return {
      problemId: problemId || '',
      offensiveGraffiti,
      goToNext,
    };
  },
  validationSchema: (props) =>
    Yup.object().shape({
      problemId: Yup.string().required("Please supply details for 'What is the problem'."),
      offensiveGraffiti: Yup.bool().when('problemId', {
        is: () => props.isGraffiti === true,
        then: Yup.bool().required("Please supply details for 'Is the graffiti offensive?'."),
      }),
    }),
  handleSubmit(bag) {
    bag.goToNext();
  },
})(ProblemPage);

ProblemPageComponent.propTypes = {
  problemId: PropTypes.string,
  onUploadFiles: PropTypes.func.isRequired,
  onRemoveFile: PropTypes.func.isRequired,
  onProblemTypeChange: PropTypes.func.isRequired,
  uploadedFiles: PropTypes.array,
  possibleProblems: PropTypes.array.isRequired,
  isGraffiti: PropTypes.bool.isRequired,
  offensiveGraffiti: PropTypes.bool,
  onOffensiveGraffitiChange: PropTypes.func.isRequired,
};

export default ProblemPageComponent;
export { ProblemPage };
