import React from 'react';
import { msgConstants } from '../../constants/msg.constants';

// Used for any generic error message if something goes wrong on a network request
class NetworkCatchErrorComponent extends React.Component {
  state = {
    didCatchError: false,
    customMessage: undefined,
  };

  componentDidMount = () => {
    this.setState({
      didCatchError: this.props.didCatchError,
      customMessage: this.props.customMessage,
    });
  };

  componentDidUpdate() {
    // Setstate only if theres a change
    if (this.props.didCatchError !== this.state.didCatchError) {
      this.setState({
        didCatchError: this.props.didCatchError,
      });
    }
  }

  render = () =>
    this.state.didCatchError === true ? (
      this.props.customMessage === undefined ? (
        <div className="network-loader cyc-box network-loader--error">
          {msgConstants.GENERIC_API_ERROR}
        </div>
      ) : (
        <div className="network-loader cyc-box network-loader--error">
          {this.props.customMessage}
        </div>
      )
    ) : (
      <React.Fragment />
    );
}

export default NetworkCatchErrorComponent;
