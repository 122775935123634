import React, { useEffect, useReducer, useMemo, useCallback } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { CYCServices } from 'cyc-services';
import { ConfigurationService } from 'cyc-configuration-service';

import { clientMyAccountUrl, ContinueFormOrReRoute, apiMyAccountUrl, changeUrlByStepNumberv6 } from '../../common';
import IncidentFormContainer from '../../common/containers/incident-forms/incident-form.container';
import * as Services from '../../common/services/incident.service';

import FormConfiguration from './form-config';

import { HeaderTextFromUrl } from '../helpers/header-text-from-url.helper';
import { actions, fieldNames, initialState, reducer } from '../incident-form.reducer';
import { ConfirmationPage, EntryPage, LocationPage, SummaryPage } from '../components-shared';
import ProblemPageComponent from './components/problem-page.component';
import SignInContainer from '../../sign-in/containers/sign-in.container';
import RegistrationApp from '../../registration/registration.app';
import {
  FieldsBuilder,
  incidentLayersOptionsBuilder,
  popupTemplateBuilder,
} from 'incident-forms/helpers/incident-layer-options-builder.helper';

const StreetCleaningApp = () => {
  let location = useLocation();
  let navigate = useNavigate();
  const formConfig = useMemo(() => new FormConfiguration(), []);
  const initState = useMemo(
    () => initialState({ pageHeaderText: formConfig.pageHeaderText }),
    [formConfig.pageHeaderText]
  );
  const [state, dispatch] = useReducer(reducer, initState);
  const getIncidentsUrl = `${ConfigurationService.store.apiUrl}${apiMyAccountUrl.getIncidents.STREET_CLEANING_INCIDENTS}`;
  const possibleProblems = formConfig.streetCleaningCategories;

  const indexOfStep = formConfig.stepUrls.indexOf(location.pathname);

  useEffect(() => {
    const header = HeaderTextFromUrl({
      url: location.pathname,
      initialHeaderText: formConfig.pageHeaderText,
    });

    dispatch({
      type: actions.updateStateFields,
      payload: {
        [fieldNames.currentStep]: indexOfStep,
        [fieldNames.pageHeaderText]: header,
      },
    });
  }, [location.pathname, formConfig.pageHeaderText, indexOfStep]);

  // ---- ENTRY PAGE ------
  const handleResetForm = useCallback(
    () =>
      dispatch({
        type: actions.resetForm,
        payload: initialState({ pageHeaderText: formConfig.pageHeaderText }),
      }),
    [dispatch, formConfig.pageHeaderText]
  );

  // ---- LOCATION PAGE ----
  const getIncidentLayerOptions = () => {
    const fieldsBuilder = new FieldsBuilder();
    fieldsBuilder.addIncidentId();
    fieldsBuilder.addIncidentReferenceNumber();
    fieldsBuilder.addIssue();
    fieldsBuilder.addCreatedTime();

    const incident = incidentLayersOptionsBuilder({
      mapIncidentDetailsToAttributes: (incident) => ({
        issue: incident.category,
        createdTime: new Date(incident.createdTime).toLocaleString('en-GB'),
      }),
      getIncidentsUrl: getIncidentsUrl,
      mapIncidentLayerId: formConfig.mapIncidentLayerId,
      popupTemplate: popupTemplateBuilder({
        mapIncidentLayerTitle: formConfig.mapIncidentLayerTitle,
        tableDataArray: [
          { header: 'Issue', data: 'issue' },
          { header: 'Date created', data: 'createdTime' },
        ],
        showAlreadyReportedMessage: true,
      }),
      fields: fieldsBuilder.getBuilderResult(),
    });

    return incident;
  };

  // ----- SUMMARY PAGE ----
  const submitIncident = async () => {
    const form = { ...state.formData };
    form.productId = formConfig.productId;
    form.subject = formConfig.subject;

    try {
      const result = await Services.createIncident(form);
      if (result.status === 200) {
        dispatch({ type: actions.updateIncidentObject, payload: result.data });
        dispatch({
          type: actions.updateStateFields,
          payload: { [fieldNames.formStarted]: true, [fieldNames.formCompleted]: true },
        });
        changeUrlByStepNumberv6(4, formConfig, navigate);
      }
    } catch {
      return false;
    }
  };

  const buildTableData = () => {
    const problem = possibleProblems.filter((val) => val.id === state.formData.problemId)[0];

    return [
      {
        columnOne: 'Location',
        columnTwo:
          state.formData.locationObject &&
          state.formData.locationObject.streetName &&
          state.formData.locationObject.streetName.length > 0
            ? state.formData.locationObject.streetName
            : 'Selected on map',
        onChangeClicked: () => changeUrlByStepNumberv6(1, formConfig, navigate, 'location'),
      },
      {
        columnOne: 'Additional location information',
        columnTwo: state.formData.additionalInformation,
        onChangeClicked: () => changeUrlByStepNumberv6(1, formConfig, navigate, 'additionalInformation'),
      },
      {
        columnOne: 'About the problem',
        columnTwo: problem ? problem.displayName : '',
        onChangeClicked: () => changeUrlByStepNumberv6(2, formConfig, navigate, 'whatIsTheProblem'),
        columnTwoClassName: 'sentence-case',
      },
      {
        columnOne: 'Upload a photo',
        columnTwo: state.formData.uploadedFiles ? (
          <ul className="uploaded-photos">
            {state.formData.uploadedFiles.map((file, index) => (
              <li key={index}>{file.fileName}</li>
            ))}
          </ul>
        ) : (
          <React.Fragment />
        ),
        onChangeClicked: () => changeUrlByStepNumberv6(2, formConfig, navigate, 'uploadPhoto'),
      },
    ];
  };

  // ----- CONFIRMATION PAGE -----
  const sendUpdateIncidentWithUser = async (email) => {
    await Services.updateIncidentWithUser(
      state.incident.referenceNumber,
      state.incident.secretReferenceNumber,
      state.incident.incidentId,
      email
    ).then((result) => result.status === 200);
  };

  const handleUserRegistered = (userData) => {
    dispatch({ type: actions.updateStateFields, payload: { [fieldNames.userLoggedIn]: true } });
    // only update incident when user's email is not confirmed
    // save the incident into temp table while wait for the user to confirm his/her email.
    if (state.formCompleted && userData.isEmailConfirmed === false) {
      sendUpdateIncidentWithUser(userData.email);
    }
  };

  const handleSignInCallBack = () => {
    if (formConfig.userIsLoggedIn()) {
      sendUpdateIncidentWithUser('');
    }
    // after sign in succssfully, navigate back to incident creation confirmation page
    navigate(formConfig.stepUrls[formConfig.totalSteps]);
  };

  const formRouteInfo = () => ({
    formStarted: state.formStarted,
    formCompleted: state.formCompleted,
    formStartUrl: formConfig.stepUrls[0],
    formEndUrl: formConfig.stepUrls[formConfig.totalSteps],
    location: location,
  });

  return (
    <IncidentFormContainer
      displayProgressBar={() => formConfig.shouldDisplayBasedOnStep(state.currentStep)}
      pageHeaderText={state.pageHeaderText}
      documentTitle={formConfig.documentTitle}
      totalSteps={formConfig.totalSteps}
      currentStep={state.currentStep}
      breadcrumbs={[{ url: clientMyAccountUrl.streetCleaning.root, name: formConfig.pageHeaderText }]}>
      <Routes>
        <Route
          path="/"
          element={
            <EntryPage
              report={formConfig.whatToReportText}
              informationArray={formConfig.beforeYouBeginText}
              goToNextPage={() =>
                changeUrlByStepNumberv6(1, formConfig, navigate, false, true, () =>
                  dispatch({
                    type: actions.updateStateFields,
                    payload: {
                      [fieldNames.formStarted]: true,
                      [fieldNames.formCompleted]: false,
                    },
                  })
                )
              }
              onResetForm={handleResetForm}
            />
          }
        />

        <Route
          path={formConfig.baseUrl.step1}
          element={
            <ContinueFormOrReRoute {...formRouteInfo()}>
              <LocationPage
                goToNext={() => changeUrlByStepNumberv6(2, formConfig, navigate)}
                goToPrevious={() => changeUrlByStepNumberv6(0, formConfig, navigate)}
                locationObject={state.formData.locationObject}
                locationSelected={state.formData.locationSelected}
                onMapSelected={(location) => dispatch({ type: actions.updateLocationObject, payload: location })}
                incidentLayerOptions={getIncidentLayerOptions()}
                additionalInformation={state.formData.additionalInformation}
                onAdditionalInformationChange={(e) =>
                  dispatch({
                    type: actions.updateFormDataFields,
                    payload: { [fieldNames.additionalInformation]: e.target.value },
                  })
                }
                clearSelectedPoint={() => dispatch({ type: actions.resetLocation })}
                enablePointSelectionZoomLevels={formConfig.enablePointSelectionZoomLevels}
              />
            </ContinueFormOrReRoute>
          }
        />

        <Route
          path={formConfig.baseUrl.step2}
          element={
            <ContinueFormOrReRoute {...formRouteInfo()}>
              <ProblemPageComponent
                goToPrevious={() => changeUrlByStepNumberv6(1, formConfig, navigate)}
                goToNext={() => changeUrlByStepNumberv6(3, formConfig, navigate)}
                onUploadFiles={(files) =>
                  dispatch({
                    type: actions.updateUploadFiles,
                    payload: files,
                  })
                }
                onRemoveFile={(index) =>
                  dispatch({
                    type: actions.removeUploadedFile,
                    payload: index,
                  })
                }
                problemId={state.formData.problemId}
                sizeOfWaste={state.formData.sizeOfWaste}
                onProblemTypeChange={(value) =>
                  dispatch({
                    type: actions.updateFormDataFields,
                    payload: {
                      [fieldNames.problemId]: value,
                    },
                  })
                }
                uploadedFiles={state.formData.uploadedFiles}
                possibleProblems={possibleProblems}
              />
            </ContinueFormOrReRoute>
          }
        />

        <Route
          path={formConfig.baseUrl.step3}
          element={
            <ContinueFormOrReRoute {...formRouteInfo()}>
              <SummaryPage
                submitIncident={submitIncident}
                goToProblemPage={() => changeUrlByStepNumberv6(2, formConfig, navigate)}
                tableData={buildTableData()}
              />
            </ContinueFormOrReRoute>
          }
        />

        <Route
          path={formConfig.baseUrl.confirmation}
          element={
            <ContinueFormOrReRoute {...formRouteInfo()}>
              <ConfirmationPage
                referenceNumber={state.incident.referenceNumber}
                onSignInClicked={() => navigate(formConfig.baseUrl.root + clientMyAccountUrl.signIn)}
                onRegisterClicked={() => navigate(formConfig.baseUrl.root + clientMyAccountUrl.register)}
                userLoggedIn={() => CYCServices.JWTAuth.isAuthenticated()}
                goToMyAccount={() => navigate(clientMyAccountUrl.dashboard)}
                restartFormUrl={formConfig.baseUrl.root}
              />
            </ContinueFormOrReRoute>
          }
        />

        <Route
          path={clientMyAccountUrl.signIn}
          element={<SignInContainer onSuccessfulSignIn={handleSignInCallBack} />}
        />
        <Route
          path={`${clientMyAccountUrl.register}/*`}
          element={
            <RegistrationApp
              onSuccessfulRegistration={handleUserRegistered}
              extraSuccessfulContent={<p>Click 'Continue' to view the reference number for your reported problem.</p>}
              onContinueClicked={() => navigate(formConfig.baseUrl.root + formConfig.baseUrl.confirmation)}
            />
          }
        />
      </Routes>
    </IncidentFormContainer>
  );
};

export default StreetCleaningApp;
