import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useSearchParams } from 'react-router-dom';

import ResetPasswordFormComponent from '../../components/reset/reset-password-form.component';
import {
  clientMyAccountUrl,
  PageHeaderComponent,
  MyAccountPageComponent,
  DocumentTitleComponent,
} from '../../../../common';
import { postResetPasswordAsync } from '../../services/forgot-password.service';

const ResetPasswordContainer = () => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState();
  const [code, setCode] = useState();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const userIdFromUri = searchParams.get('userId');
    const codeFromUri = searchParams.get('code');
    setUserId(userIdFromUri);
    setCode(codeFromUri);

    if (!userIdFromUri || !codeFromUri) {
      navigate(clientMyAccountUrl.dashboard);
    }
  }, []);

  return (
    <React.Fragment>
      <PageHeaderComponent text="Reset your password" />
      <MyAccountPageComponent>
        <DocumentTitleComponent title="My account - Reset password">
          {userId && code && (
            <ResetPasswordFormComponent
              userId={userId}
              code={code}
              onSubmit={(values) => postResetPasswordAsync(values)}
            />
          )}
        </DocumentTitleComponent>
      </MyAccountPageComponent>
    </React.Fragment>
  );
};

ResetPasswordContainer.propTypes = {
  history: PropTypes.object,
};

export default ResetPasswordContainer;
