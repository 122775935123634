import axios from 'axios';
import { ConfigurationService } from 'cyc-configuration-service';

import appInsights from 'cyc-application-insights-react';

import { CYCServices } from 'cyc-services';
import { apiMyAccountUrl } from '../../common';

const AUTH_FAILURE_MESSAGE = 'Not authenticated';

export const postChangePasswordAsync = async (CurrentPassword, NewPassword, ConfirmPassword) => {
  if (CYCServices.JWTAuth.isAuthenticated() === false) return Promise.reject(AUTH_FAILURE_MESSAGE);

  const address = `${ConfigurationService.store.apiUrl}${apiMyAccountUrl.MANAGE_CHANGE_PASSWORD}`;
  const data = { CurrentPassword, NewPassword, ConfirmPassword };
  const config = {
    headers: { Authorization: `Bearer ${CYCServices.JWTAuth.getToken()}` },
  };

  return axios.post(address, data, config).catch((ex) => {
    appInsights.trackException({
      exception: new Error('Failed to post passwordChange'),
      properties: { newPasswordsMatch: NewPassword === ConfirmPassword },
    });
    throw ex;
  });
};

export const getContactDetailAsync = async (withAddress) => {
  if (CYCServices.JWTAuth.isAuthenticated() === false) return Promise.reject(AUTH_FAILURE_MESSAGE);
  const address = `${ConfigurationService.store.apiUrl}${apiMyAccountUrl.GET_CONTACT_DETAIL}`;
  const config = {
    headers: { Authorization: `Bearer ${CYCServices.JWTAuth.getToken()}` },
    params: { withAddress },
  };

  return axios.get(address, config).catch((ex) => {
    console.error(ex);
    appInsights.trackException({ exception: new Error('Failed to get contact details'), properties: { withAddress } });
    throw ex;
  });
};

export const updateContactPreferencesAsync = async (shareContactDetails) => {
  const address = `${ConfigurationService.store.apiUrl}${apiMyAccountUrl.UPDATE_CONTACT_PREFERENCES}`;
  const config = {
    headers: { Authorization: `Bearer ${CYCServices.JWTAuth.getToken()}` },
  };

  return await axios.post(address, { ShareContactDetails: shareContactDetails }, config).catch((ex) => {
    console.error(ex);
    appInsights.trackException({
      exception: new Error('Failed to update contact preferences'),
      properties: { shareContactDetails },
    });
    throw ex;
  });
};

export const updateContactEmailAsync = async (newEmailAddress, password) => {
  const address = `${ConfigurationService.store.apiUrl}${apiMyAccountUrl.UPDATE_CONTACT_EMAIL_ADDRESS}`;
  const config = {
    headers: { Authorization: `Bearer ${CYCServices.JWTAuth.getToken()}` },
  };

  return await axios.post(address, { NewEmailAddress: newEmailAddress, Password: password }, config).catch((ex) => {
    console.error(ex);
    appInsights.trackException({
      exception: new Error('Failed to update email address'),
      properties: { newEmailAddress, password },
    });
    throw ex;
  });
};

export const postEmailConfirmation = (userId, code) => {
  const address = `${ConfigurationService.store.apiUrl}/${apiMyAccountUrl.CONFIRM_UPDATE_CONTACT_EMAIL_ADDRESS}?userId=${userId}&code=${code}`;

  return axios.get(address).catch((ex) => {
    console.error(ex);
    appInsights.trackException({ exception: new Error('Failed to confirm email from token'), properties: { userId } });
    throw ex;
  });
};

export const updateContactAsync = async (contactDetail) => {
  if (CYCServices.JWTAuth.isAuthenticated() === false) return Promise.reject(AUTH_FAILURE_MESSAGE);

  const address = `${ConfigurationService.store.apiUrl}${apiMyAccountUrl.UPDATE_CONTACT_PROFILE}`;
  const config = {
    headers: { Authorization: `Bearer ${CYCServices.JWTAuth.getToken()}` },
  };

  return await axios.post(address, contactDetail, config).catch((ex) => {
    console.error(ex);
    appInsights.trackException({ exception: new Error('Failed to update contact details'), properties: contactDetail });
    throw ex;
  });
};
export const updateContactNameAsync = async (firstName, lastName) => {
  if (CYCServices.JWTAuth.isAuthenticated() === false) {
    return Promise.reject(AUTH_FAILURE_MESSAGE);
  }

  const address = `${ConfigurationService.store.apiUrl}${apiMyAccountUrl.UPDATE_CONTACT_NAME}`;
  const config = {
    headers: {
      Authorization: `Bearer ${CYCServices.JWTAuth.getToken()}`,
    },
  };

  return axios.post(address, { firstName, lastName }, config).catch((ex) => {
    console.error(ex);
    appInsights.trackException({
      exception: new Error('Failed to update contact name'),
      properties: { firstName, lastName },
    });
    throw ex;
  });
};

export const updateContactAddressAsync = async (addressDetails) => {
  if (CYCServices.JWTAuth.isAuthenticated() === false) {
    return Promise.reject(AUTH_FAILURE_MESSAGE);
  }

  const address = {
    ...addressDetails,
    easting: addressDetails.easting.toString(),
    northing: addressDetails.northing.toString(),
  };

  const addressUrl = `${ConfigurationService.store.apiUrl}${apiMyAccountUrl.UPDATE_CONTACT_ADDRESS}`;

  const config = {
    headers: { Authorization: `Bearer ${CYCServices.JWTAuth.getToken()}` },
  };

  return await axios.post(addressUrl, address, config).catch((ex) => {
    console.error(ex);
    appInsights.trackException({
      exception: new Error('Failed to update contact address'),
      properties: address,
    });
    throw ex;
  });
};

export const updateContactPhoneAsync = async (phoneNumber) => {
  if (CYCServices.JWTAuth.isAuthenticated() === false) return Promise.reject(AUTH_FAILURE_MESSAGE);
  const address = `${ConfigurationService.store.apiUrl}${apiMyAccountUrl.UPDATE_CONTACT_PHONE}`;

  const config = {
    headers: { Authorization: `Bearer ${CYCServices.JWTAuth.getToken()}` },
  };

  return await axios.post(address, { TelephoneNumber: phoneNumber }, config).catch((ex) => {
    console.error(ex);
    appInsights.trackException({ exception: new Error('Failed to update phone number') });
    throw ex;
  });
};
