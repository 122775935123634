// Live
export const myAccountSessionConstants = {
  duration: [15, 'minutes'],
  rateLimit: [3, 'minutes'],
  warningDuration: [30, 'seconds'],
  eventListeners: ['click', 'keyup', 'mousemove', 'scroll'],
};

// Test
// export const myAccountSessionConstants = {
//     duration: [20, 'seconds'],
//     rateLimit: [5, 'seconds'],
//     warningDuration: [10, 'seconds'],
//     eventListeners: ['click', 'keyup', 'mousemove', 'scroll']
// };
