import axios from 'axios';
import { ConfigurationService } from 'cyc-configuration-service';

import appInsights from 'cyc-application-insights-react';

import { apiMyAccountUrl as urls, categoriesToIgnoreInReports } from '../../common';
import { CYCServices } from 'cyc-services';

const getReports = async () => {
  if (CYCServices.JWTAuth.isAuthenticated() === false) {
    throw new Error('Client must be authenticated to perform this call');
  }

  const url = `${ConfigurationService.store.apiUrl}${urls.CUSTOMER_INCIDENTS}`;
  const auth = `Bearer ${CYCServices.JWTAuth.getToken()}`;
  const result = await axios
    .get(url, {
      headers: {
        Authorization: auth,
      },
    })
    .catch((ex) => {
      console.error(ex);
      appInsights.trackException({ exception: ex });
      throw ex;
    });

  const categoryIdsToFilter = categoriesToIgnoreInReports.map((category) => {
    let drillDownArray = [...category.drillDownArray];
    drillDownArray.push(category.settingName);
    return ConfigurationService.getDrillDownSettingValue(drillDownArray).id;
  });

  return result.data
    .filter((incident) => !categoryIdsToFilter.includes(incident.categoryId.toString()))
    .map((incident) => ({
      incidentId: incident.incidentId,
      reference: incident.referenceNumber,
      created: new Date(incident.createdTime),
      subject: incident.subject || '',
      status: incident.status || '',
      outcome: incident.outcome || '',
      location: incident.location || '',
      sourceId: incident.sourceId || '',
      productId: incident.productId || -1,
      product: incident.product || ''
    }));
};

export { getReports };
