import React from 'react';
import { FeatureFlagsService } from 'cyc-configuration-service';
import { clientMyAccountUrl, isEnabled, ChangeName } from '../../common';
import AccordionInternalComponent from './accordion-internal/accordion-internal.component';

const MyDetailsAccordion = () => {
  let myDetailsAccordion = [
    {
      linkText: 'View contact details',
      linkUrl: clientMyAccountUrl.account.root + clientMyAccountUrl.account.contactDetails,
      isExternal: false,
    },
    {
      linkText: 'Communication preferences',
      linkUrl: clientMyAccountUrl.account.root + clientMyAccountUrl.account.communicationPreferences,
      isExternal: false,
    },
    {
      linkText: 'Change telephone number',
      linkUrl: clientMyAccountUrl.account.root + clientMyAccountUrl.account.phone.change,
      isExternal: false,
    },
    {
      linkText: 'Change address',
      linkUrl: clientMyAccountUrl.account.root + clientMyAccountUrl.account.address.change,
      isExternal: false,
    },
    {
      linkText: 'Change email address',
      linkUrl: clientMyAccountUrl.account.root + clientMyAccountUrl.account.email.change,
      isExternal: false,
    },
  ];
  if (isEnabled(FeatureFlagsService.getFeatureFlagByName(ChangeName))) {
    myDetailsAccordion.push({
      linkText: 'Change name',
      linkUrl: clientMyAccountUrl.account.root + clientMyAccountUrl.account.name.change,
      isExternal: false,
    });
  }

  return (
    <>
      <AccordionInternalComponent
        callToActionLink={{
          linkText: 'Change password',
          linkUrl: clientMyAccountUrl.account.root + clientMyAccountUrl.account.password.change,
          isExternal: false,
        }}
        linksToRender={myDetailsAccordion}
      />
    </>
  );
};

export default MyDetailsAccordion;
