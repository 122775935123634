import React from 'react';
import { DocumentTitleComponent, PageHeaderComponent, MyAccountPageComponent } from '../..';

const SiteLoaderComponent = () => {
  return (
    <DocumentTitleComponent title="My Account - Dashboard">
      <PageHeaderComponent text="My Account" icon="icon-website" />
      <MyAccountPageComponent>
        <p>Initialising...</p>
      </MyAccountPageComponent>
    </DocumentTitleComponent>
  );
};

export default SiteLoaderComponent;
