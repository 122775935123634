import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  BinCollectionsContainer,
  MyAccountPageComponent,
  clientMyAccountUrl,
  PageHeaderComponent,
} from '../../../common';
import { getContactDetailAsync } from '../../../account/services/account.service';

const MyCollectionsContainer = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [customerUprn, setCustomerUprn] = useState();
  const [customerAddress, setCustomerAddress] = useState();

  // Getting the uprn on load
  useEffect(() => {
    const myGetContactDetails = async () => {
      try {
        const contactDetails = await getContactDetailAsync(true);

        if (contactDetails.status !== 200) {
          throw Error();
        }

        setCustomerUprn(contactDetails.data.uprn);
        setCustomerAddress(contactDetails.data.address);
        setIsLoading(false);
      } catch {
        setIsLoading(false);
      }
    };

    myGetContactDetails();
  }, []);

  return (
    <React.Fragment>
      <PageHeaderComponent text="My collection details" />
      <MyAccountPageComponent className="bin-collections-lookup">
        <h2>Your next collection dates</h2>

        <p>{customerAddress}</p>

        {isLoading && (
          <div className="network-loader cyc-box network-loader--loading">
            <i>Loading...</i>
          </div>
        )}

        {customerUprn && customerUprn.length > 0 && (
          <BinCollectionsContainer
            uprn={customerUprn}
            calendarUrl={clientMyAccountUrl.refuseAndRecycling.myCalendar}></BinCollectionsContainer>
        )}

        <Link className="link-inline-with-button" to={clientMyAccountUrl.dashboard}>
          Back to My Account
        </Link>
      </MyAccountPageComponent>
    </React.Fragment>
  );
};

export default MyCollectionsContainer;
