import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import usePrevious from 'common/hooks/use-previous.hook';

import './network-loader.component.scss';
import { msgConstants } from '../../constants/msg.constants';

const NetworkLoaderComponent = ({ children, request, ...props }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  const previousIsLoading = usePrevious(isLoading);
  const previousRequest = usePrevious(request);

  useEffect(() => {
    if (previousIsLoading === false && previousRequest !== request) {
      setIsLoading(true);
    }
    request
      .then(() => {
        setIsLoading(false);
        setHasError(false);
      })
      .catch(() => {
        setIsLoading(false);
        setHasError(true);
      });
  }, [request, previousIsLoading, previousRequest]);

  return (
    <React.Fragment>
      {isLoading && (
        <div className="network-loader cyc-box network-loader--loading">
          <i>Loading...</i>
        </div>
      )}
      {hasError && (
        <div className="network-loader cyc-box network-loader--error">
          {props.errorMessage !== undefined ? props.errorMessage : msgConstants.GENERIC_API_ERROR}
        </div>
      )}
      {isLoading === false && hasError === false && children}
    </React.Fragment>
  );
};

NetworkLoaderComponent.propTypes = {
  request: PropTypes.object.isRequired,
  children: PropTypes.any,
};

export default NetworkLoaderComponent;
