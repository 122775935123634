import { NavigationType } from 'react-router-dom';

/**
 * Takes react navigation hool and a url path and asks if use would like to go. V6 refers to react-router-dom version
 * @param {NavigationType} navigate  - useNavigate() hook
 * @param {string} path - Path to navigate to
 */
const RedirectWithWindowConfirmV6 = (navigate, path) => {
  if (window.confirm('This action will reset this form. Are you sure you want to continue?')) {
    navigate(path);
  }
};
export { RedirectWithWindowConfirmV6 };
