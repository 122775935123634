import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ConfigurationService } from 'cyc-configuration-service';

import { getIncidentDetails, updateIncident } from '../../../common/services/incident.service';
import { reportDetailsItems } from '../../constants/report.detail.items';
import ReportDetailsComponent from '../../components/report-details/report-details.component';
import AdditionalInfoComponent from '../../components/report-details/additional-info.component';
import CommunicationHistoryComponent from '../../components/report-details/communication-history.component';
import {
  DocumentTitleComponent,
  MyAccountPageComponent,
  PageHeaderComponent,
  NetworkLoaderComponent,
  msgConstants,
  fileInputConstants,
  clientMyAccountUrl,
  incidentSourceIdsSettingName,
  incidentSourceSettingNames,
  waterAndDrainageTypeSettingsNames,
  waterandDrainageTypesSettingName,
} from '../../../common';
import { withRouter } from 'common/helpers/withRouter.hoc';

const INCIDENT_RESOLVED_STATUS = 'Solved';

const ReportDetailsContainer = () => {
  const { referenceNumber, product } = useParams();
  const [incident, setIncident] = useState({
    product: '',
    incidentId: null,
    items: [],
    files: [],
    commHistory: [],
    promise: undefined,
    webchatSourceId: undefined,
  });
  const [promise, setPromise] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [webchatSourceId, setWebchatSourceId] = useState();
  const [hideUploadPhoto, setHideUploadPhoto] = useState(false);
  const [hideAdditionalInformation, setHideAdditionalInformation] = useState(false);

  const reportBreadcrumbs = [
    {
      url: clientMyAccountUrl.myReports.root,
      name: 'My report',
    },
    {
      url: clientMyAccountUrl.myReports.details,
      name: 'My report details',
    },
  ];

  useEffect(() => {
    getIncidentDetailsAsync();
    const webchatSourceSettings = ConfigurationService.getDrillDownSettingValue([
      incidentSourceIdsSettingName,
      incidentSourceSettingNames.webchat,
    ]);
    const webchatSourceId = webchatSourceSettings ? webchatSourceSettings.id : undefined;
    setWebchatSourceId(webchatSourceId);
  }, []);

  const handleSubmitIncidentAsync = async (updateNotes, uploadedFiles) => {
    setErrorMessage(msgConstants.GENERIC_API_ERROR);

    const updatePromise = updateIncident({
      incidentId: incident.incidentId,
      updateNotes: updateNotes,
      uploadedFiles: uploadedFiles,
    }).then(getIncidentDetailsAsync);

    setPromise(updatePromise);
  };

  const getIncidentDetailsAsync = async () => {
    let results = [];
    setErrorMessage(msgConstants.REPORT_FAIL);
    const incidentDetailsPromise = getIncidentDetails(referenceNumber, product).then((response) => {
      if (response.status === 200) {
        const incident = response.data;

        // Some products have the same productLookupName which sucks. We can specify a product ID instead.
        // Because product id changes between environments, it must be stored in configuration.
        // This is time consuming to maintain, so we only do these when the product lookup name matches.
        const overridingProduct = ConfigurationService.getDrillDownSettingValue(['myReports', 'productIdMatrix']).find(
          (product) => product.id === incident.productId
        );

        if (overridingProduct) {
          incident.product = overridingProduct.lookupName;
        }

        let product = reportDetailsItems.find((x) => x.productLookupName === incident.product);

        // Display default report details if can't find matched product lookup name
        if (product === undefined) {
          if (incident.sourceId === webchatSourceId) {
            product = reportDetailsItems.find((item) => item.productLookupName === 'Webchat');
          } else {
            product = reportDetailsItems.find((x) => x.productLookupName === 'Default');
          }
        }

        // Iterate over the product reportItems (fields to display)
        product.reportItems.forEach((template) => {
          const incidentFieldValue = incident[template.paramName];

          // Special case - if streetName appears but has no value, show "Selected on map" instead.
          if (template.paramName === 'streetName' && incidentFieldValue === null) {
            results.push({
              displayName: template.displayName,
              value: 'Selected on map',
            });
            return;
          }
          // Special case - Do not show categoryId if it's a surface water incident
          if (
            template.paramName === 'categoryId' &&
            incident.waterDrainageTypeId &&
            incident.waterDrainageTypeId.toString() ===
              ConfigurationService.getDrillDownSettingValue([
                waterandDrainageTypesSettingName,
                waterAndDrainageTypeSettingsNames.surfaceWater,
              ]).id
          ) {
            return;
          }

          if (incidentFieldValue !== null && incidentFieldValue !== undefined) {
            const value = template.render === undefined ? incidentFieldValue : template.render(incidentFieldValue);

            results.push({
              displayName: template.displayName,
              value,
            });
          }
        });

        // Determine whether to show or hide upload photo and additional information
        setHideUploadPhoto(incident.sourceId === webchatSourceId);
        setHideAdditionalInformation(
          incident.sourceId === webchatSourceId || incident.status === INCIDENT_RESOLVED_STATUS
        );

        setIncident({
          product: incident.product,
          incidentId: incident.incidentId,
          items: results,
          files: incident.fileAttachments,
          commHistory: [...incident.communicationHistory].reverse(),
          sourceId: incident.sourceId,
        });
      }
    });

    setPromise(incidentDetailsPromise);
    setErrorMessage(msgConstants.REPORT_FAIL);
  };

  const parseProductToHeaderString = (title) => {
    if (title === 'Missing-Lighting Asset') {
      return 'street lighting';
    }
    return title;
  };

  return (
    <React.Fragment>
      {incident.product ? (
        <PageHeaderComponent
          text={`Report ${parseProductToHeaderString(incident.product).toLowerCase()}`}
          icon="icon-website"
          breadcrumbs={reportBreadcrumbs}
        />
      ) : incident.sourceId === webchatSourceId ? (
        <PageHeaderComponent text="City of York Council webchat" icon="icon-website" breadcrumbs={reportBreadcrumbs} />
      ) : (
        <PageHeaderComponent text="My Account" icon="icon-website" breadcrumbs={reportBreadcrumbs} />
      )}

      <MyAccountPageComponent>
        <DocumentTitleComponent title="My Account - my reports">
          {promise !== undefined && (
            <NetworkLoaderComponent request={promise} errorMessage={errorMessage}>
              <ReportDetailsComponent
                details={incident.items}
                files={incident.files}
                hideUploadPhoto={hideUploadPhoto}
              />
              {!hideAdditionalInformation && (
                <AdditionalInfoComponent
                  submitIncident={handleSubmitIncidentAsync}
                  maxFileUploadCount={fileInputConstants.maxFileUploadCount - incident.files.length}
                />
              )}
              <CommunicationHistoryComponent commHistory={incident.commHistory} />
            </NetworkLoaderComponent>
          )}
          <Link className="link-inline-with-button" to={clientMyAccountUrl.myReports.root}>
            Back to My reports
          </Link>
        </DocumentTitleComponent>
      </MyAccountPageComponent>
    </React.Fragment>
  );
};

export default withRouter(ReportDetailsContainer);
