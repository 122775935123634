import React from 'react';
import { clientMyAccountUrl } from '../../common';
import AccordionInternalComponent from './accordion-internal/accordion-internal.component';

const MyReportsAccordion = () => {
  return (
    <AccordionInternalComponent
      callToActionLink={{
        linkText: 'New Report',
        linkUrl: 'https://www.york.gov.uk/reportproblems',
        isExternal: true,
      }}
      linksToRender={[{ linkText: 'My reports', linkUrl: clientMyAccountUrl.myReports.root, isExternal: false }]}
    />
  );
};

export default MyReportsAccordion;
