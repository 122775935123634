import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { FeatureFlagsService } from 'cyc-configuration-service';

import { clientMyAccountUrl, NotFoundComponent, MyCollections, isEnabled } from '../common';
import MyCollectionsContainer from './my-collections/containers/my-collections.container';
import MyCalendarContainer from './my-collections/containers/my-calendar.container';
import WithAuthentication from 'common/components/with-authentication/with-authentication.component';

const RefuseAndRecyclingRouter = () => (
  <WithAuthentication>
    <Routes>
      {isEnabled(FeatureFlagsService.getFeatureFlagByName(MyCollections)) ? (
        <>
          <Route path={clientMyAccountUrl.refuseAndRecycling.myCollections} element={<MyCollectionsContainer />} />
          <Route path={`${clientMyAccountUrl.refuseAndRecycling.myCalendar}/:uprn`} element={<MyCalendarContainer />} />
        </>
      ) : null}

      <Route path="/" element={<NotFoundComponent />} />
    </Routes>
  </WithAuthentication>
);

export default RefuseAndRecyclingRouter;
