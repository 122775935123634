import { CYCServices } from 'cyc-services';
import { ConfigurationService } from 'cyc-configuration-service';

import {
  clientMyAccountUrl,
  apiMyAccountUrl,
  mapConstants,
  processCategoryIdsSettingName,
  apiSettingName,
  graffitiSettingName,
} from '../../common';

export const testName = 'Road and street sign problems';

export class FormConfiguration {
  userIsLoggedIn = CYCServices.JWTAuth.isAuthenticated;
  baseUrl = clientMyAccountUrl.roadAndStreetSign;
  stepUrls = [
    `${this.baseUrl.root}`,
    `${this.baseUrl.root + this.baseUrl.step1}`,
    `${this.baseUrl.root + this.baseUrl.step2}`,
    `${this.baseUrl.root + this.baseUrl.step3}`,
    `${this.baseUrl.root + this.baseUrl.step4}`,
    `${this.baseUrl.root + this.baseUrl.confirmation}`,
  ];
  pageHeaderText = 'Report a road or street sign problem';
  documentTitle = 'My Account - Report a road or street sign problem';
  totalSteps = 5;
  subject = 'Road and street sign';
  missingAssetCheckBoxLabel = "I can't find the sign on the map";
  roadSignFeatureLayerId = 'roadSignFeatures';
  roadSignFeatureLayerUrl = 'https://maps.york.gov.uk/arcgis/rest/services/Public/LV_TranStreetCare/MapServer/21';
  streetNameplatesFeatureLayerId = 'streetNameplatesFeatures';
  streetNameplatesFeatureLayerUrl = 'https://maps.york.gov.uk/arcgis/rest/services/Public/LV_TranStreetCare/MapServer/23';
  enablePointSelectionZoomLevels = mapConstants.enablePointSelectionZoomLevels;
  mapIncidentLayerId = 'roadAndStreetSignIncidents';
  mapIncidentLayerTitle = 'Problem description';
  mapIncidentsUrl = `${ConfigurationService.getEnvironmentSettingByName(apiSettingName)}${
    apiMyAccountUrl.getIncidents.ROAD_AND_STREET_SIGN
  }`;

  // Entry page constants
  whatToReportText = {
    text: 'problems',
    url: 'https://www.york.gov.uk/ReportStreetSigns',
  };

  beforeYouBeginText = [
    {
      text: 'problems we can help with',
      url: 'https://www.york.gov.uk/ReportStreetSigns#problemswecanhelpwith',
    },
    {
      text: 'our road and street sign problem response times',
      url: 'https://www.york.gov.uk/ReportStreetSigns#responsetimes',
    },
  ];

  // Values from configuration service
  signTypes = [];
  signTypesPure = []; // Not set on Object.Values so we have access to object name
  categories = [];
  productId;
  missingAssetProductId;

  constructor() {
    const { productId, categoryIds, signTypes, missingAssetProductId } =
      ConfigurationService.getEnvironmentSettingByName(processCategoryIdsSettingName.roadAndStreetSign);
    this.signTypesPure = signTypes;
    this.productId = productId;
    this.missingAssetProductId = missingAssetProductId;
    this.categories = Object.values(categoryIds);
    this.signTypes = Object.values(signTypes);
  }

  shouldDisplayBasedOnStep = (number) => number > 0 && number <= this.totalSteps;

  getGraffitiSettings = () => {
    return ConfigurationService.getEnvironmentSettingByName(graffitiSettingName);
  };

  getProblemTypes = () => {
    return Object.entries(
      ConfigurationService.getDrillDownSettingValue([processCategoryIdsSettingName.roadAndStreetSign, 'types'])
    ).map((type) => type[1]);
  };

  getProductId = () => {
    return ConfigurationService.getDrillDownSettingValue([
      processCategoryIdsSettingName.roadAndStreetSign,
      'productId',
    ]);
  };
}
