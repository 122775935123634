import { clientMyAccountUrl, myAccountHeaderText } from 'common';

/**
 * Method to work out the header text to display based on Url's
 * @returns a string for displaying on the header
 */
export const HeaderTextFromUrl = ({ url, initialHeaderText }) => {
  let headerText = initialHeaderText;

  if (url.includes(clientMyAccountUrl.signIn)) {
    headerText = myAccountHeaderText.signInHeader;
  }
  if (url.includes(clientMyAccountUrl.register)) {
    headerText = myAccountHeaderText.registerHeader;
  }
  return headerText;
};
