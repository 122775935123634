import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import './communication-history.component.scss';
import { TableComponent, TableClasses } from 'cyc-react-ui-components';

const CommunicationHistoryComponent = ({ commHistory }) => (
  <div className="communication-history">
    <h2>Communication history</h2>
    {commHistory != null && commHistory.length > 0 ? (
      commHistory.map((item, index) => (
        <TableComponent className={TableClasses.restyle} key={index}>
          <tbody>
            <tr>
              <td>
                <div>
                  <div>
                    <b>{item.origin}</b>
                  </div>
                  <div>
                    <b>{moment(item.createdTime).format('DD/MM/YYYY hh:mm A')}</b>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td dangerouslySetInnerHTML={{ __html: item.text.replace(/<\/?SPAN[^>]*>/g, '') }}></td>
            </tr>
          </tbody>
        </TableComponent>
      ))
    ) : (
      <div className="communication-history__no-record">
        <p>No communication history is found</p>
      </div>
    )}
  </div>
);

CommunicationHistoryComponent.propTypes = {
  commHistory: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.arrayOf(
      PropTypes.shape({
        origin: PropTypes.node,
        createdTime: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
        text: PropTypes.text,
      })
    ).isRequired,
  ]),
};

export default CommunicationHistoryComponent;
