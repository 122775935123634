import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MyAccountPageComponent, DocumentTitleComponent, PageHeaderComponent } from '../../index';
import { clientMyAccountUrl } from '../..';
import { ButtonComponent, ButtonClasses } from 'cyc-react-ui-components';
const GO_BACK = 'goBack';

const ErrorPageComponent = () => {
  const navigate = useNavigate();
  const [reload, setReload] = useState(false);
  useEffect(() => {
    if (reload) {
      window.location.reload();
    }
  }, [reload]);
  const handleButtonClicked = (target) => {
    if (target === GO_BACK) {
      navigate(-1);
    } else {
      navigate(clientMyAccountUrl.dashboard);
    }
    setReload(true);
  };

  return (
    <DocumentTitleComponent title="My Account - Something has gone wrong">
      <PageHeaderComponent text="My Account - Something has gone wrong" />
      <MyAccountPageComponent>
        <p>
          We are afraid something has gone wrong while trying to load My Account. Please use the buttons below to try
          and reload or go to dashboard.
        </p>
        <ButtonComponent onClick={() => handleButtonClicked(GO_BACK)} className={ButtonClasses.primary + ' float-left'}>
          Reload
        </ButtonComponent>
        <ButtonComponent onClick={() => handleButtonClicked()} className={ButtonClasses.primary + ' float-right'}>
          Go to Dashboard
        </ButtonComponent>
      </MyAccountPageComponent>
    </DocumentTitleComponent>
  );
};

export default ErrorPageComponent;
