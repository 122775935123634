import React, { useState, useEffect } from 'react';

import { getContactDetailAsync, updateContactEmailAsync } from '../../services/account.service';
import ChangeEmailComponent from '../components/email-change/change-email.component';
import {
  PageHeaderComponent,
  MyAccountPageComponent,
  DocumentTitleComponent,
  clientMyAccountUrl,
  NetworkLoaderComponent,
} from '../../../common';
import WithAuthentication from 'common/components/with-authentication/with-authentication.component';
import { useNavigate } from 'react-router-dom';

const ChangeEmailContainer = () => {
  const navigate = useNavigate();
  const [currentEmail, setCurrentEmail] = useState();
  const [currentEmailPromise, setCurrentEmailPromise] = useState();

  useEffect(() => {
    const currentEmailPromise = getContactDetailAsync(false);
    setCurrentEmailPromise(currentEmailPromise);

    currentEmailPromise
      .then((response) => setCurrentEmail(response.data.primaryEmail))
      .catch((error) => error && console.error(error));
  }, []);

  return (
    <WithAuthentication>
      <PageHeaderComponent text="Change email address" icon="icon-website" />
      <MyAccountPageComponent>
        <DocumentTitleComponent title="My account - change email">
          {currentEmailPromise !== undefined && (
            <NetworkLoaderComponent request={currentEmailPromise}>
              <ChangeEmailComponent
                onSuccessfulChange={() =>
                  navigate(clientMyAccountUrl.account.root + clientMyAccountUrl.account.email.confirmation)
                }
                onSubmit={(newEmail, password) => updateContactEmailAsync(newEmail, password)}
                currentEmail={currentEmail}
              />
            </NetworkLoaderComponent>
          )}
        </DocumentTitleComponent>
      </MyAccountPageComponent>
    </WithAuthentication>
  );
};

export default ChangeEmailContainer;
