import axios from 'axios';
import { ConfigurationService } from 'cyc-configuration-service';

import appInsights from 'cyc-application-insights-react';

import { CYCServices } from 'cyc-services';
import { apiMyAccountUrl } from '../../common';

const AUTH_FAILURE_MESSAGE = 'Not authenticated';

export const getPrimaryAddressAsync = async () => {
  if (CYCServices.JWTAuth.isAuthenticated() === false) return Promise.reject(AUTH_FAILURE_MESSAGE);
  const address = `${ConfigurationService.store.apiUrl}${apiMyAccountUrl.GET_PRIMARY_ADDRESS}`;
  const config = {
    headers: { Authorization: `Bearer ${CYCServices.JWTAuth.getToken()}` },
  };

  return axios.get(address, config).catch((ex) => {
    console.error(ex);
    appInsights.trackException({ exception: new Error('Failed to get primary address') });
    throw ex;
  });
};
