export const getBase64 = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let encoded = reader.result.replace(/^.*;base64,/, '');
      resolve(encoded);
    };
    reader.onerror = (error) => reject(error);
  });
};

export const prepareFilesForSubmission = async (files) => {
  let convertedFiles = [];

  const getFile = async (item) => {
    const content = await getBase64(item);
    const fileInput = {
      fileType: item.type,
      fileName: item.name,
      fileContent: content,
    };
    convertedFiles.push(fileInput);
  };
  await Promise.all(files.map(async (item) => getFile(item)));
  return convertedFiles;
};

/**
 * Shortens a file name to less than x amount
 * @param {File} file
 * @param {number} maxLength
 * @returns new File([])
 */
export const truncateFileNameString = (file, maxLength) => {
  if (file.name.length > maxLength) {
    const fileExtension = file.name.split('.').pop();
    const newFileName = file.name.substring(0, maxLength - (fileExtension.length + 1));

    let blob = new Blob([file], { type: file.type });
    blob.lastModifiedDate = new Date();
    blob.name = newFileName + '.' + fileExtension;

    return blob;
  }
  return file;
};

/**
 * Takes 2 arrays of base64, returns an array containing 3 arrays
 * One with the files to add, one with files to remove and one with files that would not be added
 * Preference is given to newly selected files and those higher in the list
 * @param {Array} currentlySelectedFiles
 * @param {Array} newlySelectedFiles
 * @returns {Array}
 */
export const generateFileArraysForUpload = (currentlySelectedFiles, newlySelectedFiles) => {
  let filesToUpload = [];
  let filesToRemove = [];
  let filesToNotUpload = [];

  // As a potentially quick filter we will get an array of lengths
  const newlySelectedFilesLengths = newlySelectedFiles.map((file) => file.fileContent.length);
  const currentlySelectedFilesLengths = currentlySelectedFiles.map((file) => file.fileContent.length);

  const combinedLengthsArray = [...newlySelectedFilesLengths, ...currentlySelectedFilesLengths];
  const distinctCombinedLengths = [...new Set(combinedLengthsArray)];

  if (distinctCombinedLengths.length === combinedLengthsArray.length) {
    return [newlySelectedFiles, [], []];
  }

  // If this wasn't the case then we are going to have to compare the files themselves

  // First we will compare the new files with themselves
  for (let index = 0; index < newlySelectedFiles.length; index++) {
    const file = newlySelectedFiles[index];

    // First look for it in the files to add
    if (filesToUpload.map((file) => file.fileContent).includes(file.fileContent)) {
      filesToNotUpload.push(file);
      continue;
    }

    // Next check if it is in the uploaded files
    // If it isn't add it to the file to upload

    // Find the indexes of this file in the currently uploaded files
    const indexesOfMatchingFilesInUploaded = [];
    for (let i = 0; i < currentlySelectedFiles.length; i++) {
      if (currentlySelectedFiles.map((file) => file.fileContent)[i] === file.fileContent) {
        indexesOfMatchingFilesInUploaded.push(i);
      }
    }

    // Not found therefore just upload
    if (indexesOfMatchingFilesInUploaded.length === 0) {
      filesToUpload.push(file);
      continue;
    }
    // Found examples therefore we must upload it and then remove the current file
    else {
      filesToUpload.push(file);
      indexesOfMatchingFilesInUploaded.forEach((index) => {
        filesToRemove.push(currentlySelectedFiles[index]);
      });
      continue;
    }
  }

  return [filesToUpload, filesToRemove, filesToNotUpload];
};
