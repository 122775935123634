import React from 'react';
import PropTypes from 'prop-types';
import { InputLabelComponent } from 'cyc-react-ui-components';

const ErrorLabelHandlerComponent = ({
  touched,
  errors,
  id,
  text,
  requiredInput = true,
  submitCount = 1
}) => {
  const hasError = touched[id] && errors[id] && submitCount > 0;
  return (
    <InputLabelComponent htmlFor={id} error={hasError} required={requiredInput}>
      {text}
    </InputLabelComponent>
  );
};

ErrorLabelHandlerComponent.propTypes = {
  touched: PropTypes.object,
  errors: PropTypes.object,
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  submitCount: PropTypes.number,
  requiredInput: PropTypes.bool,
};

export default ErrorLabelHandlerComponent;
