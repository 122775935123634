import axios from 'axios';
import { ConfigurationService } from 'cyc-configuration-service';

import appInsights from 'cyc-application-insights-react';

import { CYCServices } from 'cyc-services';
import { apiMyAccountUrl } from '../constants/my-account-urls.constants';

export const createIncident = (incident) => {

  // Start Debug
  console.log('Incident USRN: ', incident.locationObject.USRN ? incident.locationObject.USRN.toString() : null);
  console.log('Incident AssetID: ', incident.locationObject.assetId ? incident.locationObject.assetId.toString() : null);
  // End Debug

  const data = {
    ProductId: incident.productId,
    CategoryId: incident.problemId ? parseInt(incident.problemId) : null,
    Easting: incident.locationObject.easting,
    Northing: incident.locationObject.northing,
    StreetName: incident.locationObject.streetName,
    USRN: incident.locationObject.USRN ? incident.locationObject.USRN.toString() : null,
    RoadNumber: incident.locationObject.roadNumber ? incident.locationObject.roadNumber : null,
    Ward: incident.locationObject.ward ? incident.locationObject.ward : null,
    Parish: incident.locationObject.parish ? incident.locationObject.parish : null,
    Postcode: incident.locationObject.postcode ? incident.locationObject.postcode : null,
    Location: incident.locationObject.location ? incident.locationObject.location : null,
    AssetDescription: incident.locationObject.assetDescription ? incident.locationObject.assetDescription : null,
    AssetId: incident.locationObject.assetId ? incident.locationObject.assetId : null,
    AdditionalInformation: incident.additionalInformation,
    FileAttachments: incident.uploadedFiles ? incident.uploadedFiles : null,
    StreetLightTypeId: incident.lightingTypeId ? incident.lightingTypeId : null,
    BinsTypeId: incident.binTypeId ? incident.binTypeId : null,
    Subject: incident.subject,
    SizeOfWasteId: incident.sizeOfWaste ? parseInt(incident.sizeOfWaste) : null,
    SignTypeId: incident.signTypeId ? parseInt(incident.signTypeId) : null,
    FlytippingOther: incident.problemOther,
    OffensiveGraffiti: incident.offensiveGraffiti,
    TOID: incident.locationObject.toid,
    RoadDefectsTypeId: incident.roadDefectTypeId ? parseInt(incident.roadDefectTypeId) : null,
    WaterDrainageTypeId: incident.waterDrainageTypeId ? parseInt(incident.waterDrainageTypeId) : null,
    RainedInLast2HoursId: incident.hasItRained,
    DangerToRoadUserId: incident.waterDangerToUser,
    RiskOfInternalFloodingId: incident.riskOfInternalFlooding,
    ReoccurringProblemId: incident.reocurringProblem,
    GullyType: incident.gullyType,
    GullyNumber: incident.gullyNumber,
    StreetFurnitureTypeId: incident.streetFurnitureTypeId ? parseInt(incident.streetFurnitureTypeId) : null,
    VegetationTypeId: incident.vegetationTypeId,
    AdditionalProblemInformation: incident.additionalProblemInformation,
  };
  const url = `${ConfigurationService.store.apiUrl}${apiMyAccountUrl.INCIDENTS}`;

  const config = {
    headers: { Authorization: `Bearer ${CYCServices.JWTAuth.getToken()}` },
  };

  return axios.post(url, data, config).catch((ex) => {
    console.error(ex);
    appInsights.trackException({ exception: ex, properties: incident });
    throw ex;
  });
};

export const getIncidentDetails = async (referenceNumber, product) => {
  const url = `${ConfigurationService.store.apiUrl}${apiMyAccountUrl.GET_INCIDENT_DETAILS}/${referenceNumber}/${product}`;

  const config = {
    headers: { Authorization: `Bearer ${CYCServices.JWTAuth.getToken()}` },
  };

  return axios.get(url, config).catch((ex) => {
    console.error(ex);
    appInsights.trackException({ exception: ex, properties: { referenceNumber } });
    throw ex;
  });
};

export const updateIncidentWithUser = (referenceNumber, secretReferenceNumber, incidentId, emailAddress) => {
  const data = {
    ReferenceNumber: referenceNumber,
    SecretReferenceNumber: secretReferenceNumber,
    IncidentId: incidentId,
    EmailAddress: emailAddress,
  };

  const url = `${ConfigurationService.store.apiUrl}${apiMyAccountUrl.INCIDENTS}`;

  const config = {
    headers: { Authorization: `Bearer ${CYCServices.JWTAuth.getToken()}` },
  };
  return axios.put(url, data, config).catch((ex) => {
    console.error(ex);
    appInsights.trackException({
      exception: ex,
      properties: { referenceNumber, secretReferenceNumber, incidentId, emailAddress },
    });
    throw ex;
  });
};

export const updateIncident = async (updateDetails) => {
  const data = {
    IncidentId: updateDetails.incidentId,
    FileAttachments: updateDetails.uploadedFiles ? updateDetails.uploadedFiles : null,
    UpdateNotes: updateDetails.updateNotes === '' ? null : updateDetails.updateNotes,
  };

  const url = `${ConfigurationService.store.apiUrl}${apiMyAccountUrl.UPDATE_INCIDENT}`;

  const config = {
    headers: { Authorization: `Bearer ${CYCServices.JWTAuth.getToken()}` },
  };

  return axios.put(url, data, config).catch((ex) => {
    console.error(ex);
    appInsights.trackException({ exception: ex, properties: updateDetails });
    throw ex;
  });
};

export const getExistingIncidentsForAsset = async ({ assetId, productId, optionalincidentsToIgnore = [] }) => {
  const url = `${ConfigurationService.store.apiUrl}${apiMyAccountUrl.GET_INCIDENTS_BY_ASSET_ID}/${assetId}`;

  // Get any incidents with assetId
  try {
    const incidentResponse = await axios.get(url);
    return incidentResponse.data;
  } catch (ex) {
    console.error(ex);
    appInsights.trackException({ exception: ex, properties: { assetId, productId, optionalincidentsToIgnore } });
    throw ex;
  }
};

// Check if any live incidents with given asset and productId exisit
export const assetHasLiveIncident = async ({ assetId, productId, optionalincidentsToIgnore = [] }) => {
  const url = `${ConfigurationService.store.apiUrl}${apiMyAccountUrl.GET_INCIDENTS_BY_ASSET_ID}/${assetId}`;

  // Get any incidents with assetId
  const incidentResponse = await axios.get(url).catch((ex) => {
    console.error(ex);
    appInsights.trackException({ exception: ex, properties: { assetId, productId, optionalincidentsToIgnore } });
    throw ex;
  });

  // If the call failed just return false
  // We do not want to prevent the incident being created
  if (incidentResponse.status !== 200) {
    return false;
  }

  // Now filter the incidents by the product ID and any optional incidents to ignore
  const incidents = incidentResponse.data;

  if (
    incidents.filter(
      (incident) => incident.productId === productId && !optionalincidentsToIgnore.includes(incident.incidentId)
    ).length > 0
  ) {
    return true;
  }

  return false;
};
