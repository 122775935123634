import React, { useState } from 'react';
import { withFormik, Form } from 'formik';
import PropTypes from 'prop-types';
import { FileInputComponent } from '../../../common';
import { TextareaInputComponent, HelpTextComponent, ButtonComponent, ButtonClasses } from 'cyc-react-ui-components';

const AdditionalInfoPage = ({ values, maxFileUploadCount }) => {
  const [additionalInformation, setAdditionalInformation] = useState('');
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [submitIsDisabled, setSubmitIsDisabled] = useState(true);

  const resetForm = () => {
    setAdditionalInformation('');
    setUploadedFiles([]);
    setSubmitIsDisabled(true);
  };

  const handleAdditionalInformationChange = (e) => {
    setAdditionalInformation(e.target.value);

    if (e.target.value === '' && uploadedFiles.length === 0) {
      setSubmitIsDisabled(true);
    } else {
      setSubmitIsDisabled(false);
    }
  };

  const handleUploadFiles = (files) => {
    // clone old state
    const newFilesState = uploadedFiles.splice(0);
    newFilesState.push(...files);

    setUploadedFiles(newFilesState);
    setSubmitIsDisabled(false);
  };

  const handleRemoveFile = (index) => {
    const newFilesState = uploadedFiles.splice(0);
    newFilesState.splice(index, 1);

    setUploadedFiles(newFilesState);

    if (additionalInformation === '' && newFilesState.length === 0) {
      setSubmitIsDisabled(true);
    } else {
      setSubmitIsDisabled(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    values.submitIncident(additionalInformation, uploadedFiles);
    resetForm();
  };

  return (
    <div>
      <Form noValidate onSubmit={handleSubmit}>
        <div>
          <h2 className="mt-3">Additional information</h2>
          <TextareaInputComponent
            value={additionalInformation}
            onChange={handleAdditionalInformationChange}
            name="additionalInformation"
            placeholder="Additional information"
            rows="5"
          />
          <HelpTextComponent>Help: Provide further details, if needed</HelpTextComponent>
        </div>
        <div>
          <h2 className="mt-3">Upload a photo</h2>
          <FileInputComponent
            uploadedFiles={uploadedFiles}
            onUploadFiles={handleUploadFiles}
            onRemoveFile={handleRemoveFile}
            maxFileUploadCount={maxFileUploadCount}
          />
          <div className="row">
            <div className="col">
              <ButtonComponent
                className={ButtonClasses.primary + ' float-right'}
                type="submit"
                disabled={submitIsDisabled}>
                Submit
              </ButtonComponent>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

const AdditionalInfoComponent = withFormik({
  mapPropsToValues({ submitIncident }) {
    return {
      submitIncident: submitIncident,
    };
  },
})(AdditionalInfoPage);

AdditionalInfoComponent.propTypes = {
  submitIncident: PropTypes.func.isRequired,
};

export default AdditionalInfoComponent;
export { AdditionalInfoPage };
