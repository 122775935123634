import React, { useEffect, useCallback, useReducer, useMemo } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { CYCServices } from 'cyc-services';
import { FeatureFlagsService } from 'cyc-configuration-service';
import {
  ContinueFormOrReRoute,
  IncidentFormContainer,
  changeUrlByStepNumberv6,
  clientMyAccountUrl,
  isEnabled,
  UnadoptedLayerFlag,
  MissingAssetsFlag,
} from '../../common';
import {
  incidentLayersOptionsBuilder,
  FieldsBuilder,
  popupTemplateBuilder,
  mapIncidentDetailsToAttributesBuilder,
} from '../helpers/incident-layer-options-builder.helper';
import { getUnadoptedRoadsFeatureOptions } from '../helpers/unadopted-layer.helper';
import {
  getTOIDFromPoint,
  getPostcodeFromPointAsync,
  getUSRNFromPoint,
  getParishFromPointAsync,
  getStreetFromPoint,
  getWardFromPoint,
} from 'common/components/map-components/esri-location.service';

import * as Services from '../../common/services/incident.service';

import EntryPage from '../components-shared/entry-page.component';
import AboutProblemPageComponent from './components/about-problem-page.component';
import LocationPageComponent from '../components-shared/location-page.component';
import ProblemPageComponent from './components/problem-page.component';
import SummaryPageComponent from '../components-shared/summary-page.component';
import ConfirmationPageComponent from '../components-shared/confirmation-page.component';
import SignInContainer from '../../sign-in/containers/sign-in.container';
import RegistrationApp from '../../registration/registration.app';

import { FormConfiguration } from './form-config';
import { actions, fieldNames, initialState, reducer } from 'incident-forms/incident-form.reducer';
import { HeaderTextFromUrl } from '../helpers/header-text-from-url.helper';

const RoadAndStreetSignApp = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const formConfig = useMemo(() => new FormConfiguration(), []);
  const initState = useMemo(
    () => initialState({ pageHeaderText: formConfig.pageHeaderText }),
    [formConfig.pageHeaderText]
  );
  const [state, dispatch] = useReducer(reducer, initState);

  // Change the header text on certain steps
  useEffect(() => {
    const indexOfStep = formConfig.stepUrls.indexOf(location.pathname);
    const header = HeaderTextFromUrl({
      url: location.pathname,
      initialHeaderText: formConfig.pageHeaderText,
    });

    dispatch({
      type: actions.updateStateFields,
      payload: {
        [fieldNames.currentStep]: indexOfStep,
        [fieldNames.pageHeaderText]: header,
      },
    });
  }, [location.pathname, formConfig.pageHeaderText, formConfig.stepUrls]);

  // ---- ENTRY PAGE ------
  const handleResetForm = useCallback(
    () =>
      dispatch({
        type: actions.resetForm,
        payload: initialState({ pageHeaderText: formConfig.pageHeaderText }),
      }),
    [formConfig.pageHeaderText]
  );

  // ----ABOUT PROBLEM PAGE -----
  const handleSignTypeChange = (value) => {
    dispatch({ type: actions.resetLocation });
    dispatch({ type: actions.updateFormDataFields, payload: { [fieldNames.signTypeId]: value } });
  };

  // ----LOCATION PAGE----
  const layerOptionsBuilder = () => {
    let fieldsBuilder = new FieldsBuilder();
    fieldsBuilder.addIncidentId();
    fieldsBuilder.addFaultType();
    fieldsBuilder.addIncidentReferenceNumber();
    fieldsBuilder.addCreatedTime();
    const inc = incidentLayersOptionsBuilder({
      mapIncidentDetailsToAttributes: mapIncidentDetailsToAttributesBuilder,
      getIncidentsUrl: formConfig.mapIncidentsUrl,
      mapIncidentLayerId: formConfig.mapIncidentLayerId,
      popupTemplate: popupTemplateBuilder({
        mapIncidentLayerTitle: formConfig.mapIncidentLayerTitle,
        tableDataArray: [
          { header: 'Fault type', data: 'faultType' },
          { header: 'Date created', data: 'createdTime' },
        ],
        showAlreadyReportedMessage: true,
      }),
      fields: fieldsBuilder.getBuilderResult(),
    });

    return inc;
  };

  const featureLayerOptionsBuilder = () => {
    const signType = formConfig.signTypes.find((type) => type.id === state.formData.signTypeId);
    const isRoadSign = signType !== undefined && signType.id === formConfig.signTypesPure.road.id;
    const formLayerUrl = isRoadSign ? formConfig.roadSignFeatureLayerUrl : formConfig.streetNameplatesFeatureLayerUrl;
    const formLayerId = isRoadSign ? formConfig.roadSignFeatureLayerId : formConfig.streetNameplatesFeatureLayerId;

    const normalLayers = {
      layerUrl: formLayerUrl,
      layerId: formLayerId,
      popupTemplate: popupTemplateBuilder({
        mapIncidentLayerTitle: 'Selected sign',
        tableDataArray: [
          { header: 'Sign\'s Street Text', data: 'TEXT' }, // TEXT only exists on street name plate
          { header: 'Item ID', data: 'Item_Id' },
          { header: 'Type', data: 'IIT_TYPE' },
        ],
      }),
    };

    let featureLayer = [];
    if (isEnabled(FeatureFlagsService.getFeatureFlagByName(UnadoptedLayerFlag))) {
      if (state.missingAssetChecked === true) {
        // Only load layer on missing asset
        featureLayer.push(getUnadoptedRoadsFeatureOptions());
      } else {
        featureLayer.push(normalLayers);
      }
    } else {
      featureLayer.push(normalLayers);
    }

    return featureLayer;
  };

  const getMissingAssetOptions = () => {
    if (isEnabled(FeatureFlagsService.getFeatureFlagByName(MissingAssetsFlag))) {
      return {
        checked: state.missingAssetChecked,
        label: formConfig.missingAssetCheckBoxLabel,
        showFileInputOnMapPage: false,
        handleSwitch: () => {
          dispatch({
            type: actions.updateStateFields,
            payload: { [fieldNames.missingAssetChecked]: !state.missingAssetChecked },
          });
        },
      };
    }
  };

  const handleFeatureClicked = async (e) => {
    const toid = getTOIDFromPoint(e.feature.geometry.x, e.feature.geometry.y);
    const street = await getStreetFromPoint(e.feature.geometry.x, e.feature.geometry.y);
    const ward = await getWardFromPoint(e.feature.geometry.x, e.feature.geometry.y);
    const postcode = getPostcodeFromPointAsync(e.feature.geometry.x, e.feature.geometry.y);
    const usrn = getUSRNFromPoint(street);
    const parish = getParishFromPointAsync(e.feature.geometry.x, e.feature.geometry.y);
    const [toidValue, postcodeValue, usrnValue, parishValue] = await Promise.all([toid, postcode, usrn, parish]);
    // data from location service is more reliable so we try to use that where possible
    // if some of the information is not available, we fall back to the asset layer

    const locationObject = {
      easting: e.feature.geometry.x.toString(),
      northing: e.feature.geometry.y.toString(),
      street: street ? street : e.feature.attributes.TEXT,
      toid: toidValue,
      postcode: postcodeValue,
      usrn: usrnValue ? usrnValue.toString() : null,
      ward: ward,
      parish: parishValue,
      assetId: e.feature.attributes.Item_Id,
      filter: `Item_Id=${e.feature.attributes.Item_Id}`,
    };

    dispatch({ type: actions.updateLocationObject, payload: locationObject });
  };

  const locationPageIncidentLayerOptions = layerOptionsBuilder();
  const featureLayerOptions = featureLayerOptionsBuilder();

  const locationSelectText = (
    <>
      Search the map by postcode, street name, or your current location, to view nearby signs.
      <br />
      Use the <span aria-hidden="true" className="esri-icon esri-icon-locate"></span> icon to report a problem at your
      current location.
      <br />
      Select the sign by clicking the icon on the map.
      <br />
      Select a reported problem to view details.
    </>
  );

  // ---PROBLEM PAGE---
  const handleProblemTypeChange = (value) => {
    const problemType = possibleProblems.find((problem) => problem.id === value);

    if (problemType.alternative === true) {
      dispatch({ type: actions.updateFormDataFields, payload: { [fieldNames.isAlternative]: 'true' } });
    } else {
      dispatch({ type: actions.updateFormDataFields, payload: { [fieldNames.isAlternative]: 'false' } });
      dispatch({ type: actions.updateFormDataFields, payload: { [fieldNames.offensiveGraffiti]: undefined } });
    }

    dispatch({ type: actions.updateFormDataFields, payload: { [fieldNames.problemId]: value } });
  };

  const possibleProblems = formConfig.categories;

  const submitIncident = async () => {
    let form = { ...state.formData };
    const getIdForProduct =
      state.missingAssetChecked === false ? formConfig.getProductId() : formConfig.missingAssetProductId;
    form.productId = getIdForProduct;
    form.subject = formConfig.subject;

    if (form.isAlternative === 'true') {
      const graffitiSettings = formConfig.getGraffitiSettings();
      form.productId = graffitiSettings['productId'];
      form.subject = graffitiSettings['subject'];
    }

    // Create the incident
    try {
      const result = await Services.createIncident(form);
      if (result.status === 200) {
        dispatch({ type: actions.updateIncidentObject, payload: result.data });
        dispatch({
          type: actions.updateStateFields,
          payload: { [fieldNames.formStarted]: true, [fieldNames.formCompleted]: true },
        });
      }
    } catch {
      return false;
    }
  };

  // --- render builders ----
  const formRouteInfo = () => {
    return {
      formStarted: state.formStarted,
      formCompleted: state.formCompleted,
      formStartUrl: formConfig.stepUrls[0],
      formEndUrl: formConfig.stepUrls[formConfig.totalSteps],
      location: location,
    };
  };

  // --- SUMMARY PAGE ---
  const buildTableData = () => {
    const formData = state.formData;
    const problemWith = formConfig.signTypes.find((val) => val.id === formData.signTypeId);
    let category = formConfig.categories.find((val) => val.id === formData.problemId);

    const tableData = [
      {
        columnOne: 'Problem with',
        columnTwo: problemWith && problemWith.displayName ? problemWith.displayName : null,
        onChangeClicked: () => changeUrlByStepNumberv6(1, formConfig, navigate, 'aboutTheProblem'),
        columnTwoClassName: 'sentence-case',
      },
      {
        columnOne: 'Location',
        columnTwo:
          formData.locationObject && formData.locationObject.streetName && formData.locationObject.streetName.length > 0
            ? formData.locationObject.streetName
            : 'Selected on map',
        onChangeClicked: () => changeUrlByStepNumberv6(2, formConfig, navigate, 'location'),
      },
      {
        columnOne: 'Additional location information',
        columnTwo: formData.additionalInformation,
        onChangeClicked: () => changeUrlByStepNumberv6(2, formConfig, navigate, 'additionalInformation'),
      },
      {
        columnOne: 'About the problem',
        columnTwo: category && category.displayName ? category.displayName : null,
        onChangeClicked: () => changeUrlByStepNumberv6(3, formConfig, navigate, 'whatIsTheProblem'),
        columnTwoClassName: 'sentence-case',
      },
      {
        columnOne: 'Upload a photo',
        columnTwo: formData.uploadedFiles ? (
          <ul className="uploaded-photos">
            {formData.uploadedFiles.map((file, index) => (
              <li key={index}>{file.fileName}</li>
            ))}
          </ul>
        ) : (
          <React.Fragment />
        ),
        onChangeClicked: () => changeUrlByStepNumberv6(3, formConfig, navigate, 'uploadPhoto'),
      },
    ];

    // Only show the "Offensive graffiti" response if there is a value
    if (formData.offensiveGraffiti) {
      tableData.splice(4, 0, {
        columnOne: 'Is the graffiti offensive?',
        columnTwo: formData.offensiveGraffiti === true ? 'Yes' : 'No',
        onChangeClicked: () => changeUrlByStepNumberv6(3, formConfig, navigate, 'isGraffitiOffensive'),
      });
    }

    return tableData;
  };

  // ----- CONFIRMATION PAGE -----
  const sendUpdateIncidentWithUser = async (email) => {
    await Services.updateIncidentWithUser(
      state.incident.referenceNumber,
      state.incident.secretReferenceNumber,
      state.incident.incidentId,
      email
    ).then((result) => {
      if (result.status === 200) {
        return true;
      } else {
        return false;
      }
    });
  };

  const handleSignInCallBack = () => {
    const isSignedIn = formConfig.userIsLoggedIn();
    if (isSignedIn) {
      sendUpdateIncidentWithUser('');
    }
    // after sign in succssfully, navigate back to incident creation confirmation page
    navigate(formConfig.stepUrls[formConfig.totalSteps]);
  };

  const handleUserRegistered = (userData) => {
    dispatch({ type: actions.updateFormDataFields, payload: { [fieldNames.userLoggedIn]: true } });
    // only update incident when user's email is not confirmed
    // save the incident into temp table while wait for the user to confirm his/her email.
    if (state.formCompleted && userData.isEmailConfirmed === false) {
      sendUpdateIncidentWithUser(userData.email);
    }
  };

  return (
    <IncidentFormContainer
      displayProgressBar={() => formConfig.shouldDisplayBasedOnStep(state.currentStep)}
      pageHeaderText={state.pageHeaderText}
      documentTitle={formConfig.documentTitle}
      totalSteps={formConfig.totalSteps}
      currentStep={state.currentStep}
      breadcrumbs={[{ url: clientMyAccountUrl.roadAndStreetSign.root, name: formConfig.pageHeaderText }]}>
      <Routes>
        <Route
          path="/"
          element={
            <EntryPage
              report={formConfig.whatToReportText}
              informationArray={formConfig.beforeYouBeginText}
              goToNextPage={() =>
                changeUrlByStepNumberv6(1, formConfig, navigate, false, true, () =>
                  dispatch({
                    type: actions.updateStateFields,
                    payload: {
                      [fieldNames.formStarted]: true,
                      [fieldNames.formCompleted]: false,
                    },
                  })
                )
              }
              onResetForm={handleResetForm}
            />
          }
        />
        <Route
          path={formConfig.baseUrl.step1}
          element={
            <ContinueFormOrReRoute {...formRouteInfo()}>
              <AboutProblemPageComponent
                goToPrevious={() => changeUrlByStepNumberv6(0, formConfig, navigate)}
                goToNext={() => changeUrlByStepNumberv6(2, formConfig, navigate)}
                signTypeId={state.formData.signTypeId}
                onSignTypeChange={handleSignTypeChange}
                possibleSignTypes={formConfig.signTypes}
              />
            </ContinueFormOrReRoute>
          }
        />
        <Route
          path={formConfig.baseUrl.step2}
          element={
            <ContinueFormOrReRoute {...formRouteInfo()}>
              <LocationPageComponent
                goToNext={() => changeUrlByStepNumberv6(3, formConfig, navigate)}
                goToPrevious={() => changeUrlByStepNumberv6(1, formConfig, navigate)}
                incidentLayerOptions={locationPageIncidentLayerOptions}
                featureLayerOptions={featureLayerOptions}
                handleFeatureClicked={handleFeatureClicked}
                locationObject={state.formData.locationObject}
                locationSelected={state.formData.locationSelected}
                clearSelectedPoint={() => dispatch({ type: actions.resetLocation })}
                onMapSelected={(location) => dispatch({ type: actions.updateLocationObject, payload: location })}
                disableFreeSelect={true}
                locationSelectText={locationSelectText}
                additionalInformation={state.formData.additionalInformation}
                onAdditionalInformationChange={(e) =>
                  dispatch({
                    type: actions.updateFormDataFields,
                    payload: { [fieldNames.additionalInformation]: e.target.value },
                  })
                }
                // IF missing assets is enabled, this property is required for map select to work
                enablePointSelectionZoomLevels={formConfig.enablePointSelectionZoomLevels}
                // missing assets options
                missingAssetsOptions={getMissingAssetOptions()}
              />
            </ContinueFormOrReRoute>
          }
        />
        <Route
          path={formConfig.baseUrl.step3}
          element={
            <ContinueFormOrReRoute {...formRouteInfo()}>
              <ProblemPageComponent
                goToPrevious={() => changeUrlByStepNumberv6(2, formConfig, navigate)}
                goToNext={() => changeUrlByStepNumberv6(4, formConfig, navigate)}
                onUploadFiles={(files) => dispatch({ type: actions.updateUploadFiles, payload: files })}
                onRemoveFile={(index) => dispatch({ type: actions.removeUploadedFile, payload: index })}
                problemId={state.formData.problemId}
                onProblemTypeChange={handleProblemTypeChange}
                isGraffiti={state.formData.isAlternative}
                offensiveGraffiti={state.formData.offensiveGraffiti}
                onOffensiveGraffitiChange={(isOffensive) =>
                  dispatch({
                    type: actions.updateFormDataFields,
                    payload: { [fieldNames.offensiveGraffiti]: isOffensive },
                  })
                }
                uploadedFiles={state.formData.uploadedFiles}
                possibleProblems={possibleProblems}
              />
            </ContinueFormOrReRoute>
          }
        />
        <Route
          path={formConfig.baseUrl.step4}
          element={
            <ContinueFormOrReRoute {...formRouteInfo()}>
              <SummaryPageComponent
                goToProblemPage={() => changeUrlByStepNumberv6(3, formConfig, navigate)}
                submitIncident={submitIncident}
                tableData={buildTableData()}
              />
            </ContinueFormOrReRoute>
          }
        />
        <Route
          path={formConfig.baseUrl.confirmation}
          element={
            <ContinueFormOrReRoute {...formRouteInfo()}>
              <ConfirmationPageComponent
                referenceNumber={state.incident.referenceNumber}
                onSignInClicked={() => navigate(formConfig.baseUrl.root + clientMyAccountUrl.signIn)}
                onRegisterClicked={() => navigate(formConfig.baseUrl.root + clientMyAccountUrl.register)}
                userLoggedIn={() => CYCServices.JWTAuth.isAuthenticated()}
                goToMyAccount={() => navigate(clientMyAccountUrl.dashboard)}
                restartFormUrl={formConfig.baseUrl.root}
              />
            </ContinueFormOrReRoute>
          }
        />
        <Route
          path={clientMyAccountUrl.signIn}
          element={<SignInContainer onSuccessfulSignIn={handleSignInCallBack} />}
        />
        <Route
          path={`${clientMyAccountUrl.register}/*`}
          element={
            <RegistrationApp
              onSuccessfulRegistration={handleUserRegistered}
              extraSuccessfulContent={<p>Click 'Continue' to view the reference number for your reported problem.</p>}
              onContinueClicked={() => navigate(formConfig.baseUrl.root + formConfig.baseUrl.confirmation)}
            />
          }
        />
      </Routes>
    </IncidentFormContainer>
  );
};

export default RoadAndStreetSignApp;
