import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { clientMyAccountUrl } from '../../constants/my-account-urls.constants';

import './site-breadcrumbs.component.scss';

/**
 * @param {Array} breadcrumbs - Array of objects {url, name}
 */
const SiteBreadcrumbsComponent = (props) => {
  const hasBreadcrumbs = props.breadcrumbs !== undefined;

  // Breadcrumb with a link
  const LinkBreadcrumb = ({ crumb }) => (
    <Link className="breadcrumb__list__item--link" to={crumb.url}>
      <span>{crumb.name}</span>
    </Link>
  );

  // Breadcrumb without a link
  const NonLinkBreadcrumb = ({ crumb }) => <span>{crumb.name}</span>;

  const ListItemBreadcrumb = ({ children }) => {
    return <li className="breadcrumb__list__item">{children}</li>;
  };

  // Iterate through crumbs and return list item and links
  const Breadcrumbs = ({ breadcrumbs }) => (
    <>
      {breadcrumbs.map((crumb, index) => {
        // The last breadcrumb will only be a span and not clickable
        if (index + 1 !== breadcrumbs.length) {
          return (
            <ListItemBreadcrumb key={index}>
              <LinkBreadcrumb crumb={crumb} />
            </ListItemBreadcrumb>
          );
        }
        return (
          <ListItemBreadcrumb key={index}>
            <NonLinkBreadcrumb crumb={crumb} />
          </ListItemBreadcrumb>
        );
      })}
    </>
  );

  // This is what renders the breadcrumbs to the UI
  // Home and My Account are preset as they always exist
  return (
    <nav className="breadcrumb" aria-label="Breadcrumb Navigation">
      <ol className="breadcrumb__list">
        <li className="breadcrumb__list__item">
          <a className="breadcrumb__list__item--link" rel="home" href="https://york.gov.uk">
            <span>Home</span>
          </a>
        </li>
        <li className="breadcrumb__list__item">
          {props.breadcrumbs === undefined ? (
            <NonLinkBreadcrumb crumb={{ name: 'My Account' }} />
          ) : (
            <LinkBreadcrumb crumb={{ url: clientMyAccountUrl.dashboard, name: 'My Account' }} />
          )}
        </li>

        {hasBreadcrumbs === true && <Breadcrumbs breadcrumbs={props.breadcrumbs} />}
      </ol>
    </nav>
  );
};

SiteBreadcrumbsComponent.propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      name: PropTypes.string,
    })
  ),
};

export default SiteBreadcrumbsComponent;
