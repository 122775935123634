import React, { useEffect, useState } from 'react';

import { MyAccountPageComponent, DocumentTitleComponent, PageHeaderComponent, clientMyAccountUrl } from 'common';
import { ButtonComponent, ButtonClasses } from 'cyc-react-ui-components';

const ParkingPortalErrorPageComponent = (props) => {
  const [reload, setReload] = useState(false);
  useEffect(() => {
    if (reload) {
      window.location.reload();
    }
  }, [reload]);

  const handleButtonClicked = () => {
    props.history.push(clientMyAccountUrl.dashboard);
    setReload(true);
  };

  return (
    <DocumentTitleComponent title="My Account - Parking Portal Error">
      <PageHeaderComponent text="My Account - Parking Portal Error" />
      <MyAccountPageComponent>
        <p>
          We are afraid something has gone wrong with the transfer to Parking Portal. Please use the button below to go
          to dashboard.
        </p>
        <ButtonComponent onClick={() => handleButtonClicked()} className={ButtonClasses.primary + ' float-right'}>
          Go to Dashboard
        </ButtonComponent>
      </MyAccountPageComponent>
    </DocumentTitleComponent>
  );
};

export default ParkingPortalErrorPageComponent;
