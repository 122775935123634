import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Form, withFormik } from 'formik';
import * as Yup from 'yup';
import {
  categoryIdsSettingName,
  categorySettingNames,
  ErrorBoxComponent,
  ErrorLabelHandlerComponent,
  FileInputComponent,
  processCategoryIdsSettingName,
  setInitialPosition,
  AlertMessageComponent,
} from '../../../common';

import { ConfigurationService } from 'cyc-configuration-service';
import {
  ButtonComponent,
  ButtonClasses,
  RadiobuttonInputComponent,
  InputErrorComponent,
} from 'cyc-react-ui-components';

const ProblemPageForm = (props) => {
  const [possibleProblems, setPossibleProblems] = useState(undefined);
  const generalConditionId = ConfigurationService.getDrillDownSettingValue([
    categoryIdsSettingName,
    processCategoryIdsSettingName.roadAndPavementProblems,
    categorySettingNames.roadAndPavementProblems.generalCondition,
  ]).id;

  const errorBoxRef = useRef();
  const errors = useRef(props.errors);
  const isValid = useRef(props.isValid);

  useEffect(() => {
    errors.current = props.errors;
    isValid.current = props.isValid;
  }, [props.errors, props.isValid]);

  useEffect(() => {
    if (!props.isSubmitting && !isValid.current && Object.keys(errors.current).length > 0) {
      const errorBox = ReactDOM.findDOMNode(errorBoxRef.current);
      errorBox.scrollIntoView();
    }
  }, [props.isSubmitting]);

  useEffect(() => {
    setInitialPosition();
  }, []);

  // Get the possible problems based on the selected problem with id
  useEffect(() => {
    const problemWith = props.defectTypes.find((problemWith) => problemWith.id === props.values.problemWithId);
    setPossibleProblems(problemWith !== undefined ? problemWith.categories : undefined);
  }, [props.values.problemWithId, props.defectTypes]);

  return (
    <div className="page">
      <Form>
        <div ref={errorBoxRef}></div>
        {Object.keys(props.errors).length > 0 && props.touched.problemId && props.touched.problemWithId && (
          <ErrorBoxComponent errors={props.errors}></ErrorBoxComponent>
        )}
        <h2>About the problem</h2>
        <div className="row">
          <span id="WhereIsTheProblem">
            <ErrorLabelHandlerComponent
              touched={props.touched}
              errors={props.errors}
              text="Where is the problem?"
              tag="p"
              id="problemWithId"
              submitCount={props.submitCount}
            />
            <RadiobuttonInputComponent
              name="problemWithId"
              value={props.values.problemWithId}
              onChange={(e) => {
                props.handleReset();
                props.setFieldValue('problemWithId', e);
                props.setFieldValue('problemId', '');
                props.onProblemWithChange(e);
              }}
              error={props.errors.problemWithId && props.touched.problemWithId}
              options={props.defectTypes.map((type) => ({
                label: type.displayName,
                value: type.id,
              }))}
            />
            {props.errors.problemWithId && props.touched.problemWithId && (
              <InputErrorComponent>{props.errors.problemWithId}</InputErrorComponent>
            )}
          </span>
        </div>
        {possibleProblems !== undefined && possibleProblems.length > 0 ? (
          <div className="row">
            <span id="WhatIsTheProblem">
              <ErrorLabelHandlerComponent
                touched={props.touched}
                errors={props.errors}
                tag="p"
                text="What is the problem?"
                id="problemId"
                submitCount={props.submitCount}
              />
              <RadiobuttonInputComponent
                name="problemId"
                value={props.values.problemId}
                onChange={(e) => {
                  props.setFieldValue('problemId', e);
                  props.onProblemChange(e);
                }}
                error={props.errors.problemId && props.touched.problemId}
                options={possibleProblems.map((problem) => ({
                  label: problem.displayName,
                  value: problem.id,
                }))}
              />
              {props.errors.problemId && props.touched.problemId && (
                <InputErrorComponent>{props.errors.problemId}</InputErrorComponent>
              )}
            </span>
          </div>
        ) : undefined}

        {props.problemId === generalConditionId && (
          <AlertMessageComponent>
            <p>
              We prioritise our road, pavement and cycle path repairs based on annual 'condition and safety'
              inspections, the{' '}
              <a
                href="http://www.ukroadsliaisongroup.org/en/UKRLG-and-boards/uk-roads-board/wellmaintained-highways.cfm"
                target="_blank"
                rel="noopener noreferrer">
                Code of Practice for Highway Maintenance (opens in new tab)
              </a>
              , and our{' '}
              <a
                href="https://www.york.gov.uk/info/20014/streets_roads_and_pavements/1947/highway_maintenance_policy"
                target="_blank"
                rel="noopener noreferrer">
                Highway maintenance policy (opens in new tab)
              </a>
              .
            </p>
            <p>
              Works are planned on a priority basis and considering budget, meaning we're unable to respond to
              individual resurfacing requests. However, submitting this report helps us to identify areas for concern.
            </p>
          </AlertMessageComponent>
        )}

        <h3 className="mt-3" id="UploadPhoto">
          Upload a photo
        </h3>
        <FileInputComponent
          uploadedFiles={props.uploadedFiles}
          onUploadFiles={props.onUploadFiles}
          onRemoveFile={props.onRemoveFile}
        />
        <ButtonComponent type="submit" disabled={props.isSubmitting} className={ButtonClasses.primary + ' float-right'}>
          Next
        </ButtonComponent>
        <ButtonComponent type="button" onClick={props.goToPreviousPage}>
          Previous
        </ButtonComponent>
      </Form>
    </div>
  );
};

const ProblemPageComponent = withFormik({
  mapPropsToValues({ problemId, problemWithId, goToNextPage, uploadedFiles }) {
    return {
      problemId: problemId,
      problemWithId: problemWithId,
      goToNextPage: goToNextPage,
      uploadedFiles: uploadedFiles || [],
    };
  },
  validationSchema: Yup.object().shape({
    problemWithId: Yup.string().required("Please supply details for 'Where is the problem?'."),
    problemId: Yup.string().when('problemWithId', {
      is: (val) => val !== undefined && val !== null && val !== '',
      then: Yup.string().required("Please supply details for 'What is the problem?'."),
      otherwise: Yup.string().notRequired(),
    }),
  }),
  handleSubmit(bag) {
    bag.goToNextPage();
  },
})(ProblemPageForm);

ProblemPageComponent.propTypes = {
  problemWithId: PropTypes.string,
  onProblemWithChange: PropTypes.func.isRequired,
  problemId: PropTypes.string,
  onProblemChange: PropTypes.func.isRequired,
  onUploadFiles: PropTypes.func.isRequired,
  onRemoveFile: PropTypes.func.isRequired,
  uploadedFiles: PropTypes.array,
  goToNextPage: PropTypes.func.isRequired,
  goToPreviousPage: PropTypes.func.isRequired,
  defectTypes: PropTypes.array.isRequired,
};

export default ProblemPageComponent;
export { ProblemPageForm };
