import React from 'react';
import { withFormik, Form } from 'formik';

import { AddressLookupContainer, BinCollectionsContainer, msgConstants, clientMyAccountUrl } from 'common';

import * as constants from '../../common/constants/bin-collections.constants';

const BinCollectionsLookupContainer = ({ setFieldValue, setFieldTouched, setErrors, touched, errors, values }) => {
  const handleAddressChange = async (address, outOfYork) => {
    setFieldValue('uprn', address.uprn, true);
    setFieldValue('address', address.shortAddress, true);
    setFieldValue('selectedAddress', address, true);
    setFieldValue('outOfYork', outOfYork);
  };

  return (
    <>
      <p>{constants.ServiceDescription}</p>
      <Form>
        <AddressLookupContainer
          className="bin-collections-lookup__address-lookup"
          touched={touched}
          errors={errors}
          onAddressChange={handleAddressChange}
          onAddressSearch={(data) => {
            if (data.results === null || data.results.length < 1) {
              setFieldTouched('postcode', true, false);
              setFieldTouched('address', false, false);
              setErrors({ postcode: msgConstants.INVALID_POSTCODE_MESSAGE });
            } else {
              setFieldValue('addressSource', data.source, false);
              setFieldTouched('postcode', true, true);
              setFieldTouched('address', false, false);
            }
          }}
          onValidationError={(type) => {
            setFieldTouched('postcode', true, false);
            setFieldTouched('address', true, false);

            if (type === 'REQUIRED') {
              setErrors({ postcode: msgConstants.NO_POSTCODE_MESSAGE });
            }
            if (type === 'INVALID') {
              setErrors({ postcode: msgConstants.INVALID_POSTCODE_MESSAGE });
            }
          }}
        />
      </Form>
      {values.outOfYork === true && (
        <div className="bin-collections-lookup__loader">
          <p>We can't show you data for this premise</p>
          <p>This address is not in York and we can't show you collection details for it.</p>
        </div>
      )}
      {values.outOfYork === false && values.uprn && values.uprn.length > 0 && (
        <BinCollectionsContainer
          uprn={values.uprn}
          calendarUrl={clientMyAccountUrl.binCollections.calendarWithRoute}></BinCollectionsContainer>
      )}
    </>
  );
};

export default withFormik({})(BinCollectionsLookupContainer);
