import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ButtonComponent as Button, ButtonClasses } from 'cyc-react-ui-components';

/**
 *
 * @param report Object with text and url to display as the first line of Entry Page
 * @param informationArray Array of objects (text, url) to display as more information
 * @param goToNextPage  function to call when clicking on the next button
 */
const EntryPage = ({
  report,
  informationHeaderText = 'Before you begin, check:',
  informationArray,
  goToNextPage,
  onResetForm,
  additionalInformation = <React.Fragment />,
}) => {
  useEffect(() => {
    onResetForm();
  }, [onResetForm]);

  return (
    <div>
      <p>Use this form to report {report.url ? <a href={report.url}>{report.text}</a> : report.text}.</p>
      <p>{informationHeaderText}</p>
      <ul>
        {informationArray.map((link, index) => {
          return (
            <li key={index}>
              <a href={link.url}>{link.text}</a>
            </li>
          );
        })}
      </ul>

      {additionalInformation}

      <h2>Your privacy and security</h2>
      <p>
        Please do not use this form to send anything of a confidential nature. We do not rent, sell or trade your
        personal information; please see our <a href="https://www.york.gov.uk/privacy">privacy statement</a>.
      </p>

      <Button className={ButtonClasses.primary + ' float-right'} onClick={goToNextPage}>
        Begin this form
      </Button>
    </div>
  );
};

EntryPage.propTypes = {
  goToNextPage: PropTypes.func.isRequired,
  onResetForm: PropTypes.func.isRequired,
  report: PropTypes.shape({
    text: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
  informationHeaderText: PropTypes.string,
  informationArray: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ),
};

export default EntryPage;
