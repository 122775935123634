import React from 'react';
import { Form, withFormik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ConfigurationService } from 'cyc-configuration-service';
import {
  ErrorLabelHandlerComponent,
  NetworkCatchErrorComponent,
  ErrorBoxComponent,
  clientMyAccountUrl,
  apiMyAccountUrl,
} from '../../../common';
import {
  TextInputComponent,
  InputErrorComponent,
  SubmitButtonAxiosComponent,
  ButtonClasses,
} from 'cyc-react-ui-components';

const ChangeNameForm = ({ errors, touched, values, handleSubmit, submitCount, setFieldValue }) => {
  return (
    <Form noValidate>
      <NetworkCatchErrorComponent didCatchError={values.submitError} />
      {Object.keys(errors).length > 0 && submitCount > 0 && touched.firstName && touched.lastName ? (
        <ErrorBoxComponent errors={errors}></ErrorBoxComponent>
      ) : null}

      <div className="row">
        <div className="col">
          <ErrorLabelHandlerComponent
            touched={touched}
            errors={errors}
            text="First name"
            id="firstName"
            submitCount={submitCount}
          />
          <TextInputComponent
            name="firstName"
            id="firstName"
            value={values.firstName}
            onChange={(e) => setFieldValue('firstName', e.target.value)}
            errors={errors.firstName && touched.firstName && submitCount > 0}
          />
          {errors.firstName && touched.firstName && submitCount > 0 && (
            <InputErrorComponent>{errors.firstName}</InputErrorComponent>
          )}
        </div>
      </div>

      <div className="row">
        <div className="col">
          <ErrorLabelHandlerComponent
            touched={touched}
            errors={errors}
            text="Last name"
            id="lastName"
            submitCount={submitCount}
          />
          <TextInputComponent
            name="lastName"
            id="lastName"
            value={values.lastName}
            onChange={(e) => setFieldValue('lastName', e.target.value)}
            errors={errors.lastName && touched.lastName && submitCount > 0}
          />
          {errors.lastName && touched.lastName && submitCount > 0 && (
            <InputErrorComponent>{errors.lastName}</InputErrorComponent>
          )}
        </div>
      </div>

      <div className="row">
        <div className="col">
          <SubmitButtonAxiosComponent
            type="submit"
            className={ButtonClasses.primary + ' float-right'}
            url={`${ConfigurationService.store.apiUrl}${apiMyAccountUrl.UPDATE_CONTACT_NAME}`}
            onClick={handleSubmit}
          />
          <Link className="link-inline-with-button" to={clientMyAccountUrl.dashboard}>
            Back to My Account
          </Link>
        </div>
      </div>
    </Form>
  );
};

const ChangeNameComponent = withFormik({
  mapPropsToValues: ({ onSuccessfulChange, onSubmit }) => ({
    onSuccessfulChange,
    onSubmit,
    firstName: '',
    lastName: '',
    submitError: false,
  }),
  validationSchema: Yup.object().shape({
    firstName: Yup.string().required("Please supply details for 'First name'"),
    lastName: Yup.string().required("Please supply details for 'Last name'"),
  }),
  handleSubmit: (values, { setSubmitting, setFieldValue }) => {
    values
      .onSubmit(values.firstName, values.lastName)
      .then((response) => {
        if (response.status === 200) {
          values.onSuccessfulChange(values.firstName, values.lastName);
        } else {
          setFieldValue('submitError', true);
          setSubmitting(false);
        }
      })
      .catch(() => {
        setSubmitting(false);
        setFieldValue('submitError', true);
      });
  },
})(ChangeNameForm);

ChangeNameComponent.propTypes = {
  onSuccessfulChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ChangeNameComponent;
