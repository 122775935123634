import * as Yup from 'yup';

export const equalTo = (ref, msg) => {
  return Yup.mixed().test({
    name: 'equalTo',
    exclusive: false,
    message: msg || `${this.path} must be the same as ${this.reference}`,
    params: {
      reference: ref.path,
    },
    // Below cannot be converted to ES6. Yup gets angry
    test: function (value) {
      return value !== undefined ? value === this.resolve(ref) : true;
    },
  });
};
