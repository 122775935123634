import React from 'react';
import { FeatureFlagsService } from 'cyc-configuration-service';
import { clientMyAccountUrl, isEnabled, MyCollections } from '../../common';
import AccordionInternalComponent from './accordion-internal/accordion-internal.component';

const MyRefuseAndRecyclingAccordion = () => {
  return (
    <AccordionInternalComponent
      callToActionLink={
        isEnabled(FeatureFlagsService.getFeatureFlagByName(MyCollections))
          ? {
              linkText: 'My collection details',
              linkUrl: clientMyAccountUrl.refuseAndRecycling.myCollections,
              isExternal: false,
            }
          : null
      }
      linksToRender={[]}
    />
  );
};

export default MyRefuseAndRecyclingAccordion;
