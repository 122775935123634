import axios from 'axios';
import moment from 'moment';
import { webaspxDays } from '../constants/bin-collections.constants';
import { ConfigurationService } from 'cyc-configuration-service';

export const getBinCollectionsForUPRNAsync = async (uprn) => {
  const result = await axios.get(`${ConfigurationService.store.binCollections.binCollectionsUrl}/${uprn}`);
  if (result.services && result.services.length) {
    return [];
  }

  return result.data.services.map((service) => {
    service.lastCollected = service.lastCollected ? moment(service.lastCollected) : undefined;
    service.nextCollection = service.nextCollection ? moment(service.nextCollection) : undefined;
    // replace l with litre e.g. '180l grey rubbish sack' becomes '180 litre grey rubbish sack'
    service.binDescription = service.binDescription.toLowerCase().replace(/([0-9]+)l\s/g, '$1 litre ');

    // Change frequency wording for user friendly alternative
    // Regex will return null if frequency doesn't match (e.g. blank)
    const frequency = service.frequency !== '' ? service.frequency.split(' ') : '';
    if (frequency.length > 2) {
      const day = webaspxDays[frequency[2].toLowerCase()];
      if (day) {
        service.frequency = service.frequency.replace(frequency[2], day);
      }
    }
    if (frequency.length === 2) {
      const day = webaspxDays[frequency[1].toLowerCase()];
      if (day) {
        service.frequency = service.frequency.replace(frequency[1], day);
      }
    }

    return service;
  });
};

export const getBinCalendarForUPRNAsync = async (uprn) => {
  const response = await axios.get(`${ConfigurationService.store.binCollections.binCalendarUrl}/${uprn}`);
  // Transform date strings into moment objects
  const collections = response.data.collections.map((x) => ({ ...x, date: moment(x.date) }));
  collections.sort((a, b) => b.date - a.date);
  return collections;
};

export const getCollectionLocationForUPRNAsync = async (uprn) => {
  const response = await axios.get(`${ConfigurationService.store.binCollections.binLocationUrl}/${uprn}`);

  return response.data;
};

// Group a list of objects by a given key
// @param {Object[]} list - objects to group
// @param keyGetter - lambda expression for key on each object to group by
export const groupBy = (list, keyGetter) => {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
};
