import React from 'react';
import PropTypes from 'prop-types';
import ErrorLabelHandlerComponent from '../error-handlers/error-label-handler.component';
import { RadiobuttonInputComponent, InputErrorComponent } from 'cyc-react-ui-components';

const OffensiveGraffitiInputComponent = ({
  offensiveGraffiti,
  onOffensiveGraffitiChange,
  touched,
  errors,
  submitCount,
}) => {
  return (
    <React.Fragment>
      <div className="row">
        <span id="isGraffitiOffensive">
          <ErrorLabelHandlerComponent
            errors={errors}
            id="offensiveGraffiti"
            submitCount={submitCount}
            text="Is the graffiti offensive?"
            touched={touched}
          />
          <RadiobuttonInputComponent
            name="offensiveGraffiti"
            error={errors.offensiveGraffiti && touched.offensiveGraffiti}
            value={offensiveGraffiti}
            options={[
              {
                label: 'Yes',
                value: true,
              },
              {
                label: 'No',
                value: false,
              },
            ]}
            onChange={(e) => onOffensiveGraffitiChange(e)}
          />
          {errors.offensiveGraffiti && touched.offensiveGraffiti && (
            <InputErrorComponent>{errors.offensiveGraffiti}</InputErrorComponent>
          )}
        </span>
      </div>
    </React.Fragment>
  );
};

OffensiveGraffitiInputComponent.propType = {
  offensiveGraffiti: PropTypes.bool,
  onOffensiveGraffitiChange: PropTypes.func,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequried,
  submitCount: PropTypes.number.isRequired,
};

export default OffensiveGraffitiInputComponent;
