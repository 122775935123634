import React from 'react';
import MyReportsRouter from './my-reports.router';
import WithAuthentication from 'common/components/with-authentication/with-authentication.component';

const MyReportsApp = () => (
  <WithAuthentication>
    <MyReportsRouter />
  </WithAuthentication>
);

export default MyReportsApp;
