import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FaUser } from 'react-icons/fa';
import { ButtonComponent, ButtonClasses } from 'cyc-react-ui-components';

import './header.component.scss';
import yorkLogoSvg from '../../../resources/new-styles/images/york-logo.svg';
import burgerMenuIcon from '../../../resources/images/hamburger_menu.svg';
import { CYCServices } from 'cyc-services';
import SiteSearch from './site-search/site-search.component';

import { clientMyAccountUrl, yorkMainWebsiteUrl, UserContext } from '../..';
import FlyoutComponent from './flyout/flyout.component';

const SiteHeader = ({ onLogOut }) => {
  const [searchOpen, setSearchOpen] = useState(false);
  const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);

  const userContext = useContext(UserContext);

  const handleLogOut = () => {
    userContext.setStore('firstName', undefined);
    userContext.setStore('lastName', undefined);
    setBurgerMenuOpen(false);
    onLogOut && onLogOut();
  };

  return (
    <>
      <div className="myaccount-header">
        <div className="myaccount-header__container">
          <a
            href={yorkMainWebsiteUrl.root}
            className="myaccount-header__container__cyc-logo-container myaccount-header--black-hover-effect">
            <img src={yorkLogoSvg} alt="City of York Council Logo"></img>
          </a>
          <ul className="myaccount-header__navigation-links">
            {CYCServices.JWTAuth.isAuthenticated() ? (
              <>
                <li>
                  <Link to={clientMyAccountUrl.dashboard} className="black-hover-effect account-navigation menu-item">
                    <FaUser className="user-icon" />
                    {userContext.store.firstName + ' ' + userContext.store.lastName}
                  </Link>
                </li>
                <li>
                  <button onClick={handleLogOut} className="black-hover-effect account-navigation menu-item">
                    Sign out
                  </button>
                </li>
              </>
            ) : (
              <li>
                <a href={clientMyAccountUrl.dashboard} className="black-hover-effect account-navigation menu-item">
                  My Account
                </a>
              </li>
            )}
            <li className="search-list-item">
              <button
                className="black-hover-effect search-icon menu-item myaccount-myaccount-header--background-color"
                onClick={() => setSearchOpen(!searchOpen)}>
                <span className="myaccount-header__link-text">Search</span>
                <span className={searchOpen ? 'icon-ui-search open' : 'icon-ui-search'}></span>
                <span className={searchOpen ? 'icon-closed-ui-search open' : 'icon-closed-ui-search'}></span>
              </button>
            </li>
            <li>
              <ButtonComponent
                className={`${ButtonClasses.primary} menu-item council-services screen-large`}
                type="button"
                onClick={() => (window.location = yorkMainWebsiteUrl.councilServices)}>
                COUNCIL SERVICES
              </ButtonComponent>
              <ButtonComponent
                className={`${ButtonClasses.primary} menu-item council-services screen-small`}
                type="button"
                title="Open menu"
                onClick={() => setBurgerMenuOpen(true)}>
                <img alt="Open menu" src={burgerMenuIcon} />
              </ButtonComponent>
            </li>
            <li className="browsealoud-placeholder"></li>
          </ul>
        </div>
      </div>
      <FlyoutComponent onClose={() => setBurgerMenuOpen(false)} open={burgerMenuOpen}>
        <ul>
          <li>
            <a href={yorkMainWebsiteUrl.councilServices}>Council Services</a>
          </li>
          {CYCServices.JWTAuth.isAuthenticated() ? (
            <>
              <li>
                <Link to={clientMyAccountUrl.dashboard} onClick={() => setBurgerMenuOpen(false)}>
                  {userContext.store.firstName + ' ' + userContext.store.lastName}
                </Link>
              </li>
              <li>
                <span onClick={handleLogOut}>Sign out</span>
              </li>
            </>
          ) : (
            <li>
              <Link to={clientMyAccountUrl.dashboard} onClick={() => setBurgerMenuOpen(false)}>
                My Account
              </Link>
            </li>
          )}
        </ul>
      </FlyoutComponent>
      {<SiteSearch open={searchOpen} onClose={() => setSearchOpen(false)} />}
    </>
  );
};

SiteHeader.propTypes = {
  onLogOut: PropTypes.func.isRequired,
};

export default SiteHeader;
