import { getStreetFromPoint, getUSRNFromPoint } from '../../common/components/map-components/esri-location.service';
import { mapConstants } from 'common';

/**
 * Check if the feature layer ID matches our unadopted road layer ID
 * @param {any} e event from map layer
 */
export const isUnadoptedRoad = (e) => e.feature.layer.id === mapConstants.unadoptedLayerId;

/**
 * Custom table specifically for unadopted road popup
 * @param {string} streetName
 * @param {string} USRN
 */
const unadoptedRoadStreetAndUsrnTable = (streetName, USRN) => {
  const mapUnadoptedLayerMessage =
    "<p style='word-break: normal'>This road, pavement or path is privately owned; we're not responsible for maintaining it." +
    " See information about <u><a target='_blank' rel='noopener noreferrer' href='https://www.york.gov.uk/RoadAdoption#responsibility'>" +
    'our responsibility for adopted and unadopted roads</a></u>.</p>';

  return `<p>${mapUnadoptedLayerMessage}</p>
  <table class="esri-widget__table">
    <tr>
      <th>Street Name</th>
      <td>${streetName}</td>
    </tr>
    <tr>
      <th>USRN</th>
      <td>${USRN}</td>
    </tr>
  </table>`;
};

/**
 * Returns the content table for the unadopted road popup
 * @param {any} e
 */
const getStreetAndUsrnTableForUnadoptedRoad = async (e) => {
  const streetName = await getStreetFromPoint(e.graphic.geometry.centroid.x, e.graphic.geometry.centroid.y);
  const USRN = await getUSRNFromPoint(streetName);
  return unadoptedRoadStreetAndUsrnTable(streetName, USRN);
};

/**
 * Returns the unadopted road feature layer options
 *  * @param {string} title optional title for the popup
 */
export const getUnadoptedRoadsFeatureOptions = (title = '') => {
  const mapUnadoptedLayerUrl = 'https://maps.york.gov.uk/arcgis/rest/services/Public/LV_TranStreetCare/MapServer/39';

  return {
    layerUrl: mapUnadoptedLayerUrl,
    layerId: mapConstants.unadoptedLayerId,
    popupTemplate: {
      title,
      content: (e) => getStreetAndUsrnTableForUnadoptedRoad(e),
    },
  };
};
