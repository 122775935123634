import { CYCServices } from 'cyc-services';
import { ConfigurationService } from 'cyc-configuration-service';

import { clientMyAccountUrl, mapConstants, roadDefectTypeIdsSettingName } from '../../common';

export class FormConfiguration {
  cycleTrackId = [];
  defectTypes = {};

  constructor() {
    this.cycleTrackId = ConfigurationService.getEnvironmentSettingByName(roadDefectTypeIdsSettingName).cycleTrack.id;
    this.defectTypes = Object.values(ConfigurationService.getEnvironmentSettingByName(roadDefectTypeIdsSettingName));
  }

  userIsLoggedIn = CYCServices.JWTAuth.isAuthenticated;
  // For shortening the urls and having them in 1 place
  baseUrl = clientMyAccountUrl.roadAndPavementProblems;
  // Steps in order
  // Any urls used in graffiti that are not in here get a step count of -1
  stepUrls = [
    `${this.baseUrl.root}`,
    `${this.baseUrl.root + this.baseUrl.step1}`,
    `${this.baseUrl.root + this.baseUrl.step2}`,
    `${this.baseUrl.root + this.baseUrl.step3}`,
    `${this.baseUrl.root + this.baseUrl.confirmation}`,
  ];

  pageHeaderText = 'Report a road, pavement or path problem';
  documentTitle = 'My Account - Report a road, pavement or footpath problem';
  totalSteps = 4;
  productId = 44;
  subject = 'Road and Pavement problems';
  // Map constants
  mapIncidentLayerId = 'roadAndPavementIncidents';
  mapIncidentLayerTitle = 'Problem description';
  enablePointSelectionZoomLevels = mapConstants.enablePointSelectionZoomLevels;

  // For the progress bar
  shouldDisplayBasedOnStep = (number) => number > 0 && number <= this.totalSteps;
}
