import React from 'react';
import PropTypes from 'prop-types';

import ConfirmDialog from './confirm-dialog.component';

/**
 * A dialog for displaying when a session is about to timeout.
 *
 * props
 * @param {Boolean} isOpen - Whether to show the AutoLogoutDialog
 * @param {number} timeRemaining - How long before the session will timeout in seconds
 * @param {function} onClose - The callback for when the dialog is closed (return bool)
 */
const AutoLogoutDialog = (props) => {
  const remainingSeconds = `${props.timeRemaining} ${props.timeRemaining === 1 ? 'second' : 'seconds'}`;

  if (props.timeRemaining < 1 && props.isOpen === true) {
    props.onClose(false);
  }

  return (
    <ConfirmDialog title="Session timing out" isOpen={props.isOpen} onClose={props.onClose}>
      <p>
        Your session is about to time out due to inactivity. For security reasons you will be logged out in{' '}
        <strong>{remainingSeconds}</strong>
      </p>
      <p>Do you want to continue your session?</p>
    </ConfirmDialog>
  );
};

AutoLogoutDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  timeRemaining: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AutoLogoutDialog;
