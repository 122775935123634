import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

/**
 * Stops users from entering mid form and also stops them going back from confirmation page
 */
const ContinueFormOrReRoute = ({ formStarted, formCompleted, formStartUrl, formEndUrl, children, location }) => {
  return (
    <>
      {formStarted === false && formCompleted === false && <Navigate to={formStartUrl} />}

      {formStarted === true && formCompleted === false && <React.Fragment>{children}</React.Fragment>}

      {formCompleted === true && location.pathname !== formEndUrl && <Navigate to={formEndUrl} />}

      {formCompleted === true && location.pathname === formEndUrl && <React.Fragment>{children}</React.Fragment>}
    </>
  );
};

ContinueFormOrReRoute.propTypes = {
  formStarted: PropTypes.bool.isRequired,
  formCompleted: PropTypes.bool.isRequired,
  formStartUrl: PropTypes.string.isRequired,
  formEndUrl: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
};

export default ContinueFormOrReRoute;
