import React from 'react';
import './footer.component.scss';

import CycLogoSvgComponent from '../cyc-logo/cyc-logo-svg.component';
import { ButtonComponent, ButtonClasses } from 'cyc-react-ui-components';
import { scrollToWindowTop } from 'common/helpers/dom.helper';

const FooterComponent = () => (
  <div className="myaccount-footer">
    <div className="myaccount-footer__feedback">
      <ButtonComponent
        className={ButtonClasses.secondary}
        onClick={() => (window.location = 'https://www.york.gov.uk/feedback')}>
        Feedback
      </ButtonComponent>
      <ButtonComponent className={ButtonClasses.secondary} onClick={scrollToWindowTop} title="Back to top">
        <span className="icon icon-ui-chevron-up"></span>
      </ButtonComponent>
    </div>
    <div className="myaccount-footer__social flex-container">
      <ul>
        <li>
          <a href="https://www.facebook.com/cityofyork" title="Facebook" rel="nofollow">
            <span className="icon icon-social-facebook"></span>
            <span className="hidden">Facebook</span>
          </a>
        </li>
        <li>
          <a href="https://www.youtube.com/user/cityofyorkcouncil" rel="nofollow">
            <span className="icon icon-social-youtube" title="YouTube"></span>
            <span className="hidden">YouTube</span>
          </a>
        </li>
        <li>
          <a href="https://twitter.com/cityofyork" rel="nofollow">
            <span className="icon icon-social-twitter" title="Twitter"></span>
            <span className="hidden">Twitter</span>
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/cityyorkcouncil" rel="nofollow">
            <span className="icon icon-social-instagram" title="Instagram"></span>
            <span className="hidden">Instagram</span>
          </a>
        </li>
      </ul>
    </div>

    <div className="flex-container">
      <div className="myaccount-footer-cyc__information">
        <div>
          <ul>
            <li>
              <a href="https://www.york.gov.uk/ContactUs">Contact us</a>
            </li>
            <li>
              <a href="https:////www.york.gov.uk/privacy">Privacy</a>
            </li>
            <li>
              <a href="https://www.york.gov.uk/terms">Terms and conditions</a>
            </li>
            <li>
              <a href="https://www.york.gov.uk/security">Security</a>
            </li>
            <li>
              <a href="https://www.york.gov.uk/accessibility">Accessibility</a>
            </li>
          </ul>
          <p>Copyright &copy; {new Date().getFullYear()} City of York Council.</p>
        </div>
        <div className="myaccount-footer-cyc__information__cyc-logo-wrapper">
          <a href="https://york.gov.uk">
            <div className="myaccount-footer-cyc__information__cyc-logo">
              <CycLogoSvgComponent color="cyc-logo-white-background" />
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default FooterComponent;
