import React from 'react';
import PropTypes from 'prop-types';
import './additional-information.component.scss';
import { HelpTextComponent, InputLabelComponent, TextareaInputComponent } from 'cyc-react-ui-components';
import ErrorLabelHandlerComponent from '../error-handlers/error-label-handler.component';

/**
 * Creates a <textarea> for a formik form
 * @param {string} value - value of the textarea (only used for showing with counter)
 * @param {string} className - class for the text area
 * @param {string} name - name for the text area
 * @param {string} placeholder - placeholder for the text area
 * @param {number} maximumCount - maximum character length for the text area, used with showCounter
 * @param {boolean} showCounter - reveals a counter for the text area
 * @param {function} handleAdditionalInformationChange - sends event back
 * @param {string} headerText - displays an <h2> header with this text if provided
 * @param {string} helpText - displays a help text if provided
 * @param {object} touched - the formik touched object for the embedding form
 * @param {object} errors - the formik errors object for the embedding form
 * @param {boolean} required - whether or not the additional information field is required
 */
export const AdditionalInformationComponent = ({
  showCounter,
  value,
  name,
  maximumCount,
  className,
  placeholder,
  onAdditionalInformationChange,
  headerText,
  helpText,
  touched,
  errors,
  required = false,
}) => {
  const hasError = touched && errors && touched[name] && errors[name] !== undefined;
  const hasHeader = headerText && headerText.length > 0;
  const hasHelpText = helpText && helpText.length > 0;

  const ShowLabel = ({ hasError }) => {
    return hasError ? (
      <ErrorLabelHandlerComponent
        touched={touched}
        errors={errors}
        text={headerText}
        id={name}
        requiredInput={required}
      />
    ) : (
      <InputLabelComponent htmlFor={name} required={required}>
        {headerText}
      </InputLabelComponent>
    );
  };

  const ShowCounter = ({ showCounter }) => {
    return (
      showCounter && (
        <span className="character-counter">
          <span
            className={
              value && value.length > maximumCount
                ? 'character-counter__bold-font character-counter__error-count-exceeded'
                : 'character-counter__bold-font'
            }>
            {value ? value.length : 0}/{maximumCount}
          </span>
        </span>
      )
    );
  };

  return (
    <React.Fragment>
      <span id="additionalInformation"></span>
      {hasHeader && <ShowLabel hasError={hasError} />}

      <ShowCounter showCounter={showCounter} />

      <TextareaInputComponent
        name={name}
        placeholder={placeholder ? placeholder : 'Additional information'}
        onChange={onAdditionalInformationChange}
        error={hasError}
        value={value}
        rows="5"
        className={className}
      />

      {hasHelpText && <HelpTextComponent>{helpText}</HelpTextComponent>}
    </React.Fragment>
  );
};

AdditionalInformationComponent.propTypes = {
  showCounter: PropTypes.bool,
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  maximumCount: PropTypes.number.isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onAdditionalInformationChange: PropTypes.func.isRequired,
  headerText: PropTypes.string,
  helpText: PropTypes.string,
  required: PropTypes.bool,
};
