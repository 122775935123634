import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { ForgotPasswordFormComponent } from '../../index';
import { postForgotPasswordAsync } from '../../services/forgot-password.service';
import {
  clientMyAccountUrl,
  PageHeaderComponent,
  DocumentTitleComponent,
  NetworkCatchErrorComponent,
  MyAccountPageComponent,
} from '../../../../common';
import { useNavigate } from 'react-router-dom';

const ForgotPasswordContainer = () => {
  const navigate = useNavigate();
  const [submitError, setSubmitError] = useState(false);

  const handleSubmit = (values) => {
    const promise = postForgotPasswordAsync(values.email);
    promise
      .then((response) => {
        if (response.status === 200) {
          navigate(clientMyAccountUrl.account.root + clientMyAccountUrl.account.password.emailSent);
        }
      })
      .catch(() => {
        setSubmitError(true);
      });

    return promise;
  };

  return (
    <React.Fragment>
      <PageHeaderComponent text="Forgot password" icon="icon-website" />
      <MyAccountPageComponent>
        <DocumentTitleComponent title="My account - forgot password">
          <NetworkCatchErrorComponent didCatchError={submitError} />
          <p>
            Enter your email address and we will send you a secure link to a page where you can create a new password.
          </p>
          <ForgotPasswordFormComponent onSubmit={handleSubmit} />
        </DocumentTitleComponent>
      </MyAccountPageComponent>
    </React.Fragment>
  );
};

ForgotPasswordContainer.propTypes = {
  history: PropTypes.object,
};

export default ForgotPasswordContainer;
