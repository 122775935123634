import React, { useState, useEffect, useContext } from 'react';

import {
  PageHeaderComponent,
  MyAccountPageComponent,
  DocumentTitleComponent,
  NetworkLoaderComponent,
  clientMyAccountUrl,
  UserContext,
} from '../../../common';

import ChangeNameComponent from '../components/change-name.component';
import { getContactDetailAsync, updateContactNameAsync } from '../../services/account.service';
import WithAuthentication from 'common/components/with-authentication/with-authentication.component';
import { useNavigate } from 'react-router-dom';

const ChangeNameContainer = () => {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [networkPromise, setNetworkPromise] = useState();
  const userContext = useContext(UserContext);

  useEffect(() => {
    const promise = getContactDetailAsync(false);

    promise
      .then((result) => {
        setFirstName(result.data.firstName);
        setLastName(result.data.lastName);
      })
      .catch(() => {});

    setNetworkPromise(promise);
  }, []);

  const handleSuccessfulChange = (firstName, lastName) => {
    // Send the values into the user context so that other parts of the app update :)
    userContext.setStore('firstName', firstName);
    userContext.setStore('lastName', lastName);

    navigate(clientMyAccountUrl.account.root + clientMyAccountUrl.account.name.confirmation);
  };

  return (
    <WithAuthentication>
      <PageHeaderComponent text="Change name" icon="icon-website" />
      <DocumentTitleComponent title="My Account - change name" />
      <MyAccountPageComponent>
        {networkPromise !== undefined ? (
          <NetworkLoaderComponent request={networkPromise}>
            <div className="row">
              <div className="col">
                <p>
                  <b>Current name: </b>
                  {firstName} {lastName}
                </p>
              </div>
            </div>
            <ChangeNameComponent onSuccessfulChange={handleSuccessfulChange} onSubmit={updateContactNameAsync} />
          </NetworkLoaderComponent>
        ) : (
          <React.Fragment />
        )}
      </MyAccountPageComponent>
    </WithAuthentication>
  );
};

export default ChangeNameContainer;
