import React from 'react';
import PropTypes from 'prop-types';
import { ButtonComponent, ButtonClasses } from 'cyc-react-ui-components';

const NotLoggedInUpdatesComponent = ({ onSignInClicked, onRegisterClicked }) => {
  return (
    <React.Fragment>
      <div className="row">
        <p>
          <button className="in-text-button" onClick={onRegisterClicked}>
            Register
          </button>{' '}
          for My Account to see the status/progress of all services and reported problems by signing in online.
        </p>
      </div>

      <div className="row">
        <ButtonComponent className={ButtonClasses.primary} onClick={onSignInClicked}>
          Sign in
        </ButtonComponent>
      </div>
    </React.Fragment>
  );
};

NotLoggedInUpdatesComponent.propTypes = {
  onSignInClicked: PropTypes.func,
  onRegisterClicked: PropTypes.func,
};
export default NotLoggedInUpdatesComponent;
