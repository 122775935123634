import { ConfigurationService } from 'cyc-configuration-service';
import {
  processCategoryIdsSettingName,
  categoryIdsSettingName,
  streetFurnitureSettingName,
  vegetationSettingName,
  vegetationTypesSettingName,
} from '../../common';

export const reportDetailsItems = [
  {
    productLookupName: 'Street Cleaning',
    reportItems: [
      { displayName: 'Reference', paramName: 'referenceNumber' },
      { displayName: 'Date created', paramName: 'createdTime' },
      { displayName: 'Location', paramName: 'streetName' },
      { displayName: 'Additional location information', paramName: 'additionalInformation' },
      {
        displayName: 'About the problem',
        paramName: 'categoryId',
        render: (key) => {
          return Object.values(
            ConfigurationService.getDrillDownSettingValue([
              categoryIdsSettingName,
              processCategoryIdsSettingName.streetCleaning,
            ])
          ).find((category) => category.id === key.toString()).displayName;
        },
      },
    ],
  },
  {
    productLookupName: 'Street Lighting',
    reportItems: [
      { displayName: 'Reference', paramName: 'referenceNumber' },
      { displayName: 'Date created', paramName: 'createdTime' },
      { displayName: 'Location', paramName: 'streetName' },
      { displayName: 'Light number', paramName: 'assetId' },
      { displayName: 'Light type', paramName: 'streetLightType' },
      { displayName: 'Additional location information', paramName: 'additionalInformation' },
      {
        displayName: 'About the problem',
        paramName: 'categoryId',
        render: (key) => {
          const obj = Object.values(
            ConfigurationService.getDrillDownSettingValue([
              categoryIdsSettingName,
              processCategoryIdsSettingName.streetLighting,
            ])
          ).find((category) => category.id === key.toString());
          if (obj) {
            return obj.displayName;
          }
        },
      },
    ],
  },
  {
    productLookupName: 'Fly-tipping',
    reportItems: [
      { displayName: 'Reference', paramName: 'referenceNumber' },
      { displayName: 'Date created', paramName: 'createdTime' },
      { displayName: 'Location', paramName: 'streetName' },
      { displayName: 'Additional location information', paramName: 'additionalInformation' },
      {
        displayName: 'Type of waste',
        paramName: 'categoryId',
        render: (key) => {
          return Object.values(
            ConfigurationService.getDrillDownSettingValue([
              categoryIdsSettingName,
              processCategoryIdsSettingName.flyTipping,
            ])
          ).find((category) => category.id === key.toString()).displayName;
        },
      },
      { displayName: 'Other', paramName: 'flytippingOther' },
      { displayName: 'Amount of waste', paramName: 'sizeOfWaste' },
    ],
  },
  {
    productLookupName: 'Graffiti',
    reportItems: [
      { displayName: 'Reference', paramName: 'referenceNumber' },
      { displayName: 'Date created', paramName: 'createdTime' },
      { displayName: 'Location', paramName: 'streetName' },
      { displayName: 'Additional location information', paramName: 'additionalInformation' },
      {
        displayName: 'What graffiti is on',
        paramName: 'categoryId',
        render: (key) => {
          return Object.values(
            ConfigurationService.getDrillDownSettingValue([
              categoryIdsSettingName,
              processCategoryIdsSettingName.graffiti,
            ])
          ).find((category) => category.id === key.toString()).displayName;
        },
      },
      { displayName: 'Offensive?', paramName: 'offensiveGraffiti' },
    ],
  },
  {
    productLookupName: 'Road and Pavement problems',
    reportItems: [
      { displayName: 'Reference', paramName: 'referenceNumber' },
      { displayName: 'Date created', paramName: 'createdTime' },
      { displayName: 'Location', paramName: 'streetName' },
      { displayName: 'Additional location information', paramName: 'additionalInformation' },
      {
        displayName: 'About the problem',
        paramName: 'categoryId',
        render: (key) => {
          return Object.values(
            ConfigurationService.getDrillDownSettingValue([
              categoryIdsSettingName,
              processCategoryIdsSettingName.roadAndPavementProblems,
            ])
          ).find((category) => category.id === key.toString()).displayName;
        },
      },
      { displayName: 'Problem with', paramName: 'roadDefectsType' },
    ],
  },
  {
    productLookupName: 'Salt bins',
    reportItems: [
      { displayName: 'Reference', paramName: 'referenceNumber' },
      { displayName: 'Date created', paramName: 'createdTime' },
      { displayName: 'Location', paramName: 'streetName' },
      { displayName: 'Additional location information', paramName: 'additionalInformation' },
      {
        displayName: 'Problem type',
        paramName: 'categoryId',
        render: (key) => {
          return Object.values(
            ConfigurationService.getDrillDownSettingValue([
              categoryIdsSettingName,
              processCategoryIdsSettingName.saltBins,
            ])
          ).find((category) => category.id === key.toString()).displayName;
        },
      },
    ],
  },
  {
    productLookupName: 'Water and drainage problem',
    reportItems: [
      { displayName: 'Reference', paramName: 'referenceNumber' },
      { displayName: 'Date created', paramName: 'createdTime' },
      { displayName: 'Location', paramName: 'streetName' },
      { displayName: 'Additional location information', paramName: 'additionalInformation' },
      { displayName: 'Problem with', paramName: 'waterDrainageType' },
      {
        displayName: 'About the problem',
        paramName: 'categoryId',
        render: (key) => {
          return Object.values(
            ConfigurationService.getDrillDownSettingValue([
              categoryIdsSettingName,
              processCategoryIdsSettingName.waterAndDrainage,
            ])
          ).find((category) => category.id === key.toString()).displayName;
        },
      },
    ],
  },
  {
    productLookupName: 'Webchat',
    reportItems: [
      { displayName: 'Reference', paramName: 'referenceNumber' },
      { displayName: 'Date created', paramName: 'createdTime' },
      { displayName: 'Department', paramName: 'product' },
      {
        displayName: 'Enquiry type',
        paramName: 'categoryId',
        render: (key) => {
          return Object.entries(
            ConfigurationService.getDrillDownSettingValue([
              categoryIdsSettingName,
              processCategoryIdsSettingName.saltBins,
            ])
          )
            .map((category) => category[1])
            .find((category) => category.id === key.toString()).displayName;
        },
      },
      { displayName: 'Status', paramName: 'status' },
    ],
  },
  {
    productLookupName: 'Dog/litter bins',
    reportItems: [
      { displayName: 'Incident reference number', paramName: 'referenceNumber' },
      { displayName: 'Location', paramName: 'locationDescription' },
      { displayName: 'Additional location information', paramName: 'additionalInformation' },
      { displayName: 'Street', paramName: 'streetName' },
      { displayName: 'Bin type', paramName: 'binsType' },
      { displayName: 'Date created', paramName: 'createdTime' },
      {
        displayName: 'Problem type',
        paramName: 'categoryId',
        render: (key) => {
          return Object.values(
            ConfigurationService.getDrillDownSettingValue([
              processCategoryIdsSettingName.dogAndLitterBins,
              categoryIdsSettingName,
            ])
          ).find((category) => category.id === key.toString()).displayName;
        },
      },
    ],
  },
  {
    productLookupName: 'Dog/litter bins missing asset',
    reportItems: [
      { displayName: 'Incident reference number', paramName: 'referenceNumber' },
      { displayName: 'Location', paramName: 'streetName' },
      { displayName: 'Additional location information', paramName: 'additionalInformation' },
      { displayName: 'Date created', paramName: 'createdTime' },
      {
        displayName: 'What is the problem?',
        paramName: 'categoryId',
        render: (key) => {
          return Object.values(
            ConfigurationService.getDrillDownSettingValue([
              processCategoryIdsSettingName.dogAndLitterBins,
              categoryIdsSettingName,
            ])
          ).find((category) => category.id === key.toString()).displayName;
        },
      },
    ],
  },
  {
    productLookupName: 'Street furniture problem',
    reportItems: [
      { displayName: 'Reference', paramName: 'referenceNumber' },
      { displayName: 'Date created', paramName: 'createdTime' },
      { displayName: 'Problem with', paramName: 'streetFurnitureType' },
      {
        displayName: 'About the problem',
        paramName: 'categoryId',
        render: (key) => {
          const streetFurnitureSettings = ConfigurationService.getDrillDownSettingValue([
            streetFurnitureSettingName,
            'types',
          ]);
          for (let props in streetFurnitureSettings) {
            let category = Object.values(streetFurnitureSettings[props]['categories']).find(
              (x) => x.id === key.toString()
            );
            if (category !== undefined) {
              return category.displayName;
            }
          }
        },
      },
      { displayName: 'Location', paramName: 'streetName' },
      { displayName: 'Additional location information', paramName: 'additionalInformation' },
    ],
  },
  {
    productLookupName: 'Sign problem',
    reportItems: [
      { displayName: 'Reference', paramName: 'referenceNumber' },
      { displayName: 'Date created', paramName: 'createdTime' },
      { displayName: 'Problem with', paramName: 'signType' },
      {
        displayName: 'About the problem',
        paramName: 'categoryId',
        render: (key) => {
          return Object.values(
            ConfigurationService.getDrillDownSettingValue([
              processCategoryIdsSettingName.roadAndStreetSign,
              categoryIdsSettingName,
            ])
          ).find((category) => category.id === key.toString()).displayName;
        },
      },
      { displayName: 'Location', paramName: 'streetName' },
      { displayName: 'Additional location information', paramName: 'additionalInformation' },
    ],
  },
  {
    productLookupName: 'Vegetation',
    reportItems: [
      { displayName: 'Reference', paramName: 'referenceNumber' },
      { displayName: 'Date created', paramName: 'createdTime' },
      {
        displayName: 'Problem with',
        paramName: 'vegetationTypeId',
        render: (key) => {
          return Object.values(
            ConfigurationService.getDrillDownSettingValue([vegetationSettingName, vegetationTypesSettingName])
          ).find((type) => type.id === key.toString()).displayName;
        },
      },
      {
        displayName: 'About the problem',
        paramName: 'categoryId',
        render: (key) => {
          return Object.values(
            ConfigurationService.getDrillDownSettingValue([vegetationSettingName, categoryIdsSettingName])
          ).find((category) => category.id === key.toString()).displayName;
        },
      },
      { displayName: 'Additional details', paramName: 'additionalProblemInformation' },
      { displayName: 'Location', paramName: 'streetName' },
      { displayName: 'Additional location information', paramName: 'additionalInformation' },
    ],
  },
  {
    productLookupName: 'Default',
    reportItems: [
      { displayName: 'Reference', paramName: 'referenceNumber' },
      { displayName: 'Date created', paramName: 'createdTime' },
      { displayName: 'Location', paramName: 'streetName' },
      { displayName: 'Additional location information', paramName: 'additionalInformation' },
    ],
  },
];
