import React from 'react';
import PropTypes from 'prop-types';
import { ErrorLabelHandlerComponent } from '../..';
import { RadiobuttonInputComponent, InputErrorComponent } from 'cyc-react-ui-components';

/**
 * A radio list component
 *
 * props
 * @param {bool} touched - Whether the item has been touched in a form context
 * @param {array} errors - Array of errors for the form
 * @param {string} question - The question text
 * @param {object} radioOptions - An array of possible answers in the format { id, value }
 * @param {number} submitCount - Keeps track on the form being submitted
 * @param {function} handleChange - Callback when the selected option is changed
 *
 */
const RadioList = (props) => {
  const { question, id, radioOptions, onChange, selectedItem, touched, errors, submitCount } = props;

  return (
    <div className="row" id={id}>
      <span>
        <ErrorLabelHandlerComponent
          touched={touched}
          errors={errors}
          text={question}
          tag="p"
          id={id}
          submitCount={submitCount}
        />
        <RadiobuttonInputComponent
          name={id}
          value={selectedItem}
          onChange={onChange}
          options={radioOptions.map((options) => ({
            label: options.value,
            value: options.id,
          }))}
          error={errors[id] && touched[id]}
        />
        {errors[id] && touched[id] && <InputErrorComponent>{errors[id]}</InputErrorComponent>}
      </span>
    </div>
  );
};
RadioList.propTypes = {
  question: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  radioOptions: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedItem: PropTypes.string,
  touched: PropTypes.object,
  errors: PropTypes.object,
  submitCount: PropTypes.number.isRequired,
};

export default RadioList;
