import React, { useEffect, useReducer, useMemo, useCallback } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { CYCServices } from 'cyc-services';
import { ConfigurationService } from 'cyc-configuration-service';
import { actions, fieldNames, initialState, reducer } from 'incident-forms/incident-form.reducer';
import * as Services from '../../common/services/incident.service';
import {
  clientMyAccountUrl,
  ContinueFormOrReRoute,
  apiSettingName,
  apiMyAccountUrl,
  changeUrlByStepNumberv6,
  categorySettingNames,
} from '../../common';

import { FormConfiguration } from './form-config';
import IncidentFormContainer from '../../common/containers/incident-forms/incident-form.container';
import EntryPage from '../components-shared/entry-page.component';
import LocationPageComponent from '../components-shared/location-page.component';
import ProblemPageComponent from './components/problem-page.component';
import SummaryPageComponent from '../components-shared/summary-page.component';
import ConfirmationPage from '../components-shared/confirmation-page.component';
import SignInContainer from '../../sign-in/containers/sign-in.container';
import RegistrationApp from '../../registration/registration.app';
import { HeaderTextFromUrl } from 'incident-forms/helpers/header-text-from-url.helper';
import {
  FieldsBuilder,
  mapIncidentDetailsToAttributesBuilder,
  incidentLayersOptionsBuilder,
  popupTemplateBuilder,
} from 'incident-forms/helpers/incident-layer-options-builder.helper';

const GraffitiApp = () => {
  const location = useLocation();
  const navigate = useNavigate();
  // Hold state here...
  const formConfig = useMemo(() => new FormConfiguration(), []);
  const initState = useMemo(
    () => initialState({ pageHeaderText: formConfig.pageHeaderText }),
    [formConfig.pageHeaderText]
  );
  const [state, dispatch] = useReducer(reducer, initState);
  const getIncidentsUrl = `${ConfigurationService.getEnvironmentSettingByName(apiSettingName)}${
    apiMyAccountUrl.getIncidents.GRAFFITI
  }`;
  const possibleGraffitiLocations = formConfig.getGraffitiCategories();
  const utilityBoxId = formConfig.getProblemIdByName(categorySettingNames.graffiti.utilityBox);

  // This effect is for updating headers and step urls
  useEffect(() => {
    const indexOfStep = formConfig.stepUrls.indexOf(location.pathname);

    // Update the header based on the url.
    const header = HeaderTextFromUrl({
      url: location.pathname,
      initialHeaderText: formConfig.pageHeaderText,
    });
    dispatch({
      type: actions.updateStateFields,
      payload: { [fieldNames.currentStep]: indexOfStep, [fieldNames.pageHeaderText]: header },
    });
  }, [location.pathname, formConfig.pageHeaderText, formConfig.stepUrls]);

  // --- ENTRY PAGE ---
  const handleResetForm = useCallback(() => dispatch({ type: actions.resetForm, payload: initState }), [initState]);

  // ---- LOCATION PAGE ------

  const layerOptionsBuilder = () => {
    let fieldsBuilder = new FieldsBuilder();
    fieldsBuilder.addIncidentId();
    fieldsBuilder.addFaultType();
    fieldsBuilder.addCreatedTime('Date created');
    const inc = incidentLayersOptionsBuilder({
      mapIncidentDetailsToAttributes: mapIncidentDetailsToAttributesBuilder,
      getIncidentsUrl: getIncidentsUrl,
      mapIncidentLayerId: formConfig.mapIncidentLayerId,
      popupTemplate: popupTemplateBuilder({
        mapIncidentLayerTitle: formConfig.mapIncidentLayerTitle,
        tableDataArray: [
          { header: 'Fault type', data: 'faultType' },
          { header: 'Date created', data: 'createdTime' },
        ],
      }),
      fields: fieldsBuilder.builder,
    });
    return inc;
  };

  const handleMapSelected = (location) => {
    const locationObject = {
      ...location,
      easting: location.easting.toString(),
      northing: location.northing.toString(),
      street: location.street,
      usrn: location.usrn,
      postcode: location.postcode,
    };
    dispatch({ type: actions.updateLocationObject, payload: locationObject });
  };

  // --- PROBLEM PAGE ---
  const buildTableData = () => {
    const { formData } = state;
    const offensiveGraffiti = () => {
      if (formData.offensiveGraffiti === true) {
        return 'Yes';
      }
      if (formData.offensiveGraffiti === false) {
        return 'No';
      }
      return '';
    };

    const graffitiLocation = possibleGraffitiLocations.filter((val) => val.id === formData.problemId)[0];
    return graffitiLocation
      ? [
          {
            columnOne: 'Location',
            columnTwo:
              formData.locationObject.streetName && formData.locationObject.streetName.length > 0
                ? formData.locationObject.streetName
                : 'Selected on map',
            onChangeClicked: () => changeUrlByStepNumberv6(1, formConfig, navigate, 'location'),
          },
          {
            columnOne: 'Additional location information',
            columnTwo: formData.additionalInformation,
            onChangeClicked: () => changeUrlByStepNumberv6(1, formConfig, navigate, 'additionalInformation'),
          },
          {
            columnOne: 'What is the graffiti on?',
            columnTwo: graffitiLocation ? graffitiLocation.displayName : null,
            onChangeClicked: () => changeUrlByStepNumberv6(2, formConfig, navigate, 'graffitiOn'),
            columnTwoClassName: 'sentence-case',
          },
          {
            columnOne: 'Is the graffiti offensive?',
            columnTwo: offensiveGraffiti(),
            onChangeClicked: () => changeUrlByStepNumberv6(2, formConfig, navigate, 'isGraffitiOffensive'),
            columnTwoClassName: 'sentence-case',
          },
          {
            columnOne: 'Upload a photo',
            columnTwo: formData.uploadedFiles ? (
              <ul className="uploaded-photos">
                {formData.uploadedFiles.map((file, index) => (
                  <li key={index}>{file.fileName}</li>
                ))}
              </ul>
            ) : (
              <React.Fragment />
            ),
            onChangeClicked: () => changeUrlByStepNumberv6(2, formConfig, navigate, 'uploadPhoto'),
          },
        ]
      : [];
  };

  const handleProblemTypeChange = (value) => {
    const form = { ...state.formData };
    form.problemId = value;
    dispatch({ type: actions.updateFormDataFields, payload: { [fieldNames.problemId]: value } });
  };

  // ----- SUMMARY PAGE ----
  const submitIncident = async () => {
    const form = { ...state.formData };
    form.productId = formConfig.productId;
    form.subject = formConfig.subject;

    // Create the incident
    try {
      const result = await Services.createIncident(form);
      if (result.status === 200) {
        dispatch({ type: actions.updateIncidentObject, payload: result.data });
        dispatch({
          type: actions.updateStateFields,
          payload: { [fieldNames.formStarted]: true, [fieldNames.formCompleted]: true },
        });
      }
    } catch {
      return false;
    }
  };

  // ----- CONFIRMATION PAGE -----
  const sendUpdateIncidentWithUser = async (email) => {
    await Services.updateIncidentWithUser(
      state.incident.referenceNumber,
      state.incident.secretReferenceNumber,
      state.incident.incidentId,
      email
    ).then((result) => {
      return result.status === 200;
    });
  };

  const handleUserRegistered = (userData) => {
    dispatch({ type: actions.updateStateFields, payload: { [fieldNames.userLoggedIn]: true } });
    // only update incident when user's email is not confirmed
    // save the incident into temp table while wait for the user to confirm his/her email.
    if (state.formCompleted && userData.isEmailConfirmed === false) {
      sendUpdateIncidentWithUser(userData.email);
    }
  };

  const handleSignInCallBack = () => {
    const isSignedIn = formConfig.userIsLoggedIn();
    if (isSignedIn) {
      sendUpdateIncidentWithUser('');
    }
    // after sign in succssfully, navigate back to incident creation confirmation page
    navigate(formConfig.stepUrls[formConfig.totalSteps]);
  };

  // --- render builders ----
  const formRouteInfo = {
    formStarted: state.formStarted,
    formCompleted: state.formCompleted,
    formStartUrl: formConfig.stepUrls[0],
    formEndUrl: formConfig.stepUrls[formConfig.totalSteps],
    location: location,
  };

  return (
    <IncidentFormContainer
      displayProgressBar={() => formConfig.shouldDisplayBasedOnStep(state.currentStep)}
      pageHeaderText={state.pageHeaderText}
      documentTitle={formConfig.documentTitle}
      totalSteps={formConfig.totalSteps}
      currentStep={state.currentStep}
      breadcrumbs={[{ url: clientMyAccountUrl.graffiti.root, name: formConfig.pageHeaderText }]}>
      <Routes>
        <Route
          path="/"
          element={
            <EntryPage
              report={{
                text: 'problems with graffiti',
                url: 'https://www.york.gov.uk/ReportGraffiti',
              }}
              informationArray={[
                {
                  text: 'which graffiti problems we can help with',
                  url: 'https://www.york.gov.uk/ReportGraffiti#problemswecanhelpwith',
                },
                {
                  text: 'our graffiti response times',
                  url: 'https://www.york.gov.uk/ReportGraffiti#graffitiresponsetimes',
                },
              ]}
              goToNextPage={() => {
                dispatch({
                  type: actions.updateStateFields,
                  payload: { [fieldNames.formStarted]: true, [fieldNames.formCompleted]: false },
                });
                changeUrlByStepNumberv6(1, formConfig, navigate);
              }}
              onResetForm={handleResetForm}
            />
          }
        />

        <Route
          path={formConfig.baseUrl.step1}
          element={
            <ContinueFormOrReRoute {...formRouteInfo}>
              <LocationPageComponent
                goToNext={() => changeUrlByStepNumberv6(2, formConfig, navigate)}
                goToPrevious={() => changeUrlByStepNumberv6(0, formConfig, navigate)}
                incidentLayerOptions={layerOptionsBuilder()}
                locationObject={state.formData.locationObject}
                locationSelected={state.formData.locationSelected}
                onMapSelected={handleMapSelected}
                onAdditionalInformationChange={(e) =>
                  dispatch({
                    type: actions.updateFormDataFields,
                    payload: { [fieldNames.additionalInformation]: e.target.value },
                  })
                }
                additionalInformation={state.formData.additionalInformation ? state.formData.additionalInformation : ''}
                clearSelectedPoint={() => dispatch({ type: actions.resetLocation })}
                getIncidentsUrl={getIncidentsUrl}
                possibleGraffitiLocations={possibleGraffitiLocations}
                enablePointSelectionZoomLevels={formConfig.enablePointSelectionZoomLevels}
              />
            </ContinueFormOrReRoute>
          }
        />

        <Route
          path={formConfig.baseUrl.step2}
          element={
            <ContinueFormOrReRoute {...formRouteInfo}>
              <ProblemPageComponent
                goToPrevious={() => changeUrlByStepNumberv6(1, formConfig, navigate)}
                goToNext={() => changeUrlByStepNumberv6(3, formConfig, navigate)}
                problemId={state.formData.problemId}
                offensiveGraffiti={state.formData.offensiveGraffiti}
                onProblemTypeChange={handleProblemTypeChange}
                onOffensiveGraffitiChanged={(value) => {
                  dispatch({
                    type: actions.updateFormDataFields,
                    payload: { [fieldNames.offensiveGraffiti]: value },
                  });
                }}
                uploadedFiles={state.formData.uploadedFiles}
                onUploadFiles={(files) => dispatch({ type: actions.updateUploadFiles, payload: files })}
                onRemoveFile={(index) => dispatch({ type: actions.removeUploadedFile, payload: index })}
                possibleGraffitiLocations={possibleGraffitiLocations}
                utilityBoxId={utilityBoxId}
              />
            </ContinueFormOrReRoute>
          }
        />

        <Route
          path={formConfig.baseUrl.step3}
          element={
            <ContinueFormOrReRoute {...formRouteInfo}>
              <SummaryPageComponent
                submitIncident={submitIncident}
                goToProblemPage={() => changeUrlByStepNumberv6(2, formConfig, navigate)}
                tableData={buildTableData()}
              />
            </ContinueFormOrReRoute>
          }
        />

        <Route
          path={formConfig.baseUrl.confirmation}
          element={
            <ContinueFormOrReRoute {...formRouteInfo}>
              <ConfirmationPage
                referenceNumber={state.incident.referenceNumber}
                onSignInClicked={() => navigate(clientMyAccountUrl.graffiti.root + clientMyAccountUrl.signIn)}
                onRegisterClicked={() => navigate(clientMyAccountUrl.graffiti.root + clientMyAccountUrl.register)}
                userLoggedIn={() => CYCServices.JWTAuth.isAuthenticated()}
                goToMyAccount={() => navigate(clientMyAccountUrl.dashboard)}
                restartFormUrl={formConfig.stepUrls[0]}
              />
            </ContinueFormOrReRoute>
          }
        />

        <Route
          path={clientMyAccountUrl.signIn}
          element={<SignInContainer onSuccessfulSignIn={handleSignInCallBack} />}
        />
        <Route
          path={`${clientMyAccountUrl.register}/*`}
          element={
            <RegistrationApp
              onSuccessfulRegistration={handleUserRegistered}
              extraSuccessfulContent={<p>Click 'Continue' to view the reference number for your reported problem.</p>}
              onContinueClicked={() => navigate(formConfig.baseUrl.root + formConfig.baseUrl.confirmation)}
            />
          }
        />
      </Routes>
    </IncidentFormContainer>
  );
};

export default GraffitiApp;
