export const fileInputConstants = {
  helpText: {
    noFiles: 'Help: If you have photos of the problem upload them here (.png or .jpg)',
    withFiles: 'Help: If you would like to change your photo/s, upload them here (.png or .jpg)',
  },
  errorMessages: {
    invalidFileType: 'Cannot add this file. The selected file must be a JPG or PNG',
    exceedUploadSizeLimit: 'Cannot add this file. The selected file must be smaller than 20MB',
    exceedUploadCountLimit: 'Cannot add this file. You may only upload 5 images',
    exceedFileNameCharacterLimit:
      'This file has a long name which will be shortened down to 100 characters when it is saved.',
    fileEmpty: 'Cannot add this file. The selected file is empty',
    exception: 'Cannot add this file. The selected file could not be uploaded – try again',
    fileReplaced: 'This file has been replaced by another file that contains the same content.',
    duplicateFile: 'This file could not be uploaded as it contains the same content as another file.',
  },
  maxFileUploadCount: 5,
  maxFileUploadSize: 20971520,
  maxFileNameCharacters: 100,
  acceptedFileTypes: [
    {
      mediaType: 'image/jpg',
      suffix: '.jpg',
    },
    {
      mediaType: 'image/jpeg',
      suffix: '.jpeg',
    },
    {
      mediaType: 'image/png',
      suffix: '.png',
    },
  ],
};
