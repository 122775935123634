import moment from 'moment';

let session = null;
let sessionOptions = null;

const sessionActivityService = (() => {
  // Begin a session, but only if the sessionOptions have already been set using the initalise method
  // and no session currently exists
  const beginSession = () => {
    if (sessionOptions != null && session == null) {
      const now = moment();

      session = {
        isIdle: false,
        isTimingOut: false,
        isTimingOutCounter: 0,
        duration: moment.duration(...sessionOptions.duration),
        rateLimit: moment.duration(...sessionOptions.rateLimit),
        warningDuration: moment.duration(...sessionOptions.warningDuration),
        timeStamp: now,
        latestTimeStamp: now,
        eventListeners: sessionOptions.eventListeners,
      };

      // wire up the eventListeners
      if (session.eventListeners) {
        session.eventListeners.forEach((event) => {
          window.addEventListener(event, eventHandler);
        });
      }

      // Start the interval timer
      session.sessionTickInterval = setInterval(handleSessionTick, session.rateLimit.asMilliseconds());
    }
  };

  // Refresh the session if it is currently timing out
  const refreshSession = () => {
    if (session && session.isTimingOut) {
      session.isTimingOut = false;
      session.isTimingOutCounter = 0;
      // reset timestamp in case activity event hasn't been triggered
      session.latestTimeStamp = moment();

      // clear the timeout interval
      clearInterval(session.timingOutInterval);

      // and update the session
      handleSessionTick();
    }
  };

  // End a session, if one exists
  const endSession = () => {
    if (session) {
      // Clear the previously set intervals
      clearInterval(session.sessionTickInterval);
      clearInterval(session.timingOutInterval);

      // remove the eventListeners
      session.eventListeners.forEach((event) => {
        window.removeEventListener(event, eventHandler);
      });

      session = null;
    }
  };

  // End a session and then remove the sessionOptions
  const removeSession = () => {
    endSession();
    sessionOptions = null;
  };

  // The event that is called from the event listeners
  // or called from the triggerActivity public method
  const eventHandler = () => {
    if (session) {
      session.latestTimeStamp = moment();

      if (session.isIdle) {
        handleSessionTick();
        session.isIdle = false;
      }
    }
  };

  const handleSessionTick = () => {
    if (session && session.isTimingOut === false) {
      if (session.latestTimeStamp > session.timeStamp) {
        // Reset the timestamp to the last time an action was registered
        session.timeStamp = session.latestTimeStamp;
        // Execute the callback method
        sessionOptions.refreshCallback();
      } else {
        // If no new events have been captured then the session is now idle
        // This enables the next event to trigger this method immediately
        session.isIdle = true;
      }

      // Get the elapsed time from the last timeStamp
      const elapsedTime = moment() - session.timeStamp;

      // Check whether we've timed out without going through the isTimingOut process (e.g. tab not active)
      if (elapsedTime >= session.duration) {
        endSession();
        sessionOptions.timedOutCallback();
      }
      // Check whether we need to start the isTimingOut process
      else if (elapsedTime >= session.duration - session.warningDuration) {
        // Set the timing out process going
        session.isTimingOut = true;
        session.isTimingOutCounter = session.warningDuration / 1000;

        // Set up the timing out interval, to update once a second
        session.timingOutInterval = setInterval(handleTimingOutCountdown, 1000);
      }
    }
  };

  // Method that is called to count down the timing out process
  const handleTimingOutCountdown = () => {
    session.isTimingOutCounter--;

    if (session.isTimingOutCounter < 0) {
      endSession();
      sessionOptions.timedOutCallback();
    } else {
      // Call the timing out warning callback, with the remaining number of seconds
      sessionOptions.warningCallback(session.isTimingOutCounter + 1);
    }
  };
  // #endregion

  // #region - Public methods
  return {
    initialise: (options) => (sessionOptions = options),
    begin: () => beginSession(),
    refresh: () => refreshSession(),
    remove: () => removeSession(),
    end: () => endSession(),
    triggerActivity: () => eventHandler(),
  };
  // #endregion
})();

export default sessionActivityService;
