import React, { useMemo } from 'react';

import './my-account-page.component.scss';

const MyAccountPageComponent = ({ children, className }) => {
  const classes = useMemo(
    () => (className === undefined ? 'cyc-page flex-container' : `cyc-page flex-container ${className}`),
    [className]
  );
  return (
    <div className={classes}>
      <div>{children}</div>
    </div>
  );
};

export default MyAccountPageComponent;
