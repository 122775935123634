import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Form, withFormik } from 'formik';
import * as Yup from 'yup';
import {
  ErrorBoxComponent,
  ErrorLabelHandlerComponent,
  FileInputComponent,
  setInitialPosition,
  RadioList,
} from '../../../common';

import {
  RadiobuttonInputComponent,
  InputErrorComponent,
  ButtonComponent,
  ButtonClasses,
} from 'cyc-react-ui-components';

const ProblemPageNonSurfaceWaterForm = (props) => {
  let errorBoxRef = React.createRef();
  const previousSubmitCount = React.useRef(0);

  useEffect(() => {
    if (
      !props.isSubmitting &&
      !props.isValid &&
      Object.keys(props.errors).length > 0 &&
      props.submitCount > previousSubmitCount.current
    ) {
      previousSubmitCount.current = props.submitCount;
      const errorBox = ReactDOM.findDOMNode(errorBoxRef.current);
      errorBox.scrollIntoView();
    }
  }, [props.isSubmitting, errorBoxRef, props.errors, props.isValid, props.submitCount]);

  useEffect(() => {
    setInitialPosition();
  }, []);

  const type = props.waterAndDrainageTypes.find((type) => type.id === props.problemWithId);
  const typeDisplayName = type && type.displayName;
  const problemCategories = type && Object.values(type.categories);

  return (
    <div className="page">
      <Form>
        <div ref={errorBoxRef}></div>
        {Object.keys(props.errors).length > 0 && props.submitCount > 0 && (
          <ErrorBoxComponent errors={props.errors}></ErrorBoxComponent>
        )}
        <h2>About the problem</h2>
        <p>{typeDisplayName}</p>
        <div className="row">
          <span id="whatIsTheProblem">
            <ErrorLabelHandlerComponent
              touched={props.touched}
              errors={props.errors}
              text="What is the problem?"
              tag="p"
              id="problemId"
              submitCount={props.submitCount}
            />
            <RadiobuttonInputComponent
              name="problemId"
              value={props.values.problemId}
              onChange={(e) => {
                // reset field values for flooded questions
                props.floodedQuestions.forEach((element) => {
                  props.setFieldValue([element.id], undefined);
                });
                props.setFieldValue('problemId', e);
                props.onProblemChange(e);
              }}
              error={props.errors.problemId && props.touched.problemId}
              options={problemCategories.map((problem) => ({
                label: problem.displayName,
                value: problem.id,
              }))}
            />
            {props.errors.problemId && props.touched.problemId && (
              <InputErrorComponent>{props.errors.problemId}</InputErrorComponent>
            )}
          </span>
        </div>

        {props.areFloodedQuestionsRequired &&
          props.floodedQuestions.map((element) => {
            return (
              <RadioList
                key={element.id}
                question={element.question}
                id={element.id}
                selectedItem={props[element.id]}
                radioOptions={element.radioOptions}
                onChange={(e) => {
                  props.setFieldValue(element.id, e);
                  props.onFloodedQuestionChange(element.id, e);
                }}
                touched={props.touched}
                errors={props.errors}
                submitCount={props.submitCount}
              />
            );
          })}

        <h3 className="mt-3" id="uploadPhoto">
          Upload a photo
        </h3>
        <FileInputComponent
          uploadedFiles={props.uploadedFiles}
          onUploadFiles={props.onUploadFiles}
          onRemoveFile={props.onRemoveFile}
        />

        <ButtonComponent type="submit" disabled={props.isSubmitting} className={ButtonClasses.primary + ' float-right'}>
          Next
        </ButtonComponent>
        <ButtonComponent type="button" onClick={props.goToPreviousPage}>
          Previous
        </ButtonComponent>
      </Form>
    </div>
  );
};

const ProblemPageNonSurfaceWaterComponent = withFormik({
  mapPropsToValues(props) {
    const valuesObject = {
      problemId: props.problemId,
      goToNextPage: props.goToNextPage,
      uploadedFiles: props.uploadedFiles || [],
    };

    // add fields for flooded questions
    // element.id matches prop name and form field id
    props.floodedQuestions.forEach((element) => {
      valuesObject[element.id] = props[element.id];
    });

    return valuesObject;
  },
  validationSchema: (props) => {
    const validationObject = {
      problemId: Yup.string().required("Please supply details for 'What is the problem?'."),
    };

    if (props.areFloodedQuestionsRequired) {
      props.floodedQuestions.forEach((element) => {
        validationObject[element.id] = Yup.number().required(`Please supply details for '${element.question}'`);
      });
    }

    return Yup.object().shape(validationObject);
  },
  handleSubmit(bag) {
    bag.goToNextPage();
  },
})(ProblemPageNonSurfaceWaterForm);

ProblemPageNonSurfaceWaterComponent.propTypes = {
  goToNextPage: PropTypes.func.isRequired,
  goToPreviousPage: PropTypes.func.isRequired,
  onProblemChange: PropTypes.func.isRequired,
  onUploadFiles: PropTypes.func.isRequired,
  onRemoveFile: PropTypes.func.isRequired,
  uploadedFiles: PropTypes.array.isRequired,
  problemWithId: PropTypes.string.isRequired,
  problemId: PropTypes.string,
  waterAndDrainageTypes: PropTypes.array.isRequired,
  floodedQuestions: PropTypes.array.isRequired,
  onFloodedQuestionChange: PropTypes.func.isRequired,
  areFloodedQuestionsRequired: PropTypes.bool,
  waterDangerToUser: PropTypes.string,
  riskOfInternalFlooding: PropTypes.string,
};

export default ProblemPageNonSurfaceWaterComponent;
export { ProblemPageNonSurfaceWaterForm };
