import 'react-app-polyfill/ie11'; // For IE 11 support - needed for window.fetch pollyfill
import 'core-js/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import './resources/styles/cyc-styles.scss';
import 'cyc-react-ui-components/public/index.css';

import App from './app';

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);

// Enable WCAG / accessibility checking in developer environments
// Problems will be output to the dev tools console
if (process.env.NODE_ENV !== 'production') {
  console.log(
    'Accessibility checking is enabled, problems will be logged to console. You can disable this in the root index.js file'
  );
  const axe = require('@axe-core/react');
  axe(React, ReactDOM, 1000);
}
