import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './site-search.component.scss';
import searchButtonSvg from 'resources/new-styles/images/magnifying_glass.svg';
import { ButtonComponent, ButtonClasses, TextInputComponent } from 'cyc-react-ui-components';
import FlyoutComponent from '../flyout/flyout.component';
import { yorkMainWebsiteUrl } from 'common';

const SiteSearch = ({ open, onClose, onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchTerm.length > 0) {
      onSearch && onSearch(searchTerm);
      window.location = yorkMainWebsiteUrl.siteSearchQuery + encodeURIComponent(searchTerm);
    }
  };

  return (
    <>
      <FlyoutComponent open={open} onClose={onClose} className="site-search">
        <h2>How can we help you?</h2>
        <form noValidate onSubmit={handleSearch}>
          <label htmlFor="cyc-search" />
          <TextInputComponent
            id="cyc-search"
            type="search"
            placeholder="Search"
            onChange={(e) => setSearchTerm(e.target.value)}
            value={searchTerm}
          />
          <ButtonComponent type="submit" className={ButtonClasses.secondary + ' search-button'}>
            <img src={searchButtonSvg} alt="Search" />
          </ButtonComponent>
        </form>
      </FlyoutComponent>
    </>
  );
};

SiteSearch.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onSearch: PropTypes.func,
};

export default SiteSearch;
