import React, { Component, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import appInsights from 'cyc-application-insights-react';
import CycEsriMap from './cyc-esri-map.importer';
import * as EsriLocationService from './esri-location.service';

import './cyc-esri-map.component.scss';
import usePrevious from 'common/hooks/use-previous.hook';

const CycEsriMapComponent = ({
  locationObject,
  featureLayersOptions: featureLayersOptionsProps,
  incidentLayersOptions,
  getCurrentExtent,
  initialExtent,
  disableFreeSelect,
  enablePointSelectionAtZoomLevel,
  onMapClicked,
  getAllLayersDetailsOnClick,
}) => {
  const previousLocationObject = usePrevious(locationObject);
  const previousFeatureLayersOptions = usePrevious(featureLayersOptionsProps);
  const [featureLayersOptions, setFeatureLayersOptions] = useState(); // TODO: One solution can be removing initial array
  const [initialFocus, setInitialFocus] = useState();
  const [location, setLocation] = useState({
    initialFocus: undefined,
    easting: null,
    northing: null,
    street: null,
    usrn: null,
    ward: null,
    parish: null,
  });

  const basemapLayerOptions = {
    popupTemplate: {
      title: 'Selected point',
      generateContent: async ({ mapPoint }) => {
        // Take the mapPoint from the click and retrun popup with useful info
        const locationData = await getLocationDataFromPointAsync(mapPoint);
        return `<table class="esri-widget__table">
                    <tr>
                        <th>Street</th>
                        <td>${locationData.street}</td>
                    </tr>
                    <tr>
                        <th>USRN</th>
                        <td>${locationData.usrn}</td>
                    </tr>
                </table>`;
      },
    },
    onMapClicked: async (mapPoint) => {
      const location = await getLocationDataFromPointAsync(mapPoint);
      onMapClicked && onMapClicked(location);
    },
  };

  const invalidClickPopupTemplate = {
    title: 'Not in York',
    content:
      'We’re unable to provide services here; it’s outside of our boundary. Find <a href="https://www.gov.uk/find-local-council">details of local councils</a>',
  };

  useEffect(() => {
    // we'll need to reset the location when user goes back to the location page
    // with an object selected and then clicks on missing asset checkbox
    if (locationObject !== undefined) {
      setLocation({
        ...location,
        easting: locationObject.easting,
        northing: locationObject.northing,
        street: locationObject.streetName,
        usrn: locationObject.usrn ? locationObject.usrn.toString() : null,
        ward: locationObject.ward,
        parish: locationObject.parish,
      });

      setInitialFocus({
        easting: locationObject.easting,
        northing: locationObject.northing,
        filter: locationObject.filter,
      });
    }
  }, [locationObject]);

  useEffect(() => {
    // we need to check if the feature layers changed
    // this needs to be checked manually as if any references to objects/arrays change, the map refreshes

    // if feature layers options have been added or removed, change state
    if (
      (previousFeatureLayersOptions === undefined && featureLayersOptionsProps !== undefined) ||
      (previousFeatureLayersOptions !== undefined && featureLayersOptionsProps === undefined)
    ) {
      setFeatureLayersOptions(featureLayersOptionsProps);
      return;
    }
    // otherwise, if options are set
    if (previousFeatureLayersOptions !== undefined && featureLayersOptionsProps !== undefined) {
      // if the length has changed, set state
      if (previousFeatureLayersOptions.length !== featureLayersOptionsProps.length) {
        setFeatureLayersOptions(featureLayersOptionsProps);
        return;
      }
      // otherwise, run through all the options and check if layerId has changed, set state
      let shouldUpdateFeatureLayersOptions = false;
      previousFeatureLayersOptions.forEach((option, index) => {
        if (option.layerId !== featureLayersOptionsProps[index].layerId) {
          shouldUpdateFeatureLayersOptions = true;
        }
      });
      if (shouldUpdateFeatureLayersOptions === true) {
        setFeatureLayersOptions(featureLayersOptionsProps);
      }
    }
  }, [featureLayersOptionsProps]);

  const getLocationDataFromPointAsync = async (mapPoint) => {
    const streetPromise = EsriLocationService.getStreetFromPoint(mapPoint.x, mapPoint.y);
    const toidPromise = EsriLocationService.getTOIDFromPoint(mapPoint.x, mapPoint.y);
    const wardPromise = EsriLocationService.getWardFromPoint(mapPoint.x, mapPoint.y);
    const postcodePromise = EsriLocationService.getPostcodeFromPointAsync(mapPoint.x, mapPoint.y);
    const parishPromise = EsriLocationService.getParishFromPointAsync(mapPoint.x, mapPoint.y);

    const [street, toid, ward, postcode, parish] = await Promise.all([
      streetPromise,
      toidPromise,
      wardPromise,
      postcodePromise,
      parishPromise,
    ]);

    const usrn = await EsriLocationService.getUSRNFromPoint(street);

    const newLocation = {
      ...location,
      easting: mapPoint.x,
      northing: mapPoint.y,
      street,
      usrn,
      ward,
      toid,
      postcode,
      parish,
    };

    setLocation(newLocation);

    return newLocation;
  };

  const handleMapException = (ex) => {
    appInsights.trackException({ exception: new Error('Failed to load map'), properties: ex });
    console.error(ex);
  };

  return (
    <div className="map-container">
      <CycEsriMap
        getCurrentExtent={getCurrentExtent ? getCurrentExtent : () => {}}
        initialExtent={initialExtent ? initialExtent : undefined}
        featureLayersOptions={featureLayersOptions}
        basemapLayerOptions={disableFreeSelect ? undefined : basemapLayerOptions}
        initialFocus={initialFocus}
        incidentLayersOptions={incidentLayersOptions}
        invalidClickOptions={invalidClickPopupTemplate}
        disableClicking={false}
        onException={handleMapException}
        enablePointSelectionAtZoomLevel={enablePointSelectionAtZoomLevel}
        getAllLayersDetailsOnClick={getAllLayersDetailsOnClick}
      />
    </div>
  );
};

CycEsriMapComponent.propTypes = {
  getCurrentExtent: PropTypes.func,
  initialExtent: PropTypes.shape({
    easting: PropTypes.number,
    northing: PropTypes.number,
    zoom: PropTypes.number,
  }),
  onMapClicked: PropTypes.func,
  locationObject: PropTypes.shape({
    northing: PropTypes.string,
    easting: PropTypes.string,
    ward: PropTypes.string,
    streetName: PropTypes.string,
    filter: PropTypes.string,
    usrn: PropTypes.string,
    parish: PropTypes.string,
  }),
  incidentLayersOptions: PropTypes.array,
  featureLayersOptions: PropTypes.array,
  disableFreeSelect: PropTypes.bool,
  enablePointSelectionAtZoomLevel: PropTypes.shape({
    minimum: PropTypes.number.isRequired,
    maximum: PropTypes.number,
  }),
};

export default CycEsriMapComponent;
