import React, { useReducer, useEffect, useCallback, useMemo } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { CYCServices } from 'cyc-services';
import { ConfigurationService } from 'cyc-configuration-service';

import * as Services from '../../common/services/incident.service';
import {
  clientMyAccountUrl,
  ContinueFormOrReRoute,
  apiSettingName,
  apiMyAccountUrl,
  changeUrlByStepNumberv6,
} from '../../common';
import { FormConfiguration } from './form-config';
import {
  incidentLayersOptionsBuilder,
  popupTemplateBuilder,
  FieldsBuilder,
} from '../helpers/incident-layer-options-builder.helper';
import IncidentFormContainer from '../../common/containers/incident-forms/incident-form.container';
import EntryPage from '../components-shared/entry-page.component';
import LocationPageComponent from '../components-shared/location-page.component';
import ProblemPageComponent from './components/problem-page.component';
import SummaryPageComponent from '../components-shared/summary-page.component';
import ConfirmationPage from '../components-shared/confirmation-page.component';
import SignInContainer from '../../sign-in/containers/sign-in.container';
import RegistrationApp from '../../registration/registration.app';
import { reducer, initialState, actions, fieldNames } from '../incident-form.reducer';
import { HeaderTextFromUrl } from '../helpers/header-text-from-url.helper';

const FlyTippingApp = () => {
  const location = useLocation(); // props.location
  const navigate = useNavigate(); // props.history
  const formConfig = useMemo(() => new FormConfiguration(), []);
  const initState = useMemo(
    () => initialState({ pageHeaderText: formConfig.pageHeaderText, problemOtherId: formConfig.problemOtherId() }),
    [formConfig]
  );
  const [state, dispatch] = useReducer(reducer, initialState(initState));
  const getIncidentsUrl = `${ConfigurationService.getEnvironmentSettingByName(apiSettingName)}${
    apiMyAccountUrl.getIncidents.FLY_TIPPING_INCIDENTS
  }`;
  const possibleProblems = formConfig.getFlyTippingCategories();

  // This effect is for updating headers and step urls
  useEffect(() => {
    const indexOfStep = formConfig.stepUrls.indexOf(location.pathname);

    // Update the header based on the url.
    const header = HeaderTextFromUrl({
      url: location.pathname,
      initialHeaderText: formConfig.pageHeaderText,
    });
    dispatch({
      type: actions.updateStateFields,
      payload: { [fieldNames.currentStep]: indexOfStep, [fieldNames.pageHeaderText]: header },
    });

    // Change the step number based on the url.
    dispatch({ type: actions.updateStateFields, payload: { [fieldNames.currentStep]: indexOfStep } });
  }, [location.pathname, formConfig.pageHeaderText, formConfig.stepUrls]);

  // --- ENTRY PAGE ---
  const handleResetForm = useCallback(() => dispatch({ type: actions.resetForm, payload: initState }), [initState]);

  // ---- LOCATION PAGE ------
  const mapIncidentsBuilder = (incident) => {
    return {
      incidentId: incident.incidentId,
      referenceNumber: incident.referenceNumber,
      wasteType: incident.category,
      flyTippingOther:
        incident.category === 'Other'
          ? '<tr><th>Please specify</th><td>' + incident.flytippingOther + '</td></tr>'
          : '',
      createdTime: new Date(incident.createdTime).toLocaleString('en-GB'),
    };
  };
  const layerOptionsBuilder = () => {
    let fieldsBuilder = new FieldsBuilder();
    fieldsBuilder.addIncidentId();
    fieldsBuilder.addIncidentReferenceNumber();
    fieldsBuilder.addWasteType();
    fieldsBuilder.addCreatedTime();
    const inc = incidentLayersOptionsBuilder({
      mapIncidentDetailsToAttributes: mapIncidentsBuilder,
      getIncidentsUrl: getIncidentsUrl,
      mapIncidentLayerId: formConfig.mapIncidentLayerId,
      popupTemplate: popupTemplateBuilder({
        mapIncidentLayerTitle: formConfig.mapIncidentLayerTitle,
        tableDataArray: [
          { header: 'Waste type', data: 'wasteType' },
          { header: 'Date created', data: 'createdTime' },
        ],
        nonTrData: [{ index: 0, data: '{flyTippingOther}' }],
        showAlreadyReportedMessage: true,
      }),
      fields: fieldsBuilder.builder,
    });
    return inc;
  };

  // ---- PROBLEM PAGE ----

  const handleProblemTypeChange = (value) => {
    dispatch({ type: actions.updateFormDataFields, payload: { [fieldNames.problemId]: value } });
    // If the type is not Other then clear the other data
    if (value !== state.formData.problemOtherId) {
      dispatch({ type: actions.updateFormDataFields, payload: { [fieldNames.problemOther]: '' } });
    }
  };

  // --- SUMMARY PAGE ---
  const buildTableData = () => {
    const formData = state.formData;
    const wasteType = possibleProblems.filter((val) => val.id === formData.problemId)[0];
    const wasteSize = formConfig.sizeOfWasteArray.filter((val) => val.id === formData.sizeOfWaste)[0];
    const tableData = [
      {
        columnOne: 'Location',
        columnTwo:
          formData.locationObject && formData.locationObject.streetName && formData.locationObject.streetName.length > 0
            ? formData.locationObject.streetName
            : 'Selected on map',
        onChangeClicked: () => changeUrlByStepNumberv6(1, formConfig, navigate, 'location'),
      },
      {
        columnOne: 'Additional location information',
        columnTwo: formData.additionalInformation,
        onChangeClicked: () => changeUrlByStepNumberv6(1, formConfig, navigate, 'additionalInformation'),
      },
      {
        columnOne: 'Type of waste',
        columnTwo: wasteType ? wasteType.displayName : null,
        onChangeClicked: () => changeUrlByStepNumberv6(2, formConfig, navigate, 'typeOfWaste'),
        columnTwoClassName: 'sentence-case',
      },
      {
        columnOne: 'Amount of waste',
        columnTwo: wasteSize ? wasteSize.name : null,
        onChangeClicked: () => changeUrlByStepNumberv6(2, formConfig, navigate, 'amountOfWaste'),
        columnTwoClassName: 'sentence-case',
      },
      {
        columnOne: 'Upload a photo',
        columnTwo: formData.uploadedFiles ? (
          <ul className="uploaded-photos">
            {formData.uploadedFiles.map((file, index) => (
              <li key={index}>{file.fileName}</li>
            ))}
          </ul>
        ) : (
          <React.Fragment />
        ),
        onChangeClicked: () => changeUrlByStepNumberv6(2, formConfig, navigate, 'uploadPhoto'),
      },
    ];

    // Only show the "Other waste" info if type of waste is other
    if (formData.problemId === formData.problemOtherId) {
      tableData.splice(3, 0, {
        columnOne: "Type of waste 'Other' information",
        columnTwo: formData.problemOther ? formData.problemOther : null,
        onChangeClicked: () => changeUrlByStepNumberv6(2, formConfig, navigate, 'typeOfWaste'),
        columnTwoClassName: 'sentence-case',
      });
    }

    return tableData;
  };

  const submitIncident = async () => {
    const form = { ...state.formData };
    form.productId = formConfig.productId;
    form.subject = formConfig.subject;
    // Create the incident
    try {
      const result = await Services.createIncident(form);
      if (result.status === 200) {
        dispatch({ type: actions.updateIncidentObject, payload: result.data });
        dispatch({
          type: actions.updateStateFields,
          payload: { [fieldNames.formStarted]: true, [fieldNames.formCompleted]: true },
        });
      }
    } catch {
      return false;
    }
  };

  // ----- CONFIRMATION PAGE -----
  const sendUpdateIncidentWithUser = async (email) => {
    await Services.updateIncidentWithUser(
      state.incident.referenceNumber,
      state.incident.secretReferenceNumber,
      state.incident.incidentId,
      email
    ).then((result) => {
      if (result.status === 200) {
        return true;
      } else {
        return false;
      }
    });
  };

  const handleUserRegistered = (userData) => {
    dispatch({ type: actions.updateStateFields, payload: { [fieldNames.userLoggedIn]: true } });
    // only update incident when user's email is not confirmed
    // save the incident into temp table while wait for the user to confirm his/her email.
    if (state.formCompleted && userData.isEmailConfirmed === false) {
      sendUpdateIncidentWithUser(userData.email);
    }
  };

  const handleSignInCallBack = () => {
    const isSignedIn = formConfig.userIsLoggedIn();
    if (isSignedIn) {
      sendUpdateIncidentWithUser('');
    }
    // after sign in succssfully, navigate back to incident creation confirmation page
    navigate(formConfig.stepUrls[formConfig.totalSteps]);
  };

  const formRouteInfo = () => {
    return {
      formStarted: state.formStarted,
      formCompleted: state.formCompleted,
      formStartUrl: formConfig.stepUrls[0],
      formEndUrl: formConfig.stepUrls[formConfig.totalSteps],
      location: location,
    };
  };

  const locationPageIncidentLayerOptions = layerOptionsBuilder();

  return (
    <IncidentFormContainer
      displayProgressBar={() => formConfig.shouldDisplayBasedOnStep(state.currentStep)}
      pageHeaderText={state.pageHeaderText}
      documentTitle={formConfig.documentTitle}
      totalSteps={formConfig.totalSteps}
      currentStep={state.currentStep}
      breadcrumbs={[{ url: clientMyAccountUrl.flyTipping.root, name: formConfig.pageHeaderText }]}>
      <Routes>
        <Route
          path="/"
          element={
            <EntryPage
              report={{ text: 'problems with fly-tipping', url: 'https://www.york.gov.uk/ReportFlyTipping' }}
              informationArray={[
                {
                  text: 'which fly-tipping problems we cannot help with',
                  url: 'https://www.york.gov.uk/ReportFlyTipping#problemswecannothelp',
                },
                {
                  text: 'our fly-tipping response times',
                  url: 'https://www.york.gov.uk/ReportFlyTipping#responsetimes',
                },
              ]}
              goToNextPage={() =>
                changeUrlByStepNumberv6(1, formConfig, navigate, false, true, () =>
                  dispatch({
                    type: actions.updateStateFields,
                    payload: { [fieldNames.formStarted]: true, [fieldNames.formCompleted]: false },
                  })
                )
              }
              onResetForm={handleResetForm}
            />
          }
        />

        <Route
          path={formConfig.baseUrl.step1} // /form/fly-tipping/step-one
          element={
            <ContinueFormOrReRoute {...formRouteInfo()}>
              <LocationPageComponent
                goToNext={() => changeUrlByStepNumberv6(2, formConfig, navigate)}
                goToPrevious={() => changeUrlByStepNumberv6(0, formConfig, navigate)}
                incidentLayerOptions={locationPageIncidentLayerOptions}
                locationObject={state.formData.locationObject}
                locationSelected={state.formData.locationSelected}
                onMapSelected={(location) => dispatch({ type: actions.updateLocationObject, payload: location })}
                onAdditionalInformationChange={(e) =>
                  dispatch({
                    type: actions.updateFormDataFields,
                    payload: { [fieldNames.additionalInformation]: e.target.value },
                  })
                }
                additionalInformation={state.formData.additionalInformation}
                clearSelectedPoint={() => dispatch({ type: actions.resetLocation })}
                enablePointSelectionZoomLevels={formConfig.enablePointSelectionZoomLevels}
              />
            </ContinueFormOrReRoute>
          }
        />

        <Route
          path={formConfig.baseUrl.step2}
          element={
            <ContinueFormOrReRoute {...formRouteInfo()}>
              <ProblemPageComponent
                goToPrevious={() => changeUrlByStepNumberv6(1, formConfig, navigate)}
                goToNext={() => changeUrlByStepNumberv6(3, formConfig, navigate)}
                onUploadFiles={(files) => dispatch({ type: actions.updateUploadFiles, payload: files })}
                onRemoveFile={(index) => dispatch({ type: actions.removeUploadedFile, payload: index })}
                problemId={state.formData.problemId}
                problemOther={state.formData.problemOther}
                problemOtherId={state.formData.problemOtherId}
                sizeOfWaste={state.formData.sizeOfWaste}
                sizeOfWasteArray={formConfig.sizeOfWasteArray}
                onProblemTypeChange={handleProblemTypeChange}
                onSizeOfWasteChange={(value) =>
                  dispatch({ type: actions.updateFormDataFields, payload: { [fieldNames.sizeOfWaste]: value } })
                }
                onProblemOtherChange={(value) =>
                  dispatch({ type: actions.updateFormDataFields, payload: { [fieldNames.problemOther]: value } })
                }
                uploadedFiles={state.formData.uploadedFiles}
                possibleProblems={possibleProblems}
              />
            </ContinueFormOrReRoute>
          }
        />

        <Route
          path={formConfig.baseUrl.step3}
          element={
            <ContinueFormOrReRoute {...formRouteInfo()}>
              <SummaryPageComponent
                goToProblemPage={() => changeUrlByStepNumberv6(2, formConfig, navigate)}
                submitIncident={submitIncident}
                tableData={buildTableData()}
              />
            </ContinueFormOrReRoute>
          }
        />

        <Route
          path={formConfig.baseUrl.confirmation}
          element={
            <ContinueFormOrReRoute {...formRouteInfo()}>
              <ConfirmationPage
                referenceNumber={state.incident.referenceNumber}
                onSignInClicked={() => navigate(formConfig.baseUrl.root + clientMyAccountUrl.signIn)}
                onRegisterClicked={() => navigate(formConfig.baseUrl.root + clientMyAccountUrl.register)}
                userLoggedIn={() => CYCServices.JWTAuth.isAuthenticated()}
                goToMyAccount={() => navigate(clientMyAccountUrl.dashboard)}
                restartFormUrl={formConfig.baseUrl.root}
              />
            </ContinueFormOrReRoute>
          }
        />

        <Route
          path={clientMyAccountUrl.signIn}
          element={<SignInContainer onSuccessfulSignIn={handleSignInCallBack} />}
        />
        <Route
          path={`${clientMyAccountUrl.register}/*`}
          element={
            <RegistrationApp
              onSuccessfulRegistration={handleUserRegistered}
              extraSuccessfulContent={<p>Click 'Continue' to view the reference number for your reported problem.</p>}
              onContinueClicked={() => navigate(formConfig.baseUrl.root + formConfig.baseUrl.confirmation)}
            />
          }
        />
      </Routes>
    </IncidentFormContainer>
  );
};

export default FlyTippingApp;
