import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FeatureFlagsService } from 'cyc-configuration-service';
import { isEnabled } from '../../../common';
import { clientMyAccountUrl, SummaryTableComponent } from '../../../common';
import * as featureFlagConstants from '../../../common/constants/feature-flag.constants';

const AccountDetailsComponent = ({ results }) => {
  const [accountDetails, setAccountDetails] = useState([]);

  useEffect(() => {
    if (results) {
      const accountDetails = [
        { columnOne: 'First name', columnTwo: results.firstName },
        { columnOne: 'Last name', columnTwo: results.lastName },
        {
          columnOne: 'Email address',
          columnTwo: results.primaryEmail,
          link: `${clientMyAccountUrl.account.root}${clientMyAccountUrl.account.email.change}`,
        },
        {
          columnOne: 'Telephone number',
          columnTwo: results.phoneNumber,
          link: `${clientMyAccountUrl.account.root}${clientMyAccountUrl.account.phone.change}`,
        },
        {
          columnOne: 'Address',
          columnTwo: results.address,
          link: `${clientMyAccountUrl.account.root}${clientMyAccountUrl.account.address.change}`,
        },
      ];

      if (isEnabled(FeatureFlagsService.getFeatureFlagByName(featureFlagConstants.ChangeName))) {
        accountDetails[0].link = `${clientMyAccountUrl.account.root}${clientMyAccountUrl.account.name.change}`;
        accountDetails[1].link = `${clientMyAccountUrl.account.root}${clientMyAccountUrl.account.name.change}`;
      }

      setAccountDetails(accountDetails);
    }
  }, [results]);

  return (
    <div className="account-details">
      <p>
        Currently to update your name in My Account please <a href="https://www.york.gov.uk/ContactUs">contact us</a>.
        Find out more about <a href="https://www.york.gov.uk/myaccounthelp#upgrades">My Account upgrades</a>.
      </p>
      <p>
        If you’re responsible for paying council tax you must{' '}
        <a href="https://www.york.gov.uk/info/20004/council_tax/6/moving_to_another_property">
          update your address in our Council Tax system
        </a>{' '}
        too, as this cannot be processed automatically.
      </p>
      <SummaryTableComponent headerOne="" headerTwo="Details" linkHeader="Change" rowData={accountDetails} />
      <p>
        <Link to={clientMyAccountUrl.dashboard}>Back to My Account</Link>
      </p>
    </div>
  );
};

AccountDetailsComponent.propTypes = {
  results: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    primaryEmail: PropTypes.string,
    phoneNumber: PropTypes.string,
    address: PropTypes.string,
  }),
};
export default AccountDetailsComponent;
