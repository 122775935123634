import React from 'react';
import PropTypes from 'prop-types';

import {
  clientMyAccountUrl,
  MyAccountPageComponent,
  DocumentTitleComponent,
  PageHeaderComponent,
} from '../../../../common';
import { useNavigate } from 'react-router-dom';

const ForgotPasswordEmailSentComponent = () => {
  const navigate = useNavigate();

  const signInClicked = () => navigate(clientMyAccountUrl.signIn);
  return (
    <DocumentTitleComponent title="Forgot password - confirmation">
      <PageHeaderComponent text="My Account password reset" icon="icon-website" />
      <MyAccountPageComponent>
        <p>
          Please check your emails within 24 hours; we've sent you a message which contains a secure link to reset your
          password.
        </p>
        <p>If you don't receive this email:</p>
        <ul>
          <li>
            <p>your account may be disabled</p>
          </li>
          <li>
            <p>our message may have been moved to your junk or spam email folder</p>
          </li>
          <li>
            <p>we cannot find a My Account that matches the email address you have provided</p>
          </li>
        </ul>

        <p>
          <a href="https://www.york.gov.uk/homepage/16/contact_us" target="_blank" rel="noopener noreferrer">
            Contact us
          </a>{' '}
          if you need help to reset your password.
        </p>

        <p>
          Once you've reset your password you can{' '}
          <button className="in-text-button" onClick={signInClicked}>
            sign in to My Account
          </button>
          .
        </p>
      </MyAccountPageComponent>
    </DocumentTitleComponent>
  );
};

ForgotPasswordEmailSentComponent.propTypes = {
  history: PropTypes.object,
};

export default ForgotPasswordEmailSentComponent;
