import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withFormik, Form } from 'formik';
import * as Yup from 'yup';
import { ErrorBoxComponent, ErrorLabelHandlerComponent, setInitialPosition } from '../../../common';
import {
  RadiobuttonInputComponent,
  InputErrorComponent,
  ButtonComponent,
  ButtonClasses,
} from 'cyc-react-ui-components';

const AboutProblemPageForm = (props) => {
  const errorBox = React.createRef();
  useEffect(() => {
    setInitialPosition();
  }, []);

  return (
    <Form>
      <div ref={errorBox}></div>
      {Object.keys(props.errors).length > 0 && props.touched.typeId && (
        <ErrorBoxComponent errors={props.errors}></ErrorBoxComponent>
      )}
      <h2 id="aboutTheProblem">About the problem</h2>

      <div className="row">
        <span>
          <ErrorLabelHandlerComponent
            touched={props.touched}
            errors={props.errors}
            text="What is the problem?"
            id="typeId"
            submitCount={props.submitCount}
          />
          <RadiobuttonInputComponent
            name="typeId"
            value={props.values.typeId}
            onChange={(e) => {
              props.setFieldValue('typeId', e);
              props.onSignTypeChange(e);
            }}
            error={props.errors.typeId && props.touched.typeId}
            options={props.possibleSignTypes.map((type) => ({
              label: type.displayName,
              value: type.id,
            }))}
          />
          {props.errors.typeId && props.touched.typeId && (
            <InputErrorComponent>{props.errors.typeId}</InputErrorComponent>
          )}
        </span>
      </div>

      <ButtonComponent type="submit" disabled={props.isSubmitting} className={ButtonClasses.primary + ' float-right'}>
        Next
      </ButtonComponent>
      <ButtonComponent type="button" onClick={props.goToPrevious}>
        Previous
      </ButtonComponent>
    </Form>
  );
};

const AboutProblemPageComponent = withFormik({
  mapPropsToValues({ signTypeId, goToNext }) {
    return {
      typeId: signTypeId || '',
      goToNext: goToNext,
    };
  },
  validationSchema: Yup.object().shape({
    typeId: Yup.string().required("Please supply details for 'What is the problem?'."),
  }),
  handleSubmit(bag) {
    bag.goToNext();
  },
})(AboutProblemPageForm);

AboutProblemPageComponent.propTypes = {
  signTypeId: PropTypes.string,
  onSignTypeChange: PropTypes.func.isRequired,
  possibleSignTypes: PropTypes.array.isRequired,
  goToPrevious: PropTypes.func,
};

export default AboutProblemPageComponent;
