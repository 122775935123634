import axios from 'axios';
import { ConfigurationService } from 'cyc-configuration-service';
import appInsights from 'cyc-application-insights-react';

import { apiMyAccountUrl } from '../../../common';

// TODO: axios this
const postForgotPasswordAsync = async (form) => {
  let addressQuery = `${ConfigurationService.store.apiUrl}${apiMyAccountUrl.ACCOUNT_FORGOT_PASSWORD}`;
  return axios.post(addressQuery, { email: form }).catch((ex) => {
    console.error(ex);
    appInsights.trackException({
      exception: new Error('Failed to post a forgot password form'),
      properties: { email: form },
    });
    throw ex;
  });
};

const postResetPasswordAsync = async (form) => {
  const addressQuery = `${ConfigurationService.store.apiUrl}${apiMyAccountUrl.ACCOUNT_RESET_PASSWORD}`;
  const data = {
    userId: form.userId,
    code: form.code,
    password: form.password,
    confirmPassword: form.confirmPassword,
    email: form.email,
  };

  return axios.post(addressQuery, data).catch((ex) => {
    console.error(ex);
    appInsights.trackException({
      exception: new Error('Failed to post a reset password form'),
      properties: { userId: form.userId, email: form.email },
    });
    throw ex;
  });
};

export { postForgotPasswordAsync, postResetPasswordAsync };
