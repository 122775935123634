import React, { useRef, useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

import { clientMyAccountUrl } from 'common';

const ParkingCustomerDetails = () => {
  const formRef = useRef();
  const [formData, setFormData] = useState({
    fullCustomerDetails: '',
    hashedFullCustomerDetails: '',
  });
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // For initial loading of the page
  useEffect(() => {
    // get form data from query params
    const hash = searchParams.get('hash');
    const xml = searchParams.get('xml');

    if (!hash || !xml) {
      navigate(clientMyAccountUrl.dashboard);
      return;
    }

    setFormData({
      fullCustomerDetails: xml,
      hashedFullCustomerDetails: hash,
    });
  }, []);

  // When the form data is populated, we can assume the client is ready to post to WSP
  useEffect(() => {
    if (formData.fullCustomerDetails !== '' && formData.hashedFullCustomerDetails !== '') {
      formRef.current.submit();
    }
  }, [formData]);

  return (
    <form
      ref={formRef}
      action="https://york.tarantopermits.com/customerDetails"
      method="post"
      id="authenticated--form-submit">
      <input type="hidden" id="FullCustomerDetails" name="FullCustomerDetails" value={formData.fullCustomerDetails} />
      <input
        type="hidden"
        id="HashedFullCustomerDetails"
        name="HashedFullCustomerDetails"
        value={formData.hashedFullCustomerDetails}
      />
    </form>
  );
};

export default ParkingCustomerDetails;
