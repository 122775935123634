import React from 'react';
import { Link } from 'react-router-dom';
import { clientMyAccountUrl, yorkMainWebsiteUrl } from '../../constants/my-account-urls.constants';
import DocumentTitleComponent from '../document-title/document-title.component';
import PageHeaderComponent from '../page-header/page-header.component';
import MyAccountPageComponent from '../myaccount-page/my-account-page.component';

/**
 * 404 not found component to show when a route is not found
 */
const NotFoundComponent = () => (
  <>
    <DocumentTitleComponent title="My Account - 404 page not found">
      <PageHeaderComponent text="404 page not found" />
      <MyAccountPageComponent>
        <p>
          Sorry, we can't find the page you're looking for - perhaps it doesn't exist anymore, it's moved, or the
          address is incorrect/out of date.
        </p>
        <p>Please try the following:</p>
        <ul>
          <li>
            go back to <Link to={clientMyAccountUrl.home}>My Account</Link>
          </li>
          <li>
            visit our <a href={yorkMainWebsiteUrl.root}>home page</a>
          </li>
        </ul>
        <p>If you still need help:</p>
        <ul>
          <li>
            <a href={yorkMainWebsiteUrl.feedback}>send a message to our Web Admin team</a> (about the website)
          </li>
          <li>
            <a href={yorkMainWebsiteUrl.customerContactCentre}>send a message to our Customer Centre</a> (about council
            services)
          </li>
        </ul>
      </MyAccountPageComponent>
    </DocumentTitleComponent>
  </>
);

export default NotFoundComponent;
