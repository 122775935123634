import React from 'react';
import './cyc-logo-svg.component.scss';
import logoSVG from '../../../resources/images/york-logo-reversed.svg';
import logoPNG from '../../../resources/images/york-logo-reversed.png';

const CycLogoSvgComponent = () => (
  <picture title="City of York Council Home Page">
    <source srcSet={logoSVG} type="image/svg+xml" />
    <img src={logoPNG} alt="Logo: Visit the City of York Council home page" />
  </picture>
);

CycLogoSvgComponent.propTypes = {};

export default CycLogoSvgComponent;
