import { CYCServices } from 'cyc-services';
import { ConfigurationService } from 'cyc-configuration-service';

import { clientMyAccountUrl, vegetationSettingName, mapConstants } from 'common';

export default class FormConfiguration {
  baseUrl = clientMyAccountUrl.vegetation;
  documentTitle = 'My Account - Report a vegetation problem';
  mapIncidentLayerTitle = 'Problem description';
  pageHeaderText = 'Report a vegetation problem';
  stepUrls = [
    `${this.baseUrl.root}`,
    `${this.baseUrl.root + this.baseUrl.step1}`,
    `${this.baseUrl.root + this.baseUrl.step2}`,
    `${this.baseUrl.root + this.baseUrl.step3}`,
    `${this.baseUrl.root + this.baseUrl.step4}`,
    `${this.baseUrl.root + this.baseUrl.confirmation}`,
  ];
  subject = 'Vegetation problem';
  totalSteps = 5;
  userIsLoggedIn = CYCServices.JWTAuth.isAuthenticated;

  // Values from configuration service
  vegetationTypes = [];
  categories = [];
  productId;

  constructor() {
    const { productId, vegetationTypes } = ConfigurationService.getEnvironmentSettingByName(vegetationSettingName);

    this.productId = productId;
    // this.categories = Object.values(categoryIds);
    this.vegetationTypes = Object.values(vegetationTypes);
  }

  shouldDisplayBasedOnStep = (number) => number > 0 && number <= this.totalSteps;

  // Entry page constants
  whatToReportText = {
    text: 'problems with any type of vegetation',
  };

  beforeYouBeginHeadingText = 'Before you begin, see how we deal with;';

  beforeYouBeginText = [
    {
      text: 'Issues with trees ',
      url: 'https://www.york.gov.uk/ReportTrees',
    },
    {
      text: 'Issues with hedges ',
      url: 'https://www.york.gov.uk/ReportHedgerows',
    },
    {
      text: 'Issues with grass ',
      url: 'https://www.york.gov.uk/ReportGrass',
    },
    {
      text: 'Issues with leaves and blossom ',
      url: 'https://www.york.gov.uk/ReportLeavesAndBlossom',
    },
    {
      text: 'Issues with weeds and moss ',
      url: 'https://www.york.gov.uk/ReportWeedsAndMoss',
    },
  ];

  uploadPhotoHelpText = {
    noFiles:
      'Help: Uploaded photos of the problem can support and influence how and when this is dealt with. If you have any photos upload them here (.png or .jpg)',
    withFiles: 'Help: If you would like to change your photo/s, upload them here (.png or .jpg)',
  };

  disclaimer =
    "We plan maintenance of vegetation on a priority basis, as part of a programme of work. We'll only take action if problems with grass, trees, blossom or leaves are causing a risk to safety.";

  // Map constants
  mapIncidentLayerId = 'vegetationIncidents';
  mapIncidentLayerTitle = 'Problem description';
  enablePointSelectionZoomLevels = mapConstants.enablePointSelectionZoomLevels;
}
