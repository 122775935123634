import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { withFormik, Form } from 'formik';
import * as Yup from 'yup';

import {
  InputErrorComponent,
  ButtonComponent,
  RadiobuttonInputComponent,
  ButtonClasses,
} from 'cyc-react-ui-components';

import { ErrorBoxComponent, FileInputComponent, ErrorLabelHandlerComponent, setInitialPosition } from '../../../common';

import usePrevious from 'common/hooks/use-previous.hook';
import { IncidentsExistDisplay } from 'incident-forms/components-shared';
import {
  generalHeader,
  workingToFixProblemFooter,
} from 'incident-forms/components-shared/existing-incidents-display/incidents-exist-display.component';
import { ConfigurationService } from 'cyc-configuration-service';

const ProblemPage = ({
  isSubmitting,
  isValid,
  values,
  errors,
  touched,
  submitCount,
  setFieldValue,
  onProblemTypeChange,
  uploadedFiles,
  onUploadFiles,
  onRemoveFile,
  goToPrevious,
  possibleProblems,
  existingIncidents,
  productName,
}) => {
  const previousIsSubmitting = usePrevious(isSubmitting);
  const errorBox = useRef();

  useEffect(() => {
    setInitialPosition();
  }, []);

  useEffect(() => {
    if (previousIsSubmitting !== undefined && isSubmitting === true && isValid === false) {
      ReactDOM.findDOMNode(errorBox.current).scrollIntoView();
    }
  }, [isSubmitting]);

  const filteredPossibleProblems = () => {
    const mapped = possibleProblems().map((problem) => {
      return {
        label: problem.displayName,
        value: problem.id,
      };
    });
    if (existingIncidents) {
      const filtered = [];
      mapped.forEach((item) => {
        // filter out ones that already exist
        const exists = existingIncidents.find((x) => x.categoryId.toString() === item.value);
        if (exists === undefined) {
          filtered.push(item);
        }
      });
      return filtered;
    }
    // Return original array if existingIncidents does not exist
    return mapped;
  };

  return (
    <div className="page">
      <Form>
        <div ref={errorBox}></div>
        {Object.keys(errors).length > 0 && touched.problemId && <ErrorBoxComponent errors={errors}></ErrorBoxComponent>}

        <div className="row">
          <span id="whatIsTheProblem">
            <ErrorLabelHandlerComponent
              touched={touched}
              errors={errors}
              text="What is the problem?"
              id="problemId"
              submitCount={submitCount}
            />
            <RadiobuttonInputComponent
              name="problemId"
              onChange={(e) => {
                setFieldValue('problemId', e);
                onProblemTypeChange(e);
              }}
              value={values.problemId}
              error={errors.problemId && touched.problemId}
              options={filteredPossibleProblems()}
            />
          </span>
          {errors.problemId && touched.problemId && <InputErrorComponent>{errors.problemId}</InputErrorComponent>}
        </div>

        <div className="my-4">
          <IncidentsExistDisplay
            headerText={generalHeader(productName)}
            footerText={workingToFixProblemFooter(productName)}
            incidentsList={existingIncidents ? existingIncidents : []}
            categorys={ConfigurationService.store.categoryIds.streetLighting}
          />
        </div>

        <h2 className="mt-3" id="uploadPhoto">
          Upload a photo
        </h2>

        <FileInputComponent uploadedFiles={uploadedFiles} onUploadFiles={onUploadFiles} onRemoveFile={onRemoveFile} />

        <ButtonComponent type="submit" disabled={isSubmitting} className={ButtonClasses.primary + ' float-right'}>
          Next
        </ButtonComponent>
        <ButtonComponent type="button" onClick={goToPrevious}>
          Previous
        </ButtonComponent>
      </Form>
    </div>
  );
};

const ProblemPageComponent = withFormik({
  mapPropsToValues({ problemId, uploadedFiles, goToNext }) {
    return {
      problemId: problemId || '',
      goToNext: goToNext,
      uploadedFiles: uploadedFiles || [],
    };
  },
  validationSchema: Yup.object().shape({
    problemId: Yup.string().required("Please supply details for 'What is the problem?.'"),
  }),
  handleSubmit(bag) {
    bag.goToNext();
  },
})(ProblemPage);

ProblemPageComponent.propTypes = {
  problemId: PropTypes.string,
  possibleProblems: PropTypes.func,
  onUploadFiles: PropTypes.func.isRequired,
  onRemoveFile: PropTypes.func.isRequired,
  onProblemTypeChange: PropTypes.func.isRequired,
  uploadedFiles: PropTypes.array,
  existingIncidents: PropTypes.array,
  productName: PropTypes.string,
};

export default ProblemPageComponent;
export { ProblemPage };
