export const ChangeName = 'ChangeName';
export const GraffitiForm = 'GraffitiForm';
export const RoadAndPavementProblemsForm = 'RoadAndPavementProblemsForm';
export const ReportDetailsOutcome = 'ReportDetailsOutcome';
export const WaterAndDrainageProblemsForm = 'WaterAndDrainageProblemsForm';
export const StreetFurnitureForm = 'StreetFurnitureForm';
export const DogAndLitterBinsForm = 'DogAndLitterBins';
export const RefuseAndRecycling = 'RefuseAndRecycling';
export const MyCollections = 'MyCollections';
export const RoadAndStreetSignForm = 'RoadAndStreetSignForm';
export const UnadoptedLayerFlag = 'UnadoptedLayer';
export const MissingAssetsFlag = 'MissingAssets';
export const MissingAssetsStreetLightingFlag = 'MissingAssetsStreetLighting';
export const VegetationForm = 'VegetationForm';
export const DownForMaintenance = 'DownForMaintenance';
export const ChangeAddressMaintenance = 'ChangeAddressMaintenance';
export const RegistrationMaintenance = 'RegistrationMaintenance';
export const ParkingServices = 'ParkingServices';
export const TestingPage = 'TestingPage';
