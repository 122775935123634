import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { MyAccountPageComponent } from '../../../common';

import { clientMyAccountUrl, PageHeaderComponent } from '../../../common';
import WithAuthentication from 'common/components/with-authentication/with-authentication.component';

const ChangePasswordSuccessComponent = () => (
  <WithAuthentication>
    <PageHeaderComponent text="Change of password confirmation" icon="icon-website" />
    <MyAccountPageComponent>
      <p>Thank you. Your password has been changed and we will send you confirmation by email shortly.</p>
      <p>
        Back to <Link to={clientMyAccountUrl.dashboard}>My Account</Link>
      </p>
    </MyAccountPageComponent>
  </WithAuthentication>
);

ChangePasswordSuccessComponent.propTypes = {
  history: PropTypes.object,
};

export default ChangePasswordSuccessComponent;
