import { ConfigurationService } from 'cyc-configuration-service';
import { CYCServices } from 'cyc-services';

import { clientMyAccountUrl, apiMyAccountUrl, streetFurnitureSettingName, graffitiSettingName } from '../../common';
import { mapConstants } from '../../common/constants/map.constants';

export default class FormConfiguration {
  testName = 'Street furniture';
  totalSteps = 5;
  userIsLoggedIn = CYCServices.JWTAuth.isAuthenticated;
  pageHeaderText = 'Report a street furniture problem';
  documentTitle = 'My Account - Report a street furniture problem';
  subject = 'Street furniture problem';

  enablePointSelectionZoomLevels = mapConstants.enablePointSelectionZoomLevels;
  mapIncidentLayerId = 'streetFurnitureIncidents';
  mapIncidentLayerTitle = 'Problem description';

  getGraffitiSettings = () => {
    return ConfigurationService.getEnvironmentSettingByName(graffitiSettingName);
  };

  getProblemTypes = () => {
    return Object.entries(ConfigurationService.getDrillDownSettingValue([streetFurnitureSettingName, 'types'])).map(
      (type) => type[1]
    );
  };

  getProductId = () => {
    return ConfigurationService.getDrillDownSettingValue([streetFurnitureSettingName, 'productId']);
  };

  getIncidentsUrl = `${ConfigurationService.store.apiUrl}${apiMyAccountUrl.getIncidents.STREET_FURNITURE}`;

  shouldDisplayBasedOnStep = (number) => number > 0 && number <= this.totalSteps;

  baseUrl = clientMyAccountUrl.streetFurniture;
  stepUrls = [
    `${this.baseUrl.root}`,
    `${this.baseUrl.root + this.baseUrl.step1}`,
    `${this.baseUrl.root + this.baseUrl.step2}`,
    `${this.baseUrl.root + this.baseUrl.step3}`,
    `${this.baseUrl.root + this.baseUrl.step4}`,
    `${this.baseUrl.root + this.baseUrl.confirmation}`,
  ];
}
