import axios from 'axios';
import appInsights from 'cyc-application-insights-react';

export const _cache = [];
export const addressQuery = 'https://addresses.york.gov.uk/api/address/lookupbypostcode/';
export const osPlacesQuery = 'https://addresses.york.gov.uk/api/osplaces/lookupbypostcode/';

export const addressSources = {
  NONE: 'NONE',
  LLPG: 'LLPG',
  OS_API: 'OS API',
};

export const fetchAddressArrayAsync = async (postcode) => {
  // Check the address cache
  if (_cache[postcode.toUpperCase()]) {
    // Return cached value
    return _cache[postcode.toUpperCase()];
  } else {
    // Otherwise call the remote service
    try {
      let response = await axios.get(addressQuery + postcode);
      const result = {
        results: [],
        source: addressSources.NONE,
      };

      // add to the cache, if there are results
      if (response.data.length > 0) {
        result.results = response.data;
        result.source = addressSources.LLPG;

        _cache[postcode.toUpperCase()] = result;
      } else {
        // If there are no results then call the os places endpoint
        response = await axios.get(osPlacesQuery + postcode);
        if (response.data.length > 0) {
          result.results = response.data;
          result.source = addressSources.OS_API;
          _cache[postcode.toUpperCase()] = result;
        }
      }

      return result;
    } catch (ex) {
      appInsights.trackException({ exception: ex, properties: { postcode } });
      throw ex;
    }
  }
};
