/**
 * Builds the incidentLayersOptions that go into the CYC Esri map
 * @param {Function} mapIncidentDetailsToAttributes - object for mapIncidentDetailsToAttributes
 * @param {string} getIncidentsUrl - url string to fetch incidents
 * @param {number} mapIncidentLayerId  - map layer Id
 * @param {string} popupTemplate - popup template string
 * @param {string} fields - fields for displaying
 * @returns incidentLayersOptions
 */
export const incidentLayersOptionsBuilder = ({
  mapIncidentDetailsToAttributes,
  getIncidentsUrl,
  mapIncidentLayerId,
  popupTemplate,
  fields,
}) => {
  return [
    {
      layerUrl: getIncidentsUrl,
      layerId: mapIncidentLayerId,
      popupTemplate: popupTemplate,
      mapIncidentDetailsToAttributes: mapIncidentDetailsToAttributes,
      fields: fields,
    },
  ];
};

/**
 * @param incident -  incident details from form for basic n
 * @param customBuild - (Optional) pass in a custom object with incident information. Overrised basic return object
 */
export const mapIncidentDetailsToAttributesBuilder = (incident) => {
  return {
    incidentId: incident.incidentId,
    assetDescription: incident.assetDescription ? incident.assetDescription.split('(')[0].trim() : null,
    faultType: incident.category,
    createdTime: new Date(incident.createdTime).toLocaleString('en-GB'),
  };
};

export const popupTemplateBuilder = ({
  mapIncidentLayerTitle,
  tableDataArray = [],
  nonTrData = [],
  showAlreadyReportedMessage = false,
}) => {
  let dataString = '';
  const reportedMessage = showAlreadyReportedMessage === true ? 'This problem has already been reported.' : '';
  tableDataArray.forEach((element, index) => {
    dataString += `<tr><th>${element.header}</th><td>{${element.data}}</td></tr>`;
    nonTrData.forEach((data) => {
      if (data.index === index) {
        dataString += data.data;
      }
    });
  });
  return {
    title: mapIncidentLayerTitle,
    content: `<table class="esri-widget__table">${dataString}</table><br />${reportedMessage}`,
  };
};

export class FieldsBuilder {
  constructor() {
    this.builder = [];
  }

  pushToBuilder(object, alias = undefined) {
    this.builder.push({
      name: object.name,
      alias: alias ? alias : object.alias,
      type: object.type,
    });
  }

  addIncidentId(alias = undefined) {
    this.pushToBuilder(
      {
        name: 'incidentId',
        alias: 'Incident ID',
        type: 'oid',
      },
      alias
    );
  }

  addIncidentReferenceNumber(alias = undefined) {
    this.pushToBuilder(
      {
        name: 'referenceNumber',
        alias: 'Reference number',
        type: 'oid',
      },
      alias
    );
  }

  addCreatedTime(alias = undefined) {
    this.pushToBuilder(
      {
        name: 'createdTime',
        alias: 'Created',
        type: 'string',
      },
      alias
    );
  }

  addWasteType(alias = undefined) {
    this.pushToBuilder(
      {
        name: 'wasteType',
        alias: 'Waste type',
        type: 'string',
      },
      alias
    );
  }

  addFlyTippingOther(alias = undefined) {
    this.pushToBuilder(
      {
        name: 'flytippingOther',
        alias: 'Please specify',
        type: 'string',
      },
      alias
    );
  }

  addFaultType(alias = undefined) {
    this.pushToBuilder(
      {
        name: 'faultType',
        alias: 'Fault type',
        type: 'string',
      },
      alias
    );
  }

  addFeatureType(alias = undefined) {
    this.pushToBuilder(
      {
        name: 'featureType',
        alias: 'Feature type',
        type: 'string',
      },
      alias
    );
  }

  addAssetDescription(alias = undefined) {
    this.pushToBuilder(
      {
        name: 'assetDescription',
        alias: 'Lamp type',
        type: 'string',
      },
      alias
    );
  }

  addIssue(alias = undefined) {
    this.pushToBuilder(
      {
        name: 'issue',
        alias: 'Issue',
        type: 'string',
      },
      alias
    );
  }

  getBuilderResult() {
    return this.builder;
  }
}
