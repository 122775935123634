import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withFormik, Form } from 'formik';
import * as Yup from 'yup';
import {
  ErrorBoxComponent,
  FileInputComponent,
  ErrorLabelHandlerComponent,
  setInitialPosition,
  OffensiveGraffitiInputComponent,
} from '../../../common';
import {
  RadiobuttonInputComponent,
  InputErrorComponent,
  ButtonComponent,
  ButtonClasses,
} from 'cyc-react-ui-components';

const ProblemPageForm = (props) => {
  const errorBox = React.createRef();
  useEffect(() => {
    setInitialPosition();
  }, []);

  return (
    <Form>
      <div ref={errorBox}></div>
      {Object.keys(props.errors).length > 0 && props.touched.problemId && (
        <ErrorBoxComponent errors={props.errors}></ErrorBoxComponent>
      )}

      <h2 id="whatIsTheProblem">About the problem</h2>
      <p>What is the problem?</p>

      <div className="row">
        <span>
          <ErrorLabelHandlerComponent
            touched={props.touched}
            errors={props.errors}
            text="What is the problem"
            id="problemId"
            submitCount={props.submitCount}
          />
          <RadiobuttonInputComponent
            name="problemId"
            value={props.values.problemId}
            onChange={(e) => {
              props.setFieldValue('problemId', e);
              props.setFieldValue('offensiveGraffiti', '');
              props.onProblemTypeChange(
                e,
                props.possibleProblems.find((x) => x.id === e)
              );
            }}
            error={props.errors.problemId && props.touched.problemId}
            options={props.possibleProblems.map((problem) => ({
              label: problem.displayName,
              value: problem.id,
            }))}
          />
          {props.errors.problemId && props.touched.problemId && (
            <InputErrorComponent>{props.errors.problemId}</InputErrorComponent>
          )}
        </span>
      </div>

      {props.isGraffiti === 'true' ? (
        <OffensiveGraffitiInputComponent
          offensiveGraffiti={props.offensiveGraffiti}
          onOffensiveGraffitiChange={(value) => {
            props.setFieldValue('offensiveGraffiti', value);
            props.onOffensiveGraffitiChange(value);
          }}
          errors={props.errors}
          touched={props.touched}
          submitCount={props.submitCount}
        />
      ) : (
        <React.Fragment />
      )}

      <h2 className="mt-3" id="uploadPhoto">
        Upload a photo
      </h2>

      <FileInputComponent
        uploadedFiles={props.uploadedFiles}
        onUploadFiles={props.onUploadFiles}
        onRemoveFile={props.onRemoveFile}
      />

      <ButtonComponent type="submit" disabled={props.isSubmitting} className={ButtonClasses.primary + ' float-right'}>
        Next
      </ButtonComponent>
      <ButtonComponent type="button" onClick={props.goToPrevious}>
        Previous
      </ButtonComponent>
    </Form>
  );
};

const ProblemPageAfterLocationComponent = withFormik({
  mapPropsToValues({ problemId, offensiveGraffiti, goToNext }) {
    return {
      problemId: problemId || '',
      offensiveGraffiti,
      goToNext: goToNext,
    };
  },
  validationSchema: (props) =>
    Yup.object().shape({
      problemId: Yup.string().required("Please supply details for 'What is the problem'."),
      offensiveGraffiti: Yup.string().when('problemId', {
        is: () => props.isGraffiti === 'true',
        then: Yup.string().required("Please supply details for 'Is the graffiti offensive?'."),
      }),
    }),
  handleSubmit(bag) {
    bag.goToNext();
  },
})(ProblemPageForm);

ProblemPageAfterLocationComponent.propTypes = {
  problemId: PropTypes.string,
  onUploadFiles: PropTypes.func.isRequired,
  onRemoveFile: PropTypes.func.isRequired,
  onProblemTypeChange: PropTypes.func.isRequired,
  uploadedFiles: PropTypes.array,
  possibleProblems: PropTypes.array.isRequired,
};

export default ProblemPageAfterLocationComponent;
