import React from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';
import { FeatureFlagsService } from 'cyc-configuration-service';
import { AccountRouterComponent } from '../account';
import RegistrationApp from '../registration/registration.app';
import { WebChatDetailsRouterComponent, WebChatRedirect } from '../web-chat-details';
import MyReportsApp from '../my-reports/my-reports.app';
import {
  clientMyAccountUrl as urls,
  binCollectionsRoot,
  ErrorPageComponent,
  incidentFormRoot,
  NotFoundComponent,
  refuseAndRecyclingRoot,
  isEnabled,
  RefuseAndRecycling,
  ParkingPortalErrorPageComponent,
  ParkingServices,
  MyCollections,
  TestingPage,
} from '../common';
import HomePageContainer from '../dashboard/containers/home/home-page.container';
import SignInRouter from '../sign-in/sign-in.router';
import { TestingApp } from '../testing';
import IncidentFormRouter from '../incident-forms/incident-form.router';
import RefuseAndRecyclingRouter from '../refuse-and-recycling/refuse-and-recycling.router';
import ParkingCustomerDetails from 'parking-services/parking-portal-customer-details.component';
import BinCollectionsApp from '../bin-collections/bin-collections.app';

const SiteRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to={urls.dashboard} />} />
      <Route path={urls.dashboard} element={<HomePageContainer />} />
      <Route path={`${urls.registration.root}/*`} element={<RegistrationApp />} />
      <Route path={`${urls.myReports.root}/*`} element={<MyReportsApp />} />
      <Route path={urls.error} element={<ErrorPageComponent />} />
      {isEnabled(FeatureFlagsService.getFeatureFlagByName(TestingPage)) ? (
        <Route path={urls.testing.root} element={<TestingApp />} />
      ) : null}
      <Route path={`${urls.account.root}/*`} element={<AccountRouterComponent />} />
      <Route path={urls.signIn} element={<SignInRouter />} />
      <Route path={`${incidentFormRoot}/*`} element={<IncidentFormRouter />} />

      <Route path={`${urls.webchat.root}/*`} element={<WebChatRedirect url={'https://www.york.gov.uk/ContactUs'} />} />

      {/* Web chat is currently turned off and redirecting to the ContactUs page (line above)  */}
      {/* <Route path={`${urls.webchat.root}/*`} element={<WebChatDetailsRouterComponent />} /> */}

      {isEnabled(FeatureFlagsService.getFeatureFlagByName(RefuseAndRecycling)) ? (
        <Route path={`${refuseAndRecyclingRoot}/*`} element={<RefuseAndRecyclingRouter />} />
      ) : null}
      <Route path={urls.parkingPortalErrorPage} element={<ParkingPortalErrorPageComponent />} />

      {isEnabled(FeatureFlagsService.getFeatureFlagByName(MyCollections)) && (
        <Route path={`${binCollectionsRoot}/*`} element={<BinCollectionsApp />} />
      )}
      {isEnabled(FeatureFlagsService.getFeatureFlagByName(ParkingServices)) && (
        <Route path="/GetCustomerDetails" element={<ParkingCustomerDetails />} />
      )}

      <Route path="*" element={<NotFoundComponent />} />
    </Routes>
  );
};

export default SiteRouter;
