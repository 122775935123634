import { CYCServices } from 'cyc-services';
import { ConfigurationService } from 'cyc-configuration-service';
import { clientMyAccountUrl, mapConstants, processCategoryIdsSettingName } from 'common';

// name used in tests to allow searching them by name
export const testName = 'Water and Drainage';

export default class FormConfiguration {
  surfaceWaterQuestions = [];
  waterAndDrainageTypes = {};
  productId;
  missingAssetProductId;
  userIsLoggedIn = CYCServices.JWTAuth.isAuthenticated;

  constructor() {
    const waterAndDrainageData = ConfigurationService.getEnvironmentSettingByName(
      processCategoryIdsSettingName.waterAndDrainage
    );

    this.surfaceWaterQuestions = waterAndDrainageData.surfaceWaterQuestions;
    this.waterAndDrainageTypes = waterAndDrainageData.waterAndDrainageTypes;
    this.productId = waterAndDrainageData.productId;
    this.missingAssetProductId = waterAndDrainageData.missingAssetProductId;
  }

  // get type id based on the name of the setting
  getTypeId = (typeName) => {
    const types = Object.entries(this.waterAndDrainageTypes);
    const result = types.find((type) => type[0] === typeName)[1];
    if (result) return result.id;
    throw Error(`Could not find id for '${typeName}' category`);
  };

  // For shortening the urls and having them in 1 place
  baseUrl = clientMyAccountUrl.waterAndDrainageProblems;
  // Steps in order
  // Any urls used that are not in here get a step count of -1
  stepUrls = [
    `${this.baseUrl.root}`,
    `${this.baseUrl.root + this.baseUrl.step1}`,
    `${this.baseUrl.root + this.baseUrl.step2}`,
    `${this.baseUrl.root + this.baseUrl.step3}`,
    `${this.baseUrl.root + this.baseUrl.step4}`,
    `${this.baseUrl.root + this.baseUrl.confirmation}`,
  ];

  // Entry page constants
  whatToReportText = {
    text: 'problems with water or drainage',
    url: 'https://www.york.gov.uk/ReportWaterAndDrainageProblems',
  };

  beforeYouBeginText = [
    {
      text: 'problems we can help with',
      url: 'https://www.york.gov.uk/ReportWaterAndDrainageProblems#problemswecanhelpwith',
    },
    {
      text: 'our water and drainage problem response times',
      url: 'https://www.york.gov.uk/ReportWaterAndDrainageProblems#responsetimes',
    },
  ];

  pageHeaderText = 'Report a water or drainage problem';
  documentTitle = 'My Account - Report a water or drainage problem';
  totalSteps = this.stepUrls.length - 1;

  // subject field passed to OSC
  subject = 'Water and Drainage problems';
  enablePointSelectionZoomLevels = mapConstants.enablePointSelectionZoomLevels;

  shouldDisplayBasedOnStep = (number) => number > 0 && number <= this.totalSteps;

  // Map constants
  mapIncidentLayerId = 'waterAndDrainageIncidents';
  mapIncidentLayerTitle = 'Problem description';
  mapFeatureLayerId = 'waterAndDrainageFeatures';
  mapFeatureLayerTitle = 'Selected gully';
  mapFeatureLayerUrl = 'https://maps.york.gov.uk/arcgis/rest/services/Public/LV_TranStreetCare/MapServer/16';

  missingAssetCheckBoxLabel = "I can't find the gully on the map";

  // surface water question names for summary page
  sumaryQuestionNames = {
    hasItRained: 'Rained in last 2 hours?',
    waterDangerToUser: 'Danger to road users?',
    riskOfInternalFlooding: 'Risk of internal flooding?',
    reocurringProblem: 'Reoccuring problem?',
  };
}
