import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Form, withFormik } from 'formik';
import * as Yup from 'yup';
import { ErrorBoxComponent, FileInputComponent, setInitialPosition, RadioList } from '../../../common';

import { ButtonComponent, ButtonClasses } from 'cyc-react-ui-components';

const ProblemPageSurfaceWaterForm = (props) => {
  let errorBoxRef = React.createRef();
  const previousSubmitCount = React.useRef(0);

  useEffect(() => {
    if (
      !props.isSubmitting &&
      !props.isValid &&
      Object.keys(props.errors).length > 0 &&
      props.submitCount > previousSubmitCount.current
    ) {
      previousSubmitCount.current = props.submitCount;
      const errorBox = ReactDOM.findDOMNode(errorBoxRef.current);
      errorBox.scrollIntoView();
    }
  }, [props.isSubmitting, errorBoxRef, props.errors, props.isValid, props.submitCount]);

  useEffect(() => {
    setInitialPosition();
  }, []);

  return (
    <div className="page">
      <Form>
        <div ref={errorBoxRef}></div>
        {Object.keys(props.errors).length > 0 && props.submitCount > 0 && (
          <ErrorBoxComponent errors={props.errors}></ErrorBoxComponent>
        )}

        <h2>About the problem</h2>
        <p>Surface water</p>
        <p>Providing the following information will help us assess the problem (All answers are required)</p>

        {props.surfaceWaterQuestions.map((element) => {
          return (
            <RadioList
              key={element.id}
              question={element.question}
              id={element.id}
              selectedItem={props[element.id]}
              radioOptions={element.radioOptions}
              onChange={(e) => {
                props.setFieldValue(element.id, e);
                props.onSurfaceWaterQuestionChange(element.id, e);
              }}
              touched={props.touched}
              errors={props.errors}
              submitCount={props.submitCount}
            />
          );
        })}

        <h3 className="mt-3" id="uploadPhoto">
          Upload a photo
        </h3>

        <FileInputComponent
          uploadedFiles={props.uploadedFiles}
          onUploadFiles={props.onUploadFiles}
          onRemoveFile={props.onRemoveFile}
        />

        <ButtonComponent type="submit" disabled={props.isSubmitting} className={ButtonClasses.primary + ' float-right'}>
          Next
        </ButtonComponent>
        <ButtonComponent type="button" onClick={props.goToPreviousPage}>
          Previous
        </ButtonComponent>
      </Form>
    </div>
  );
};

const ProblemPageSurfaceWaterComponent = withFormik({
  mapPropsToValues(props) {
    const valuesObject = {
      goToNextPage: props.goToNextPage,
      uploadedFiles: props.uploadedFiles || [],
    };

    props.surfaceWaterQuestions.forEach((element) => {
      valuesObject[element.id] = props[element.id];
    });

    return valuesObject;
  },
  validationSchema: (props) => {
    const validationObject = {
      goToNextPage: Yup.string().required(),
      uploadedFiles: Yup.array(),
    };

    props.surfaceWaterQuestions.forEach((element) => {
      validationObject[element.id] = Yup.number().required(`Please supply details for '${element.question}'`);
    });
    return Yup.object().shape(validationObject);
  },
  handleSubmit(bag) {
    bag.goToNextPage();
  },
})(ProblemPageSurfaceWaterForm);

ProblemPageSurfaceWaterComponent.propTypes = {
  goToNextPage: PropTypes.func.isRequired,
  goToPreviousPage: PropTypes.func.isRequired,
  onSurfaceWaterQuestionChange: PropTypes.func.isRequired,
  surfaceWaterQuestions: PropTypes.array.isRequired,
  onUploadFiles: PropTypes.func.isRequired,
  onRemoveFile: PropTypes.func.isRequired,
  uploadedFiles: PropTypes.array.isRequired,
  hasItRained: PropTypes.string,
  waterDangerToUser: PropTypes.string,
  riskOfInternalFlooding: PropTypes.string,
  reocurringProblem: PropTypes.string,
};

export default ProblemPageSurfaceWaterComponent;
export { ProblemPageSurfaceWaterForm };
