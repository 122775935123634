import React from 'react';
import { withFormik, Form } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { ConfigurationService } from 'cyc-configuration-service';

import { postChangePasswordAsync } from '../../services/account.service';
import {
  equalTo,
  msgConstants,
  PASSWORD_COMPLICATED_REGEX,
  NetworkCatchErrorComponent,
  ErrorBoxComponent,
  ErrorLabelHandlerComponent,
  clientMyAccountUrl,
  apiMyAccountUrl,
} from '../../../common';
import {
  HelpTextComponent,
  TextInputComponent,
  InputErrorComponent,
  SubmitButtonAxiosComponent,
  ButtonClasses,
} from 'cyc-react-ui-components';

Yup.addMethod(Yup.string, 'equalTo', equalTo);

// The actual form to implement
const changePasswordForm = ({ values, errors, touched, handleSubmit, submitCount, setFieldValue }) => (
  <Form noValidate>
    <NetworkCatchErrorComponent didCatchError={values.submitError} customMessage={values.submitErrorMsg} />
    {Object.keys(errors).length > 0 &&
    touched.currentPassword &&
    touched.newPassword &&
    touched.confirmPassword &&
    submitCount > 0 ? (
      <ErrorBoxComponent errors={errors}></ErrorBoxComponent>
    ) : (
      <React.Fragment />
    )}
    <div className="row">
      <div className="col">
        <ErrorLabelHandlerComponent touched={touched} errors={errors} text="Current password" id="currentPassword" />
        <TextInputComponent
          type="password"
          name="currentPassword"
          id="currentPassword"
          value={values.currentPassword}
          onChange={(e) => setFieldValue('currentPassword', e.target.value)}
          error={errors.currentPassword && touched.currentPassword}
        />
        {errors.currentPassword && touched.currentPassword && (
          <InputErrorComponent>{errors.currentPassword}</InputErrorComponent>
        )}
      </div>
    </div>

    <div className="row">
      <div className="col">
        <ErrorLabelHandlerComponent touched={touched} errors={errors} text="New password" id="newPassword" />
        <TextInputComponent
          type="password"
          name="newPassword"
          id="newPassword"
          value={values.newPassword}
          onChange={(e) => setFieldValue('newPassword', e.target.value)}
          error={errors.newPassword && touched.newPassword}
        />
        {errors.newPassword && touched.newPassword && <InputErrorComponent>{errors.newPassword}</InputErrorComponent>}
        <HelpTextComponent>
          Help: Password must be at least 8 characters and contain at least 1 uppercase letter, 1 lowercase letter, 1
          number and 1 special character
        </HelpTextComponent>
      </div>
    </div>
    <div className="row">
      <div className="col">
        <ErrorLabelHandlerComponent
          touched={touched}
          errors={errors}
          text="Confirm new password"
          id="confirmPassword"
        />
        <TextInputComponent
          type="password"
          name="confirmPassword"
          id="confirmPassword"
          value={values.confirmPassword}
          onChange={(e) => setFieldValue('confirmPassword', e.target.value)}
          error={errors.confirmPassword && touched.confirmPassword}
        />
        {errors.confirmPassword && touched.confirmPassword && (
          <InputErrorComponent>{errors.confirmPassword}</InputErrorComponent>
        )}
        <HelpTextComponent>Help: Please re-enter your new password</HelpTextComponent>
      </div>
    </div>

    <div className="row">
      <div className="col">
        <SubmitButtonAxiosComponent
          type="submit"
          className={ButtonClasses.primary + ' float-right'}
          url={`${ConfigurationService.store.apiUrl}${apiMyAccountUrl.MANAGE_CHANGE_PASSWORD}`}
          onClick={handleSubmit}
        />
        <Link className="link-inline-with-button" to={clientMyAccountUrl.dashboard}>
          Back to My Account
        </Link>
      </div>
    </div>
  </Form>
);

// This is what is used anywhere in the app
const ChangePasswordFormComponent = withFormik({
  mapPropsToValues({ currentPassword = '', newPassword = '', confirmPassword = '', handleRedirect }) {
    return {
      currentPassword: currentPassword || '',
      newPassword: newPassword || '',
      confirmPassword: confirmPassword || '',
      submitError: false,
      submitErrorMsg: undefined,
      handleRedirect: handleRedirect || console.log('redirect not handled'),
    };
  },
  validationSchema: Yup.object().shape({
    currentPassword: Yup.string()
      .min(8, "'Current password' - Error: this password does not appear to be valid. Please re-enter.")
      .required("Please supply details for 'Current password'."),
    newPassword: Yup.string()
      .required("Please supply details for 'New password'.")
      .matches(PASSWORD_COMPLICATED_REGEX, msgConstants.NEW_PASSWORD_COMPLICATED_MESSAGE),
    confirmPassword: Yup.string()
      .equalTo(
        Yup.ref('newPassword'),
        "'Confirm new password' - Error: this password does not appear to match. Please re-enter."
      )
      .required("Please supply details for 'Confirm new password'."),
  }),
  handleSubmit(values, { setValues, setSubmitting, setFieldValue, resetForm, setFieldError }) {
    if (values.currentPassword === values.newPassword) {
      setFieldError('newPassword', 'Error: Your new password cannot be the same as your current password');
      setSubmitting(false);
      return;
    }
    postChangePasswordAsync(values.currentPassword, values.newPassword, values.confirmPassword)
      .then((response) => {
        if (response.status === 200) {
          setValues({
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
          });
          values.handleRedirect();
        }
      })
      .catch((error) => {
        resetForm();
        setSubmitting(false);
        if (error !== undefined && error.response !== undefined && error.response.status === 400) {
          setFieldValue('submitError', true);
          setFieldValue('submitErrorMsg', 'Unable to change password, please try again.');
        } else {
          setFieldValue('submitError', true);
        }
      });
  },
})(changePasswordForm);

export default ChangePasswordFormComponent;
