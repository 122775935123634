import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getContactDetailAsync, updateContactAddressAsync } from '../../services/account.service';
import ChangeAddressComponent from '../components/change-address.component';
import { ChangeAddressMaintenance } from 'common/constants/feature-flag.constants';
import { isEnabled } from 'common';
import { FeatureFlagsService } from 'cyc-configuration-service';
import MaintenanceApp from 'maintenance/maintenance.app';

import {
  NetworkLoaderComponent,
  DocumentTitleComponent,
  PageHeaderComponent,
  MyAccountPageComponent,
  clientMyAccountUrl,
} from '../../../common';
import WithAuthentication from 'common/components/with-authentication/with-authentication.component';

const ChangeAddressContainer = () => {
  const navigate = useNavigate();
  const [currentAddressPromise, setCurrentAddressPromise] = useState();
  const [currentAddress, setCurrentAddress] = useState('');

  useEffect(() => {
    const currentAddressPromise = getContactDetailAsync(true);
    setCurrentAddressPromise(currentAddressPromise);

    currentAddressPromise
      .then((response) => setCurrentAddress(response.data.address))
      .catch((error) => error && console.error(error));
  }, []);

  return isEnabled(FeatureFlagsService.getFeatureFlagByName(ChangeAddressMaintenance)) ? (
    <MaintenanceApp />
  ) : (
    <WithAuthentication>
      <PageHeaderComponent text="Change address" icon="icon-website" />
      <MyAccountPageComponent>
        <DocumentTitleComponent title="My account - change address">
          {currentAddressPromise !== undefined ? (
            <NetworkLoaderComponent request={currentAddressPromise}>
              <ChangeAddressComponent
                currentAddress={currentAddress}
                onSubmit={updateContactAddressAsync}
                onSumitSuccess={() =>
                  navigate(clientMyAccountUrl.account.root + clientMyAccountUrl.account.address.successful)
                }
              />
            </NetworkLoaderComponent>
          ) : (
            <React.Fragment />
          )}
        </DocumentTitleComponent>
      </MyAccountPageComponent>
    </WithAuthentication>
  );
};

export default ChangeAddressContainer;
