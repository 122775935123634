import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withFormik, Form } from 'formik';
import * as Yup from 'yup';
import { ErrorBoxComponent, ErrorLabelHandlerComponent, setInitialPosition } from '../../../common';
import {
  RadiobuttonInputComponent,
  InputErrorComponent,
  ButtonComponent,
  ButtonClasses,
} from 'cyc-react-ui-components';

const ProblemPageForm = (props) => {
  const errorBox = React.createRef();
  useEffect(() => {
    setInitialPosition();
  }, []);

  return (
    <Form>
      <div ref={errorBox}></div>
      {Object.keys(props.errors).length > 0 && props.touched.problemId && (
        <ErrorBoxComponent errors={props.errors}></ErrorBoxComponent>
      )}

      <h2 id="aboutTheProblem">About the problem</h2>

      <div className="row">
        <span>
          <ErrorLabelHandlerComponent
            touched={props.touched}
            errors={props.errors}
            text="What is the problem"
            id="problemId"
            submitCount={props.submitCount}
          />
          <RadiobuttonInputComponent
            name="problemId"
            value={props.values.problemId}
            onChange={(e) => {
              props.setFieldValue('problemId', e);
              props.onProblemTypeChange(e);
            }}
            error={props.errors.problemId && props.touched.problemId}
            options={props.possibleProblems.map((problem) => ({
              label: problem.displayName,
              value: problem.id,
            }))}
          />
          {props.errors.problemId && props.touched.problemId && (
            <InputErrorComponent>{props.errors.problemId}</InputErrorComponent>
          )}
        </span>
      </div>

      <ButtonComponent type="submit" disabled={props.isSubmitting} className={ButtonClasses.primary + ' float-right'}>
        Next
      </ButtonComponent>
      <ButtonComponent type="button" onClick={props.goToPrevious}>
        Previous
      </ButtonComponent>
    </Form>
  );
};

const ProblemPageComponent = withFormik({
  mapPropsToValues({ problemId, goToNext }) {
    return {
      problemId: problemId || '',
      goToNext: goToNext,
    };
  },
  validationSchema: Yup.object().shape({
    problemId: Yup.string().required("Please supply details for 'What is the problem'."),
  }),
  handleSubmit(bag) {
    bag.goToNext();
  },
})(ProblemPageForm);

ProblemPageComponent.propTypes = {
  problemId: PropTypes.string,
  onProblemTypeChange: PropTypes.func.isRequired,
  possibleProblems: PropTypes.array.isRequired,
};

export default ProblemPageComponent;
