import React, { useEffect, useReducer, useCallback, useMemo } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { CYCServices } from 'cyc-services';
import FormConfiguration from './form-config';
import { reducer, initialState, actions, fieldNames } from '../incident-form.reducer';
import {
  IncidentFormContainer,
  ContinueFormOrReRoute,
  clientMyAccountUrl,
  changeUrlByStepNumberv6,
  UnadoptedLayerFlag,
  isEnabled,
} from '../../common';
import * as Services from '../../common/services/incident.service';
import {
  incidentLayersOptionsBuilder,
  FieldsBuilder,
  popupTemplateBuilder,
  mapIncidentDetailsToAttributesBuilder,
} from '../helpers/incident-layer-options-builder.helper';
import { HeaderTextFromUrl } from '../helpers/header-text-from-url.helper';
import EntryPage from '../components-shared/entry-page.component';
import LocationPageComponent from '../components-shared/location-page.component';
import ProblemPageComponent from './components/problem-page.component.component';
import ProblemPageAfterLocationComponent from './components/problem-page-after-location.component';
import SummaryPageComponent from '../components-shared/summary-page.component';
import ConfirmationPageComponent from '../components-shared/confirmation-page.component';
import SignInContainer from '../../sign-in/containers/sign-in.container';
import RegistrationApp from '../../registration/registration.app';
import { getUnadoptedRoadsFeatureOptions } from '../helpers/unadopted-layer.helper';
import { FeatureFlagsService } from 'cyc-configuration-service';

const StreetFurnitureApp = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const formConfig = useMemo(() => new FormConfiguration(), []);
  const initState = useMemo(
    () => initialState({ pageHeaderText: formConfig.pageHeaderText }),
    [formConfig.pageHeaderText]
  );

  const [state, dispatch] = useReducer(reducer, initialState(initState));
  const indexOfStep = formConfig.stepUrls.indexOf(location.pathname);
  const problemTypes = formConfig.getProblemTypes();

  // Change the header text on certain steps
  useEffect(() => {
    const header = HeaderTextFromUrl({
      url: location.pathname,
      initialHeaderText: formConfig.pageHeaderText,
    });

    dispatch({
      type: actions.updateStateFields,
      payload: {
        [fieldNames.currentStep]: indexOfStep,
        [fieldNames.pageHeaderText]: header,
      },
    });
  }, [location.pathname, formConfig.pageHeaderText, indexOfStep]);

  // --- HELPERS ---
  const checkAndReturnCategory = (customId = undefined) => {
    try {
      // use the input id if it exists, else go for the states one
      const problemId = customId ? customId : state.formData.problemId;
      const problemWith = problemTypes.find((val) => val.id === state.formData.streetFurnitureTypeId);
      for (let props in problemWith.categories) {
        if (problemWith.categories[props].id === problemId) {
          const category = problemWith.categories[props];
          return category;
        }
      }
    } catch {
      return undefined;
    }
  };

  // ---- ENTRY PAGE -----
  const handleResetForm = useCallback(
    () =>
      dispatch({
        type: actions.resetForm,
        payload: initialState({ pageHeaderText: formConfig.pageHeaderText }),
      }),
    [formConfig.pageHeaderText]
  );

  // ---- LOCATION PAGE ------
  // Build feature Layer options (currently only unadopted roads)
  const getFeatureLayerOptions = () => {
    if (isEnabled(FeatureFlagsService.getFeatureFlagByName(UnadoptedLayerFlag))) {
      return [getUnadoptedRoadsFeatureOptions()];
    }
  };

  const layerOptionsBuilder = () => {
    let fieldsBuilder = new FieldsBuilder();
    fieldsBuilder.addIncidentId();
    fieldsBuilder.addFaultType();
    fieldsBuilder.addIncidentReferenceNumber();
    fieldsBuilder.addCreatedTime();
    const inc = incidentLayersOptionsBuilder({
      mapIncidentDetailsToAttributes: mapIncidentDetailsToAttributesBuilder,
      getIncidentsUrl: formConfig.getIncidentsUrl,
      mapIncidentLayerId: formConfig.mapIncidentLayerId,
      popupTemplate: popupTemplateBuilder({
        mapIncidentLayerTitle: formConfig.mapIncidentLayerTitle,
        tableDataArray: [
          { header: 'Fault type', data: 'faultType' },
          { header: 'Date created', data: 'createdTime' },
        ],
        showAlreadyReportedMessage: true,
      }),
      fields: fieldsBuilder.getBuilderResult(),
    });
    return inc;
  };

  // --- PROBLEM AFTER LOCATION PAGE ---

  const possibleProblemsBuilder = () => {
    let arr = [];
    const type = problemTypes.find((val) => val.id === state.formData.streetFurnitureTypeId);
    if (type === undefined) return; // Stops the app crashing when dev tools open if refreshing page
    // set out a list of option objects [{}] based on that particular ID
    for (let props in type.categories) {
      arr.push(type.categories[props]);
    }
    return arr;
  };

  const handleProblemTypeChange = (id, problem) => {
    const isGraffiti = problemTypes.map((x) => x.categories.graffiti && x.categories.graffiti.id).includes(problem.id);

    if (isGraffiti) {
      dispatch({
        type: actions.updateFormDataFields,
        payload: { [fieldNames.isAlternative]: 'true' },
      });
    } else {
      dispatch({
        type: actions.updateFormDataFields,
        payload: { [fieldNames.isAlternative]: 'false' },
      });
      dispatch({
        type: actions.updateFormDataFields,
        payload: { [fieldNames.offensiveGraffiti]: undefined },
      });
    }

    dispatch({
      type: actions.updateFormDataFields,
      payload: { [fieldNames.problemId]: id },
    });
  };

  // --- SUMMARY PAGE ---
  const buildTableData = () => {
    const formData = state.formData;
    const problemWith = problemTypes.find((val) => val.id === formData.streetFurnitureTypeId);
    let category = checkAndReturnCategory();

    const tableData = [
      {
        columnOne: 'Problem with',
        columnTwo: problemWith && problemWith.displayName ? problemWith.displayName : null,
        onChangeClicked: () => changeUrlByStepNumberv6(1, formConfig, navigate, 'aboutTheProblem'),
        columnTwoClassName: 'sentence-case',
      },
      {
        columnOne: 'Location',
        columnTwo:
          formData.locationObject && formData.locationObject.streetName && formData.locationObject.streetName.length > 0
            ? formData.locationObject.streetName
            : 'Selected on map',
        onChangeClicked: () => changeUrlByStepNumberv6(2, formConfig, navigate, 'location'),
      },
      {
        columnOne: 'Additional location information',
        columnTwo: formData.additionalInformation,
        onChangeClicked: () => changeUrlByStepNumberv6(2, formConfig, navigate, 'additionalInformation'),
      },
      {
        columnOne: 'About the problem',
        columnTwo: category && category.displayName ? category.displayName : null,
        onChangeClicked: () => changeUrlByStepNumberv6(3, formConfig, navigate, 'whatIsTheProblem'),
        columnTwoClassName: 'sentence-case',
      },
      {
        columnOne: 'Upload a photo',
        columnTwo: formData.uploadedFiles ? (
          <ul className="uploaded-photos">
            {formData.uploadedFiles.map((file, index) => (
              <li key={index}>{file.fileName}</li>
            ))}
          </ul>
        ) : (
          <React.Fragment />
        ),
        onChangeClicked: () => changeUrlByStepNumberv6(3, formConfig, navigate, 'uploadPhoto'),
      },
    ];

    // Only show the "Offensive graffiti" response if there is a value
    if (formData.offensiveGraffiti) {
      tableData.splice(4, 0, {
        columnOne: 'Is the graffiti offensive?',
        columnTwo: formData.offensiveGraffiti === true ? 'Yes' : 'No',
        onChangeClicked: () => changeUrlByStepNumberv6(3, formConfig, navigate, 'isGraffitiOffensive'),
      });
    }

    return tableData;
  };

  const submitIncident = async () => {
    let form = { ...state.formData };
    form.productId = formConfig.getProductId();
    form.subject = formConfig.subject;

    if (form.isAlternative === 'true') {
      const graffitiSettings = formConfig.getGraffitiSettings();
      form.productId = graffitiSettings['productId'];
      form.subject = graffitiSettings['subject'];
    }

    // Create the incident
    try {
      const result = await Services.createIncident(form);
      if (result.status === 200) {
        dispatch({ type: actions.updateIncidentObject, payload: result.data });
        dispatch({
          type: actions.updateStateFields,
          payload: { [fieldNames.formStarted]: true, [fieldNames.formCompleted]: true },
        });
      }
    } catch {
      return false;
    }
  };

  // ----- CONFIRMATION PAGE -----
  const sendUpdateIncidentWithUser = async (email) => {
    await Services.updateIncidentWithUser(
      state.incident.referenceNumber,
      state.incident.secretReferenceNumber,
      state.incident.incidentId,
      email
    ).then((result) => {
      if (result.status === 200) {
        return true;
      } else {
        return false;
      }
    });
  };

  const handleUserRegistered = (userData) => {
    dispatch({ type: actions.updateFormDataFields, payload: { [fieldNames.userLoggedIn]: true } });
    // only update incident when user's email is not confirmed
    // save the incident into temp table while wait for the user to confirm his/her email.
    if (state.formCompleted && userData.isEmailConfirmed === false) {
      sendUpdateIncidentWithUser(userData.email);
    }
  };

  const handleSignInCallBack = () => {
    const isSignedIn = formConfig.userIsLoggedIn();
    if (isSignedIn) {
      sendUpdateIncidentWithUser('');
    }
    // after sign in succssfully, navigate back to incident creation confirmation page
    navigate(formConfig.stepUrls[formConfig.totalSteps]);
  };

  // --- render builders ----
  const formRouteInfo = () => {
    return {
      formStarted: state.formStarted,
      formCompleted: state.formCompleted,
      formStartUrl: formConfig.stepUrls[0],
      formEndUrl: formConfig.stepUrls[formConfig.totalSteps],
      location: location,
    };
  };

  return (
    <IncidentFormContainer
      displayProgressBar={() => formConfig.shouldDisplayBasedOnStep(state.currentStep)}
      pageHeaderText={state.pageHeaderText}
      documentTitle={formConfig.documentTitle}
      totalSteps={formConfig.totalSteps}
      currentStep={state.currentStep}
      breadcrumbs={[{ url: clientMyAccountUrl.streetFurniture.root, name: formConfig.pageHeaderText }]}>
      <Routes>
        <Route
          path="/"
          element={
            <EntryPage
              report={{ text: 'problems with street furniture', url: 'https://www.york.gov.uk/ReportStreetFurniture' }}
              informationArray={[
                {
                  text: 'Problems we can help with',
                  url: 'https://www.york.gov.uk/ReportStreetFurniture#problemswecanhelpwith',
                },
                {
                  text: 'Our street furniture problem response times',
                  url: 'https://www.york.gov.uk/ReportStreetFurniture#responsetimes',
                },
              ]}
              goToNextPage={() =>
                changeUrlByStepNumberv6(1, formConfig, navigate, undefined, true, () =>
                  dispatch({ type: actions.updateStateFields, payload: { formStarted: true, formCompleted: false } })
                )
              }
              onResetForm={handleResetForm}
            />
          }
        />

        <Route
          path={formConfig.baseUrl.step1}
          element={
            <ContinueFormOrReRoute {...formRouteInfo()}>
              <ProblemPageComponent
                goToPrevious={() => changeUrlByStepNumberv6(0, formConfig, navigate)}
                goToNext={() => changeUrlByStepNumberv6(2, formConfig, navigate)}
                problemId={state.formData.streetFurnitureTypeId}
                onProblemTypeChange={(value) => {
                  dispatch({
                    type: actions.updateFormDataFields,
                    payload: { [fieldNames.streetFurnitureTypeId]: value },
                  });
                }}
                possibleProblems={problemTypes}
              />
            </ContinueFormOrReRoute>
          }
        />

        <Route
          path={formConfig.baseUrl.step2}
          element={
            <ContinueFormOrReRoute {...formRouteInfo()}>
              <LocationPageComponent
                goToNext={() => changeUrlByStepNumberv6(3, formConfig, navigate)}
                goToPrevious={() => changeUrlByStepNumberv6(1, formConfig, navigate)}
                incidentLayerOptions={layerOptionsBuilder()}
                featureLayerOptions={getFeatureLayerOptions()}
                locationObject={state.formData.locationObject}
                locationSelected={state.formData.locationSelected}
                clearSelectedPoint={() => dispatch({ type: actions.resetLocation })}
                additionalInformation={state.formData.additionalInformation}
                onAdditionalInformationChange={(e) =>
                  dispatch({
                    type: actions.updateFormDataFields,
                    payload: { [fieldNames.additionalInformation]: e.target.value },
                  })
                }
                onMapSelected={(location) => dispatch({ type: actions.updateLocationObject, payload: location })}
                enablePointSelectionZoomLevels={formConfig.enablePointSelectionZoomLevels}
              />
            </ContinueFormOrReRoute>
          }
        />

        <Route
          path={formConfig.baseUrl.step3}
          element={
            <ContinueFormOrReRoute {...formRouteInfo()}>
              <ProblemPageAfterLocationComponent
                goToPrevious={() => changeUrlByStepNumberv6(2, formConfig, navigate)}
                goToNext={() => changeUrlByStepNumberv6(4, formConfig, navigate)}
                onUploadFiles={(files) => dispatch({ type: actions.updateUploadFiles, payload: files })}
                onRemoveFile={(index) => dispatch({ type: actions.removeUploadedFile, payload: index })}
                problemId={state.formData.problemId}
                onProblemTypeChange={handleProblemTypeChange}
                uploadedFiles={state.formData.uploadedFiles}
                possibleProblems={possibleProblemsBuilder()}
                isGraffiti={state.formData.isAlternative}
                offensiveGraffiti={state.formData.offensiveGraffiti}
                onOffensiveGraffitiChange={(isOffensive) =>
                  dispatch({
                    type: actions.updateFormDataFields,
                    payload: { [fieldNames.offensiveGraffiti]: isOffensive },
                  })
                }
              />
            </ContinueFormOrReRoute>
          }
        />

        <Route
          path={formConfig.baseUrl.step4}
          element={
            <ContinueFormOrReRoute {...formRouteInfo()}>
              <SummaryPageComponent
                goToProblemPage={() => changeUrlByStepNumberv6(3, formConfig, navigate)}
                submitIncident={submitIncident}
                tableData={buildTableData()}
              />
            </ContinueFormOrReRoute>
          }
        />

        <Route
          path={formConfig.baseUrl.confirmation}
          element={
            <ContinueFormOrReRoute {...formRouteInfo()}>
              <ConfirmationPageComponent
                referenceNumber={state.incident.referenceNumber}
                onSignInClicked={() => navigate(formConfig.baseUrl.root + clientMyAccountUrl.signIn)}
                onRegisterClicked={() => navigate(formConfig.baseUrl.root + clientMyAccountUrl.register)}
                userLoggedIn={() => CYCServices.JWTAuth.isAuthenticated()}
                goToMyAccount={() => navigate(clientMyAccountUrl.dashboard)}
                restartFormUrl={formConfig.baseUrl.root}
              />
            </ContinueFormOrReRoute>
          }
        />

        <Route
          path={clientMyAccountUrl.signIn}
          element={<SignInContainer onSuccessfulSignIn={handleSignInCallBack} />}
        />
        <Route
          path={`${clientMyAccountUrl.register}/*`}
          element={
            <RegistrationApp
              onSuccessfulRegistration={handleUserRegistered}
              extraSuccessfulContent={<p>Click 'Continue' to view the reference number for your reported problem.</p>}
              onContinueClicked={() => navigate(formConfig.baseUrl.root + formConfig.baseUrl.confirmation)}
            />
          }
        />
      </Routes>
    </IncidentFormContainer>
  );
};

export default StreetFurnitureApp;
