import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const DocumentTitleComponent = ({ title, children }) => {
  useEffect(() => {
    if (title) {
      document.title = title;
    }
    return () => (document.title = 'My Account');
  });

  return <>{children}</>;
};

DocumentTitleComponent.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};

export default DocumentTitleComponent;
