import React from 'react';
import { withFormik, Form } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ConfigurationService } from 'cyc-configuration-service';

import {
  ErrorBoxComponent,
  ErrorLabelHandlerComponent,
  isValidTelephoneNumber,
  NetworkCatchErrorComponent,
  clientMyAccountUrl,
  apiMyAccountUrl,
} from '../../../common';
import {
  HelpTextComponent,
  TextInputComponent,
  InputErrorComponent,
  SubmitButtonAxiosComponent,
  ButtonClasses,
} from 'cyc-react-ui-components';

const changePhonenumberForm = ({ errors, touched, values, handleSubmit, submitCount, setFieldValue }) => (
  <Form noValidate>
    <NetworkCatchErrorComponent didCatchError={values.submitError} />
    {Object.keys(errors).length > 0 && touched.telephone && submitCount > 0 ? (
      <ErrorBoxComponent errors={errors}></ErrorBoxComponent>
    ) : (
      <React.Fragment />
    )}
    <div className="row">
      <div className="col">
        <p>
          <b>Current telephone number: </b> {values.phoneNumber}
        </p>
      </div>
    </div>
    <div className="row">
      <div className="col">
        <ErrorLabelHandlerComponent
          touched={touched}
          errors={errors}
          text="New telephone number"
          id="telephone"
          submitCount={submitCount}
        />
        <TextInputComponent
          type="tel"
          pattern="[0-9\s]*"
          name="telephone"
          id="telephone"
          value={values.telephone}
          onChange={(e) => setFieldValue('telephone', e.target.value)}
          error={errors.telephone && touched.telephone && submitCount > 0}
        />
        {errors.telephone && touched.telephone && submitCount > 0 && (
          <InputErrorComponent>{errors.telephone}</InputErrorComponent>
        )}
        <HelpTextComponent>Help: Provide a mobile or home telephone number</HelpTextComponent>
      </div>
    </div>
    <div className="row">
      <div className="col">
        <SubmitButtonAxiosComponent
          type="submit"
          className={ButtonClasses.primary + ' float-right'}
          url={`${ConfigurationService.store.apiUrl}${apiMyAccountUrl.UPDATE_CONTACT_PHONE}`}
          onClick={handleSubmit}
        />
        <Link className="link-inline-with-button" to={clientMyAccountUrl.dashboard}>
          Back to My Account
        </Link>
      </div>
    </div>
  </Form>
);

const ChangePhonenumberComponent = withFormik({
  mapPropsToValues({ phoneNumber, onSubmit, onSuccessfulChange }) {
    return {
      phoneNumber: phoneNumber || '',
      onSubmit: onSubmit || null,
      onSuccessfulChange: onSuccessfulChange || null,
      telephone: '',
      submitError: false,
    };
  },
  validationSchema: Yup.object().shape({
    telephone: Yup.string()
      .required("Please supply details for 'New telephone number'.")
      .test(
        'telephone',
        "'New telephone number' - Error: This does not appear to be a valid telephone number. Please re-enter.",
        (value) => isValidTelephoneNumber(value, true)
      ),
  }),
  handleSubmit(values, { setSubmitting, setFieldValue }) {
    values
      .onSubmit(values.telephone)
      .then((res) => {
        if (res.status === 200) {
          values.onSuccessfulChange();
        }
      })
      .catch((ex) => {
        setFieldValue('submitError', true);
        setSubmitting(false);
      });
  },
})(changePhonenumberForm);

export default ChangePhonenumberComponent;

ChangePhonenumberComponent.propTypes = {
  phoneNumber: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onSuccessfulChange: PropTypes.func.isRequired,
};
