import { clientMyAccountUrl } from '../common';

export const pageHeaderText = 'Register for My Account';
export const baseUrl = clientMyAccountUrl.registration;
export const stepUrls = [
  `${baseUrl.root}`,
  `${baseUrl.root + baseUrl.step1}`,
  `${baseUrl.root + baseUrl.step2}`,
  `${baseUrl.root + baseUrl.step3}`,
  `${baseUrl.root + baseUrl.emailConfirmation}`,
  `${baseUrl.root + baseUrl.successful}`,
];
