import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import SignInFormComponent from '../components/sign-in-form.component';
import { clientMyAccountUrl, PageHeaderComponent, DocumentTitleComponent, MyAccountPageComponent } from '../../common';
const SignInContainer = ({ onSuccessfulSignIn }) => {
  let location = useLocation();

  // We are going to find the path we are at, to make this the path for the Register link
  const currentPath = location.pathname;

  // We will split this at the point we reach the sign in url
  const preSignInPath = currentPath.split(clientMyAccountUrl.signIn)[0];

  // Append the registration path onto this
  const registerLinkUrl = `${preSignInPath}${clientMyAccountUrl.registration.root}${clientMyAccountUrl.registration.step1}`;

  return (
    <DocumentTitleComponent title="My Account - Sign in">
      {location.pathname.toLowerCase() === clientMyAccountUrl.signIn.toLowerCase() ? (
        <PageHeaderComponent text="Sign in" icon="icon-website" />
      ) : (
        <React.Fragment />
      )}

      <MyAccountPageComponent>
        <SignInFormComponent onSuccessfulSignIn={onSuccessfulSignIn} registerLinkUrl={registerLinkUrl} />
      </MyAccountPageComponent>
    </DocumentTitleComponent>
  );
};

SignInContainer.propTypes = {
  onSuccessfulSignIn: PropTypes.func,
};

export default SignInContainer;
