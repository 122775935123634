import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FeatureFlagsService } from 'cyc-configuration-service';

import { TableComponent, ButtonComponent, ButtonClasses, TableClasses } from 'cyc-react-ui-components';

import { clientMyAccountUrl as urls, isEnabled, ReportDetailsOutcome } from '../../../common';
import ReportSearchCounter from '../report-search-counter/report-search-counter.component';

import './report-search-results-table.component.scss';

const ReportSearchResultsTableComponent = ({ results, webchatSourceId }) => {
  // This could be turned into state if we ever need to change then no. results per page.
  const resultsPerPage = 20;

  const [currentPage, setCurrentPage] = useState(0);
  const [sortOrder, setSortOrder] = useState('created');
  const [sortDescending, setSortDescending] = useState(true);

  useEffect(() => {
    setCurrentPage(0);
  }, [results]);

  const sortClasses = useMemo(() => (sortDescending === false ? 'sort' : 'sort desc'), [sortDescending]);

  const [renderingResults, start, end] = useMemo(() => {
    const sorted = [...results].sort((a, b) => {
      if (a[sortOrder] < b[sortOrder]) return -1;
      if (a[sortOrder] > b[sortOrder]) return 1;
      return 0;
    });
    if (sortDescending) {
      sorted.reverse();
    }
    const start = currentPage * resultsPerPage;
    const end = start + resultsPerPage > sorted.length ? sorted.length : start + resultsPerPage;

    return [sorted.slice(start, end), start, end];
  }, [sortDescending, sortOrder, results, currentPage]);

  const handleSortClick = (order) => {
    if (order === sortOrder) {
      setSortDescending(!sortDescending);
    } else {
      setSortOrder(order);
    }
    setCurrentPage(0);
  };

  const handlePreviousPageClick = () => currentPage > 0 && setCurrentPage(currentPage - 1);

  const handleNextPageClick = () => {
    const nextPage = currentPage + 1;

    if (canShowPage(nextPage)) {
      setCurrentPage(nextPage);
    }
  };

  const canShowPage = (nextPage) => nextPage * resultsPerPage < results.length;

  return (
    <div className="report-search-results-table">
      <ReportSearchCounter max={renderingResults.length} currentMin={start} currentMax={end - 1} />
      {/* This div is for scroll bar on table */}
      <div className="report-search-results-table__wrapper">
        <TableComponent className={TableClasses.restyle}>
          <thead>
            <tr>
              <th onClick={() => handleSortClick('reference')} className={sortOrder === 'reference' ? sortClasses : ''}>
                Reference
              </th>
              <th onClick={() => handleSortClick('created')} className={sortOrder === 'created' ? sortClasses : ''}>
                Date created
              </th>
              <th onClick={() => handleSortClick('subject')} className={sortOrder === 'subject' ? sortClasses : ''}>
                Subject
              </th>
              <th onClick={() => handleSortClick('location')} className={sortOrder === 'location' ? sortClasses : ''}>
                Location
              </th>
              {isEnabled(FeatureFlagsService.getFeatureFlagByName(ReportDetailsOutcome)) ? (
                <th onClick={() => handleSortClick('outcome')} className={sortOrder === 'outcome' ? sortClasses : ''}>
                  Outcome
                </th>
              ) : (
                <th onClick={() => handleSortClick('status')} className={sortOrder === 'status' ? sortClasses : ''}>
                  Status
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {results?.length > 0 ? (
              renderingResults.map((result, key) => (
                <tr key={key}>
                  <td>
                    <span className={TableClasses.inlineHeader}>Reference: </span>
                    <Link
                      to={`${urls.myReports.root}${urls.myReports.details}/${result.reference}/${encodeURIComponent(
                        result.product
                      )}`}>
                      {result.reference}
                    </Link>
                  </td>
                  <td>
                    <span className={TableClasses.inlineHeader}>Date created: </span>
                    {result.created && moment(result.created).format('DD/MM/YYYY')}
                  </td>
                  <td>
                    <span className={TableClasses.inlineHeader}>Subject: </span>
                    {result.subject}
                  </td>
                  <td>
                    <span className={TableClasses.inlineHeader}>Location: </span>
                    {result.location === ''
                      ? result.sourceId === webchatSourceId
                        ? ''
                        : 'Selected on map'
                      : result.location}
                  </td>
                  {isEnabled(FeatureFlagsService.getFeatureFlagByName(ReportDetailsOutcome)) ? (
                    <td>
                      <span className={TableClasses.inlineHeader}>Outcome: </span>
                      {result.outcome ? result.outcome : result.status}
                    </td>
                  ) : (
                    <td>
                      <span className={TableClasses.inlineHeader}>Status: </span>
                      {result.status}
                    </td>
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5">No records found</td>
              </tr>
            )}
          </tbody>
        </TableComponent>
      </div>

      <div className="float-right">
        {currentPage !== 0 && (
          <ButtonComponent
            className={ButtonClasses.secondary}
            onClick={handlePreviousPageClick}
            title="Previous page of results">
            &lt;
          </ButtonComponent>
        )}
        <ButtonComponent className={ButtonClasses.secondary} title="Current page">
          Page {currentPage + 1}
        </ButtonComponent>
        {canShowPage(currentPage + 1) && (
          <ButtonComponent
            className={ButtonClasses.secondary}
            onClick={handleNextPageClick}
            title="Next page of results">
            &gt;
          </ButtonComponent>
        )}
      </div>
      <div className="clear-fix" />
    </div>
  );
};

ReportSearchResultsTableComponent.propTypes = {
  results: PropTypes.array.isRequired,
  webchatSourceId: PropTypes.number,
};

export default ReportSearchResultsTableComponent;
