import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Form, withFormik } from 'formik';
import * as Yup from 'yup';
import { ErrorBoxComponent, ErrorLabelHandlerComponent, setInitialPosition } from '../../../common';

import {
  ButtonComponent,
  ButtonClasses,
  RadiobuttonInputComponent,
  InputErrorComponent,
} from 'cyc-react-ui-components';

const LightTypePageForm = (props) => {
  const errorBoxRef = useRef();
  const errors = useRef(props.errors);
  const isValid = useRef(props.isValid);
  const lightTypes = props.lightTypes;

  useEffect(() => {
    errors.current = props.errors;
    isValid.current = props.isValid;
  }, [props.errors, props.isValid]);

  useEffect(() => {
    if (!props.isSubmitting && !isValid.current && Object.keys(errors.current).length > 0) {
      const errorBox = ReactDOM.findDOMNode(errorBoxRef.current);
      errorBox.scrollIntoView();
    }
  }, [props.isSubmitting]);

  useEffect(() => {
    setInitialPosition();
  }, []);

  const getRadioButtonOptions = () =>
    Object.entries(lightTypes)
      .map(([key, value]) => {
        const streetLightType = props.streetLightTypes[key];
        if (streetLightType) {
          return {
            label: value,
            value: streetLightType.lightingTypeId,
          };
        }
        return undefined;
      })
      .filter(Boolean);

  return (
    <div className="page">
      <Form>
        <div ref={errorBoxRef}></div>
        {Object.keys(props.errors).length > 0 && props.touched.lightTypeId && (
          <ErrorBoxComponent errors={props.errors}></ErrorBoxComponent>
        )}
        <div className="row"></div>
        {lightTypes !== undefined && (
          <div className="row">
            <span id="WhatIsTheLightType">
              <ErrorLabelHandlerComponent
                touched={props.touched}
                errors={props.errors}
                tag="p"
                text="What is the light type?"
                id="lightTypeId"
                submitCount={props.submitCount}
              />
              <RadiobuttonInputComponent
                name="lightTypeId"
                value={props.values.lightTypeId}
                onChange={(id) => {
                  props.setFieldValue('lightTypeId', id);
                  props.onLightTypeChange(id);
                }}
                error={props.errors.lightTypeId && props.touched.lightTypeId}
                options={getRadioButtonOptions()}
              />
              {props.errors.lightTypeId && props.touched.lightTypeId && (
                <InputErrorComponent>{props.errors.lightTypeId}</InputErrorComponent>
              )}
            </span>
          </div>
        )}

        <ButtonComponent type="submit" disabled={props.isSubmitting} className={ButtonClasses.primary + ' float-right'}>
          Next
        </ButtonComponent>
        <ButtonComponent type="button" onClick={props.goToPrevious}>
          Previous
        </ButtonComponent>
      </Form>
    </div>
  );
};

const LightTypePageComponent = withFormik({
  mapPropsToValues({ lightTypeId, goToNext, goToPrevious }) {
    return {
      lightTypeId: lightTypeId,
      goToNext: goToNext,
      goToPrevious: goToPrevious,
    };
  },
  validationSchema: Yup.object().shape({
    lightTypeId: Yup.string().required("Please supply details for 'What is the light type?"),
  }),
  handleSubmit(bag) {
    bag.goToNext();
  },
})(LightTypePageForm);

LightTypePageComponent.propTypes = {
  onLightTypeChange: PropTypes.func.isRequired,
  lightTypeId: PropTypes.number,
  goToNext: PropTypes.func.isRequired,
  goToPrevious: PropTypes.func.isRequired,
  lightTypes: PropTypes.object.isRequired,
  streetLightTypes: PropTypes.object.isRequired,
};

export default LightTypePageComponent;
export { LightTypePageForm };
