export const incidentFormRoot = '/form';
export const refuseAndRecyclingRoot = '/refuse-and-recycling';
export const binCollectionsRoot = '/bin-collections';

// URL's for the My Account App
export const clientMyAccountUrl = {
  signIn: '/sign-in',
  register: '/register',
  signInSuccessful: '/sign-in-successful',
  dashboard: '/dashboard',
  registration: {
    root: '/register',
    step1: '/step-one',
    step2: '/step-two',
    step3: '/step-three',
    successful: '/successful',
    emailConfirmation: '/confirmation',
  },
  streetLighting: {
    root: incidentFormRoot + '/street-lighting',
    step1: '/step-one',
    step2: '/step-two',
    step3: '/step-three',
    step4: '/step-four',
    confirmation: '/confirmation',
  },
  streetCleaning: {
    root: incidentFormRoot + '/street-cleaning',
    step1: '/step-one',
    step2: '/step-two',
    step3: '/step-three',
    confirmation: '/confirmation',
  },
  missingAssets: {
    root: incidentFormRoot + '/missing-assets',
    streetLighting: '/street-lighting',
    step1: '/step-one',
    step2: '/step-two',
    confirmation: '/confirmation',
  },
  flyTipping: {
    root: incidentFormRoot + '/fly-tipping',
    step1: '/step-one',
    step2: '/step-two',
    step3: '/step-three',
    confirmation: '/confirmation',
  },
  graffiti: {
    root: incidentFormRoot + '/graffiti',
    step1: '/step-one',
    step2: '/step-two',
    step3: '/step-three',
    confirmation: '/confirmation',
    signIn: '/sign-in',
    register: '/register',
  },
  roadAndPavementProblems: {
    root: incidentFormRoot + '/road-and-pavement-problems',
    step1: '/step-one',
    step2: '/step-two',
    step3: '/step-three',
    confirmation: '/confirmation',
  },
  saltBinProblems: {
    root: incidentFormRoot + '/salt-bin-problems',
    step1: '/step-one',
    step2: '/step-two',
    step3: '/step-three',
    confirmation: '/confirmation',
  },
  dogAndLitterBin: {
    root: incidentFormRoot + '/dog-and-litter-bins',
    step1: '/step-one',
    step2: '/step-two',
    step3: '/step-three',
    confirmation: '/confirmation',
  },
  waterAndDrainageProblems: {
    root: incidentFormRoot + '/water-and-drainage-problems',
    step1: '/step-one',
    step2: '/step-two',
    step3: '/step-three',
    step4: '/step-four',
    step5: '/step-five',
    confirmation: '/confirmation',
  },
  streetFurniture: {
    root: incidentFormRoot + '/street-furniture',
    step1: '/step-one',
    step2: '/step-two',
    step3: '/step-three',
    step4: '/step-four',
    confirmation: '/confirmation',
  },
  roadAndStreetSign: {
    root: incidentFormRoot + '/road-and-street-sign',
    step1: '/step-one',
    step2: '/step-two',
    step3: '/step-three',
    step4: '/step-four',
    confirmation: '/confirmation',
  },
  vegetation: {
    root: incidentFormRoot + '/vegetation',
    step1: '/step-one',
    step2: '/step-two',
    step3: '/step-three',
    step4: '/step-four',
    confirmation: '/confirmation',
  },
  account: {
    root: '/account',
    password: {
      change: '/change-password',
      changeSuccessful: '/change-password-successful',
      forgot: '/forgot-password',
      emailSent: '/forgot-password-email-sent',
      reset: '/reset-password',
    },
    email: {
      change: '/change-email',
      confirmation: '/change-email-confirmation',
      successful: '/change-email-successful',
    },
    phone: {
      change: '/change-phone',
    },
    address: {
      change: '/change-address',
      successful: '/change-address-successful',
    },
    name: {
      change: '/change-name',
      confirmation: '/change-name-confirmation',
    },
    contactDetails: '/contact-details',
    communicationPreferences: '/communication-preferences',
  },
  home: '/dashboard',
  webchat: {
    root: '/webchat',
    signIn: '/signin',
    register: '/signin/register',
  },
  myReports: {
    root: '/my-reports',
    details: '/details',
  },
  refuseAndRecycling: {
    myCollections: refuseAndRecyclingRoot + '/my-collection-details',
    myCalendar: refuseAndRecyclingRoot + '/my-collection-calender',
  },
  testing: {
    root: '/testing',
  },
  error: '/error',
  parkingPortalErrorPage: '/parking-portal-error',
  binCollections: {
    root: binCollectionsRoot,
    calendar: '/calendar',
    calendarWithRoute: binCollectionsRoot + '/calendar',
  },
};

// Url for the main york website
export const yorkMainWebsiteUrl = {
  root: 'https://www.york.gov.uk/',
  feedback: 'https://www.york.gov.uk/feedback',
  customerContactCentre: 'https://www.york.gov.uk/form/yorkcustomercentre',
  councilServices: 'https://www.york.gov.uk/CouncilServices',
  siteSearchQuery: 'https://www.york.gov.uk/site-search/results/?q=',
};

export const oracleServiceCloudWebChatUrls = {
  FORM_SUBMIT_URL: '/app/chat/chat_landing',
};

const getIncidents = '/api/incident/getIncidents';

export const apiSettingName = 'apiUrl';

export const apiMyAccountUrl = {
  REGISTER: '/api/Account/Register',
  ACCOUNT: '/api/Account',
  ACCOUNT_LOGIN: '/api/Account/Login',
  ACCOUNT_LOGOUT: '/api/Account/Logout',
  ACCOUNT_FORGOT_PASSWORD: '/api/Account/ForgotPassword',
  ACCOUNT_RESET_PASSWORD: '/api/Account/ResetPassword',
  MANAGE_CHANGE_PASSWORD: '/api/Manage/ChangePassword',
  GET_CONTACT_DETAIL: '/api/Manage/GetDetail',
  GET_PRIMARY_ADDRESS: '/api/Manage/GetPrimaryAddress',
  UPDATE_CONTACT_PREFERENCES: '/api/Manage/UpdateContactPreferences',
  UPDATE_CONTACT_EMAIL_ADDRESS: '/api/Manage/UpdateEmailAddress',
  UPDATE_CONTACT_PROFILE: '/api/Manage/UpdateProfile',
  UPDATE_CONTACT_ADDRESS: '/api/Manage/ChangeAddress',
  UPDATE_CONTACT_PHONE: '/api/Manage/ChangePhone',
  UPDATE_CONTACT_NAME: '/api/Manage/ChangeName',
  CONFIRM_UPDATE_CONTACT_EMAIL_ADDRESS: 'api/Manage/ConfirmUpdateEmailAddress',
  INCIDENTS: '/api/Incident',
  CUSTOMER_INCIDENTS: '/api/Incident/getIncidentsForCurrentCustomer',
  GET_INCIDENT_DETAILS: '/api/Incident/GetIncidentDetails',
  UPDATE_INCIDENT: '/api/Incident/UpdateIncident',
  GET_INCIDENTS_BY_ASSET_ID: '/api/Incident/getLiveIncidentsByAssetId',
  getIncidents: {
    LIGHTING_INCIDENTS: getIncidents + '/Street%20Lighting',
    STREET_CLEANING_INCIDENTS: getIncidents + '/Street%20Cleaning',
    FLY_TIPPING_INCIDENTS: getIncidents + '/Fly-tipping',
    GRAFFITI: getIncidents + '/Graffiti',
    ROAD_AND_PAVEMENT_PROBLEMS: getIncidents + '/Road%20and%20Pavement%20problems',
    SALT_BIN_PROBLEMS: getIncidents + '/Salt%20Bins',
    DOG_AND_LITTER_BINS: getIncidents + '/Dog%2Flitter%20bins',
    WATER_AND_DRAINAGE_PROBLEMS: getIncidents + '/Water%20and%20drainage%20problem',
    STREET_FURNITURE: getIncidents + '/Street%20Furniture%20problem',
    ROAD_AND_STREET_SIGN: getIncidents + '/Sign%20problem',
    VEGETATION: getIncidents + '/Vegetation',
  },
  GET_PARKING_VALIDATION_DATA: '/api/Wsp/GetWspDataForUser',
  GET_PARKING_CUSTOMER_DETAILS: '/api/Wsp/GetCustomerDetails',
};

export const cycConfigurationUrls = {
  configuration: '/Configuration',
  features: '/FeatureFlags',
};
