import React from 'react';
import { ButtonComponent, ButtonClasses } from 'cyc-react-ui-components';

const LoggedInUpdatesComponent = ({ goToMyAccount }) => {
  return (
    <React.Fragment>
      <div className="row">
        <p>
          Go to{' '}
          <button className="in-text-button" onClick={goToMyAccount}>
            My Account
          </button>{' '}
          to see the status/progress of all services and reported problems.
        </p>
      </div>

      <div className="row">
        <ButtonComponent className={ButtonClasses.primary} onClick={goToMyAccount}>
          Go to My Account
        </ButtonComponent>
      </div>
    </React.Fragment>
  );
};

export default LoggedInUpdatesComponent;
