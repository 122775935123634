import React, { useEffect, useState, useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import appInsights from 'cyc-application-insights-react';
import { ButtonComponent, ButtonClasses } from 'cyc-react-ui-components';

import { binCollectionsRoot } from 'common';

import { getBinCalendarForUPRNAsync, groupBy } from '../../services/bin-collections.service';

import RecycleLogo from './recycle-logo.png';
import RefuseIcon from './house-icon.svg';
import RecyclingIcon from './recycling-icon.svg';
import GardenIcon from './garden-icon.svg';

import * as constants from '../../constants/bin-collections.constants';

const RecyclingIconComponent = () => <img src={RecyclingIcon} alt="Recycling collection icon" />;
const RefuseIconComponent = () => <img src={RefuseIcon} alt="Household waste collection icon" />;
const GardenIconComponent = () => <img src={GardenIcon} alt="Garden waste collection icon" />;

const BinCalendarComponent = () => {
  const [collections, setCollections] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showSimpleCalendar, setShowSimpleCalendar] = useState(false);
  const { uprn } = useParams();

  useEffect(() => {
    const binData = async () => {
      setLoading(true);
      const apiData = await getBinCalendarForUPRNAsync(uprn);
      const data = [...groupBy(apiData, (collection) => collection.date.month())] // Group into a Map (dictionary) on month, then convert Map to array
        .map((x) => x[1]) // Only keep the values, discard the key
        .map((x) => x.sort((a, b) => a.date - b.date)) // Sort the collections in each month
        .sort((a, b) => a[0].date - b[0].date); // Sort the months

      setCollections(data);
      setLoading(false);
    };
    binData();
  }, [uprn]);

  const handlePrintClick = () => {
    appInsights.trackEvent({ name: 'Print waste calendar option selected' });
    window.print();
  };

  const RoundComponent = ({ collection }) => {
    let RoundIcon;
    let classes = 'bin-collections-lookup__calendar__months__month__collection ';

    switch (collection.roundType) {
      case 'REFUSE':
        RoundIcon = RefuseIconComponent;
        classes += 'bin-collections-lookup__calendar__months__month__collection--refuse';
        break;
      case 'RECYCLING':
        RoundIcon = RecyclingIconComponent;
        classes += 'bin-collections-lookup__calendar__months__month__collection--recycling';
        break;
      case 'GARDEN':
        RoundIcon = GardenIconComponent;
        classes += 'bin-collections-lookup__calendar__months__month__collection--garden';
        break;
      default:
        RoundIcon = () => <span>{collection.roundType}</span>;
        break;
    }

    return (
      <div className={classes} title={`${constants.collectionTypeTitles[collection.roundType]} collection`}>
        <span className="bin-collections-lookup__calendar__months__month__day">{collection.date.format('ddd DD')}</span>
        <span className="bin-collections-lookup__calendar__months__month__round-type">
          <RoundIcon />
        </span>
      </div>
    );
  };

  const KeyComponent = ({ media }) => (
    <div className={`bin-collections-lookup__calendar__key bin-collections-lookup__calendar__key--${media}`}>
      <div className="bin-collections-lookup__calendar__key__refuse">
        <span>Household waste collection</span>
        <RefuseIconComponent />
      </div>
      <div className="bin-collections-lookup__calendar__key__recycling">
        <span>Recycling collections</span>
        <RecyclingIconComponent />
      </div>
      <div className="bin-collections-lookup__calendar__key__garden">
        <span>Garden waste collections</span>
        <GardenIconComponent />
      </div>
    </div>
  );

  const RoundChangesWarningComponent = ({ media }) => (
    <h2
      className={`bin-collections-lookup__round-changes-warning bin-collections-lookup__round-changes-warning--${media}`}>
      Please note: this schedule is subject to change, owing to process updates, collection requirements, availability
      of resources, or inclement weather.
    </h2>
  );

  return (
    <div className="bin-collections-lookup__calendar">
      {loading && (
        <div className="network-loader cyc-box network-loader--loading">
          <i>Retrieving bin calendar data...</i>
        </div>
      )}
      {loading === false && (
        <>
          <RoundChangesWarningComponent media="screen" />
          <ButtonComponent
            className={`${ButtonClasses.primary} bin-collections-lookup__calendar__print-button float-right`}
            onClick={() => setShowSimpleCalendar(!showSimpleCalendar)}>
            {showSimpleCalendar ? 'Colour version' : 'Text only version'}
          </ButtonComponent>
          <ButtonComponent
            className={`${ButtonClasses.primary} bin-collections-lookup__calendar__print-button float-right`}
            onClick={handlePrintClick}>
            Print
          </ButtonComponent>
          {/* Standard collection calendar view */}
          {showSimpleCalendar === false && (
            <>
              <KeyComponent media="screen" />
              <div className="bin-collections-lookup__calendar__months">
                {/* This is to enable screen reader navigation by heading, invisible in the UI */}
                <h2 style={{ display: 'none' }}>Bin collection calendar</h2>
                {collections &&
                  collections.map((month, monthKey) => (
                    <div className="bin-collections-lookup__calendar__months__month" key={monthKey}>
                      <h3>{month[0].date.format('MMM YYYY')}</h3>
                      {month.map((collection, collectionKey) => (
                        <div key={collectionKey}>
                          <RoundComponent collection={collection} />
                        </div>
                      ))}
                    </div>
                  ))}
              </div>
              <KeyComponent media="print" />
            </>
          )}
        </>
      )}
      <RoundChangesWarningComponent media="print" />
      {/* Simple text only version of calendar view */}
      {loading === false && showSimpleCalendar === true && (
        <>
          {collections &&
            collections.map((month, monthKey) => (
              <div key={month[0].date.toString()}>
                <h3>
                  <strong>{month[0].date.format('MMMM YYYY')}</strong>
                </h3>
                <ul>
                  {month.map((collection, collectionKey) => (
                    <li key={collectionKey}>
                      {collection.date.format('dddd, Do MMMM YYYY')} - <strong>{collection.roundType}</strong>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
        </>
      )}
      {loading === false && (
        <div className="bin-collections-lookup__calendar__help-links">
          <p>See also</p>
          <ul>
            <li>
              Check <Link to={binCollectionsRoot}>waste collection details for a different address</Link>
            </li>
            <li>
              Find out more about our <a href="https://www.york.gov.uk/WasteAndRecycling">Waste Services</a>
            </li>
          </ul>
        </div>
      )}
      <div className="bin-collections-lookup__calendar__footer">
        <div className="bin-collections-lookup_calendar__footer__message">
          <strong>Important information:</strong> Putting your waste out incorrectly can cause problems; check
          collection details for <a href="https://www.york.gov.uk/HouseholdWaste">household waste collections</a>,{' '}
          <a href="https://www.york.gov.uk/RecyclingCollections">recycling collections</a>, and{' '}
          <a href="https://www.york.gov.uk/GardenWaste">garden waste collections</a>, and{' '}
          <strong>always put containers out by 7am.</strong>
        </div>
        <div className="bin-collections-lookup_calendar__footer__icon">
          <img src={RecycleLogo} alt="Recycle for York icon" />
        </div>
      </div>
    </div>
  );
};

export default BinCalendarComponent;
