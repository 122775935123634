import React, { useMemo } from 'react';
import { ButtonComponent } from 'cyc-react-ui-components';

import './flyout.component.scss';

/**
 * A full screen flyout - best used on mobile devices
 *
 * props
 * @param {object} children - Content to show in the flyout
 * @param {boolean} [open=false] - Should the flyout show when it is loaded?
 * @param {function} onClose - The callback for when the dialog is closed
 * @param {object} props - any props you want to add to the flyout
 */
const FlyoutComponent = ({ children, open, onClose, className, ...props }) => {
  const classes = useMemo(() => {
    let c = `myaccount-flyout myaccount-flyout--${open ? 'open' : 'closed'}`;
    if (className) {
      c += ` ${className}`;
    }
    return c.trim();
  }, [className, open]);

  const handleCloseClick = (e) => {
    e.preventDefault();
    onClose && onClose();
  };
  return (
    <div className={classes} {...props}>
      <div className = "myaccount-flyout__controls">
        <ButtonComponent title="Close menu" onClick={handleCloseClick} className="myaccount-flyout__close">
          Close <span className="toggle-icon"></span>
        </ButtonComponent>
      </div>
      <div className="myaccount-flyout__content">{children}</div>
      <div className="myaccount-flyout__canvas"></div>
    </div>
  );
};

export default FlyoutComponent;
