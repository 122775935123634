import { CYCServices } from 'cyc-services';

export const initialState = ({ pageHeaderText, problemOtherId = undefined }) => {
  return {
    pageHeaderText: pageHeaderText,
    formStarted: false, // Turn this to true for developement
    formCompleted: false,
    currentStep: 0,
    userLoggedIn: CYCServices.JWTAuth.isAuthenticated,
    missingAssetChecked: false,
    formData: {
      incidentSubmitted: false,
      additionalInformation: '',
      unitType: null,
      problemId: '',
      problemOther: '',
      offensiveGraffiti: undefined,
      problemOtherId: problemOtherId ? problemOtherId : undefined,
      sizeOfWaste: '',
      binTypeId: undefined,
      streetFurnitureTypeId: undefined,
      roadDefectTypeId: '',
      waterDrainageTypeId: '',
      locationSelected: false,
      locationObject: undefined,
      uploadedFiles: [],
      isAlternative: 'false',
      gullyType: null,
      gullyNumber: null,
      hasItRained: undefined,
      waterDangerToUser: undefined,
      riskOfInternalFlooding: undefined,
      reocurringProblem: undefined,
      signTypeId: undefined,
      vegetationTypeId: undefined,
      additionalProblemInformation: undefined,
      lightTypeId: undefined,
    },
    incident: {
      referenceNumber: '',
      secretReferenceNumber: '',
      incidentSubmitted: false,
      incidentId: '',
    },
  };
};

// Actions for the reducer and forms
const updateStateFields = 'update-state-field';
const updateFormDataFields = 'update-form-data-field';
const updateLocationObject = 'update-location-object';
const resetLocation = 'reset-location';
const updateUploadFiles = 'update-upload-files';
const removeUploadedFile = 'remove-uploaded-file';
const resetForm = 'reset-state';
const updateIncidentObject = 'update-incident-object';

// Export into 1 object for ease of use
// Keep in order of the actions above for discoverability.
export const actions = {
  updateStateFields,
  updateFormDataFields,
  updateLocationObject,
  resetLocation,
  updateUploadFiles,
  removeUploadedFile,
  resetForm,
  updateIncidentObject,
};

export const fieldNames = {
  // state fields
  pageHeaderText: 'pageHeaderText',
  formStarted: 'formStarted',
  formCompleted: 'formCompleted',
  currentStep: 'currentStep',
  userLoggedIn: 'userLoggedIn',
  incident: 'incident',
  missingAssetChecked: 'missingAssetChecked',
  // formData fields
  incidentSubmitted: 'incidentSubmitted',
  additionalInformation: 'additionalInformation',
  unitType: 'unitType',
  problemId: 'problemId',
  problemOther: 'problemOther',
  offensiveGraffiti: 'offensiveGraffiti',
  problemOtherId: 'problemOtherId',
  sizeOfWaste: 'sizeOfWaste',
  binTypeId: 'binTypeId',
  streetFurnitureTypeId: 'streetFurnitureTypeId',
  roadDefectTypeId: 'roadDefectTypeId',
  waterDrainageTypeId: 'waterDrainageTypeId',
  locationSelected: 'locationSelected',
  locationObject: 'locationObject',
  uploadedFiles: 'uploadedFiles',
  isAlternative: 'isAlternative',
  gullyType: 'gullyType',
  gullyNumber: 'gullyNumber',
  hasItRained: 'hasItRained',
  waterDangerToUser: 'waterDangerToUser',
  riskOfInternalFlooding: 'riskOfInternalFlooding',
  reocurringProblem: 'reocurringProblem',
  signTypeId: 'signTypeId',
  vegetationTypeId: 'vegetationTypeId',
  additionalProblemInformation: 'additionalProblemInformation',
  lightTypeId: 'lightTypeId',
};

export const reducer = (state, action) => {
  switch (action.type) {
    case updateStateFields:
      return {
        ...state,
        ...action.payload,
      };
    case updateFormDataFields:
      return {
        ...state,
        formData: {
          ...state.formData,
          ...action.payload,
        },
      };
    case updateIncidentObject:
      return {
        ...state,
        incident: {
          ...state.incident,
          ...action.payload,
        },
      };
    case updateLocationObject:
      return {
        ...state,
        formData: {
          ...state.formData,
          locationObject: {
            easting: action.payload.easting.toString(),
            northing: action.payload.northing.toString(),
            streetName: action.payload.street,
            location: action.payload.location,
            USRN: action.payload.usrn,
            postcode: action.payload.postcode,
            toid: action.payload.toid,
            ward: action.payload.ward,
            parish: action.payload.parish,
            filter: action.payload.filter,
            assetId: action.payload.assetId,
            assetDescription: action.payload.assetDescription,
            roadNumber: action.payload.roadNumber,
          },
          locationSelected: true,
        },
      };
    case resetLocation:
      return {
        ...state,
        formData: {
          ...state.formData,
          locationObject: undefined,
          locationSelected: false,
        },
      };
    case updateUploadFiles:
      let exisitingFiles = [...state.formData.uploadedFiles];
      exisitingFiles.push(...action.payload);
      return {
        ...state,
        formData: {
          ...state.formData,
          uploadedFiles: exisitingFiles,
        },
      };
    case removeUploadedFile:
      let files = state.formData.uploadedFiles;
      files.splice(action.payload, 1);
      return {
        ...state,
        formData: {
          ...state.formData,
          uploadedFiles: files,
        },
      };
    case resetForm:
      return { ...action.payload };
    default:
      return state;
  }
};
