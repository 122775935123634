import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Form, withFormik } from 'formik';
import * as Yup from 'yup';
import {
  ErrorBoxComponent,
  ErrorLabelHandlerComponent,
  FileInputComponent,
  setInitialPosition,
  ADDITIONAL_INFORMATION_MAX_LENGTH,
  msgConstants,
  AdditionalInformationComponent,
} from 'common';

import {
  InputErrorComponent,
  ButtonComponent,
  RadiobuttonInputComponent,
  ButtonClasses,
} from 'cyc-react-ui-components';

const ProblemPage = (props) => {
  let errorBoxRef = React.createRef();
  const previousSubmitCount = React.useRef(0);

  const selectedVegetationType = props.vegetationTypes.find((type) => type.id === props.problemWithId);
  const categories = selectedVegetationType.categories ? Object.values(selectedVegetationType.categories) : null;

  const handleAdditionalInformationChange = (e) => {
    props.setFieldValue('additionalProblemInformation', e.target.value);
    props.onAdditionalInformationChange && props.onAdditionalInformationChange(e);
  };

  useEffect(() => {
    if (
      !props.isSubmitting &&
      !props.isValid &&
      Object.keys(props.errors).length > 0 &&
      props.submitCount > previousSubmitCount.current
    ) {
      previousSubmitCount.current = props.submitCount;
      const errorBox = ReactDOM.findDOMNode(errorBoxRef.current);
      errorBox.scrollIntoView();
    }
  }, [props.isSubmitting, errorBoxRef, props.errors, props.isValid, props.submitCount]);

  useEffect(() => {
    setInitialPosition();
  }, []);

  return (
    <div className="page">
      <Form>
        <div ref={errorBoxRef}></div>
        {Object.keys(props.errors).length > 0 && props.submitCount > 0 && (
          <ErrorBoxComponent errors={props.errors}></ErrorBoxComponent>
        )}

        <h2>About the problem</h2>
        <p>Tell us more about the issue.</p>

        {categories ? (
          <div className="row">
            <span id="whatIsTheProblem">
              <ErrorLabelHandlerComponent
                touched={props.touched}
                errors={props.errors}
                text="What is the problem?"
                tag="p"
                id="problemId"
                submitCount={props.submitCount}
              />
              <RadiobuttonInputComponent
                name="problemId"
                onChange={(e) => {
                  props.setFieldValue('problemId', e);
                  props.onProblemChange(e);
                }}
                value={props.values.problemId}
                error={props.errors.problemId && props.touched.problemId}
                options={categories.map((category) => ({
                  label: category.displayName,
                  value: category.id,
                }))}
              />
              {props.errors.problemId && props.touched.problemId && (
                <InputErrorComponent>{props.errors.problemId}</InputErrorComponent>
              )}
            </span>
          </div>
        ) : null}

        <AdditionalInformationComponent
          showCounter={true}
          maximumCount={ADDITIONAL_INFORMATION_MAX_LENGTH}
          className="w-100"
          name="additionalProblemInformation"
          placeholder="Additional details"
          value={props.additionalProblemInformation}
          onAdditionalInformationChange={handleAdditionalInformationChange}
          headerText="Additional details"
          helpText="Help: Details that will help us prioritise the problem. For example, the cuttings are wet and currently presenting a trip hazard."
          required={false}
        />
        {props.errors.additionalProblemInformation && props.touched.additionalProblemInformation && (
          <InputErrorComponent>{props.errors.additionalProblemInformation}</InputErrorComponent>
        )}

        <h3 className="mt-3" id="uploadPhoto">
          Upload a photo
        </h3>

        <FileInputComponent
          uploadedFiles={props.uploadedFiles}
          onUploadFiles={props.onUploadFiles}
          onRemoveFile={props.onRemoveFile}
          helpText={props.uploadPhotoHelpText}
        />

        <ButtonComponent type="submit" disabled={props.isSubmitting} className={ButtonClasses.primary + ' float-right'}>
          Next
        </ButtonComponent>
        <ButtonComponent type="button" onClick={props.goToPreviousPage}>
          Previous
        </ButtonComponent>
      </Form>
    </div>
  );
};

const ProblemPageComponent = withFormik({
  mapPropsToValues({ problemId, problemWithId, uploadedFiles, goToNextPage, additionalProblemInformation }) {
    return {
      problemId: problemId || '',
      problemWithId: problemWithId,
      goToNextPage: goToNextPage,
      uploadedFiles: uploadedFiles || [],
      additionalProblemInformation: additionalProblemInformation,
    };
  },
  validationSchema: (props) => {
    const validationObject = {
      goToNextPage: Yup.string().required(),
      uploadedFiles: Yup.array(),
      additionalProblemInformation: Yup.string().max(
        ADDITIONAL_INFORMATION_MAX_LENGTH,
        msgConstants.CHARACTER_LIMIT_EXCEEDED_ERROR
      ),
    };

    const selectedVegetationType = props.vegetationTypes.find((type) => type.id === props.problemWithId);
    const categories = selectedVegetationType.categories ? Object.values(selectedVegetationType.categories) : null;

    if (categories) {
      validationObject.problemId = Yup.string().required("Please supply details for 'What is the problem?'.");
    }

    return Yup.object().shape(validationObject);
  },
  handleSubmit(bag) {
    bag.goToNextPage();
  },
})(ProblemPage);

ProblemPageComponent.propTypes = {
  goToNextPage: PropTypes.func.isRequired,
  goToPreviousPage: PropTypes.func.isRequired,
  onProblemChange: PropTypes.func.isRequired,
  vegetationTypes: PropTypes.array.isRequired,
  onUploadFiles: PropTypes.func.isRequired,
  onRemoveFile: PropTypes.func.isRequired,
  uploadedFiles: PropTypes.array.isRequired,
  problemWithId: PropTypes.string,
  problemId: PropTypes.string,
  uploadPhotoHelpText: PropTypes.object.isRequired,
};

export default ProblemPageComponent;
export { ProblemPage };
