import axios from 'axios';
import { ConfigurationService } from 'cyc-configuration-service';

import appInsights from 'cyc-application-insights-react';
import { apiMyAccountUrl } from '../../common';

export const postRegistrationDataAsync = (registrationForm) => {
  let address = `${ConfigurationService.store.apiUrl}${apiMyAccountUrl.REGISTER}`;

  return axios.post(address, registrationForm).catch((ex) => {
    console.error(ex);
    appInsights.trackException({
      exception: new Error('Failed to post a registration form'),
      properties: {
        passwordsMatch: registrationForm.password === registrationForm.confirmPassword,
        email: registrationForm.email,
      },
    });
    throw ex;
  });
};

export const postEmailConfirmation = (userId, code) => {
  let addressQuery = `${ConfigurationService.store.apiUrl}${apiMyAccountUrl.ACCOUNT}?userId=${userId}&code=${code}`;

  return axios.get(addressQuery).catch((ex) => {
    console.error(ex);
    appInsights.trackException({ exception: ex });
    throw ex;
  });
};

export const registrationObject = (values) => {
  return {
    firstName: values.firstName,
    lastName: values.lastName,
    email: values.email,
    password: values.password,
    confirmPassword: values.confirmPassword,
    telephoneNumber: values.telephoneNumber,
    postcode: values.postcode,
    address: values.address,
    uprn: values.uprn,
    shareContactDetails: values.shareContactDetails,
  };
};
