import React from 'react';
import AccordionInternalComponent from './accordion-internal/accordion-internal.component';

const MyBenefitsAccordion = () => {
  return (
    <AccordionInternalComponent
      callToActionLink={{
        linkText: 'Claim housing benefit',
        linkUrl: 'https://www.york.gov.uk/claimhousingbenefit',
        isExternal: true,
      }}
      linksToRender={[
        {
          linkText: 'Council Tax Support',
          linkUrl: 'https://www.york.gov.uk/CouncilTaxSupport',
          isExternal: true,
        },
        {
          linkText: 'Discretionary Housing Payment',
          linkUrl: 'https://www.york.gov.uk/DHP',
          isExternal: true,
        },
        {
          linkText: 'Report a change in circumstances',
          linkUrl: 'https://www.york.gov.uk/BenefitsChangeOfCircumstances',
          isExternal: true,
        },
        {
          linkText: 'Other benefits information',
          linkUrl: 'https://www.york.gov.uk/benefits',
          isExternal: true,
        },
      ]}
    />
  );
};

export default MyBenefitsAccordion;
