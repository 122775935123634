import { ConfigurationService } from 'cyc-configuration-service';
import { CYCServices } from 'cyc-services';

import { clientMyAccountUrl, apiMyAccountUrl, lightingTypesSettingName, mapConstants } from '../../common';

export const testName = 'Street lighting';
export default class FormConfiguration {
  totalSteps = 5;
  featureLayerUrl = 'https://maps.york.gov.uk/arcgis/rest/services/Public/LV_TranStreetCare/MapServer/3';
  featureLayerId = 'streetLighting';
  userIsLoggedIn = CYCServices.JWTAuth.isAuthenticated;
  pageHeaderText = 'Report a street lighting problem';
  productId;
  missingAssetProductId;
  documentTitle = 'Report a street lighting problem';
  subject = 'Street lighting';
  infoAlertSubjectName = 'street light';

  // Allow the user to select an asset that already has an incident
  allowIncidentSelect = true;

  streetLightTypes = [];
  missingAssetsLightTypes;

  constructor() {
    const streetLightingData = ConfigurationService.getEnvironmentSettingByName('streetLighting');
    this.streetLightTypes = streetLightingData[lightingTypesSettingName];
    this.missingAssetsLightTypes = streetLightingData.missingAssetsLightTypes;
    this.productId = streetLightingData.productId;
    this.missingAssetProductId = streetLightingData.missingAssetProductId;
  }

  getIncidentsUrl = `${ConfigurationService.store.apiUrl}${apiMyAccountUrl.getIncidents.LIGHTING_INCIDENTS}`;

  shouldDisplayBasedOnStep = (number) => number > 0 && number <= this.totalSteps;

  urlHelper = {
    root: clientMyAccountUrl.streetLighting.root,
    steps: clientMyAccountUrl.streetLighting,
    formSwitchUrl:
      clientMyAccountUrl.missingAssets.root +
      clientMyAccountUrl.missingAssets.streetLighting +
      clientMyAccountUrl.missingAssets.step1,
  };

  stepUrls = [
    `${this.urlHelper.root}`,
    `${this.urlHelper.root + this.urlHelper.steps.step1}`,
    `${this.urlHelper.root + this.urlHelper.steps.step2}`,
    `${this.urlHelper.root + this.urlHelper.steps.step3}`,
    `${this.urlHelper.root + this.urlHelper.steps.step4}`,
    `${this.urlHelper.root + this.urlHelper.steps.confirmation}`,
  ];

  missingAssetCheckBoxLabel = "I can't find the street light on the map";
  enablePointSelectionZoomLevels = mapConstants.enablePointSelectionZoomLevels;
}
