import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { withFormik, Form } from 'formik';
import * as Yup from 'yup';
import {
  TextInputComponent,
  RadiobuttonInputComponent,
  ButtonComponent,
  ButtonClasses,
  InputErrorComponent,
} from 'cyc-react-ui-components';

import usePrevious from 'common/hooks/use-previous.hook';

import { ErrorBoxComponent, FileInputComponent, ErrorLabelHandlerComponent, setInitialPosition } from '../../../common';

const ProblemPage = ({
  isSubmitting,
  isValid,
  errors,
  values,
  touched,
  submitCount,
  setFieldValue,
  onProblemTypeChange,
  possibleProblems,
  uploadedFiles,
  onUploadFiles,
  onRemoveFile,
  goToPrevious,
  sizeOfWasteArray,
  onSizeOfWasteChange,
  problemOtherId,
  onProblemOtherChange,
}) => {
  const previousIsSubmitting = usePrevious(isSubmitting);
  const errorBox = useRef();

  useEffect(() => {
    setInitialPosition();
  }, []);

  useEffect(() => {
    if (previousIsSubmitting !== undefined && isSubmitting === true && isValid === false) {
      ReactDOM.findDOMNode(errorBox.current).scrollIntoView();
    }
  }, [isSubmitting]);

  return (
    <div className="page">
      <Form>
        <div ref={errorBox}></div>
        {Object.keys(errors).length > 0 && touched.problemId && touched.sizeOfWaste && (
          <ErrorBoxComponent errors={errors}></ErrorBoxComponent>
        )}

        <h2>About the problem</h2>
        <p>Tell us what needs cleaning up.</p>
        <div className="row">
          <span id="typeOfWaste">
            <ErrorLabelHandlerComponent
              touched={touched}
              errors={errors}
              text="Type of waste"
              id="problemId"
              submitCount={submitCount}
            />
            <RadiobuttonInputComponent
              name="problemId"
              value={values.problemId}
              onChange={(e) => {
                setFieldValue('problemId', e);
                if (e !== problemOtherId) {
                  setFieldValue('problemOther', '');
                }
                onProblemTypeChange(e);
              }}
              options={possibleProblems.map((problem) => ({
                value: problem.id,
                label: problem.displayName,
              }))}
              error={errors.problemId && touched.problemId}
            />
            {errors.problemId && touched.problemId && <InputErrorComponent>{errors.problemId}</InputErrorComponent>}
            {values.problemId === values.problemOtherId ? (
              <div>
                <ErrorLabelHandlerComponent
                  touched={touched}
                  errors={errors}
                  text="Please specify"
                  id="problemOther"
                  submitCount={submitCount}
                />
                <TextInputComponent
                  name="problemOther"
                  id="problemOther"
                  value={values.problemOther}
                  onChange={(e) => {
                    setFieldValue('problemOther', e.target.value);
                    onProblemOtherChange(e.target.value);
                  }}
                  error={errors.problemOther && touched.problemOther}
                />
                {errors.problemOther && touched.problemOther && (
                  <InputErrorComponent>{errors.problemOther}</InputErrorComponent>
                )}
              </div>
            ) : (
              <React.Fragment />
            )}
          </span>
        </div>

        <h2>Amount of waste</h2>
        <p>Tell us how much waste has been dumped so we can remove it in a single visit.</p>
        <div className="row">
          <span id="amountOfWaste">
            <ErrorLabelHandlerComponent
              touched={touched}
              errors={errors}
              text="Approximate size of waste"
              id="sizeOfWaste"
              submitCount={submitCount}
            />
            <RadiobuttonInputComponent
              name="sizeOfWaste"
              value={values.sizeOfWaste}
              onChange={(e) => {
                setFieldValue('sizeOfWaste', e);
                onSizeOfWasteChange && onSizeOfWasteChange(e);
              }}
              error={errors.sizeOfWaste && touched.sizeOfWaste}
              options={sizeOfWasteArray.map((size) => ({
                label: size.name,
                value: size.id,
              }))}
            />
            {errors.sizeOfWaste && touched.sizeOfWaste && (
              <InputErrorComponent>{errors.sizeOfWaste}</InputErrorComponent>
            )}
          </span>
        </div>

        <h2 className="mt-3" id="uploadPhoto">
          Upload a photo
        </h2>

        <FileInputComponent uploadedFiles={uploadedFiles} onUploadFiles={onUploadFiles} onRemoveFile={onRemoveFile} />

        <ButtonComponent type="submit" disabled={isSubmitting} className={ButtonClasses.primary + ' float-right'}>
          Next
        </ButtonComponent>
        <ButtonComponent type="button" onClick={goToPrevious}>
          Previous
        </ButtonComponent>
      </Form>
    </div>
  );
};

const ProblemPageComponent = withFormik({
  mapPropsToValues({ problemId, problemOther, problemOtherId, goToNext, sizeOfWaste }) {
    return {
      problemId: problemId || '',
      problemOther: problemOther || '',
      problemOtherId: problemOtherId || '-1',
      goToNext: goToNext,
      sizeOfWaste: sizeOfWaste || '',
    };
  },
  validationSchema: Yup.object().shape({
    problemId: Yup.string().required("Please supply details for 'Type of waste'."),
    sizeOfWaste: Yup.string().required("Please supply details for 'Approximate size of waste'."),
    problemOther: Yup.string().when(['problemId', 'problemOtherId'], {
      is: (problemId, problemOtherId) => problemId === problemOtherId,
      then: Yup.string().required("Please supply details for 'Other'."),
    }),
  }),
  handleSubmit(bag) {
    bag.goToNext();
  },
})(ProblemPage);

ProblemPageComponent.propTypes = {
  problemId: PropTypes.string,
  problemOtherId: PropTypes.string,
  sizeOfWaste: PropTypes.string,
  sizeOfWasteArray: PropTypes.array,
  onUploadFiles: PropTypes.func.isRequired,
  onRemoveFile: PropTypes.func.isRequired,
  onProblemTypeChange: PropTypes.func.isRequired,
  onProblemOtherChange: PropTypes.func.isRequired,
  onSizeOfWasteChange: PropTypes.func.isRequired,
  uploadedFiles: PropTypes.array,
  possibleProblems: PropTypes.array.isRequired,
};

export default ProblemPageComponent;
export { ProblemPage };
