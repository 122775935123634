import { CYCServices } from 'cyc-services';
import { ConfigurationService } from 'cyc-configuration-service';

import { clientMyAccountUrl, mapConstants, categoryIdsSettingName, processCategoryIdsSettingName } from '../../common';

export class FormConfiguration {
  userIsLoggedIn = CYCServices.JWTAuth.isAuthenticated;
  baseUrl = clientMyAccountUrl.saltBinProblems;
  stepUrls = [
    `${this.baseUrl.root}`,
    `${this.baseUrl.root + this.baseUrl.step1}`,
    `${this.baseUrl.root + this.baseUrl.step2}`,
    `${this.baseUrl.root + this.baseUrl.step3}`,
    `${this.baseUrl.root + this.baseUrl.confirmation}`,
  ];
  testHeader = 'Salt bin problems';
  pageHeaderText = 'Report a salt bin problem';
  documentTitle = 'My Account - Report a salt bin problem';
  totalSteps = 4;
  productId = 932;
  subject = 'Salt bin problems';
  enablePointSelectionZoomLevels = mapConstants.enablePointSelectionZoomLevels;
  mapIncidentLayerId = 'saltBinIncidents';
  mapIncidentLayerTitle = 'Problem description';

  getSaltBinsCategories = () => {
    return Object.values(
      ConfigurationService.getDrillDownSettingValue([categoryIdsSettingName, processCategoryIdsSettingName.saltBins])
    );
  };

  shouldDisplayBasedOnStep = (number) => number > 0 && number <= this.totalSteps;
}
