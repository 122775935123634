import React, { Component } from 'react';
import PropTypes from 'prop-types';
import HeaderComponent from 'common/components/header/header.component';
import { ErrorPageComponent, FooterComponent } from 'common';

import appInsights from 'cyc-application-insights-react';

// This cannot be converted into a functional component unless React implements componentDidCatch as a hook!
class ErrorBoundaryComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  componentDidCatch = (ex, info) => {
    ex.stack = info;
    if (appInsights.initialized) {
      appInsights.trackException({ exception: new Error('Uncaught exception'), properties: { ex } });
    }
  };

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
          <header id="pageTop">
            <HeaderComponent onLogOut={() => {}} />
          </header>
          <main className="mainBody">
            <ErrorPageComponent {...this.props} />
          </main>
          <footer>
            <FooterComponent />
          </footer>
        </>
      );
    }

    return this.props.children;
  }
}

ErrorBoundaryComponent.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
  history: PropTypes.object,
};

export default ErrorBoundaryComponent;
