import { ConfigurationService } from 'cyc-configuration-service';
import { CYCServices } from 'cyc-services';
import { mapConstants } from '../../common/constants/map.constants';

import { clientMyAccountUrl, processCategoryIdsSettingName, categoryIdsSettingName } from '../../common';

export class FormConfiguration {
  userIsLoggedIn = CYCServices.JWTAuth.isAuthenticated;

  baseUrl = clientMyAccountUrl.graffiti;
  pageHeaderText = 'Report a graffiti problem';
  documentTitle = 'My Account - Report a graffiti problem';
  totalSteps = 4;
  productId = 76;
  subject = 'Graffiti';
  enablePointSelectionZoomLevels = mapConstants.enablePointSelectionZoomLevels;
  // Map constants
  mapIncidentLayerId = 'graffitiIncidents';
  mapIncidentLayerTitle = 'Problem description';

  // Urls
  stepUrls = [
    `${this.baseUrl.root}`,
    `${this.baseUrl.root + this.baseUrl.step1}`,
    `${this.baseUrl.root + this.baseUrl.step2}`,
    `${this.baseUrl.root + this.baseUrl.step3}`,
    `${this.baseUrl.root + this.baseUrl.confirmation}`,
  ];

  getGraffitiCategories = () => {
    return Object.values(
      ConfigurationService.getDrillDownSettingValue([categoryIdsSettingName, processCategoryIdsSettingName.graffiti])
    );
  };

  getProblemIdByName = (problemName) => {
    return ConfigurationService.getDrillDownSettingValue([
      categoryIdsSettingName,
      processCategoryIdsSettingName.graffiti,
      problemName,
    ]).id;
  };

  shouldDisplayBasedOnStep = (number) => {
    if (number > 0 && number <= this.totalSteps) return true;
    return false;
  };
}
