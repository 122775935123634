import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { FeatureFlagsService } from 'cyc-configuration-service';

import { ChangePasswordContainer, ChangePasswordSuccessComponent } from './index';
import { clientMyAccountUrl as urls, NotFoundComponent, isEnabled } from '../common';
import { ChangeName } from '../common/constants/feature-flag.constants';

import AccountDetailsContainer from './account-details/containers/account-details.container';
import CommunicationPreferenceContainer from './communication-preferences/containers/comm-preferences.container';
import ChangeEmailContainer from './change-email/containers/change-email.container';
import ChangeEmailConfirmationComponent from './change-email/components/confirmation-page/change-email-confirmation.component';
import ChangeEmailSuccessComponent from './change-email/components/email-change-successful/change-email-success.component';
import ChangePhonenumberContainer from './change-phone/containers/change-phone-number.container';
import ChangeAddresssContainer from './change-address/containers/change-address.container';
import ChangeAddressSuccessComponent from './change-address/components/change-address-success.component';
import { ForgotPasswordContainer, ResetPasswordContainer, ForgotPasswordEmailSentComponent } from './forgot-password';
import { ChangeNameConfirmationComponent, ChangeNameContainer } from './change-name';

const AccountRouterComponent = () => (
  <Routes>
    <Route path={urls.account.password.change} element={<ChangePasswordContainer />} />
    <Route path={urls.account.password.changeSuccessful} element={<ChangePasswordSuccessComponent />} />
    <Route path={urls.account.contactDetails} element={<AccountDetailsContainer />} />
    <Route path={urls.account.communicationPreferences} element={<CommunicationPreferenceContainer />} />
    <Route path={urls.account.email.change} element={<ChangeEmailContainer />} />
    <Route path={urls.account.email.confirmation} element={<ChangeEmailConfirmationComponent />} />
    <Route path={urls.account.email.successful} element={<ChangeEmailSuccessComponent />} />
    <Route path={urls.account.phone.change} element={<ChangePhonenumberContainer />} />
    <Route path={urls.account.address.change} element={<ChangeAddresssContainer />} />
    <Route path={urls.account.address.successful} element={<ChangeAddressSuccessComponent />} />
    {isEnabled(FeatureFlagsService.getFeatureFlagByName(ChangeName)) ? (
      <Route path={urls.account.name.change} element={<ChangeNameContainer />} />
    ) : undefined}
    {isEnabled(FeatureFlagsService.getFeatureFlagByName(ChangeName)) ? (
      <Route path={urls.account.name.confirmation} element={<ChangeNameConfirmationComponent />} />
    ) : undefined}
    <Route path={urls.account.password.forgot} element={<ForgotPasswordContainer />} />
    <Route path={urls.account.password.reset} element={<ResetPasswordContainer />} />
    <Route path={urls.account.password.emailSent} element={<ForgotPasswordEmailSentComponent />} />
    <Route path={urls.account.root} element={<NotFoundComponent />} />
  </Routes>
);

export default AccountRouterComponent;
