import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Form, withFormik } from 'formik';
import * as Yup from 'yup';
import { ErrorBoxComponent, FileInputComponent, ErrorLabelHandlerComponent, setInitialPosition } from '../../../common';
import {
  RadiobuttonInputComponent,
  InputErrorComponent,
  ButtonComponent,
  ButtonClasses,
} from 'cyc-react-ui-components';

const ProblemPageForm = (props) => {
  const errorBoxRef = useRef();
  const errors = useRef(props.errors);
  const isValid = useRef(props.isValid);

  useEffect(() => {
    errors.current = props.errors;
    isValid.current = props.isValid;
  }, [props.errors, props.isValid]);

  useEffect(() => {
    if (!props.isSubmitting && !isValid.current && Object.keys(errors.current).length > 0) {
      const errorBox = ReactDOM.findDOMNode(errorBoxRef.current);
      errorBox.scrollIntoView();
    }
  }, [props.isSubmitting]);

  useEffect(() => {
    setInitialPosition();
  }, []);

  return (
    <div className="page">
      <Form>
        <div ref={errorBoxRef}></div>
        {Object.keys(props.errors).length > 0 && props.touched.problemId && (
          <ErrorBoxComponent errors={props.errors}></ErrorBoxComponent>
        )}

        <h2>About the problem</h2>
        <p>Tell us more about the issue.</p>
        <div className="row">
          <span id="whatIsTheProblem">
            <ErrorLabelHandlerComponent
              touched={props.touched}
              errors={props.errors}
              text="What is the problem?"
              tag="p"
              id="problemId"
              submitCount={props.submitCount}
            />
            <RadiobuttonInputComponent
              name="problemId"
              value={props.values.problemId}
              onChange={(e) => {
                props.setFieldValue('problemId', e);
                props.onProblemTypeChange(e);
              }}
              error={props.errors.problemId && props.touched.problemId}
              options={props.possibleProblems.map((problem) => ({
                label: problem.displayName,
                value: problem.id,
              }))}
            />
            {props.errors.problemId && props.touched.problemId && (
              <InputErrorComponent>{props.errors.problemId}</InputErrorComponent>
            )}
          </span>
        </div>

        <p>
          Find out how to make{' '}
          <a href="https://www.york.gov.uk/SaltBins#othersaltbinenquiries">other salt bin enquiries</a> such as
          requesting a new salt bin, moving an existing salt bin, or telling us a salt bin is missing.
        </p>

        <h3 className="mt-3" id="uploadPhoto">
          Upload a photo
        </h3>

        <FileInputComponent
          uploadedFiles={props.uploadedFiles}
          onUploadFiles={props.onUploadFiles}
          onRemoveFile={props.onRemoveFile}
        />

        <ButtonComponent type="submit" disabled={props.isSubmitting} className={ButtonClasses.primary + ' float-right'}>
          Next
        </ButtonComponent>
        <ButtonComponent type="button" onClick={props.goToPrevious}>
          Previous
        </ButtonComponent>
      </Form>
    </div>
  );
};

const ProblemPageComponent = withFormik({
  mapPropsToValues({ goToNext, problemId }) {
    return {
      problemId: problemId,
      goToNext: goToNext,
    };
  },
  validationSchema: Yup.object().shape({
    problemId: Yup.string().required("Please supply details for 'What is the problem?'."),
  }),
  handleSubmit(bag) {
    bag.goToNext();
  },
})(ProblemPageForm);

ProblemPageComponent.propTypes = {
  problemId: PropTypes.string,
  onProblemTypeChange: PropTypes.func.isRequired,
  onUploadFiles: PropTypes.func.isRequired,
  onRemoveFile: PropTypes.func.isRequired,
  uploadedFiles: PropTypes.array,
  goToNext: PropTypes.func.isRequired,
  goToPrevious: PropTypes.func.isRequired,
  possibleProblems: PropTypes.array.isRequired,
};

export default ProblemPageComponent;
export { ProblemPageForm };
