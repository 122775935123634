import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import {
  clientMyAccountUrl,
  NetworkCatchErrorComponent,
  ContinueFormOrReRoute,
  ProgressBarComponent,
  isEnabled,
} from 'common';
import IncidentFormContainer from '../common/containers/incident-forms/incident-form.container';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { RegistrationStepOneComponent } from './components/register-form-step-one/reg-form-step-one.component';
import { RegistrationStepTwoComponent } from './components/register-form-step-two/reg-form-step-two.component';
import { RegistrationStepThreeComponent } from './components/register-form-step-three/reg-form-step-three.component';
import { RegisterSuccessfulComponent } from './components/register-successful/register-successful.component';
import { RegisterSuccessfulWithWarningComponent } from './components/register-successful/register-successful-with-warning.component';
import { RegisterEmailConfirmComponent } from './components/register-email-confirm/register-email-confirm.component';
import { postRegistrationDataAsync } from './services/registration.service';
import * as formConfig from './form-config';
import { FeatureFlagsService } from 'cyc-configuration-service';
import MaintenanceApp from 'maintenance/maintenance.app';
import { RegistrationMaintenance } from 'common/constants/feature-flag.constants';

const getRegisterUrlPath = (pathname) => {
  let finalString = pathname;
  const splitString = pathname.split('/');
  if (splitString[splitString.length - 1] !== 'register') {
    const findRegisterIndex = splitString.indexOf('register');
    finalString = splitString.slice(0, findRegisterIndex + 1).join('/');
  }

  return finalString;
};

const RegistrationApp = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0); // Starts at 0 so email confirmation cant use it
  const [formState, setFormState] = useState({ started: false, finished: false });
  const [shareContactDetails, setShareContactDetails] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [submitRegistrationError, setSubmitRegistrationError] = useState(false);
  const [isExistingEmail, setIsExistingEmail] = useState(false);

  // Location pointer
  const currentBasePath = getRegisterUrlPath(location.pathname);

  useEffect(() => {
    if (location.pathname === clientMyAccountUrl.registration.step1) {
      setCurrentStep(1);
    }
  }, [location.pathname]);

  const handleStepOneSubmit = (values) => {
    setShareContactDetails(values);
    setFormState({ started: true, finished: false });
    navigate(currentBasePath + clientMyAccountUrl.registration.step2);
    setCurrentStep(2);
  };

  const handleStepTwoSubmit = (values) => {
    setFirstName(values.firstName);
    setLastName(values.lastName);
    setEmailAddress(values.emailAddress);
    setPassword(values.password);
    setConfirmPassword(values.confirmPassword);
    setCurrentStep(3);
    navigate(currentBasePath + clientMyAccountUrl.registration.step3);
  };

  const handleStepThreeSubmit = async (values) => {
    const formSubmitData = {
      shareContactDetails: shareContactDetails,
      firstName: firstName,
      lastName: lastName,
      email: emailAddress,
      password: password,
      confirmPassword: confirmPassword,
      postcode: values.postcode, // Comes from form
      address: values.address, // Comes from form
      addressSource: values.addressSource,
      uprn: values.uprn, // Comes from form
      outofYork: values.outofYork, // Comes from form
      telephoneNumber: values.telephone, // Comes from form
    };
    try {
      const response = await postRegistrationDataAsync(formSubmitData);
      setCurrentStep(4);
      navigate(currentBasePath + clientMyAccountUrl.registration.successful);
      setFormState({ started: true, finished: true });

      props.onSuccessfulRegistration &&
        props.onSuccessfulRegistration({ email: emailAddress, isEmailConfirmed: response.status === 202 });
      setIsExistingEmail(response.status === 202);
    } catch (err) {
      setSubmitRegistrationError(true);
      return false;
    }
  };

  const formRouteInfo = () => {
    return {
      formStarted: formState.started,
      formCompleted: formState.finished,
      formStartUrl: currentBasePath + clientMyAccountUrl.registration.step1,
      formEndUrl: currentBasePath + clientMyAccountUrl.registration.successful,
      location: location,
    };
  };

  const appContent = (
    <Routes>
      <Route path="/" element={<Navigate to={location.pathname + clientMyAccountUrl.registration.step1} />} />
      <Route
        path={clientMyAccountUrl.registration.step1}
        element={
          <React.Fragment>
            <TermsAndConditionsComponent />
            <RegistrationStepOneComponent
              shareContactDetails={shareContactDetails}
              onSubmitSuccess={handleStepOneSubmit}
            />
          </React.Fragment>
        }
      />

      <Route
        path={clientMyAccountUrl.registration.step2}
        element={
          <ContinueFormOrReRoute {...formRouteInfo()}>
            <RegistrationStepTwoComponent
              firstName={firstName}
              lastName={lastName}
              emailAddress={emailAddress}
              password={password}
              confirmPassword={confirmPassword}
              onSubmitSuccess={handleStepTwoSubmit}
              goToPrevious={() => {
                setCurrentStep(1);
                navigate(-1);
              }}
            />
          </ContinueFormOrReRoute>
        }
      />

      <Route
        path={clientMyAccountUrl.registration.step3}
        element={
          <ContinueFormOrReRoute {...formRouteInfo()}>
            <NetworkCatchErrorComponent didCatchError={submitRegistrationError} />
            <RegistrationStepThreeComponent
              onSubmitSuccess={handleStepThreeSubmit}
              goToPrevious={() => {
                setCurrentStep(2);
                navigate(-1);
              }}
            />
          </ContinueFormOrReRoute>
        }
      />
      <Route
        path={clientMyAccountUrl.registration.successful}
        element={
          <ContinueFormOrReRoute {...formRouteInfo()}>
            {isExistingEmail === true ? (
              <RegisterSuccessfulWithWarningComponent />
            ) : (
              <RegisterSuccessfulComponent
                onContinueClicked={props.onContinueClicked ? props.onContinueClicked : undefined}
                extraSuccessfulContent={props.extraSuccessfulContent ? props.extraSuccessfulContent : undefined}
              />
            )}
          </ContinueFormOrReRoute>
        }
      />
      <Route path={clientMyAccountUrl.registration.emailConfirmation} element={<RegisterEmailConfirmComponent />} />
      <Route
        path="/"
        element={<Navigate to={clientMyAccountUrl.registration.root + clientMyAccountUrl.registration.step1} />}
      />
    </Routes>
  );

  // we need to check here whether we're at the root of the website and add IncidentFormContainer if yes
  return isEnabled(FeatureFlagsService.getFeatureFlagByName(RegistrationMaintenance)) ? (
    <MaintenanceApp />
  ) : (
    <>
      {formConfig.stepUrls.includes(location.pathname.toLowerCase()) ? (
        <IncidentFormContainer
          displayProgressBar={() => currentStep > 0}
          pageHeaderText={formConfig.pageHeaderText}
          documentTitle="Registration"
          totalSteps={4}
          currentStep={currentStep}>
          {appContent}
        </IncidentFormContainer>
      ) : (
        <>
          <ProgressBarComponent totalSteps={4} currentStep={currentStep} />
          {appContent}
        </>
      )}
    </>
  );
};

export default RegistrationApp;
export { getRegisterUrlPath as getRegisterUrl };
