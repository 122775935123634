import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ConfigurationService } from 'cyc-configuration-service';
import {
  RadiobuttonInputComponent,
  ButtonClasses,
  InputLabelComponent,
  SubmitButtonAxiosComponent,
} from 'cyc-react-ui-components';

import {
  NetworkLoaderComponent,
  DocumentTitleComponent,
  PageHeaderComponent,
  MyAccountPageComponent,
  clientMyAccountUrl,
  apiMyAccountUrl,
} from '../../../common';
import { getContactDetailAsync, updateContactPreferencesAsync } from '../../services/account.service';
import NetworkCatchErrorComponent from '../../../common/components/error-handlers/network-catch-error.component';
import WithAuthentication from 'common/components/with-authentication/with-authentication.component';

const CommunicationPreferenceContainer = () => {
  const navigate = useNavigate();
  const [componentLoadPromise, setComponentLoadPromise] = useState(undefined);
  const [shareContactDetails, setShareContactDetails] = useState(null);
  const [submitError, setSubmitError] = useState(false);

  useEffect(() => {
    const componentLoadPromise = getContactDetailAsync(false);
    setComponentLoadPromise(componentLoadPromise);

    componentLoadPromise
      .then((results) => setShareContactDetails(results.data.shareContactDetails))
      .catch(() => setSubmitError(true));
  }, []);

  // Whenever the user changes preference
  const updateShareContactDetailsHandler = () => {
    setShareContactDetails(!shareContactDetails);
    setSubmitError(false);
  };

  // Posts to API
  const submitShareContactDetailsHandler = () => {
    setSubmitError(false);

    updateContactPreferencesAsync(shareContactDetails)
      .then((response) => {
        if (response.status === 204) {
          navigate(clientMyAccountUrl.dashboard);
        } else {
          setSubmitError(true);
        }
      })
      .catch(() => {
        setSubmitError(true);
      });
  };

  return (
    <WithAuthentication>
      <PageHeaderComponent text="Communication preferences" icon="icon-website" />
      <MyAccountPageComponent>
        <DocumentTitleComponent title="My account - contact details">
          {componentLoadPromise !== undefined ? (
            <NetworkLoaderComponent request={componentLoadPromise}>
              <div>
                <NetworkCatchErrorComponent didCatchError={submitError} />
                <p>If you agree, we'll use your contact details to send you relevant information about our services.</p>
                <InputLabelComponent>
                  Would you like to receive relevant information from City of York Council? (Required)
                </InputLabelComponent>

                <div id="shareContactDetails">
                  <RadiobuttonInputComponent
                    name="shareContactDetails"
                    value={shareContactDetails}
                    onChange={updateShareContactDetailsHandler}
                    options={[
                      { value: true, label: 'Yes, I agree to my contact details being used for this purpose' },
                      { value: false, label: 'No, I do not agree to my contact details being used for this purpose' },
                    ]}
                  />
                </div>

                <div>
                  <SubmitButtonAxiosComponent
                    className={ButtonClasses.primary + ' float-right'}
                    onClick={submitShareContactDetailsHandler}
                    url={`${ConfigurationService.store.apiUrl}${apiMyAccountUrl.UPDATE_CONTACT_PREFERENCES}`}
                  />
                  <Link className="link-inline-with-button" to={clientMyAccountUrl.dashboard}>
                    Back to My Account
                  </Link>
                </div>
              </div>
            </NetworkLoaderComponent>
          ) : (
            <React.Fragment />
          )}
        </DocumentTitleComponent>
      </MyAccountPageComponent>
    </WithAuthentication>
  );
};

export default CommunicationPreferenceContainer;
