import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DataArrayTableComponent, TableClasses } from 'cyc-react-ui-components';

import './report-details.component.scss';

const ReportDetailsComponent = ({ details, files, hideUploadPhoto }) => {
  const data = useMemo(() => {
    const renderData = details.map((item) => ({
      col1: item.displayName,
      col2: item.displayName === 'Date created' ? moment(item.value).format('DD MMM YYYY, hh:mm A') : item.value,
    }));

    if (hideUploadPhoto !== true) {
      renderData.push({
        col1: 'Upload a photo',
        col2: (
          <ul className="report-details-component__report-attachment-list">
            {files.map((file, index) => (
              <li key={index}>{file.fileName}</li>
            ))}
          </ul>
        ),
      });
    }

    return renderData;
  }, [details, files, hideUploadPhoto]);

  return (
    <div className="report-details-component">
      <h2>Details</h2>
      <DataArrayTableComponent className={TableClasses.restyle} headers={['Question', 'Answer']} data={data} />
    </div>
  );
};

ReportDetailsComponent.propTypes = {
  details: PropTypes.array.isRequired,
  files: PropTypes.array.isRequired,
  hideUploadPhoto: PropTypes.bool,
};

export default ReportDetailsComponent;
