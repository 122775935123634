import React from 'react';
import PropTypes from 'prop-types';
import { ButtonComponent, ButtonClasses } from 'cyc-react-ui-components';

const RegisterSuccessfulComponent = ({ onContinueClicked, extraSuccessfulContent }) => (
  <>
    <h1>My Account registration confirmation</h1>
    <p>
      Thank you for registering for My Account. We’ve sent you an email to verify your email address and finish setting
      up your account.
    </p>
    {extraSuccessfulContent}
    {onContinueClicked !== undefined ? (
      <ButtonComponent type="submit" className={ButtonClasses.primary} onClick={onContinueClicked}>
        Continue
      </ButtonComponent>
    ) : (
      <React.Fragment />
    )}
    <p>If you don't receive the verification email:</p>
    <ul>
      <p>
        <li>please check your junk folders in your email account</li>
        <li>you may have entered an incorrect email address in the My Account registration form</li>
      </p>
    </ul>

    <p>
      Please <a href="https://www.york.gov.uk/contactus">contact us</a> if you haven’t received the verification email
      or need further assistance.
    </p>
  </>
);

RegisterSuccessfulComponent.propTypes = {
  onContinueClicked: PropTypes.func,
  extraSuccessfulContent: PropTypes.any,
};

export { RegisterSuccessfulComponent };
