import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import ChangePasswordForm from '../components/change-password-form.component';
import {
  clientMyAccountUrl,
  PageHeaderComponent,
  DocumentTitleComponent,
  MyAccountPageComponent,
} from '../../../common';
import WithAuthentication from 'common/components/with-authentication/with-authentication.component';

const ChangePasswordContainer = () => {
  const navigation = useNavigate();
  return (
    <WithAuthentication>
      <PageHeaderComponent text="Change password" icon="icon-website" />
      <MyAccountPageComponent>
        <DocumentTitleComponent title="My account - change password">
          <ChangePasswordForm
            handleRedirect={() =>
              navigation(clientMyAccountUrl.account.root + clientMyAccountUrl.account.password.changeSuccessful)
            }
          />
        </DocumentTitleComponent>
      </MyAccountPageComponent>
    </WithAuthentication>
  );
};

ChangePasswordContainer.propTypes = {
  history: PropTypes.object,
};

export default ChangePasswordContainer;
