import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { withFormik, Form } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';

import {
  TextInputComponent,
  InputErrorComponent,
  HelpTextComponent,
  ButtonComponent as Button,
  ButtonClasses,
} from 'cyc-react-ui-components';

import {
  equalTo,
  IsValidComplexPassword,
  ErrorBoxComponent,
  ErrorLabelHandlerComponent,
  msgConstants,
} from '../../../common';

Yup.addMethod(Yup.string, 'equalTo', equalTo);

// The actual form to implement
const RegStepTwoForm = ({ errors, touched, isSubmitting, values, submitCount, setFieldValue }) => {
  let errorBoxRef = React.createRef();
  const previousSubmitCount = React.useRef(0);
  const firstNameError = errors.firstName && touched.firstName && submitCount > 0;
  const lastNameError = errors.lastName && touched.lastName && submitCount > 0;
  const emailError = errors.emailAddress && touched.emailAddress && submitCount > 0;
  const passwordError = errors.password && touched.password && submitCount > 0;
  const confirmPasswordError = errors.confirmPassword && touched.confirmPassword && submitCount > 0;

  useEffect(() => {
    if (Object.keys(errors).length > 0 && submitCount > previousSubmitCount.current) {
      previousSubmitCount.current = submitCount;
      const errorBox = ReactDOM.findDOMNode(errorBoxRef.current);
      errorBox.scrollIntoView();
    }
  }, [errors, errorBoxRef, submitCount, previousSubmitCount]);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <Form noValidate>
      <div ref={errorBoxRef}></div>
      {Object.keys(errors).length > 0 &&
        submitCount > 0 &&
        touched.firstName &&
        touched.lastName &&
        touched.emailAddress &&
        touched.password &&
        touched.confirmPassword && <ErrorBoxComponent />}

      <ErrorLabelHandlerComponent
        touched={touched}
        errors={errors}
        text="First name"
        id="firstName"
        submitCount={submitCount}
      />
      <TextInputComponent
        name="firstName"
        type="text"
        id="firstName"
        autoComplete="off"
        error={firstNameError}
        value={values.firstName}
        onChange={(e) => setFieldValue('firstName', e.target.value)}
      />
      {firstNameError && <InputErrorComponent>{errors.firstName}</InputErrorComponent>}

      <ErrorLabelHandlerComponent
        touched={touched}
        errors={errors}
        text="Last name"
        id="lastName"
        submitCount={submitCount}
      />
      <TextInputComponent
        name="lastName"
        type="text"
        id="lastName"
        error={lastNameError}
        autoComplete="off"
        value={values.lastName}
        onChange={(e) => setFieldValue('lastName', e.target.value)}
      />
      {lastNameError && <InputErrorComponent>{errors.lastName}</InputErrorComponent>}

      <ErrorLabelHandlerComponent
        touched={touched}
        errors={errors}
        text="Email address"
        id="emailAddress"
        submitCount={submitCount}
      />
      <TextInputComponent
        name="emailAddress"
        type="text"
        id="emailAddress"
        error={emailError}
        autoComplete="off"
        value={values.emailAddress}
        onChange={(e) => setFieldValue('emailAddress', e.target.value)}
      />
      {emailError && <InputErrorComponent>{errors.emailAddress}</InputErrorComponent>}

      <ErrorLabelHandlerComponent
        touched={touched}
        errors={errors}
        text="Password"
        id="password"
        submitCount={submitCount}
      />

      <TextInputComponent
        name="password"
        type="password"
        id="password"
        error={passwordError}
        autoComplete="off"
        value={values.password}
        onChange={(e) => setFieldValue('password', e.target.value)}
      />
      {passwordError > 0 && <InputErrorComponent>{errors.password}</InputErrorComponent>}
      <HelpTextComponent>
        Help: Password must be at least 8 characters and contain at least 1 uppercase letter, 1 lowercase letter, 1
        number and 1 special character
      </HelpTextComponent>

      <ErrorLabelHandlerComponent
        touched={touched}
        errors={errors}
        text="Confirm password"
        id="confirmPassword"
        submitCount={submitCount}
      />

      <TextInputComponent
        name="confirmPassword"
        type="password"
        id="confirmPassword"
        error={confirmPasswordError}
        autoComplete="off"
        value={values.confirmPassword}
        onChange={(e) => setFieldValue('confirmPassword', e.target.value)}
      />
      {confirmPasswordError && <InputErrorComponent>{errors.confirmPassword}</InputErrorComponent>}
      <HelpTextComponent>Help: Please re-enter your password</HelpTextComponent>

      <Button type="submit" className={ButtonClasses.primary + ' float-right'} disabled={isSubmitting}>
        Next
      </Button>
      <Button className={ButtonClasses.secondary} onClick={values.goToPrevious}>
        Previous
      </Button>
    </Form>
  );
};

const RegistrationStepTwoComponent = withFormik({
  mapPropsToValues({ firstName, lastName, emailAddress, password, confirmPassword, onSubmitSuccess, goToPrevious }) {
    return {
      firstName: firstName || '',
      lastName: lastName || '',
      emailAddress: emailAddress || '',
      password: password || '',
      confirmPassword: confirmPassword || '',
      onSubmitSuccess: onSubmitSuccess,
      goToPrevious: goToPrevious || null,
    };
  },
  validationSchema: Yup.object().shape({
    firstName: Yup.string().required("Please supply details for 'First name'."),
    lastName: Yup.string().required("Please supply details for 'Last name'."),
    emailAddress: Yup.string()
      .email("'Email address' - Error: this email address does not appear to be valid. Please re-enter.")
      .required("Please supply details for 'Email address'."),
    password: Yup.string()
      .required("Please supply details for 'Password'.")
      .test('password', msgConstants.PASSWORD_COMPLICATED_MESSAGE, (value) => IsValidComplexPassword(value, true)),
    confirmPassword: Yup.string()
      .equalTo(
        Yup.ref('password'),
        "'Confirm password' - Error: this password does not appear to match. Please re-enter."
      )
      .required("Please supply details for 'Confirm password'."),
  }),
  handleSubmit(values) {
    values.onSubmitSuccess({
      firstName: values.firstName,
      lastName: values.lastName,
      emailAddress: values.emailAddress,
      password: values.password,
      confirmPassword: values.confirmPassword,
    });
  },
})(RegStepTwoForm);

RegistrationStepTwoComponent.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  password: PropTypes.string,
  confirmPassword: PropTypes.string,
  onSubmitSuccess: PropTypes.func.isRequired,
  goToPrevious: PropTypes.func.isRequired,
};

export { RegistrationStepTwoComponent, RegStepTwoForm };
