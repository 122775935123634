import React, { useReducer, useEffect, useCallback, useMemo } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { reducer, initialState, actions, fieldNames } from '../incident-form.reducer';
import { ConfigurationService } from 'cyc-configuration-service';
import { FeatureFlagsService } from 'cyc-configuration-service';
import {
  getTOIDFromPoint,
  getPostcodeFromPointAsync,
  getStreetFromPoint,
  getUSRNFromPoint,
  getWardFromPoint,
  getParishFromPointAsync,
} from '../../common/components/map-components/esri-location.service';
import FormConfiguration from './form-config';
import { HeaderTextFromUrl } from '../helpers/header-text-from-url.helper';
import {
  incidentLayersOptionsBuilder,
  FieldsBuilder,
  popupTemplateBuilder,
} from '../helpers/incident-layer-options-builder.helper';
import { getUnadoptedRoadsFeatureOptions } from '../helpers/unadopted-layer.helper';

import {
  ContinueFormOrReRoute,
  IncidentFormContainer,
  IncidentService,
  apiMyAccountUrl,
  clientMyAccountUrl,
  changeUrlByStepNumberv6,
  waterAndDrainageTypeSettingsNames,
  isEnabled,
  UnadoptedLayerFlag,
  MissingAssetsFlag,
} from 'common';

// Pages
import { ConfirmationPage, EntryPage, SummaryPage, LocationPage } from '../components-shared';
import ProblemWithPage from './components/problem-with-page.component';
import ProblemPageSurfaceWater from './components/problem-page-surface-water.component';
import ProblemPageNonSurfaceWater from './components/problem-page-non-surface-water.component';
import SignInContainer from 'sign-in/containers/sign-in.container';
import RegistrationApp from '../../registration/registration.app';

const WaterAndDrainageProblemsApp = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const formConfig = useMemo(() => new FormConfiguration(), []);
  const initState = useMemo(
    () => initialState({ pageHeaderText: formConfig.pageHeaderText }),
    [formConfig.pageHeaderText]
  );
  const [state, dispatch] = useReducer(reducer, initState);
  const formData = state.formData;
  const getIncidentsUrl = `${ConfigurationService.store.apiUrl}${apiMyAccountUrl.getIncidents.WATER_AND_DRAINAGE_PROBLEMS}`;
  const gullyId = formConfig.getTypeId(waterAndDrainageTypeSettingsNames.gully);
  const waterAndDrainageTypes = Object.values(formConfig.waterAndDrainageTypes);
  const surfaceWaterQuestions = Object.values(formConfig.surfaceWaterQuestions);
  const floodedQuestions = [
    formConfig.surfaceWaterQuestions.waterDangerToUser,
    formConfig.surfaceWaterQuestions.riskOfInternalFlooding,
  ];
  const surfaceWaterId = formConfig.getTypeId(waterAndDrainageTypeSettingsNames.surfaceWater);
  const isGullySelected = () => formData.waterDrainageTypeId === gullyId;
  const isSurfaceWaterSelected = () => formData.waterDrainageTypeId === surfaceWaterId;

  // Updates the header based on location
  const indexOfStep = formConfig.stepUrls.indexOf(location.pathname);
  useEffect(() => {
    const header = HeaderTextFromUrl({
      url: location.pathname,
      initialHeaderText: formConfig.pageHeaderText,
    });

    dispatch({
      type: actions.updateStateFields,
      payload: { [fieldNames.currentStep]: indexOfStep, [fieldNames.pageHeaderText]: header },
    });
  }, [location.pathname, formConfig.pageHeaderText, indexOfStep]);

  // ----ENTRY PAGE -----
  const handleResetForm = useCallback(
    () => dispatch({ type: actions.resetForm, payload: initState }),
    [dispatch, initState]
  );

  // ----- PROBLEM PAGE -----
  const handleProblemWithChange = (newProblemWithId) => {
    // reset surfaceWaterQuestions (in case they're not present on new type)
    surfaceWaterQuestions.forEach((question) => {
      dispatch({ type: actions.updateFormDataFields, payload: { [fieldNames[question.id]]: undefined } });
    });
    // reset problemId (in case it is not present on new type, otherwise validation will not trigger)
    dispatch({ type: actions.updateFormDataFields, payload: { [fieldNames.problemId]: undefined } });
    // If the selection changes to 'Gully' we want to reset the map
    // as the user might think they have aready selected it due to the
    // red circle marking previously selected location
    if (newProblemWithId === gullyId) {
      dispatch({ type: actions.resetLocation });
      dispatch({
        type: actions.updateFormDataFields,
        payload: { [fieldNames.gullyType]: null, [fieldNames.gullyNumber]: null },
      });
    }
    // If they have selected non gully but are coming from gully, reset to avoid markers
    if (formData.waterDrainageTypeId === gullyId && newProblemWithId !== gullyId) {
      dispatch({ type: actions.resetLocation });
    }
    dispatch({
      type: actions.updateFormDataFields,
      payload: { [fieldNames.waterDrainageTypeId]: newProblemWithId },
    });
  };

  // ---- LOCATION PAGE ------
  // We're using custom mapIncidentDetailsToAttributes builder here
  // There is a default available if you don't need custom fields
  // See: DogAndLitterBins form app
  const customMapIncidentDetailsToAttributes = (incident) => {
    const type = waterAndDrainageTypes.find((type) => type.id === incident.waterDrainageTypeId.toString());
    const categories = type && type.categories;
    const category = categories && Object.values(categories).find((cat) => cat.id === incident.categoryId);
    return {
      incidentId: incident.incidentId,
      featureType: type !== undefined ? type.displayName : incident.waterDrainageType,
      faultType: category !== undefined ? category.displayName : incident.category,
      createdTime: new Date(incident.createdTime).toLocaleString('en-GB'),
    };
  };
  const getIncidentLayerOptions = () => {
    let fieldsBuilder = new FieldsBuilder();
    fieldsBuilder.addIncidentId();
    fieldsBuilder.addFeatureType();
    fieldsBuilder.addFaultType();
    fieldsBuilder.addCreatedTime();
    const inc = incidentLayersOptionsBuilder({
      mapIncidentDetailsToAttributes: customMapIncidentDetailsToAttributes,
      getIncidentsUrl: getIncidentsUrl,
      mapIncidentLayerId: formConfig.mapIncidentLayerId,
      popupTemplate: popupTemplateBuilder({
        mapIncidentLayerTitle: formConfig.mapIncidentLayerTitle,
        tableDataArray: [
          { header: 'Feature type', data: 'featureType' },
          { header: 'Fault type', data: 'faultType' },
          { header: 'Date created', data: 'createdTime' },
        ],
        showAlreadyReportedMessage: true,
      }),
      fields: fieldsBuilder.getBuilderResult(),
    });
    return inc;
  };

  const getFeatureLayerOptions = () => {
    let featureLayer = [];
    if (isEnabled(FeatureFlagsService.getFeatureFlagByName(UnadoptedLayerFlag))) {
      featureLayer.push(getUnadoptedRoadsFeatureOptions());
    }
    if (isGullySelected() === true) {
      featureLayer.push({
        layerUrl: formConfig.mapFeatureLayerUrl,
        layerId: formConfig.mapFeatureLayerId,
        popupTemplate: popupTemplateBuilder({
          mapIncidentLayerTitle: formConfig.mapFeatureLayerTitle,
          tableDataArray: [
            { header: 'Gully No', data: 'OBJECTID' },
            { header: 'Type', data: 'TYPE' },
            { header: 'Item ID', data: 'ITEM_ID' }
          ],
        }),
      });
    }
    return featureLayer;
  };

  const getMissingAssetOptions = () => {
    if (isGullySelected() && isEnabled(FeatureFlagsService.getFeatureFlagByName(MissingAssetsFlag))) {
      return {
        checked: state.missingAssetChecked,
        label: formConfig.missingAssetCheckBoxLabel,
        handleSwitch: () => {
          dispatch({
            type: actions.updateStateFields,
            payload: { [fieldNames.missingAssetChecked]: !state.missingAssetChecked },
          });
        },
        onUploadFiles: (files) => dispatch({ type: actions.updateUploadFiles, payload: files }),
        onRemoveFile: (index) => dispatch({ type: actions.removeUploadedFile, payload: index }),
        uploadedFiles: formData.uploadedFiles,
      };
    }
  };

  const handleFeatureClicked = async (e) => {
    const toid = await getTOIDFromPoint(e.feature.geometry.x, e.feature.geometry.y);
    const postcode = await getPostcodeFromPointAsync(e.feature.geometry.x, e.feature.geometry.y);
    const street = await getStreetFromPoint(e.feature.geometry.x, e.feature.geometry.y);
    const usrn = await getUSRNFromPoint(street);
    const ward = await getWardFromPoint(e.feature.geometry.x, e.feature.geometry.y);
    const parish = await getParishFromPointAsync(e.feature.geometry.x, e.feature.geometry.y);

    // Start Debug
    const itemId = e.feature.attributes.Item_Id;
    console.log('Feature ItemID: ', itemId);
    // End Debug

    const locationObject = {
      easting: e.feature.geometry.x.toString(),
      northing: e.feature.geometry.y.toString(),
      street,
      usrn: usrn ? usrn.toString() : null,
      ward,
      parish,
      assetId: e.feature.attributes.Item_Id,
      filter: `Item_Id=${e.feature.attributes.Item_Id}`,
      toid,
      postcode,
    };

    dispatch({ type: actions.updateLocationObject, payload: locationObject });
    dispatch({
      type: actions.updateFormDataFields,
      payload: {
        [fieldNames.gullyType]: e.feature.attributes.TYPE,
        [fieldNames.gullyNumber]: e.feature.attributes.OBJECTID,
      },
    });
  };

  const getLocationSelectText = () => {
    if (isGullySelected())
      return (
        <>
          Search the map by postcode, street name, or your current location, to view nearby gullies.
          <br />
          Use the <span aria-hidden="true" className="esri-icon esri-icon-locate"></span> icon to report a problem at
          your current location.
          <br />
          Select the gully by clicking the icon on the map.
          <br />
          Select a reported problem to view details.
        </>
      );
  };

  const incidentExistsCheck = async (e) => {
    const serviceResult = await IncidentService.assetHasLiveIncident({
      assetId: e.feature.attributes.Item_Id,
      productId: formConfig.productId,
    });
    return serviceResult;
  };
  // --- PROBLEM PAGE ---
  const areFloodedQuestionsRequired = () => {
    const type = waterAndDrainageTypes.find((type) => type.id === formData.waterDrainageTypeId);
    const floodedId = type && type.categories.flooded && type.categories.flooded.id;
    return formData.problemId !== '' && floodedId === formData.problemId;
  };

  // --- SUMMARY PAGE ---
  const buildTableData = () => {
    const selectedType = waterAndDrainageTypes.find((type) => type.id === formData.waterDrainageTypeId);
    const tableData = [
      {
        columnOne: 'Location',
        columnTwo:
          formData.locationObject && formData.locationObject.streetName && formData.locationObject.streetName.length > 0
            ? formData.locationObject.streetName
            : 'Selected on map',
        onChangeClicked: () => changeUrlByStepNumberv6(2, formConfig, navigate, 'location'),
      },
      {
        columnOne: 'Additional location information',
        columnTwo: formData.additionalInformation,
        onChangeClicked: () => changeUrlByStepNumberv6(2, formConfig, navigate, 'additionalInformation'),
      },
      {
        columnOne: 'Problem is with?',
        columnTwo: selectedType && selectedType.displayName,
        onChangeClicked: () => changeUrlByStepNumberv6(1, formConfig, navigate, 'typeOfTheProblem'),
        columnTwoClassName: 'sentence-case',
      },
      {
        columnOne: 'Upload a photo',
        columnTwo: formData.uploadedFiles ? (
          <ul className="uploaded-photos">
            {formData.uploadedFiles.map((file, index) => (
              <li key={index}>{file.fileName}</li>
            ))}
          </ul>
        ) : (
          <React.Fragment />
        ),
        onChangeClicked: () => changeUrlByStepNumberv6(3, formConfig, navigate, 'uploadPhoto'),
      },
    ];

    const surfaceWaterQuestionsTable = [];
    surfaceWaterQuestions.forEach((question) => {
      if (formData[question.id]) {
        surfaceWaterQuestionsTable.push({
          columnOne: formConfig.sumaryQuestionNames[question.id],
          columnTwo: formConfig.surfaceWaterQuestions[question.id].radioOptions.find(
            (option) => option.id.toString() === formData[question.id]
          ).value,
          onChangeClicked: () => changeUrlByStepNumberv6(3, formConfig, navigate, question.id),
          columnTwoClassName: 'sentence-case',
        });
      }
    });

    tableData.splice(3, 0, ...surfaceWaterQuestionsTable);

    if (!isSurfaceWaterSelected()) {
      const category =
        selectedType && Object.values(selectedType.categories).find((category) => category.id === formData.problemId);
      const categoryDisplayName = category && category.displayName;
      tableData.splice(3, 0, {
        columnOne: 'About the problem',
        columnTwo: categoryDisplayName,
        onChangeClicked: () => changeUrlByStepNumberv6(3, formConfig, navigate, 'whatIsTheProblem'),
        columnTwoClassName: 'sentence-case',
      });
    }

    return tableData;
  };

  const submitIncident = async () => {
    let form = { ...formData };
    form.productId = state.missingAssetChecked ? formConfig.missingAssetProductId : formConfig.productId;
    form.subject = formConfig.subject;

    // if surface water has been chosen, we need to set category id
    // as it's expected in BUI yet we're not setting it anywhere else
    if (formData.waterDrainageTypeId === surfaceWaterId) {
      const surfaceWaterType = waterAndDrainageTypes.find((type) => type.id === surfaceWaterId);
      const categories = surfaceWaterType && surfaceWaterType.categories;
      // there is only one category for the surface water
      const categoryId = categories && categories[0].id;
      form.problemId = categoryId;
    }

    // Create the incident
    try {
      const result = await IncidentService.createIncident(form);
      if (result.status === 200) {
        dispatch({ type: actions.updateIncidentObject, payload: result.data });
        dispatch({
          type: actions.updateStateFields,
          payload: { [fieldNames.formStarted]: true, [fieldNames.formCompleted]: true },
        });
      }
    } catch {
      return false;
    }
  };

  // ----- CONFIRMATION PAGE -----
  const sendUpdateIncidentWithUser = async (email) => {
    await IncidentService.updateIncidentWithUser(
      state.incident.referenceNumber,
      state.incident.secretReferenceNumber,
      state.incident.incidentId,
      email
    ).then((result) => {
      if (result.status === 200) {
        return true;
      } else {
        return false;
      }
    });
  };

  const handleUserRegistered = (userData) => {
    dispatch({ type: actions.updateStateFields, payload: { [fieldNames.userLoggedIn]: true } });
    // only update incident when user's email is not confirmed
    // save the incident into temp table while wait for the user to confirm his/her email.
    if (state.formCompleted && userData.isEmailConfirmed === false) {
      sendUpdateIncidentWithUser(userData.email);
    }
  };

  const handleSignInCallBack = () => {
    const isSignedIn = formConfig.userIsLoggedIn();
    if (isSignedIn) {
      sendUpdateIncidentWithUser('');
    }
    // after sign in succssfully, navigate back to incident creation confirmation page
    navigate(formConfig.stepUrls[formConfig.totalSteps]);
  };

  // --- render builders ----
  const formRouteInfo = () => {
    return {
      formStarted: state.formStarted,
      formCompleted: state.formCompleted,
      formStartUrl: formConfig.stepUrls[0],
      formEndUrl: formConfig.stepUrls[formConfig.totalSteps],
      location: location,
    };
  };

  return (
    <IncidentFormContainer
      displayProgressBar={() => formConfig.shouldDisplayBasedOnStep(state.currentStep)}
      pageHeaderText={state.pageHeaderText}
      documentTitle={formConfig.documentTitle}
      totalSteps={formConfig.totalSteps}
      currentStep={state.currentStep}
      breadcrumbs={[{ url: clientMyAccountUrl.waterAndDrainageProblems.root, name: formConfig.pageHeaderText }]}>
      <Routes>
        <Route
          path="/"
          element={
            <EntryPage
              report={formConfig.whatToReportText}
              informationArray={formConfig.beforeYouBeginText}
              goToNextPage={() =>
                changeUrlByStepNumberv6(1, formConfig, navigate, false, true, () => {
                  dispatch({
                    type: actions.updateStateFields,
                    payload: { [fieldNames.formStarted]: true, [fieldNames.formCompleted]: false },
                  });
                })
              }
              onResetForm={handleResetForm}
            />
          }
        />
        <Route
          path={formConfig.baseUrl.step1}
          element={
            <ContinueFormOrReRoute {...formRouteInfo()}>
              <ProblemWithPage
                goToNextPage={() => changeUrlByStepNumberv6(2, formConfig, navigate)}
                goToPreviousPage={() => changeUrlByStepNumberv6(0, formConfig, navigate)}
                problemWithId={formData.waterDrainageTypeId}
                onProblemWithChange={handleProblemWithChange}
                types={waterAndDrainageTypes}
              />
            </ContinueFormOrReRoute>
          }
        />
        <Route
          path={formConfig.baseUrl.step2}
          element={
            <ContinueFormOrReRoute {...formRouteInfo()}>
              <LocationPage
                goToNext={() => changeUrlByStepNumberv6(3, formConfig, navigate)}
                goToPrevious={() => changeUrlByStepNumberv6(1, formConfig, navigate)}
                // currently selected location object
                locationObject={formData.locationObject}
                // boolean => is location selected?
                locationSelected={formData.locationSelected}
                // on map click
                onMapSelected={(location) => dispatch({ type: actions.updateLocationObject, payload: location })}
                // is map click allowed?
                disableFreeSelect={isGullySelected() ? true : false}
                incidentLayerOptions={getIncidentLayerOptions()}
                featureLayerOptions={getFeatureLayerOptions()}
                // on asset click
                handleFeatureClicked={handleFeatureClicked}
                // used to reset location when clicking on a new incident/asset
                clearSelectedPoint={() => dispatch({ type: actions.resetLocation })}
                // text above the map with instructions on what to select
                locationSelectText={getLocationSelectText()}
                // content of additionalInformation form field
                additionalInformation={formData.additionalInformation}
                // checks if a given object has an incident logged, marks it as invalid selection
                incidentExistsCheck={incidentExistsCheck}
                // message to display when the above returned a result (required when incidentExistsCheck set)
                incidentExistsAlertMessage="This feature already has an incident reported and can therefore not have another."
                // custom error message when no location/asset selected
                locationSelectedValidationText={isGullySelected() ? 'Please select an asset on the map.' : undefined}
                onAdditionalInformationChange={(e) =>
                  dispatch({
                    type: actions.updateFormDataFields,
                    payload: { [fieldNames.additionalInformation]: e.target.value },
                  })
                }
                enablePointSelectionZoomLevels={formConfig.enablePointSelectionZoomLevels}
                // missing assets options
                missingAssetsOptions={getMissingAssetOptions()}
              />
            </ContinueFormOrReRoute>
          }
        />
        <Route
          path={formConfig.baseUrl.step3}
          element={
            <ContinueFormOrReRoute {...formRouteInfo()}>
              {isSurfaceWaterSelected() ? (
                <ProblemPageSurfaceWater
                  goToNextPage={() => changeUrlByStepNumberv6(4, formConfig, navigate)}
                  goToPreviousPage={() => changeUrlByStepNumberv6(2, formConfig, navigate)}
                  onSurfaceWaterQuestionChange={(questionId, value) =>
                    dispatch({ type: actions.updateFormDataFields, payload: { [fieldNames[questionId]]: value } })
                  }
                  surfaceWaterQuestions={surfaceWaterQuestions}
                  onUploadFiles={(files) => dispatch({ type: actions.updateUploadFiles, payload: files })}
                  onRemoveFile={(index) => dispatch({ type: actions.removeUploadedFile, payload: index })}
                  uploadedFiles={formData.uploadedFiles}
                  hasItRained={formData.hasItRained}
                  waterDangerToUser={formData.waterDangerToUser}
                  riskOfInternalFlooding={formData.riskOfInternalFlooding}
                  reocurringProblem={formData.reocurringProblem}
                />
              ) : (
                <ProblemPageNonSurfaceWater
                  goToNextPage={() => changeUrlByStepNumberv6(4, formConfig, navigate)}
                  goToPreviousPage={() => changeUrlByStepNumberv6(2, formConfig, navigate)}
                  onProblemChange={(value) => {
                    // reset surface water questions
                    floodedQuestions.forEach((question) => {
                      dispatch({
                        type: actions.updateFormDataFields,
                        payload: { [fieldNames[question.id]]: undefined },
                      });
                    });
                    dispatch({ type: actions.updateFormDataFields, payload: { [fieldNames.problemId]: value } });
                  }}
                  onUploadFiles={(files) => dispatch({ type: actions.updateUploadFiles, payload: files })}
                  onRemoveFile={(index) => dispatch({ type: actions.removeUploadedFile, payload: index })}
                  uploadedFiles={formData.uploadedFiles}
                  problemWithId={formData.waterDrainageTypeId}
                  problemId={formData.problemId}
                  waterAndDrainageTypes={waterAndDrainageTypes}
                  floodedQuestions={floodedQuestions}
                  areFloodedQuestionsRequired={areFloodedQuestionsRequired()}
                  onFloodedQuestionChange={(questionId, value) =>
                    dispatch({ type: actions.updateFormDataFields, payload: { [fieldNames[questionId]]: value } })
                  }
                  waterDangerToUser={formData.waterDangerToUser}
                  riskOfInternalFlooding={formData.riskOfInternalFlooding}
                />
              )}
            </ContinueFormOrReRoute>
          }
        />
        <Route
          path={formConfig.baseUrl.step4}
          element={
            <ContinueFormOrReRoute {...formRouteInfo()}>
              <SummaryPage
                goToProblemPage={() => changeUrlByStepNumberv6(3, formConfig, navigate)}
                submitIncident={submitIncident}
                tableData={buildTableData()}
              />
            </ContinueFormOrReRoute>
          }
        />
        <Route
          path={formConfig.baseUrl.confirmation}
          element={
            <ContinueFormOrReRoute {...formRouteInfo()}>
              <ConfirmationPage
                referenceNumber={state.incident.referenceNumber}
                onSignInClicked={() => navigate(formConfig.baseUrl.root + clientMyAccountUrl.signIn)}
                onRegisterClicked={() => navigate(formConfig.baseUrl.root + clientMyAccountUrl.register)}
                userLoggedIn={() => formConfig.userIsLoggedIn()}
                goToMyAccount={() => navigate(clientMyAccountUrl.dashboard)}
                restartFormUrl={formConfig.baseUrl.root}
              />
            </ContinueFormOrReRoute>
          }
        />
        <Route
          path={clientMyAccountUrl.signIn}
          element={<SignInContainer onSuccessfulSignIn={handleSignInCallBack} />}
        />
        <Route
          path={`${clientMyAccountUrl.register}/*`}
          element={
            <RegistrationApp
              onSuccessfulRegistration={handleUserRegistered}
              extraSuccessfulContent={<p>Click 'Continue' to view the reference number for your reported problem.</p>}
              onContinueClicked={() => navigate(formConfig.baseUrl.root + formConfig.baseUrl.confirmation)}
            />
          }
        />
      </Routes>
    </IncidentFormContainer>
  );
};

export default WaterAndDrainageProblemsApp;
