import React from 'react';
import PropTypes from 'prop-types';
import { withFormik, Form } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { ConfigurationService } from 'cyc-configuration-service';

import {
  ErrorBoxComponent,
  ErrorLabelHandlerComponent,
  equalTo,
  NetworkCatchErrorComponent,
  msgConstants,
  clientMyAccountUrl,
  apiMyAccountUrl,
} from '../../../../common';
import {
  TextInputComponent,
  HelpTextComponent,
  InputErrorComponent,
  SubmitButtonAxiosComponent,
  ButtonClasses,
} from 'cyc-react-ui-components';

Yup.addMethod(Yup.string, 'equalTo', equalTo);

const ChangeEmailForm = ({ errors, touched, values, handleSubmit, submitCount, setFieldValue }) => (
  <Form noValidate>
    <NetworkCatchErrorComponent didCatchError={values.submitError} />
    {Object.keys(errors).length > 0 && submitCount > 0 && touched.email && touched.confirmEmail && touched.password ? (
      <ErrorBoxComponent errors={errors}></ErrorBoxComponent>
    ) : null}
    <div className="row">
      <div className="col">
        <p>
          <b>Current email address: </b> {values.currentEmail}
        </p>
      </div>
    </div>

    <div className="row">
      <div className="col">
        <p>
          Enter your new email address. You will need to verify your new email address before you are able to use it to
          sign in to My Account.
        </p>
      </div>
    </div>

    <div className="row">
      <div className="col">
        <ErrorLabelHandlerComponent
          touched={touched}
          errors={errors}
          text="New email address"
          id="email"
          submitCount={submitCount}
        />
        <TextInputComponent
          name="email"
          type="email"
          id="email"
          value={values.email}
          onChange={(e) => setFieldValue('email', e.target.value)}
          error={errors.email && touched.email && submitCount > 0}
        />
        {errors.email && touched.email && submitCount > 0 && <InputErrorComponent>{errors.email}</InputErrorComponent>}
      </div>
    </div>

    <div className="row">
      <div className="col">
        <ErrorLabelHandlerComponent
          touched={touched}
          errors={errors}
          text="Confirm new email address"
          id="confirmEmail"
          submitCount={submitCount}
        />
        <TextInputComponent
          name="confirmEmail"
          type="email"
          id="confirmEmail"
          value={values.confirmEmail}
          onChange={(e) => setFieldValue('confirmEmail', e.target.value)}
          error={errors.confirmEmail && touched.confirmEmail && submitCount > 0}
        />
        {errors.confirmEmail && touched.confirmEmail && submitCount > 0 && (
          <InputErrorComponent>{errors.confirmEmail}</InputErrorComponent>
        )}
      </div>
    </div>

    <div className="row">
      <div className="col">
        <ErrorLabelHandlerComponent
          touched={touched}
          errors={errors}
          text="Password"
          id="password"
          submitCount={submitCount}
        />
        <TextInputComponent
          name="password"
          type="password"
          id="password"
          value={values.password}
          onChange={(e) => setFieldValue('password', e.target.value)}
          error={errors.password && touched.password && submitCount > 0}
        />
        <HelpTextComponent>
          Help: For security reasons, enter your My Account password to confirm your changes
        </HelpTextComponent>
        {errors.password && touched.password && <InputErrorComponent>{errors.password}</InputErrorComponent>}
      </div>
    </div>

    <div className="row">
      <div className="col">
        <SubmitButtonAxiosComponent
          type="submit"
          className={ButtonClasses.primary + ' float-right'}
          url={`${ConfigurationService.store.apiUrl}${apiMyAccountUrl.UPDATE_CONTACT_EMAIL_ADDRESS}`}
          onClick={handleSubmit}
        />
        <Link className="link-inline-with-button" to={clientMyAccountUrl.dashboard}>
          Back to My Account
        </Link>
      </div>
    </div>
  </Form>
);

const ChangeEmailComponent = withFormik({
  mapPropsToValues({ currentEmail, onSuccessfulChange, onSubmit }) {
    return {
      currentEmail: currentEmail,
      email: '',
      confirmEmail: '',
      password: '',
      onSuccessfulChange,
      onSubmit,
      submitError: false,
    };
  },
  validationSchema: Yup.object().shape({
    email: Yup.string()
      .notOneOf(
        [Yup.ref('currentEmail')],
        "'New email address' -  Error: You are already using this email address. Please re-enter."
      )
      .email("'New email address' - Error: this email address does not appear to be valid. Please re-enter.")
      .required("Please supply details for 'New email address'."),

    confirmEmail: Yup.string()
      .equalTo(
        Yup.ref('email'),
        "'Confirm new email address' 'Confirm email address' - Error: email addresses do not match. Please re-enter."
      )
      .required("Please supply details for 'Confirm new email address'."),

    password: Yup.string().required("Please supply details for 'Password'."),
  }),
  handleSubmit(values, { setSubmitting, setFieldError, setFieldValue }) {
    values
      .onSubmit(values.email, values.password)
      .then((res) => {
        if (res.status === 200) {
          values.onSuccessfulChange();
        } else {
          setFieldValue('submitError', true);
          setSubmitting(false);
        }
      })
      .catch((error) => {
        setSubmitting(false);
        // If the my account server is unresponsive
        if (error === undefined || error.response === undefined) {
          setFieldValue('submitError', true);
          return;
        }
        // My account responsive with error codes
        if (error.response.status === 401) {
          setSubmitting(false);
          setFieldError('password', msgConstants.PASSWORD_SIMPLE_MESSAGE);
        } else {
          // Catch all other error codes
          setFieldValue('submitError', true);
          setSubmitting(false);
        }
      });
  },
})(ChangeEmailForm);

ChangeEmailComponent.propTypes = {
  currentEmail: PropTypes.string,
  onSuccessfulChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ChangeEmailComponent;

export { ChangeEmailForm };
