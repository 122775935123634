import { useNavigate } from 'react-router-dom';

import { ButtonComponent, ButtonClasses } from 'cyc-react-ui-components';
import { clientMyAccountUrl } from '../../../common';

const RegisterSuccessfulWithWarningComponent = () => {
  const navigate = useNavigate();

  // We are going to find the path we are at
  const currentPath = location.pathname;

  // Find the root of the current
  const currentPathRoot = currentPath.split(clientMyAccountUrl.register)[0];
  // Append the sign in path onto this
  const signInLinkUrl = `${currentPathRoot}${clientMyAccountUrl.signIn}`;

  return (
    <>
      <h1>My Account registration confirmation</h1>
      <p>
        Thank you for registering for a City of York Council My Account. Our records show that you already have an
        account with us.
      </p>
      <p>Enter your email address and password on the sign in page to access your My Account.</p>
      <ButtonComponent
        type="submit"
        className={ButtonClasses.primary + ' float-right'}
        onClick={() => navigate(signInLinkUrl)}>
        Sign in
      </ButtonComponent>
    </>
  );
};

export { RegisterSuccessfulWithWarningComponent };
