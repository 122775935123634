import React from 'react';

/**
 * A component to render an alert message
 *
 * props
 * @param {Array} children - Array of react elements to render
 */
const AlertMessageComponent = ({ children }) => {
  return <div className="alert-message">{children}</div>;
};

export default AlertMessageComponent;
