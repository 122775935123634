import React, { useEffect, useReducer, useMemo, useCallback, useState } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { CYCServices } from 'cyc-services';
import { ConfigurationService } from 'cyc-configuration-service';

import {
  clientMyAccountUrl,
  ContinueFormOrReRoute,
  apiMyAccountUrl,
  IncidentService,
  changeUrlByStepNumberv6,
  MissingAssetsStreetLightingFlag,
  isEnabled,
} from 'common';
import { getTOIDFromPoint, getPostcodeFromPointAsync } from 'common/components/map-components/esri-location.service';
import * as Services from 'common/services/incident.service';
import { FeatureFlagsService } from 'cyc-configuration-service';
import FormConfiguration from './form-config';

import EntryPage from '../components-shared/entry-page.component';
import LocationPageComponent from '../components-shared/location-page.component';
import ProblemPageComponent from './components/problem-page.component';
import LightTypePageComponent from './components/light-type-page.component';
import SummaryPageComponent from '../components-shared/summary-page.component';
import ConfirmationPage from '../components-shared/confirmation-page.component';
import SignInContainer from '../../sign-in/containers/sign-in.container';
import RegistrationApp from '../../registration/registration.app';
import IncidentFormContainer from 'common/containers/incident-forms/incident-form.container';
import {
  FieldsBuilder,
  incidentLayersOptionsBuilder,
  popupTemplateBuilder,
  mapIncidentDetailsToAttributesBuilder,
} from '../helpers/incident-layer-options-builder.helper';
import { actions, fieldNames } from 'incident-forms/incident-form.reducer';
import { initialState, reducer } from '../incident-form.reducer';
import { HeaderTextFromUrl } from 'incident-forms/helpers/header-text-from-url.helper';
import { IncidentsExistDisplay } from 'incident-forms/components-shared';
import {
  cannotReportAnotherProblemFooter,
  differentProblemFooter,
  generalHeader,
} from 'incident-forms/components-shared/existing-incidents-display/incidents-exist-display.component';

const StreetLightingApp = () => {
  let location = useLocation();
  let navigate = useNavigate();
  const [existingIncidents, setExistingIncidents] = useState([]);
  const formConfig = useMemo(() => new FormConfiguration(), []);
  const initState = useMemo(
    () => initialState({ pageHeaderText: formConfig.pageHeaderText }),
    [formConfig.pageHeaderText]
  );
  const [state, dispatch] = useReducer(reducer, initState);
  const getIncidentsUrl = `${ConfigurationService.store.apiUrl}${apiMyAccountUrl.getIncidents.LIGHTING_INCIDENTS}`;

  // This effect is for updating headers and step urls
  useEffect(() => {
    const indexOfStep = formConfig.stepUrls.indexOf(location.pathname);

    // Update the header based on the url.
    const header = HeaderTextFromUrl({
      url: location.pathname,
      initialHeaderText: formConfig.pageHeaderText,
    });
    dispatch({
      type: actions.updateStateFields,
      payload: { [fieldNames.currentStep]: indexOfStep, [fieldNames.pageHeaderText]: header },
    });

    // Change the step number based on the url.
    dispatch({ type: actions.updateStateFields, payload: { [fieldNames.currentStep]: indexOfStep } });
  }, [state.currentStep, location.pathname, formConfig.pageHeaderText, formConfig.stepUrls]);

  // --- ENTRY PAGE ---
  const handleResetForm = useCallback(
    () => dispatch({ type: actions.resetForm, payload: initState }),
    [dispatch, initState]
  );

  const handleFeatureClicked = async (e) => {
    const toid = await getTOIDFromPoint(e.feature.geometry.x, e.feature.geometry.y);
    const postcode = await getPostcodeFromPointAsync(e.feature.geometry.x, e.feature.geometry.y);
    const locationObject = {
      easting: e.feature.geometry.x.toString(),
      northing: e.feature.geometry.y.toString(),
      street: e.feature.attributes.ROAD_NAME,
      roadNumber: e.feature.attributes.ROAD_NUMBER,
      usrn: e.feature.attributes.USRN,
      ward: e.feature.attributes.WARD,
      parish: e.feature.attributes.PARISH,
      assetId: e.feature.attributes.Item_Id, // Light number
      assetDescription: e.feature.attributes.DESCRIPTION,
      location: e.feature.attributes.LOCATION,
      filter: `Item_Id=${e.feature.attributes.Item_Id}`,
      toid,
      postcode,
    };
    dispatch({ type: actions.updateLocationObject, payload: locationObject });
    dispatch({
      type: actions.updateFormDataFields,
      payload: { [fieldNames.unitType]: e.feature.attributes.UNIT_TYPE },
    });
  };

  /**
   * Gets all map layer features and filters to find the lighting asset
   * Then checks for existing incidents and sets location object
   * @param {array} features array of map 'feature'
   */
  const handleGetAllLayerAttributesOnClick = (features) => {
    if (formConfig.allowIncidentSelect) {
      const featureFound = features.find((x) => x.feature.attributes.Item_Id);
      if (featureFound) {
        incidentExistsCheck(featureFound);
        // Set the data found to the form
        handleFeatureClicked(featureFound);
      } else {
        setExistingIncidents([]);
      }
    }
  };

  const clearSelectedFeature = () => {
    dispatch({ type: actions.resetLocation });
    dispatch({ type: actions.updateFormDataFields, payload: { [fieldNames.problemId]: '' } });
    dispatch({ type: actions.updateFormDataFields, payload: { [fieldNames.unitType]: null } });
  };

  const layerOptionsBuilder = () => {
    let fieldsBuilder = new FieldsBuilder();
    fieldsBuilder.addIncidentId();
    fieldsBuilder.addFaultType();
    fieldsBuilder.addAssetDescription('Lamp type');
    fieldsBuilder.addCreatedTime('Created');
    const inc = incidentLayersOptionsBuilder({
      mapIncidentDetailsToAttributes: mapIncidentDetailsToAttributesBuilder,
      getIncidentsUrl: getIncidentsUrl,
      mapIncidentLayerId: 'streetLightingIncidents',
      popupTemplate: popupTemplateBuilder({
        mapIncidentLayerTitle: 'Reported street light',
        tableDataArray: [
          { header: 'Light type', data: 'assetDescription' },
          { header: 'Fault type', data: 'faultType' },
          { header: 'Created', data: 'createdTime' },
        ],
      }),
      fields: fieldsBuilder.builder,
    });
    return inc;
  };

  const featureLayerOptionsBuilder = () => {
    return [
      {
        layerUrl: formConfig.featureLayerUrl,
        layerId: formConfig.featureLayerId,
        popupTemplate: popupTemplateBuilder({
          mapIncidentLayerTitle: 'Selected street light',
          tableDataArray: [
            { header: 'Light number', data: 'Item_Id' },
            { header: 'Description', data: 'DESCRIPTION' },
            { header: 'Location', data: 'LOCATION' },
            { header: 'Road name', data: 'ROAD_NAME' },
            { header: 'Ward', data: 'WARD' },
            { header: 'Parish', data: 'PARISH' },
          ],
        }),
      },
    ];
  };

  const getMissingAssetOptions = () => {
    if (isEnabled(FeatureFlagsService.getFeatureFlagByName(MissingAssetsStreetLightingFlag))) {
      return {
        checked: state.missingAssetChecked,
        label: formConfig.missingAssetCheckBoxLabel,
        handleSwitch: () => {
          dispatch({
            type: actions.updateStateFields,
            payload: { [fieldNames.missingAssetChecked]: !state.missingAssetChecked },
          });
        },
        onUploadFiles: (files) => dispatch({ type: actions.updateUploadFiles, payload: files }),
        onRemoveFile: (index) => dispatch({ type: actions.removeUploadedFile, payload: index }),
        uploadedFiles: state.formData.uploadedFiles,
        useUnadoptedLayer: false,
        showFileInputOnMapPage: false,
      };
    }
  };

  // --- PROBLEM PAGE ---
  const getPossibleProblems = () => {
    const lightingTypes = Object.values(formConfig.streetLightTypes);
    const getType = lightingTypes.filter((val) => val.unitType === state.formData.unitType)[0];
    if (getType !== undefined) {
      return getType.possibleProblems;
    }
    return undefined;
  };

  const buildTableData = () => {
    const lightingTypes = Object.values(formConfig.streetLightTypes);
    const { formData } = state;
    const type = lightingTypes.filter((val) => val.unitType === formData.unitType)[0];
    const problem = type ? type.possibleProblems.filter((val) => val.id === formData.problemId)[0] : undefined;

    const tableData = [
      {
        columnOne: 'Street name',
        columnTwo:
          formData.locationObject && formData.locationObject.streetName && formData.locationObject.streetName.length > 0
            ? formData.locationObject.streetName
            : 'Selected on map',
        onChangeClicked: () => changeUrlByStepNumberv6(1, formConfig, navigate, 'location'),
      },
      {
        columnOne: 'Additional location information',
        columnTwo: formData.additionalInformation,
        onChangeClicked: () => changeUrlByStepNumberv6(1, formConfig, navigate, 'additionalInformation'),
      },
      {
        columnOne: 'Upload a photo',
        columnTwo: formData.uploadedFiles ? (
          <ul className="uploaded-photos">
            {formData.uploadedFiles.map((file, index) => (
              <li key={index}>{file.fileName}</li>
            ))}
          </ul>
        ) : (
          <React.Fragment />
        ),
        onChangeClicked: () => changeUrlByStepNumberv6(3, formConfig, navigate, 'uploadPhoto'),
      },
    ];

    if (problem) {
      tableData.splice(1, 0, {
        columnOne: 'Light type',
        columnTwo: type.assetName,
        onChangeClicked: () =>
          changeUrlByStepNumberv6(state.missingAssetChecked ? 2 : 1, formConfig, navigate, 'location'),
      });
      tableData.splice(4, 0, {
        columnOne: 'What is the problem?',
        columnTwo: problem ? problem.displayName : null,
        onChangeClicked: () => changeUrlByStepNumberv6(3, formConfig, navigate, 'whatIsTheProblem'),
        columnTwoClassName: 'sentence-case',
      });
    }

    // add light number if not missed (as then we don't have that info)
    if (state.missingAssetChecked === false) {
      tableData.splice(1, 0, {
        columnOne: 'Light number',
        columnTwo: formData.locationObject?.assetId,
        onChangeClicked: () => changeUrlByStepNumberv6(1, formConfig, navigate, 'location'),
      });
    }

    return tableData;
  };

  const submitIncident = async () => {
    const form = { ...state.formData };
    const lightingTypes = Object.values(formConfig.streetLightTypes);
    form.productId = state.missingAssetChecked ? formConfig.missingAssetProductId : formConfig.productId;
    form.subject = formConfig.subject;
    form.lightingTypeId = state.missingAssetChecked
      ? state.formData.lightTypeId
      : lightingTypes.filter((val) => val.unitType === form.unitType)[0].lightingTypeId;

    // Create the incident
    try {
      const result = await Services.createIncident(form);
      if (result.status === 200) {
        dispatch({ type: actions.updateIncidentObject, payload: result.data });
        dispatch({
          type: actions.updateStateFields,
          payload: { [fieldNames.formStarted]: true, [fieldNames.formCompleted]: true },
        });
        setExistingIncidents([]);
      }
    } catch {
      return false;
    }
  };

  // ----- CONFIRMATION PAGE -----
  const sendUpdateIncidentWithUser = async (email) => {
    await Services.updateIncidentWithUser(
      state.incident.referenceNumber,
      state.incident.secretReferenceNumber,
      state.incident.incidentId,
      email
    ).then((result) => {
      return result.status === 200 ? true : false;
    });
  };

  const handleUserRegistered = (userData) => {
    dispatch({ type: actions.updateStateFields, payload: { [fieldNames.userLoggedIn]: true } });
    // only update incident when user's email is not confirmed
    // save the incident into temp table while wait for the user to confirm his/her email.
    if (state.formCompleted && userData.isEmailConfirmed === false) {
      sendUpdateIncidentWithUser(userData.email);
    }
  };

  const handleSignInCallBack = () => {
    const isSignedIn = formConfig.userIsLoggedIn();
    if (isSignedIn) {
      sendUpdateIncidentWithUser('');
    }
    // after sign in succssfully, navigate back to incident creation confirmation page
    navigate(formConfig.stepUrls[formConfig.totalSteps]);
  };

  // --- render builders ----
  const formRouteInfo = {
    formStarted: state.formStarted,
    formCompleted: state.formCompleted,
    formStartUrl: formConfig.stepUrls[0],
    formEndUrl: formConfig.stepUrls[formConfig.totalSteps],
    location: location,
  };

  const locationSelectText = (
    <>
      Search the map by postcode, street name, or your current location, to view nearby street lights.
      <br />
      Use the <span aria-hidden="true" className="esri-icon esri-icon-locate"></span> icon to report a problem at your
      current location.
      <br />
      Select the street light by clicking the icon on the map.
      <br />
      Select a reported problem to view details.
      <br />
    </>
  );

  /**
   * Calls service to see if any incidents exist on the street light asset.
   * SIDE EFFECT: populates the existingIncidents state object
   * @param {object} map feature and featureIntersect
   * @returns true if there is incidents
   */
  const incidentExistsCheck = async (e) => {
    // Happens on feature select
    const incidentsResult = await IncidentService.getExistingIncidentsForAsset({
      assetId: e.feature.attributes.Item_Id,
      productId: formConfig.productId,
    });
    if (formConfig.allowIncidentSelect) {
      setExistingIncidents(incidentsResult);
    }
    return incidentsResult.length > 0;
  };

  const hasProblemsLeftToReport = () => {
    const problems = getPossibleProblems();
    if (problems && formConfig.allowIncidentSelect) {
      // Filter through problems and find ones where no incident exists
      const r = problems.filter(
        (problem) => !existingIncidents.find(({ categoryId }) => problem.id === categoryId.toString())
      );
      return r.length > 0;
    }
    return true;
  };

  const hasProblemsLeft = () => {
    let problemsLeft = true;
    if (formConfig.allowIncidentSelect) {
      problemsLeft = hasProblemsLeftToReport();
    }
    return problemsLeft;
  };

  const handleCanClickNextOnLocationPage = () => hasProblemsLeft();

  const handleAdditionalInformation = () => {
    const result = hasProblemsLeft();
    if (result === true) return state.formData.additionalInformation;
    return undefined;
  };

  return (
    <IncidentFormContainer
      displayProgressBar={() => formConfig.shouldDisplayBasedOnStep(state.currentStep)}
      pageHeaderText={state.pageHeaderText}
      documentTitle={formConfig.documentTitle}
      totalSteps={formConfig.totalSteps}
      currentStep={state.currentStep}
      breadcrumbs={[{ url: clientMyAccountUrl.streetLighting.root, name: formConfig.pageHeaderText }]}>
      <Routes>
        <Route
          path="/"
          element={
            <EntryPage
              report={{
                text: 'problems with street lights and illuminated signs',
                url: 'https://www.york.gov.uk/ReportStreetLighting',
              }}
              informationArray={[
                {
                  text: 'which street lighting problems we cannot help with',
                  url: 'https://www.york.gov.uk/ReportStreetLighting#problemswecannothelp',
                },
                {
                  text: 'our street lighting response times',
                  url: 'https://www.york.gov.uk/ReportStreetLighting#streetlightingresponsetimes',
                },
              ]}
              goToNextPage={() => {
                dispatch({
                  type: actions.updateStateFields,
                  payload: { [fieldNames.formStarted]: true, [fieldNames.formCompleted]: false },
                });
                changeUrlByStepNumberv6(1, formConfig, navigate);
              }}
              onResetForm={handleResetForm}
            />
          }
        />

        <Route
          path={clientMyAccountUrl.streetLighting.step1}
          element={
            <ContinueFormOrReRoute {...formRouteInfo}>
              <LocationPageComponent
                goToNext={
                  state.missingAssetChecked
                    ? () => changeUrlByStepNumberv6(2, formConfig, navigate)
                    : () => changeUrlByStepNumberv6(3, formConfig, navigate)
                }
                goToPrevious={() => changeUrlByStepNumberv6(0, formConfig, navigate)}
                incidentLayerOptions={layerOptionsBuilder()}
                featureLayerOptions={featureLayerOptionsBuilder()}
                disableFreeSelect={true}
                locationObject={state.formData.locationObject}
                locationSelected={state.formData.locationSelected}
                locationSelectText={locationSelectText}
                handleFeatureClicked={handleFeatureClicked}
                onAdditionalInformationChange={(e) =>
                  dispatch({
                    type: actions.updateFormDataFields,
                    payload: { [fieldNames.additionalInformation]: e.target.value },
                  })
                }
                additionalInformation={handleAdditionalInformation()}
                additionalInformationHeaderText={
                  state.missingAssetChecked
                    ? 'Additional location information. If you know the light number, please include below.'
                    : null
                }
                clearSelectedPoint={clearSelectedFeature}
                incidentExistsCheck={incidentExistsCheck}
                incidentExistsAlertMessage="This light already has an incident reported and can therefore not have another."
                enablePointSelectionZoomLevels={formConfig.enablePointSelectionZoomLevels}
                missingAssetsOptions={getMissingAssetOptions()}
                onMapSelected={(location) => dispatch({ type: actions.updateLocationObject, payload: location })}
                getAllLayersDetailsOnClick={handleGetAllLayerAttributesOnClick}
                allowIncidentSelect={formConfig.allowIncidentSelect}
                showNextButton={handleCanClickNextOnLocationPage()}
                extraInformationUnderMap={
                  <IncidentsExistDisplay
                    headerText={generalHeader(formConfig.infoAlertSubjectName)}
                    footerText={
                      hasProblemsLeftToReport()
                        ? differentProblemFooter(formConfig.infoAlertSubjectName)
                        : cannotReportAnotherProblemFooter(formConfig.infoAlertSubjectName)
                    }
                    incidentsList={existingIncidents}
                    categorys={ConfigurationService.store.categoryIds.streetLighting}
                  />
                }
              />
            </ContinueFormOrReRoute>
          }
        />

        <Route
          path={clientMyAccountUrl.streetLighting.step2}
          element={
            <ContinueFormOrReRoute {...formRouteInfo}>
              <LightTypePageComponent
                goToPrevious={() => changeUrlByStepNumberv6(1, formConfig, navigate)}
                goToNext={() => changeUrlByStepNumberv6(3, formConfig, navigate)}
                lightTypeId={state.formData.lightTypeId}
                lightTypes={formConfig.missingAssetsLightTypes}
                streetLightTypes={formConfig.streetLightTypes}
                onLightTypeChange={(id) => {
                  dispatch({ type: actions.updateFormDataFields, payload: { [fieldNames.lightTypeId]: id } });
                  dispatch({
                    type: actions.updateFormDataFields,
                    payload: {
                      [fieldNames.unitType]: Object.values(formConfig.streetLightTypes).filter(
                        (val) => val.lightingTypeId === id
                      )[0].unitType,
                    },
                  });
                }}
              />
            </ContinueFormOrReRoute>
          }
        />

        <Route
          path={clientMyAccountUrl.streetLighting.step3}
          element={
            <ContinueFormOrReRoute {...formRouteInfo}>
              <ProblemPageComponent
                goToPrevious={
                  state.missingAssetChecked
                    ? () => changeUrlByStepNumberv6(2, formConfig, navigate)
                    : () => changeUrlByStepNumberv6(1, formConfig, navigate)
                }
                goToNext={() => changeUrlByStepNumberv6(4, formConfig, navigate)}
                onUploadFiles={(files) => dispatch({ type: actions.updateUploadFiles, payload: files })}
                onRemoveFile={(index) => dispatch({ type: actions.removeUploadedFile, payload: index })}
                problemId={state.formData.problemId}
                possibleProblems={getPossibleProblems}
                onProblemTypeChange={(value) =>
                  dispatch({ type: actions.updateFormDataFields, payload: { [fieldNames.problemId]: value } })
                }
                uploadedFiles={state.formData.uploadedFiles}
                existingIncidents={existingIncidents}
                productName={formConfig.infoAlertSubjectName}
              />
            </ContinueFormOrReRoute>
          }
        />

        <Route
          path={clientMyAccountUrl.streetLighting.step4}
          element={
            <ContinueFormOrReRoute {...formRouteInfo}>
              <SummaryPageComponent
                goToProblemPage={() => changeUrlByStepNumberv6(3, formConfig, navigate)}
                submitIncident={submitIncident}
                tableData={buildTableData()}
              />
            </ContinueFormOrReRoute>
          }
        />

        <Route
          path={clientMyAccountUrl.streetLighting.confirmation}
          element={
            <ContinueFormOrReRoute {...formRouteInfo}>
              <ConfirmationPage
                referenceNumber={state.incident.referenceNumber}
                onSignInClicked={() => navigate(clientMyAccountUrl.streetLighting.root + clientMyAccountUrl.signIn)}
                onRegisterClicked={() => navigate(clientMyAccountUrl.streetLighting.root + clientMyAccountUrl.register)}
                userLoggedIn={() => CYCServices.JWTAuth.isAuthenticated()}
                goToMyAccount={() => navigate(clientMyAccountUrl.dashboard)}
                restartFormUrl={formConfig.stepUrls[0]}
              />
            </ContinueFormOrReRoute>
          }
        />

        <Route
          path={clientMyAccountUrl.signIn}
          element={<SignInContainer onSuccessfulSignIn={handleSignInCallBack} />}
        />
        <Route
          path={`${clientMyAccountUrl.register}/*`}
          element={
            <RegistrationApp
              onSuccessfulRegistration={handleUserRegistered}
              extraSuccessfulContent={<p>Click 'Continue' to view the reference number for your reported problem.</p>}
              onContinueClicked={() =>
                navigate(clientMyAccountUrl.streetLighting.root + clientMyAccountUrl.streetLighting.confirmation)
              }
            />
          }
        />
      </Routes>
    </IncidentFormContainer>
  );
};

export default StreetLightingApp;
