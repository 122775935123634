import React from 'react';
import PropTypes from 'prop-types';

const ReportSearchCounterComponent = ({ currentMin, currentMax, max }) => (
  <span className="report-search-results-table__result-counter">
    Results:{' '}
    <strong>{max === 0 ? '0' : currentMin + 1}</strong> -{' '}
    <strong>{max === 0 ? '0' : currentMax + 1}</strong> of{' '}
    <strong>{max}</strong>
  </span>
);

ReportSearchCounterComponent.propTypes = {
  max: PropTypes.number.isRequired,
  currentMax: PropTypes.number.isRequired,
  currentMin: PropTypes.number.isRequired,
};

export default ReportSearchCounterComponent;
