import React from 'react';
import PropTypes from 'prop-types';

import { ButtonComponent } from 'cyc-react-ui-components';

import './error-box.component.scss';

const ErrorBoxComponent = ({
  children,
  jumpButton = true,
  text = 'Please check the required details are completed',
  ...props
}) => {
  const handleClick = () => {
    const elements = document.getElementsByClassName('error-cyc');
    if (elements.length > 0) {
      elements[0].scrollIntoView();
    }
  };
  return (
    <div className="cyc-input-errors-box" {...props} data-testid="error-box-component">
      <div className="cyc-input-errors-box__head">
        <h2 className="cyc-input-errors">{text}</h2>
        <div className="cyc-input-error__head__call-to-action">
          {jumpButton && (
            <ButtonComponent id="goToError" type="button" onClick={handleClick}>
              Jump to first error
            </ButtonComponent>
          )}
        </div>
      </div>
      <div className="cyc-input-errors-box__body">{children}</div>
    </div>
  );
};

ErrorBoxComponent.propTypes = {
  jumpButton: PropTypes.bool,
  children: PropTypes.node,
};

export default ErrorBoxComponent;

export { ErrorBoxComponent };
