import React, { useState, useEffect } from 'react';
import { CYCServices } from 'cyc-services';

import {
  DocumentTitleComponent,
  PageHeaderComponent,
  MyAccountPageComponent,
  clientMyAccountUrl,
  NetworkLoaderComponent,
  NetworkCatchErrorComponent,
  msgConstants,
} from '../../../../common';
import { postEmailConfirmation } from '../../../services/account.service';
import { ButtonComponent, ButtonClasses } from 'cyc-react-ui-components';
import { useNavigate, useSearchParams } from 'react-router-dom';

const ChangeEmailSuccessComponent = () => {
  const navigate = useNavigate();
  const [componentLoadPromise, setComponentLoadPromise] = useState();
  const [submitError, setSubmitError] = useState(false);
  const [submitErrorMessage, setSubmitErrorMessage] = useState('');
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const userId = searchParams.get('userId');
    const code = searchParams.get('code');

    // Log the user out
    CYCServices.JWTAuth.signOut();

    const componentLoadPromise = postEmailConfirmation(userId, code);
    setComponentLoadPromise(componentLoadPromise);
    componentLoadPromise
      .then((response) => {
        if (response.status !== 200) {
          setSubmitError(true);
          setSubmitErrorMessage(msgConstants.GENERIC_API_ERROR);
        }
      })
      .catch((error) => {
        setSubmitError(true);
        setSubmitErrorMessage(msgConstants.GENERIC_API_ERROR);
        error && console.error(error);
      });
  }, []);

  return (
    <DocumentTitleComponent title="My account - change email">
      <PageHeaderComponent text="My Account change email address confirmation" icon="icon-website" />
      <MyAccountPageComponent>
        {componentLoadPromise !== undefined && (
          <NetworkLoaderComponent request={componentLoadPromise}>
            <NetworkCatchErrorComponent didCatchError={submitError} customMessage={submitErrorMessage} />
            {submitError === false && (
              <p>Thank you for verifying your My Account email address. You can now sign in to My Account.</p>
            )}

            <div className="row">
              <div className="col">
                <ButtonComponent
                  type="button"
                  className={ButtonClasses.primary + ' float-right'}
                  onClick={() => navigate(clientMyAccountUrl.signIn)}>
                  Sign in
                </ButtonComponent>
              </div>
            </div>
          </NetworkLoaderComponent>
        )}
      </MyAccountPageComponent>
    </DocumentTitleComponent>
  );
};

export default ChangeEmailSuccessComponent;
