import { useRef, useState, useEffect } from 'react';

import AccordionInternalComponent from './accordion-internal/accordion-internal.component';
import { getParkingValidationData } from '../services/parking.service';

const MyParkingServicesAccordion = () => {
  const [validationFormData, setValidationFormData] = useState({
    session: '',
    customer: '',
    keyhash: '',
  });
  const [formReadyToPost, setFormReadyToPost] = useState(false);
  const validateWspRef = useRef();

  const handleSubmitValidateForm = async () => {
    const response = await getParkingValidationData();
    if (response.status === 200) {
      const dataToUpdate = { ...validationFormData };
      dataToUpdate.customer = response.data.customer;
      dataToUpdate.keyhash = response.data.keyhash;
      dataToUpdate.session = response.data.session;
      setValidationFormData(dataToUpdate);
    }

    setFormReadyToPost(true);
  };

  useEffect(() => {
    if (formReadyToPost === true) {
      validateWspRef.current.submit();
    }
  }, [formReadyToPost]);

  return (
    <>
      <AccordionInternalComponent
        customCallToAction={handleSubmitValidateForm}
        callToActionLink={{
          linkText: 'Access parking system',
          linkUrl: '#',
          isExternal: true,
        }}
        linksToRender={[]}
      />
      <form ref={validateWspRef} action="https://york.tarantopermits.com/validate" method="POST">
        <input type="hidden" id="session" name="session" value={validationFormData.session} />
        <input type="hidden" id="customer" name="customer" value={validationFormData.customer} />
        <input type="hidden" id="keyhash" name="keyhash" value={validationFormData.keyhash} />
      </form>
    </>
  );
};

export default MyParkingServicesAccordion;
