import WithAuthentication from 'common/components/with-authentication/with-authentication.component';
import React from 'react';
import { Link } from 'react-router-dom';

import {
  PageHeaderComponent,
  MyAccountPageComponent,
  DocumentTitleComponent,
  clientMyAccountUrl,
} from '../../../../common';

const ChangeEmailConfirmationComponent = () => (
  <WithAuthentication>
    <DocumentTitleComponent title="My account - change email">
      <PageHeaderComponent text="Change email address" icon="icon-website" />
      <MyAccountPageComponent>
        <p>Thank you for submitting your change of email address.</p>

        <p>We've sent you an email to verify your new email address. If you don't receive this email:</p>

        <ul>
          <li>
            <p>please check your junk folders in your email account</p>
          </li>
          <li>
            <p>you may have entered an incorrect email address in the change email address form</p>
          </li>
        </ul>

        <p>You'll need to verify your new email address before you are able to use it to sign in to My Account.</p>
        <p>You should also receive a message at your old email address confirming the change to your account.</p>

        <p>
          Please <a href="https://www.york.gov.uk/contactus">contact us</a> if you haven't received the verification
          email or need further assistance.
        </p>

        <p>
          Back to <Link to={clientMyAccountUrl.dashboard}>My Account</Link>.
        </p>
      </MyAccountPageComponent>
    </DocumentTitleComponent>
  </WithAuthentication>
);

export default ChangeEmailConfirmationComponent;
