import WithAuthentication from 'common/components/with-authentication/with-authentication.component';
import React from 'react';
import { Link } from 'react-router-dom';

import {
  PageHeaderComponent,
  MyAccountPageComponent,
  DocumentTitleComponent,
  clientMyAccountUrl,
} from '../../../common';

const ChangeAddressSuccessComponent = () => (
  <WithAuthentication>
    <DocumentTitleComponent title="My account - change address confirmation">
      <PageHeaderComponent text="Change of address confirmation" icon="icon-website" />
      <MyAccountPageComponent>
        <p>Thank you for submitting your change of address details.</p>

        <p>
          If you’re responsible for paying council tax you must{' '}
          <a href="https://www.york.gov.uk/info/20004/council_tax/6/moving_to_another_property">
            update your address in our Council Tax system
          </a>{' '}
          too, as this cannot be processed automatically.
        </p>

        <p>
          Back to <Link to={clientMyAccountUrl.dashboard}>My Account</Link>.
        </p>
      </MyAccountPageComponent>
    </DocumentTitleComponent>
  </WithAuthentication>
);

export default ChangeAddressSuccessComponent;
