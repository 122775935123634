import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { withFormik, Form } from 'formik';
import * as Yup from 'yup';

import {
  ButtonComponent,
  ButtonClasses,
  RadiobuttonInputComponent,
  InputErrorComponent,
} from 'cyc-react-ui-components';

import {
  ErrorBoxComponent,
  FileInputComponent,
  ErrorLabelHandlerComponent,
  setInitialPosition,
  AlertMessageComponent,
  OffensiveGraffitiInputComponent,
} from '../../../common';

import usePrevious from 'common/hooks/use-previous.hook';

const ProblemPage = ({
  isSubmitting,
  isValid,
  errors,
  values,
  touched,
  submitCount,
  setFieldValue,
  onProblemTypeChange,
  uploadedFiles,
  onUploadFiles,
  onRemoveFile,
  goToPrevious,
  utilityBoxId,
  onOffensiveGraffitiChanged,
  possibleGraffitiLocations,
}) => {
  const previousIsSubmitting = usePrevious(isSubmitting);
  const errorBox = useRef();

  useEffect(() => {
    setInitialPosition();
  }, []);

  useEffect(() => {
    if (previousIsSubmitting !== undefined && isSubmitting === true && isValid === false) {
      ReactDOM.findDOMNode(errorBox.current).scrollIntoView();
    }
  }, [isSubmitting]);

  return (
    <div className="page">
      <Form>
        <div ref={errorBox}></div>
        {Object.keys(errors).length > 0 && touched.problemId && <ErrorBoxComponent errors={errors}></ErrorBoxComponent>}

        <h2>About the problem</h2>
        <p>Tell us more about the graffiti.</p>
        <div className="row">
          <span id="graffitiOn">
            <ErrorLabelHandlerComponent
              touched={touched}
              errors={errors}
              text="What is the graffiti on?"
              tag="h3"
              id="problemId"
              submitCount={submitCount}
            />
            <RadiobuttonInputComponent
              name="problemId"
              value={values.problemId}
              onChange={(e) => {
                setFieldValue('problemId', e);
                onProblemTypeChange(e);
              }}
              options={possibleGraffitiLocations.map((location) => ({
                label: location.displayName,
                value: location.id,
              }))}
              error={errors.problemId && touched.problemId}
            />

            {values.problemId === utilityBoxId ? (
              <AlertMessageComponent>
                <p>We are not responsible for utility boxes in York; we'll inform the owner if you report graffiti.</p>
              </AlertMessageComponent>
            ) : null}
            {errors.problemId && touched.problemId && <InputErrorComponent>{errors.problemId}</InputErrorComponent>}
          </span>
        </div>

        <div className="row">
          <span id="isGraffitiOffensive">
            <OffensiveGraffitiInputComponent
              offensiveGraffiti={values.offensiveGraffiti}
              onOffensiveGraffitiChange={(value) => {
                setFieldValue('offensiveGraffiti', value);
                onOffensiveGraffitiChanged && onOffensiveGraffitiChanged(value);
              }}
              errors={errors}
              touched={touched}
              submitCount={submitCount}
            />
            {errors.offensiveGraffiti && touched.offensiveGraffiti && (
              <InputErrorComponent>{errors.offensiveGraffiti}</InputErrorComponent>
            )}
          </span>
        </div>

        <h2 className="mt-3" id="uploadPhoto">
          Upload a photo
        </h2>

        <FileInputComponent uploadedFiles={uploadedFiles} onUploadFiles={onUploadFiles} onRemoveFile={onRemoveFile} />

        <ButtonComponent type="submit" disabled={isSubmitting} className={ButtonClasses.primary + ' float-right'}>
          Next
        </ButtonComponent>
        <ButtonComponent type="button" onClick={goToPrevious}>
          Previous
        </ButtonComponent>
      </Form>
    </div>
  );
};

const ProblemPageComponent = withFormik({
  mapPropsToValues({ problemId, goToNext, offensiveGraffiti, uploadedFiles }) {
    return {
      problemId: problemId || '',
      goToNext: goToNext,
      offensiveGraffiti: offensiveGraffiti || undefined,
      uploadedFiles: uploadedFiles || [],
    };
  },
  validationSchema: Yup.object().shape({
    problemId: Yup.string().required("Please supply details for 'What is the graffiti on?'."),
    offensiveGraffiti: Yup.bool().nullable().required("Please supply details for 'Is the graffiti offensive?'."),
  }),
  handleSubmit(bag) {
    bag.goToNext();
  },
})(ProblemPage);

ProblemPageComponent.propTypes = {
  problemId: PropTypes.string,
  onProblemTypeChange: PropTypes.func.isRequired,
  onUploadFiles: PropTypes.func.isRequired,
  onRemoveFile: PropTypes.func.isRequired,
  uploadedFiles: PropTypes.array,
  offensiveGraffiti: PropTypes.bool,
  possibleGraffitiLocations: PropTypes.array.isRequired,
  utilityBoxId: PropTypes.string.isRequired,
};

export default ProblemPageComponent;
export { ProblemPage };
