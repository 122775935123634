import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { SignInContainer } from './index';
import { clientMyAccountUrl } from '../common';
import { CYCServices } from 'cyc-services';

const SignInRouter = ({ goBackToPreviousLocation }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (CYCServices.JWTAuth.isAuthenticated()) {
      navigate(clientMyAccountUrl.dashboard);
    }
  }, []);
  // if is authenticated, nav to dashboard
  return <SignInContainer goToPreviousLocation={goBackToPreviousLocation} />;
};

SignInRouter.propTypes = {
  goBackToPreviousLocation: PropTypes.bool,
};

export default SignInRouter;
