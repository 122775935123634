import { ConfigurationService } from 'cyc-configuration-service';
import { CYCServices } from 'cyc-services';

import { clientMyAccountUrl, processCategoryIdsSettingName } from '../../common';
import { mapConstants } from '../../common/constants/map.constants';

export default class FormConfiguration {
  baseUrl = clientMyAccountUrl.dogAndLitterBin;
  documentTitle = 'My Account - Report a dog waste and litter bin problem';
  dogAndLitterBinsFeatureLayerId = 'dogAndLitterBinsFeatures';
  dogAndLitterBinsFeatureLayerUrl = 'https://maps.york.gov.uk/arcgis/rest/services/Public/LV_TranStreetCare/MapServer/14';
  enablePointSelectionZoomLevels = mapConstants.enablePointSelectionZoomLevels;
  mapIncidentLayerId = 'dogAndLitterBinIncidents';
  mapIncidentLayerTitle = 'Problem description';
  pageHeaderText = 'Report a dog waste and litter bin problem';
  stepUrls = [
    `${this.baseUrl.root}`,
    `${this.baseUrl.root + this.baseUrl.step1}`,
    `${this.baseUrl.root + this.baseUrl.step2}`,
    `${this.baseUrl.root + this.baseUrl.step3}`,
    `${this.baseUrl.root + this.baseUrl.confirmation}`,
  ];
  subject = 'Dog/litter bins';
  totalSteps = 4;
  userIsLoggedIn = CYCServices.JWTAuth.isAuthenticated;
  missingAssetCheckBoxLabel = "I can't find the bin on the map";

  // Entry page constants
  whatToReportText = {
    text: 'problems with dog waste and litter bins',
    url: 'https://www.york.gov.uk/ReportDogWasteAndLitterBins',
  };

  beforeYouBeginText = [
    {
      text: 'which dog waste and litter bin problems we can help with ',
      url: 'https://www.york.gov.uk/ReportDogWasteAndLitterBins#problemswecanhelpwith',
    },
    {
      text: 'our dog and litter bin response times',
      url: 'https://www.york.gov.uk/ReportDogWasteAndLitterBins#responsetimes',
    },
  ];

  // Values from configuration service
  binTypes = [];
  binNames = [];
  categories = [];
  productId;

  constructor() {
    const { productId, categoryIds, binTypes, binNames, missingAssetProductId } =
      ConfigurationService.getEnvironmentSettingByName(processCategoryIdsSettingName.dogAndLitterBins);

    this.productId = productId;
    this.missingAssetProductId = missingAssetProductId;
    this.categories = Object.values(categoryIds);
    this.binTypes = binTypes;
    this.binNames = binNames;
  }

  shouldDisplayBasedOnStep = (number) => number > 0 && number <= this.totalSteps;
}

export const testName = 'DogBins';
