import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './report-search-input.component.scss';
import { ButtonComponent, ButtonClasses, TextInputComponent } from 'cyc-react-ui-components';

const ReportSearchInputComponent = ({ onSearch }) => {
  const [searchValue, setSearchValue] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSearch(searchValue);
  };

  return (
    <div className="report-search-input">
      <form onSubmit={handleSubmit}>
        <label htmlFor="search">
          <small>Search your report history by entering your reference number or keywords here</small>
        </label>
        <TextInputComponent
          type="search"
          id="search"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <ButtonComponent type="submit" className={ButtonClasses.primary}>
          Search
        </ButtonComponent>
      </form>
    </div>
  );
};

ReportSearchInputComponent.propTypes = {
  onSearch: PropTypes.func.isRequired,
};

export default ReportSearchInputComponent;
