import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ConfigurationService, FeatureFlagsService } from 'cyc-configuration-service';
import appInsights, { SeverityLevel } from 'cyc-application-insights-react';
import { CYCServices } from 'cyc-services';
import { createBrowserHistory } from 'history';
import { name as app_name, version as app_version } from '../package.json';

import registerServiceWorker, { unregister } from './registerServiceWorker';
import {
  FooterComponent,
  clientMyAccountUrl,
  ErrorBoundaryComponent,
  cycConfigurationUrls,
  isEnabled,
  SiteLoaderComponent,
  DownForMaintenance,
} from './common';

import HeaderComponent from 'common/components/header/header.component';
import SiteRouter from './site-router/site.router';
import AutoLogoutDialog from './common/components/dialog/auto-logout-dialog.component';
import sessionActivityService from './common/services/session-activity.service';
import { myAccountSessionConstants } from './common/constants/my-account-session.constants';

import MaintenanceApp from './maintenance/maintenance.app';

import './app.scss';

const App = () => {
  let history = createBrowserHistory();
  const location = useLocation();
  const navigate = useNavigate();
  const [configurationLoaded, setConfigurationLoaded] = useState(false);
  const [featureFlagsLoaded, setFeatureFlagsLoaded] = useState(false);
  const [downForMaintenance, setDownForMaintenance] = useState(false);
  const [session, setSession] = useState({ isTimingOut: false, timeRemaining: -1 });

  const message = app_name + ': v' + app_version;
  console.log(message);

  const resetSessionState = () => {
    setSession({
      isTimingOut: false,
      timeRemaining: -1,
    });
  };

  // Reset the session activity state
  const handleSessionRefresh = useCallback(async () => {
    // Renew the session token
    const url = `${ConfigurationService.store.apiUrl}/api/Account/JwtRenew`;
    await CYCServices.JWTAuth.renewToken(url);

    sessionActivityService.refresh();
    resetSessionState();
  }, []);

  // Called from the sessionActivityService with the number of seconds remaining before timing out
  const handleSessionWarning = (timeRemaining) => {
    setSession({
      isTimingOut: true,
      timeRemaining: timeRemaining,
    });
  };

  const handleLogOut = useCallback(() => {
    sessionActivityService.end();
    resetSessionState();

    const signOutPath = `${ConfigurationService.store.apiUrl}/api/Account/Logout`;
    CYCServices.JWTAuth.signOut(signOutPath);
    navigate(clientMyAccountUrl.signIn);
  }, []);

  // The method called from AutoLogoutDialog when either the buttons are clicked
  // or the remainingTime prop is less than 1
  const handleAutoLogoutDialogClose = (result) => {
    if (result === true) {
      handleSessionRefresh();
    } else {
      handleLogOut();
    }
  };

  const AppStatus = useMemo(
    () => () => {
      if (downForMaintenance) {
        return <MaintenanceApp />;
      } else if (configurationLoaded === true && featureFlagsLoaded === true) {
        return <SiteRouter />;
      }
      return <SiteLoaderComponent />;
    },
    [downForMaintenance, configurationLoaded, featureFlagsLoaded]
  );

  useEffect(() => {
    const initialiseApp = async () => {
      try {
        // Set up the session activity service & initialise it
        const sessionOptions = {
          duration: myAccountSessionConstants.duration,
          rateLimit: myAccountSessionConstants.rateLimit,
          warningDuration: myAccountSessionConstants.warningDuration,
          refreshCallback: handleSessionRefresh,
          warningCallback: handleSessionWarning,
          timedOutCallback: handleLogOut,
          eventListeners: myAccountSessionConstants.eventListeners,
        };
        sessionActivityService.initialise(sessionOptions);

        // Start Debug
        console.log('my-account CYC Config Set: ', process.env.REACT_APP_CYC_CONFIGURATION_SET);
        // End Debug

        await ConfigurationService.load(
          `${process.env.REACT_APP_CYC_CONFIGURATION_URL}`,
          'configuration',
          process.env.REACT_APP_CYC_CONFIGURATION_SET
        );
        appInsights.initialize(history, ConfigurationService.store.applicationInsightsKey, defaults);

        await FeatureFlagsService.load(
          `${process.env.REACT_APP_CYC_FEATURE_FLAGS_URL}${cycConfigurationUrls.features}`,
          'my-account',
          process.env.REACT_APP_CYC_FEATURE_FLAGS_SET
        );
        // If the user is authenticated then begin the sessionActivityService
        if (CYCServices.JWTAuth.isAuthenticated()) {
          sessionActivityService.begin();
        }

        setConfigurationLoaded(true);
        setFeatureFlagsLoaded(true);
        setDownForMaintenance(isEnabled(FeatureFlagsService.getFeatureFlagByName(DownForMaintenance)));

        if (ConfigurationService.store.registerServiceWorker === true) {
          registerServiceWorker();
        } else {
          unregister();
        }

        const defaults = { severityLevel: SeverityLevel.Error };

        appInsights.trackEvent({ name: 'My Account initialized' });
      } catch (err) {
        if (appInsights.initialized) {
          // Error is to do with feature flags because if it was configuration then app insights wouldnt even load...
          appInsights.trackException({
            exception: new Error('Uncaught exception when loading feature flag service'),
            properties: { err },
          });
        }
        setConfigurationLoaded(true);
        setFeatureFlagsLoaded(true);
      }
    };
    initialiseApp();
    return () => sessionActivityService.remove();
  }, [handleLogOut, handleSessionRefresh]);

  useEffect(() => {
    if (featureFlagsLoaded === true) {
      // We reload to we ensure we get the newest values from the api.
      FeatureFlagsService.load(
        `${process.env.REACT_APP_CYC_FEATURE_FLAGS_URL}${cycConfigurationUrls.features}`,
        'my-account',
        process.env.REACT_APP_CYC_FEATURE_FLAGS_SET
      )
        .then(() => setDownForMaintenance(isEnabled(FeatureFlagsService.getFeatureFlagByName(DownForMaintenance))))
        .catch((err) => {
          if (appInsights.initialized) {
            // Error is to do with feature flags because if it was configuration then app insights wouldnt even load...
            appInsights.trackException({
              exception: new Error('Uncaught exception when loading feature flag service'),
              properties: { err },
            });
          }
        });
    }
  }, [location, featureFlagsLoaded, handleLogOut, handleSessionRefresh]);

  return (
    <ErrorBoundaryComponent>
      <header id="pageTop">
        <HeaderComponent onLogOut={handleLogOut} />
      </header>
      <main className="mainBody">
        <AppStatus />
      </main>
      <footer>
        <FooterComponent />
      </footer>
      <AutoLogoutDialog
        isOpen={session.isTimingOut}
        timeRemaining={session.timeRemaining}
        onClose={handleAutoLogoutDialogClose}
      />
    </ErrorBoundaryComponent>
  );
};

export default App;
