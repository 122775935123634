import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withFormik, Form } from 'formik';
import * as Yup from 'yup';
import { ErrorBoxComponent, ErrorLabelHandlerComponent, setInitialPosition } from '../../../common';
import {
  RadiobuttonInputComponent,
  InputErrorComponent,
  ButtonClasses,
  ButtonComponent,
} from 'cyc-react-ui-components';

const ProblemWithPageForm = (props) => {
  const errorBox = React.createRef();
  useEffect(() => {
    setInitialPosition();
  }, []);

  return (
    <Form>
      <div ref={errorBox}></div>
      {Object.keys(props.errors).length > 0 && props.touched.typeId && (
        <ErrorBoxComponent errors={props.errors}></ErrorBoxComponent>
      )}
      <h2 id="aboutTheProblem">About the problem</h2>
      <div className="row">
        <span>
          <ErrorLabelHandlerComponent
            touched={props.touched}
            errors={props.errors}
            text="What is the problem with?"
            id="typeId"
            submitCount={props.submitCount}
          />
          <RadiobuttonInputComponent
            name="typeId"
            value={props.values.typeId}
            onChange={(e) => {
              props.setFieldValue('typeId', e);
              props.onVegetationTypeChange(e);
            }}
            error={props.errors.typeId && props.touched.typeId}
            options={props.possibleVegetationTypes.map((type) => ({
              label: type.displayName,
              value: type.id,
            }))}
          />
          {props.errors.typeId && props.touched.typeId && (
            <InputErrorComponent>{props.errors.typeId}</InputErrorComponent>
          )}
        </span>
      </div>

      <ButtonComponent type="submit" disabled={props.isSubmitting} className={ButtonClasses.primary + ' float-right'}>
        Next
      </ButtonComponent>
      <ButtonComponent type="button" onClick={props.goToPrevious}>
        Previous
      </ButtonComponent>
    </Form>
  );
};

const ProblemWithPageComponent = withFormik({
  mapPropsToValues({ vegetationTypeId, goToNext }) {
    return {
      typeId: vegetationTypeId || '',
      goToNext: goToNext,
    };
  },
  validationSchema: Yup.object().shape({
    typeId: Yup.string().required("Please supply details for 'What is the problem with?'."),
  }),
  handleSubmit(bag) {
    bag.goToNext();
  },
})(ProblemWithPageForm);

ProblemWithPageComponent.propTypes = {
  vegetationTypeId: PropTypes.string,
  onVegetationTypeChange: PropTypes.func.isRequired,
  possibleVegetationTypes: PropTypes.array.isRequired,
  goToPrevious: PropTypes.func,
};

export default ProblemWithPageComponent;
