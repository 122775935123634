import { ConfigurationService } from 'cyc-configuration-service';
import { CYCServices } from 'cyc-services';

import {
  clientMyAccountUrl,
  categorySettingNames,
  categoryIdsSettingName,
  processCategoryIdsSettingName,
} from '../../common';
import { mapConstants } from '../../common/constants/map.constants';

export class FormConfiguration {
  // private
  sizeOfWasteConst = {
    SingleBlackBag: { id: '1', name: 'Single black bag' },
    CarbootLoad: { id: '2', name: 'Car boot load' },
    SmallvanLoad: { id: '3', name: 'Small van load' },
    LargevanLoad: { id: '4', name: 'Large van load' },
    Multiplevanloads: { id: '5', name: 'Multiple van loads' },
  };
  // public
  sizeOfWasteArray = [
    this.sizeOfWasteConst.SingleBlackBag,
    this.sizeOfWasteConst.CarbootLoad,
    this.sizeOfWasteConst.SmallvanLoad,
    this.sizeOfWasteConst.LargevanLoad,
    this.sizeOfWasteConst.Multiplevanloads,
  ];
  pageHeaderText = 'Report a fly-tipping problem';
  documentTitle = 'My Account - Report a fly-tipping problem';
  totalSteps = 4;
  productId = 80;
  subject = 'Fly-tipping';
  // Map constants
  mapIncidentLayerId = 'flyTippingIncidents';
  mapIncidentLayerTitle = 'Problem description';

  baseUrl = clientMyAccountUrl.flyTipping;
  stepUrls = [
    `${this.baseUrl.root}`,
    `${this.baseUrl.root + this.baseUrl.step1}`,
    `${this.baseUrl.root + this.baseUrl.step2}`,
    `${this.baseUrl.root + this.baseUrl.step3}`,
    `${this.baseUrl.root + this.baseUrl.confirmation}`,
  ];

  problemOtherId = () =>
    ConfigurationService.getDrillDownSettingValue([
      categoryIdsSettingName,
      processCategoryIdsSettingName.flyTipping,
      categorySettingNames.flyTipping.other,
      'id',
    ]);

  getFlyTippingCategories = () => {
    return Object.values(
      ConfigurationService.getDrillDownSettingValue([categoryIdsSettingName, processCategoryIdsSettingName.flyTipping])
    );
  };

  userIsLoggedIn = CYCServices.JWTAuth.isAuthenticated;
  enablePointSelectionZoomLevels = mapConstants.enablePointSelectionZoomLevels;

  shouldDisplayBasedOnStep = (number) => number > 0 && number <= this.totalSteps;

  // -----------------------
}
