import axios from 'axios';
import appInsights from 'cyc-application-insights-react';

// Takes a point and returns the nearest street within 20 meters
export const getStreetFromPoint = async (x, y, spatialReferenceWkid = 27700) => {
  const uri = encodeURI(
    'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/reverseGeocode?f=json' +
      '&location={"x":' +
      x +
      ',"y":' +
      y +
      ',"spatialReference":{"wkid":' +
      spatialReferenceWkid +
      '}}&distance=20)'
  );

  return await axios
    .get(uri)
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error('Could not find street for selected point');
      }
    })
    .then((location) => {
      if (location.address.Address !== undefined && location.address.Address !== '') {
        // Find the first letter and return everything else after it
        // This is so we dont get house numbers but we keep road numbers e.g A64
        const splitter = location.address.Address.match(/[a-zA-Z]/);
        const find = location.address.Address.indexOf(splitter.join());
        const restOfString = location.address.Address.substring(find);
        return restOfString.trim();
      }
      return null;
    })
    .catch((ex) => {
      console.error(ex);
      appInsights.trackException({ exception: ex, properties: { x, y, spatialReferenceWkid } });
      throw ex;
    });
};

// Takes a point and gets the TOID underneath it.
export const getTOIDFromPoint = async (x, y, spatialReferenceWkid = 27700) => {
  const uri = encodeURI(
    'https://maps.york.gov.uk/arcgis/rest/services/Basemaps/cache_bw/MapServer/12/query' +
      `?geometry=${x},${y}` +
      `&inSR=${spatialReferenceWkid}` +
      `&outSR=${spatialReferenceWkid}` +
      '&outFields=Toid&returnGeometry=false&geometryType=esriGeometryPoint&f=json'
  );

  return await axios
    .get(uri)
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error('Could not find a TOID');
      }
    })
    .then((data) => {
      if (data.features.length > 0) {
        return data.features[0].attributes.Toid;
      }
    })
    .catch((ex) => {
      console.error(ex);
      appInsights.trackException({
        exception: ex,
        properties: {
          x,
          y,
          spatialReferenceWkid,
        },
      });
      throw ex;
    });
};

export const getParishFromPointAsync = async (x, y, spatialReferenceWkid = 27700) => {
  const uri = encodeURI(
    'https://maps.york.gov.uk/arcgis/rest/services/Public/LV_CouncilDemocracy/MapServer/9/query' +
      `?geometry={"x":${x},"y":${y}}` +
      '&geometryType=esriGeometryPoint' +
      `&inSR=${spatialReferenceWkid}` +
      '&outFields=NAME' +
      '&returnGeometry=false' +
      '&f=json'
  );

  return axios
    .get(uri)
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error('Could not find a Parish');
      }
    })
    .then((data) => (data.features.length > 0 ? data.features[0].attributes.NAME : undefined))
    .catch((ex) => {
      console.error(ex);
      appInsights.trackException({
        exception: ex,
        properties: {
          x,
          y,
          spatialReferenceWkid,
        },
      });
      throw ex;
    });
};

export const getUSRNFromPoint = async (streetName) => {
  const uri = encodeURI(
    'https://maps.york.gov.uk/arcgis/rest/services/Public/LV_TranStreetCare/MapServer/5/' +
      'query?' +
      'where=' +
      `&text=${streetName}` +
      '&objectIds=' +
      '&time=' +
      '&geometry=' +
      '&geometryType=esriGeometryPoint' +
      '&inSR=27700' +
      '&spatialRel=esriSpatialRelIntersects' +
      '&relationParam=' +
      '&outFields=usrn' +
      '&returnGeometry=true' +
      '&returnTrueCurves=false' +
      '&maxAllowableOffset=' +
      '&geometryPrecision=' +
      '&outSR=&returnIdsOnly=false' +
      '&returnCountOnly=false' +
      '&orderByFields=' +
      '&groupByFieldsForStatistics=' +
      '&outStatistics=&returnZ=false' +
      '&returnM=false&gdbVersion=' +
      '&returnDistinctValues=false' +
      '&resultOffset=' +
      '&resultRecordCount=' +
      '&f=json'
  );

  return await axios
    .get(uri)
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error('Could not find street for selected point');
      }
    })
    .then((reference) => {
      // Return empty string if no USRN exists
      if (reference.features === undefined) {
        return null;
      }
      if (reference.features.length > 0) {
        return reference.features[0].attributes.usrn;
      }
      return null;
    })
    .catch((ex) => {
      console.error(ex);
      appInsights.trackException({ exception: ex, properties: { streetName } });
      throw ex;
    });
};

export const getWardFromPoint = async (x, y) => {
  const uri = encodeURI(
    'https://maps.york.gov.uk/arcgis/rest/services/Public/LV_CouncilDemocracy/MapServer/8/' +
      'query?where=' +
      '&objectIds=' +
      '&time=' +
      '&geometry=' +
      x +
      ',' +
      y +
      '&geometryType=esriGeometryPoint' +
      '&inSR=27700' +
      '&spatialRel=esriSpatialRelIntersects' +
      '&relationParam=' +
      '&outFields=NAME' +
      '&returnGeometry=false' +
      '&returnTrueCurves=false' +
      '&maxAllowableOffset=' +
      '&geometryPrecision=' +
      '&outSR=&returnIdsOnly=false' +
      '&returnCountOnly=false' +
      '&orderByFields=' +
      '&groupByFieldsForStatistics=' +
      '&outStatistics=&returnZ=false' +
      '&returnM=false&gdbVersion=' +
      '&returnDistinctValues=false' +
      '&resultOffset=' +
      '&resultRecordCount=' +
      '&f=json'
  );

  return await axios
    .get(uri)
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error('Could not find ward for selected point');
      }
    })
    .then((reference) => {
      // Return empty string if no ward exists
      if (reference.features.length > 0) {
        return reference.features[0].attributes.NAME.replace(/Ward/g, '').trim();
      }
      return '';
    })
    .catch((ex) => {
      console.error(ex);
      appInsights.trackException({ exception: ex, properties: { x, y } });
      throw ex;
    });
};

export const getPostcodeFromPointAsync = async (x, y) => {
  const uri = encodeURI(
    'https://datahub.esriuk.com/arcgis/rest/services/gb_locators/os_codepointopen_postcode_locator/GeocodeServer/reverseGeocode' +
      `?location=${x},${y}` +
      '&f=json'
  );

  const result = await axios
    .get(uri)
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error('Could not find postcode for selected point');
      }
    })
    .then((data) => {
      if (data === undefined || data.address === undefined || data.address.Full_Postcode === undefined) {
        return undefined;
      }

      return data.address.Full_Postcode;
    })
    .catch((ex) => {
      console.error(ex);
      appInsights.trackException({
        exception: ex,
        properties: {
          x,
          y,
        },
      });
      throw ex;
    });

  return result;
};
