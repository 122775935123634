import React, { useReducer, useCallback, useMemo, useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import FormConfiguration from './form-config';
import { reducer, initialState, actions, fieldNames } from '../incident-form.reducer';
import {
  IncidentFormContainer,
  ContinueFormOrReRoute,
  changeUrlByStepNumberv6,
  clientMyAccountUrl,
  apiMyAccountUrl,
  IncidentService,
} from 'common';
import { HeaderTextFromUrl } from '../helpers/header-text-from-url.helper';
import { ConfigurationService } from 'cyc-configuration-service';
import {
  incidentLayersOptionsBuilder,
  FieldsBuilder,
  popupTemplateBuilder,
  mapIncidentDetailsToAttributesBuilder,
} from '../helpers/incident-layer-options-builder.helper';

import { EntryPage, LocationPage, SummaryPage, ConfirmationPage } from '../components-shared';
import ProblemWithPageComponent from './components/problem-with-page.component';
import ProblemPageComponent from './components/problem-page.component';
import SignInContainer from 'sign-in/containers/sign-in.container';
import RegistrationApp from '../../registration/registration.app';

const VegetationApp = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const formConfig = useMemo(() => new FormConfiguration(), []);

  const initState = useMemo(
    () => initialState({ pageHeaderText: formConfig.pageHeaderText }),
    [formConfig.pageHeaderText]
  );
  const [state, dispatch] = useReducer(reducer, initState);
  const formData = state.formData;
  const getIncidentsUrl = `${ConfigurationService.store.apiUrl}${apiMyAccountUrl.getIncidents.VEGETATION}`;

  // --- render builders ----
  const formRouteInfo = () => {
    return {
      formStarted: state.formStarted,
      formCompleted: state.formCompleted,
      formStartUrl: formConfig.stepUrls[0],
      formEndUrl: formConfig.stepUrls[formConfig.totalSteps],
      location: location,
    };
  };

  // Change the header text on certain steps
  useEffect(() => {
    const indexOfStep = formConfig.stepUrls.indexOf(location.pathname);
    const header = HeaderTextFromUrl({
      url: location.pathname,
      initialHeaderText: formConfig.pageHeaderText,
    });

    dispatch({
      type: actions.updateStateFields,
      payload: {
        [fieldNames.currentStep]: indexOfStep,
        [fieldNames.pageHeaderText]: header,
      },
    });
  }, [location.pathname, formConfig.pageHeaderText, formConfig.stepUrls]);

  // ---- ENTRY PAGE ------
  const handleResetForm = useCallback(
    () =>
      dispatch({
        type: actions.resetForm,
        payload: initialState({ pageHeaderText: formConfig.pageHeaderText }),
      }),
    [formConfig.pageHeaderText]
  );

  // ---- ABOUT PROBLEM PAGE ----
  const handleVegetationTypeChange = (value) => {
    dispatch({ type: actions.resetLocation });
    dispatch({
      type: actions.updateFormDataFields,
      payload: { [fieldNames.vegetationTypeId]: value, [fieldNames.problemId]: '' },
    });
  };

  // ---- LOCATION PAGE ----
  // Build incident Layer options
  let fieldsBuilder = new FieldsBuilder();
  fieldsBuilder.addIncidentId();
  fieldsBuilder.addFaultType();
  fieldsBuilder.addIncidentReferenceNumber();
  fieldsBuilder.addCreatedTime();
  const incidentLayerOptions = incidentLayersOptionsBuilder({
    mapIncidentDetailsToAttributes: mapIncidentDetailsToAttributesBuilder,
    getIncidentsUrl: getIncidentsUrl,
    mapIncidentLayerId: formConfig.mapIncidentLayerId,
    popupTemplate: popupTemplateBuilder({
      mapIncidentLayerTitle: formConfig.mapIncidentLayerTitle,
      tableDataArray: [
        { header: 'Fault type', data: 'faultType' },
        { header: 'Date created', data: 'createdTime' },
      ],
      showAlreadyReportedMessage: true,
    }),
    fields: fieldsBuilder.getBuilderResult(),
  });

  // --- SUMMARY PAGE ---
  const buildTableData = () => {
    const type = formConfig.vegetationTypes.find((type) => type.id === formData.vegetationTypeId);
    const problem =
      type && type.categories && Object.values(type.categories).find((category) => category.id === formData.problemId);

    const tableData = [
      {
        columnOne: 'What is the problem with?',
        columnTwo: type && type.displayName ? type.displayName : null,
        onChangeClicked: () => changeUrlByStepNumberv6(1, formConfig, navigate, 'aboutTheProblem'),
        columnTwoClassName: 'sentence-case',
      },
      {
        columnOne: 'Location',
        columnTwo:
          formData.locationObject && formData.locationObject.streetName && formData.locationObject.streetName.length > 0
            ? formData.locationObject.streetName
            : 'Selected on map',
        onChangeClicked: () => changeUrlByStepNumberv6(2, formConfig, navigate, 'location'),
      },
      {
        columnOne: 'Additional location information',
        columnTwo: formData.additionalInformation,
        onChangeClicked: () => changeUrlByStepNumberv6(2, formConfig, navigate, 'additionalInformation'),
      },
      {
        columnOne: 'Additional details',
        columnTwo: formData.additionalProblemInformation ? formData.additionalProblemInformation : null,
        onChangeClicked: () => changeUrlByStepNumberv6(3, formConfig, navigate, 'additionalInformation'),
        columnTwoClassName: 'sentence-case',
      },
      {
        columnOne: 'Upload a photo',
        columnTwo: formData.uploadedFiles ? (
          <ul className="uploaded-photos">
            {formData.uploadedFiles.map((file, index) => (
              <li key={index}>{file.fileName}</li>
            ))}
          </ul>
        ) : (
          <React.Fragment />
        ),
        onChangeClicked: () => changeUrlByStepNumberv6(3, formConfig, navigate, 'uploadPhoto'),
      },
    ];

    // Only show the "What is the problem" row when shown on problem page
    if (problem) {
      tableData.splice(3, 0, {
        columnOne: 'What is the problem?',
        columnTwo: problem && problem.displayName ? problem.displayName : null,
        onChangeClicked: () => changeUrlByStepNumberv6(3, formConfig, navigate, 'whatIsTheProblem'),
        columnTwoClassName: 'sentence-case',
      });
    }

    return tableData;
  };

  const submitIncident = async () => {
    let form = { ...formData };
    form.productId = formConfig.productId;
    form.subject = formConfig.subject;

    // Create the incident
    try {
      const result = await IncidentService.createIncident(form);
      if (result.status === 200) {
        dispatch({ type: actions.updateIncidentObject, payload: result.data });
        dispatch({
          type: actions.updateStateFields,
          payload: { [fieldNames.formStarted]: true, [fieldNames.formCompleted]: true },
        });
      }
    } catch {
      return false;
    }
  };

  // ----- CONFIRMATION PAGE -----
  const sendUpdateIncidentWithUser = async (email) => {
    await IncidentService.updateIncidentWithUser(
      state.incident.referenceNumber,
      state.incident.secretReferenceNumber,
      state.incident.incidentId,
      email
    ).then((result) => {
      if (result.status === 200) {
        return true;
      } else {
        return false;
      }
    });
  };

  const handleUserRegistered = (userData) => {
    dispatch({ type: actions.updateStateFields, payload: { [fieldNames.userLoggedIn]: true } });
    // only update incident when user's email is not confirmed
    // save the incident into temp table while wait for the user to confirm his/her email.
    if (state.formCompleted && userData.isEmailConfirmed === false) {
      sendUpdateIncidentWithUser(userData.email);
    }
  };

  const handleSignInCallBack = () => {
    const isSignedIn = formConfig.userIsLoggedIn();
    if (isSignedIn) {
      sendUpdateIncidentWithUser('');
    }
    // after sign in succssfully, navigate back to incident creation confirmation page
    navigate(formConfig.stepUrls[formConfig.totalSteps]);
  };

  return (
    <IncidentFormContainer
      displayProgressBar={() => formConfig.shouldDisplayBasedOnStep(state.currentStep)}
      pageHeaderText={state.pageHeaderText}
      documentTitle={formConfig.documentTitle}
      totalSteps={formConfig.totalSteps}
      currentStep={state.currentStep}
      breadcrumbs={[{ url: clientMyAccountUrl.vegetation.root, name: formConfig.pageHeaderText }]}>
      <Routes>
        <Route
          path="/"
          element={
            <EntryPage
              report={formConfig.whatToReportText}
              informationHeaderText={formConfig.beforeYouBeginHeadingText}
              informationArray={formConfig.beforeYouBeginText}
              goToNextPage={() =>
                changeUrlByStepNumberv6(1, formConfig, navigate, false, true, () =>
                  dispatch({
                    type: actions.updateStateFields,
                    payload: {
                      [fieldNames.formStarted]: true,
                      [fieldNames.formCompleted]: false,
                    },
                  })
                )
              }
              onResetForm={handleResetForm}
              additionalInformation={<p>{formConfig.disclaimer}</p>}
            />
          }
        />
        <Route
          path={formConfig.baseUrl.step1}
          element={
            <ContinueFormOrReRoute {...formRouteInfo()}>
              <ProblemWithPageComponent
                goToPrevious={() => changeUrlByStepNumberv6(0, formConfig, navigate)}
                goToNext={() => changeUrlByStepNumberv6(2, formConfig, navigate)}
                vegetationTypeId={formData.vegetationTypeId}
                onVegetationTypeChange={handleVegetationTypeChange}
                possibleVegetationTypes={formConfig.vegetationTypes}
              />
            </ContinueFormOrReRoute>
          }
        />
        <Route
          path={formConfig.baseUrl.step2}
          element={
            <ContinueFormOrReRoute {...formRouteInfo()}>
              <LocationPage
                goToNext={() => changeUrlByStepNumberv6(3, formConfig, navigate)}
                goToPrevious={() => changeUrlByStepNumberv6(1, formConfig, navigate)}
                incidentLayerOptions={incidentLayerOptions}
                locationObject={formData.locationObject}
                locationSelected={formData.locationSelected}
                onMapSelected={(location) => dispatch({ type: actions.updateLocationObject, payload: location })}
                clearSelectedPoint={() => dispatch({ type: actions.resetLocation })}
                additionalInformation={formData.additionalInformation}
                onAdditionalInformationChange={(e) =>
                  dispatch({
                    type: actions.updateFormDataFields,
                    payload: { [fieldNames.additionalInformation]: e.target.value },
                  })
                }
                enablePointSelectionZoomLevels={formConfig.enablePointSelectionZoomLevels}
              />
            </ContinueFormOrReRoute>
          }
        />
        <Route
          path={formConfig.baseUrl.step3}
          element={
            <ContinueFormOrReRoute {...formRouteInfo()}>
              <ProblemPageComponent
                goToNextPage={() => changeUrlByStepNumberv6(4, formConfig, navigate)}
                goToPreviousPage={() => changeUrlByStepNumberv6(2, formConfig, navigate)}
                onProblemChange={(value) =>
                  dispatch({ type: actions.updateFormDataFields, payload: { [fieldNames.problemId]: value } })
                }
                onUploadFiles={(files) => dispatch({ type: actions.updateUploadFiles, payload: files })}
                onRemoveFile={(index) => dispatch({ type: actions.removeUploadedFile, payload: index })}
                uploadedFiles={formData.uploadedFiles}
                problemWithId={formData.vegetationTypeId}
                problemId={formData.problemId}
                additionalProblemInformation={formData.additionalProblemInformation}
                onAdditionalInformationChange={(e) =>
                  dispatch({
                    type: actions.updateFormDataFields,
                    payload: { [fieldNames.additionalProblemInformation]: e.target.value },
                  })
                }
                vegetationTypes={formConfig.vegetationTypes}
                uploadPhotoHelpText={formConfig.uploadPhotoHelpText}
              />
            </ContinueFormOrReRoute>
          }
        />
        <Route
          path={formConfig.baseUrl.step4}
          element={
            <ContinueFormOrReRoute {...formRouteInfo()}>
              <SummaryPage
                goToProblemPage={() => changeUrlByStepNumberv6(3, formConfig, navigate)}
                submitIncident={submitIncident}
                tableData={buildTableData()}
                additionalInformation={<p>{formConfig.disclaimer}</p>}
              />
            </ContinueFormOrReRoute>
          }
        />
        <Route
          path={formConfig.baseUrl.confirmation}
          element={
            <ContinueFormOrReRoute {...formRouteInfo()}>
              <ConfirmationPage
                referenceNumber={state.incident.referenceNumber}
                onSignInClicked={() => navigate(formConfig.baseUrl.root + clientMyAccountUrl.signIn)}
                onRegisterClicked={() => navigate(formConfig.baseUrl.root + clientMyAccountUrl.register)}
                userLoggedIn={() => formConfig.userIsLoggedIn()}
                goToMyAccount={() => navigate(clientMyAccountUrl.dashboard)}
                restartFormUrl={formConfig.baseUrl.root}
              />
            </ContinueFormOrReRoute>
          }
        />
        <Route
          path={clientMyAccountUrl.signIn}
          element={<SignInContainer onSuccessfulSignIn={handleSignInCallBack} />}
        />
        <Route
          path={`${clientMyAccountUrl.register}/*`}
          element={
            <RegistrationApp
              onSuccessfulRegistration={handleUserRegistered}
              extraSuccessfulContent={<p>Click 'Continue' to view the reference number for your reported problem.</p>}
              onContinueClicked={() => navigate(formConfig.baseUrl.root + formConfig.baseUrl.confirmation)}
            />
          }
        />
      </Routes>
    </IncidentFormContainer>
  );
};

export default VegetationApp;
