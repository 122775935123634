import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withFormik, Form } from 'formik';
import { ButtonComponent, ButtonClasses, SubmitButtonAxiosComponent } from 'cyc-react-ui-components';
import { ConfigurationService } from 'cyc-configuration-service';

import { SummaryTableComponent, msgConstants, apiMyAccountUrl } from '../../common';

const SummaryPage = ({ errors, tableData, goToProblemPage, isSubmitting, additionalInformation, handleSubmit }) => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="page">
      <Form>
        {errors.networkError !== undefined ? <h3 className="cyc-input-errors-radio">{errors.networkError}</h3> : null}
        <h2>Summary</h2>
        <div className="row">
          <SummaryTableComponent headerOne="Question" headerTwo="Answer" linkHeader="Change" rowData={tableData} />
        </div>

        <div className="row">
          <p>
            Check the details you've provided are correct before you 'Submit' this form. You can click on the edit icon
            or use the 'Previous' button to change your details.
          </p>
        </div>
        {additionalInformation ? <div className="row">{additionalInformation}</div> : null}

        <ButtonComponent onClick={goToProblemPage} type="button">
          Previous
        </ButtonComponent>
        <SubmitButtonAxiosComponent
          className={ButtonClasses.primary + ' float-right'}
          url={`${ConfigurationService.store.apiUrl}${apiMyAccountUrl.INCIDENTS}`}
          onClick={handleSubmit}
        />
      </Form>
    </div>
  );
};

const SummaryPageComponent = withFormik({
  mapPropsToValues: () => ({
    networkError: false,
  }),
  handleSubmit: async (values, { setFieldError, props }) => {
    const submitSuccess = await props.submitIncident();
    if (submitSuccess === false) {
      setFieldError('networkError', msgConstants.GENERIC_API_ERROR);
    }
  },
})(SummaryPage);

SummaryPageComponent.propTypes = {
  tableData: PropTypes.array.isRequired,
  errors: PropTypes.string,
  goToProblemPage: PropTypes.func.isRequired,
  submitIncident: PropTypes.func.isRequired,
  additionalInformation: PropTypes.any,
};

export default SummaryPageComponent;
