import React from 'react';
import BinCalendarComponent from '../../../common/components/bin-collections/bin-calendar.component';
import { MyAccountPageComponent, PageHeaderComponent } from '../../../common';

const MyCalendarContainer = () => {
  return (
    <React.Fragment>
      <PageHeaderComponent text="My collection calendar" />
      <MyAccountPageComponent className="bin-collections-lookup">
        <BinCalendarComponent></BinCalendarComponent>
      </MyAccountPageComponent>
    </React.Fragment>
  );
};

export default MyCalendarContainer;
