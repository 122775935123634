import React from 'react';

import { MyAccountPageComponent, PageHeaderComponent, DocumentTitleComponent } from '../common';

const MaintenanceApp = ({ message }) => {
  return (
    <>
      <PageHeaderComponent text="Down for maintenance" />
      <MyAccountPageComponent>
        <DocumentTitleComponent title="Down for maintenance" />
        <p>
          {message ||
            "We're making essential system updates to this part of our website; our apologies for any inconvenience caused. We're working to restore this service as quickly as possible."}
        </p>
      </MyAccountPageComponent>
    </>
  );
};

export default MaintenanceApp;
