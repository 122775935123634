import React from 'react';
import './progress-bar.component.scss';

const ProgressBarComponent = ({ totalSteps, currentStep }) =>
  currentStep === 0 ||
  currentStep === null ||
  totalSteps === 0 ||
  totalSteps === null ||
  currentStep === undefined ||
  totalSteps === undefined ? (
    <React.Fragment />
  ) : (
    <React.Fragment>
      <div className="progress-bar">
        <div className="progress-bar__filler" style={{ width: (currentStep * 100) / totalSteps + '%' }}></div>
      </div>
      <p>
        Page <b>{currentStep}</b> of <b>{totalSteps}</b>. You are <b>{Math.floor((currentStep * 100) / totalSteps)}%</b>{' '}
        complete.
      </p>
    </React.Fragment>
  );

export default ProgressBarComponent;
