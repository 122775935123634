import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ButtonComponent, ButtonClasses } from 'cyc-react-ui-components';

const AccordionInternalComponent = ({ linksToRender, callToActionLink, customCallToAction }) => {
  const [firstColumnLinks, setFirstColumnLinks] = useState([]);
  const [secondColumnLinks, setSecondColumnLinks] = useState([]);

  useEffect(() => {
    const linksLength = linksToRender.length;
    const halfLinksLength = linksLength / 2;

    let firstColumnLinks;
    let secondColumnLinks;

    if (halfLinksLength === Math.round(halfLinksLength)) {
      firstColumnLinks = linksToRender.slice(0, halfLinksLength);
      secondColumnLinks = linksToRender.slice(halfLinksLength);
    } else {
      firstColumnLinks = linksToRender.slice(0, Math.ceil(halfLinksLength));
      secondColumnLinks = linksToRender.slice(Math.ceil(halfLinksLength));
    }
    setFirstColumnLinks(firstColumnLinks);
    setSecondColumnLinks(secondColumnLinks);
  }, [linksToRender]);

  return (
    <div className="row">
      <div className="col-sm-12 col-md-4">
        {callToActionLink && (
          <ButtonComponent
            className={ButtonClasses.primary}
            type="button"
            onClick={() => (customCallToAction ? customCallToAction() : (window.location = callToActionLink.linkUrl))}>
            {callToActionLink.linkText}
          </ButtonComponent>
        )}
      </div>
      <div className="col-sm-12 col-md-4">
        <ul>
          {firstColumnLinks.map((link) => {
            if (link.isExternal) {
              return (
                <li key={link.linkText}>
                  <a href={link.linkUrl}>{link.linkText}</a>
                </li>
              );
            } else {
              return (
                <li key={link.linkText}>
                  <Link to={link.linkUrl}>{link.linkText}</Link>
                </li>
              );
            }
          })}
        </ul>
      </div>
      <div className="col-sm-12 col-md-4">
        <ul>
          {secondColumnLinks.map((link) => {
            if (link.isExternal) {
              return (
                <li key={link.linkText}>
                  <a href={link.linkUrl}>{link.linkText}</a>
                </li>
              );
            } else {
              return (
                <li key={link.linkText}>
                  <Link to={link.linkUrl}>{link.linkText}</Link>
                </li>
              );
            }
          })}
        </ul>
      </div>
    </div>
  );
};

AccordionInternalComponent.propTypes = {
  callToActionLink: PropTypes.shape({
    linkText: PropTypes.string.isRequired,
    linkUrl: PropTypes.string.isRequired,
    isExternal: PropTypes.bool.isRequired,
  }),
  customCallToAction: PropTypes.func,
  linksToRender: PropTypes.arrayOf(
    PropTypes.shape({
      linkText: PropTypes.string.isRequired,
      linkUrl: PropTypes.string.isRequired,
      isExternal: PropTypes.bool.isRequired,
    })
  ),
};

export default AccordionInternalComponent;
