import axios from 'axios';
import { ConfigurationService } from 'cyc-configuration-service';

import appInsights from 'cyc-application-insights-react';

import { CYCServices } from 'cyc-services';
import { apiMyAccountUrl } from '../../common/constants/my-account-urls.constants';

export const getParkingValidationData = async () => {
  const url = `${ConfigurationService.store.apiUrl}${apiMyAccountUrl.GET_PARKING_VALIDATION_DATA}`;

  const config = {
    headers: { Authorization: `Bearer ${CYCServices.JWTAuth.getToken()}` },
  };
  try {
    return axios.post(url, {}, config);
  } catch (ex) {
    console.error(ex);
    appInsights.trackException({ exception: ex });
    throw ex;
  }
};
