import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ConfigurationService } from 'cyc-configuration-service';

import ReportSearchInputComponent from '../../components/report-search-input/report-search-input.component';
import ReportSearchResultsTableComponent from '../../components/report-search-results-table/report-search-results-table.component';
import { getReports } from '../../services/my-reports.service';
import {
  NetworkLoaderComponent,
  DocumentTitleComponent,
  MyAccountPageComponent,
  PageHeaderComponent,
  clientMyAccountUrl,
  incidentSourceIdsSettingName,
  incidentSourceSettingNames,
} from '../../../common';

import './MyReportsIndex.container.scss';

const MyReportsIndexContainer = () => {
  const [reports, setReports] = useState([]);
  const [searchValue, setSearchValue] = useState();
  const [reportsPromise, setReportsPromise] = useState();
  const [webChatSourceId, setWebChatSourceId] = useState();

  useEffect(() => {
    const initialise = async () => {
      const reportsPromise = getReports()
        .then(setReports);
      setReportsPromise(reportsPromise);
    };
    const webchatSourceSettings = ConfigurationService.getDrillDownSettingValue([
      incidentSourceIdsSettingName,
      incidentSourceSettingNames.webchat,
    ]);
    const webChatSourceId = webchatSourceSettings ? webchatSourceSettings.id : undefined;
    setWebChatSourceId(webChatSourceId);
    initialise();
  }, []);

  const handleSearch = (searchValue) => {
    if (searchValue !== undefined && searchValue.length > 0) {
      setSearchValue(searchValue);
    } else {
      setSearchValue(undefined);
    }
  };

  const results = useMemo(() => {
    const lowerCaseSearchValue = searchValue !== undefined ? searchValue.toLowerCase() : undefined;
    if (lowerCaseSearchValue !== undefined) {
      return reports.filter((report) => {
        if (
          report.reference.toLowerCase().indexOf(lowerCaseSearchValue) > -1 ||
          report.subject.toLowerCase().indexOf(lowerCaseSearchValue) > -1 ||
          report.location.toLowerCase().indexOf(lowerCaseSearchValue) > -1 ||
          report.status.toLowerCase().indexOf(lowerCaseSearchValue) > -1
        ) {
          return true;
        } else return false;
      });
    }
    return reports;
  }, [reports, searchValue]);

  return (
    <React.Fragment>
      <PageHeaderComponent text="My reports" icon="icon-website" />
      <MyAccountPageComponent>
        <DocumentTitleComponent title="My Account - my reports">
          <ReportSearchInputComponent onSearch={handleSearch} />
          {reportsPromise && (
            <NetworkLoaderComponent request={reportsPromise}>
              <ReportSearchResultsTableComponent results={results} webchatSourceId={webChatSourceId} />
            </NetworkLoaderComponent>
          )}
          <p>
            <Link className="link-inline-with-button" to={clientMyAccountUrl.dashboard}>
              Back to My Account
            </Link>
          </p>
        </DocumentTitleComponent>
      </MyAccountPageComponent>
    </React.Fragment>
  );
};

export default MyReportsIndexContainer;
