import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { withFormik, Form } from 'formik';
import * as Yup from 'yup';

import {
  RadiobuttonInputComponent,
  InputErrorComponent,
  ButtonComponent,
  ButtonClasses,
} from 'cyc-react-ui-components';

import { ErrorBoxComponent, FileInputComponent, ErrorLabelHandlerComponent, setInitialPosition } from '../../../common';

import usePrevious from 'common/hooks/use-previous.hook';

const ProblemPage = ({
  isSubmitting,
  isValid,
  values,
  errors,
  touched,
  submitCount,
  setFieldValue,
  onProblemTypeChange,
  uploadedFiles,
  onUploadFiles,
  onRemoveFile,
  goToPrevious,
  possibleProblems
}) => {
  const previousIsSubmitting = usePrevious(isSubmitting);
  const errorBox = useRef();

  useEffect(() => {
    setInitialPosition();
  }, []);

  useEffect(() => {
    if (previousIsSubmitting !== undefined && isSubmitting === true && isValid === false) {
      ReactDOM.findDOMNode(errorBox.current).scrollIntoView();
    }
  }, [isSubmitting]);

  return (
    <div className="page">
      <Form>
        <div ref={errorBox}></div>
        {Object.keys(errors).length > 0 && touched.problemId && <ErrorBoxComponent errors={errors}></ErrorBoxComponent>}

        <h2>About the problem</h2>
        <p>Tell us what needs cleaning up.</p>
        <div className="row">
          <span id="whatIsTheProblem">
            <ErrorLabelHandlerComponent
              touched={touched}
              errors={errors}
              text="What is the problem"
              id="problemId"
              submitCount={submitCount}
            />
            <RadiobuttonInputComponent
              name="problemId"
              value={values.problemId}
              onChange={(e) => {
                setFieldValue('problemId', e);
                onProblemTypeChange(e);
              }}
              error={errors.problemId && touched.problemId}
              options={possibleProblems.map((problem) => ({
                label: problem.displayName,
                value: problem.id,
              }))}
            />
            {errors.problemId && touched.problemId && <InputErrorComponent>{errors.problemId}</InputErrorComponent>}
          </span>
        </div>

        <h2 className="mt-3" id="uploadPhoto">
          Upload a photo
        </h2>

        <FileInputComponent uploadedFiles={uploadedFiles} onUploadFiles={onUploadFiles} onRemoveFile={onRemoveFile} />

        <ButtonComponent
          type="submit"
          disabled={isSubmitting}
          className={ButtonClasses.primary + ' button float-right'}>
          Next
        </ButtonComponent>
        <ButtonComponent type="button" onClick={goToPrevious}>
          Previous
        </ButtonComponent>
      </Form>
    </div>
  );
};

const ProblemPageComponent = withFormik({
  mapPropsToValues({ problemId, goToNext }) {
    return {
      problemId: problemId || '',
      goToNext: goToNext,
    };
  },
  validationSchema: Yup.object().shape({
    problemId: Yup.string().required("Please supply details for 'What is the problem'."),
  }),
  handleSubmit(bag) {
    bag.goToNext();
  },
})(ProblemPage);

ProblemPageComponent.propTypes = {
  problemId: PropTypes.string,
  onUploadFiles: PropTypes.func.isRequired,
  onRemoveFile: PropTypes.func.isRequired,
  onProblemTypeChange: PropTypes.func.isRequired,
  uploadedFiles: PropTypes.array,
  possibleProblems: PropTypes.array.isRequired,
};

export default ProblemPageComponent;
export { ProblemPage };
