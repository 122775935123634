import React from 'react';
import { withFormik, Form } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ConfigurationService } from 'cyc-configuration-service';

import {
  ErrorBoxComponent,
  isValidPostcode,
  NetworkCatchErrorComponent,
  msgConstants,
  AddressLookupContainer,
  clientMyAccountUrl,
  apiMyAccountUrl,
} from '../../../common';
import { SubmitButtonAxiosComponent, ButtonClasses } from 'cyc-react-ui-components';

const changeAddressForm = ({
  values,
  errors,
  touched,
  setFieldValue,
  setErrors,
  setFieldTouched,
  submitCount,
  handleSubmit,
  ...props
}) => (
  <Form>
    <NetworkCatchErrorComponent didCatchError={values.submitError} />
    {submitCount > 0 &&
    ((touched['postcode'] === true && errors['postcode'] !== undefined) ||
      (touched['address'] === true && errors['address'] !== undefined)) ? (
      <ErrorBoxComponent errors={errors}></ErrorBoxComponent>
    ) : (
      <React.Fragment />
    )}
    <div className="row">
      <div className="col">
        <p>
          <b>Current address: </b> {props.currentAddress}
        </p>
      </div>
    </div>
    <div className="row">
      <div className="col">
        <AddressLookupContainer
          touched={touched}
          errors={errors}
          onPostcodeChange={(postcode) => {
            setFieldValue('postcode', postcode, false);
            setFieldTouched('postcode', false, false);
            setFieldTouched('address', false, false);
          }}
          onAddressChange={(address, outofYork) => {
            setFieldValue('uprn', address.uprn, true);
            setFieldValue('address', address.shortAddress, true);
            setFieldValue('selectedAddress', address, true);
            setFieldValue('outofYork', outofYork);
          }}
          onAddressSearch={(data) => {
            if (data.results === null || data.results.length < 1) {
              setFieldTouched('postcode', true, false);
              setFieldTouched('address', false, false);
              setErrors({ postcode: msgConstants.INVALID_POSTCODE_MESSAGE });
            } else {
              setFieldValue('addressSource', data.source, false);
              setFieldTouched('postcode', true, true);
              setFieldTouched('address', false, false);
            }
          }}
          onValidationError={(type) => {
            setFieldTouched('postcode', true, false);
            setFieldTouched('address', true, false);

            if (type === 'REQUIRED') {
              setErrors({ postcode: msgConstants.NO_POSTCODE_MESSAGE });
            }
            if (type === 'INVALID') {
              setErrors({ postcode: msgConstants.INVALID_POSTCODE_MESSAGE });
            }
          }}
        />
      </div>
    </div>
    <div className="row">
      <div className="col">
        <SubmitButtonAxiosComponent
          type="submit"
          className={ButtonClasses.primary + ' float-right'}
          url={`${ConfigurationService.store.apiUrl}${apiMyAccountUrl.UPDATE_CONTACT_ADDRESS}`}
          onClick={handleSubmit}
        />
        <Link className="link-inline-with-button" to={clientMyAccountUrl.dashboard}>
          Back to My Account
        </Link>
      </div>
    </div>
  </Form>
);

const ChangeAddressComponent = withFormik({
  mapPropsToValues({ currentAddress, onSubmit, onSumitSuccess }) {
    return {
      onSubmit: onSubmit || null,
      onSumitSuccess: onSumitSuccess || null,
      postcode: '',
      address: '',
      submitError: false,
    };
  },
  validationSchema: Yup.object({
    postcode: Yup.string()
      .required(msgConstants.NO_POSTCODE_MESSAGE)
      .test('postcode', msgConstants.INVALID_POSTCODE_MESSAGE, async (value) => await isValidPostcode(value)),
  }),

  handleSubmit(values, { setSubmitting, setFieldError, setFieldValue }) {
    // Validating address here as it's proving very difficult to get it to work in the validator
    if (values.address === '') {
      setFieldError('address', msgConstants.SELECT_ADDRESS_MESSAGE);
      setSubmitting(false);
      return 0;
    }

    const payload = values.selectedAddress;
    payload.addressSource = values.addressSource;
    payload.outofYork = values.outofYork;

    values
      .onSubmit(payload)
      .then((res) => {
        if (res.status === 200) {
          values.onSumitSuccess();
        }
      })
      .catch(() => {
        setFieldValue('submitError', true);
        setSubmitting(false);
      });
  },
})(changeAddressForm);

export default ChangeAddressComponent;

ChangeAddressComponent.propTypes = {
  currentAddress: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onSumitSuccess: PropTypes.func.isRequired,
};
