import React, { useState, useRef, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { HelpTextComponent } from 'cyc-react-ui-components';

import { fileInputConstants } from '../../constants/file-input-component.constants';
import './file-input.component.scss';

import {
  prepareFilesForSubmission,
  truncateFileNameString,
  generateFileArraysForUpload,
} from '../../services/file-utilities.service';

const FileInputComponent = ({ uploadedFiles, onRemoveFile, onUploadFiles, ...props }) => {
  const [errorMessages, setErrorMessages] = useState([]);
  const inputRef = useRef(null);

  // Properties that can either be passed in through props or are set to default from the constansts file
  const helpText = useMemo(
    () => (props.helpText !== undefined ? props.helpText : fileInputConstants.helpText),
    [props.helpText]
  );
  const maxFileUploadCount = useMemo(
    () => (props.maxFileUploadCount !== undefined ? props.maxFileUploadCount : fileInputConstants.maxFileUploadCount),
    [props.maxFileUploadCount]
  );
  const maxFileUploadSize = useMemo(
    () => (props.maxFileUploadSize !== undefined ? props.maxFileUploadSize : fileInputConstants.maxFileUploadSize),
    [props.maxFileUploadSize]
  );
  const maxFileNameCharacters = useMemo(
    () =>
      props.maxFileNameCharacters !== undefined
        ? props.maxFileNameCharacters
        : fileInputConstants.maxFileNameCharacters,
    [props.maxFileNameCharacters]
  );
  const acceptedFileTypes = useMemo(
    () => (props.acceptedFileTypes !== undefined ? props.acceptedFileTypes : fileInputConstants.acceptedFileTypes),
    [props.acceptedFileTypes]
  );
  const errors = useMemo(
    () => (props.errors !== undefined ? props.errors : fileInputConstants.errorMessages),
    [props.errors]
  );

  // Handle adding files up to the limit set
  const handleFileChange = async (e) => {
    const files = e.target.files;

    let filteredFiles = [];
    let errorMessages = [];

    if (files !== undefined && files.length > 0) {
      // Check if the file upload amount has been reached
      const currentUploadLimit = maxFileUploadCount - uploadedFiles.length;

      for (let i = 0; i < files.length; i++) {
        // Get the extension
        const fileExtension = files[i].type;

        // Check if file is accepted type
        if (acceptedFileTypes.filter((acceptedFileType) => acceptedFileType.mediaType === fileExtension).length === 0) {
          errorMessages.push({ fileName: files[i].name, message: errors.invalidFileType });
          continue;
        }

        // Check if file has hit upload limit
        if (i >= currentUploadLimit) {
          errorMessages.push({ fileName: files[i].name, message: errors.exceedUploadCountLimit });
          continue;
        }

        // Check if file size is too large
        if (files[i].size >= maxFileUploadSize) {
          errorMessages.push({ fileName: files[i].name, message: errors.exceedUploadSizeLimit });
          continue;
        }

        // Check if file name is too long
        if (files[i].name.length > maxFileNameCharacters) {
          // set error message then continue to shorten name
          errorMessages.push({ fileName: files[i].name, message: errors.exceedFileNameCharacterLimit });
          // Get file extension and create shortened name
          const newFile = truncateFileNameString(files[i], maxFileNameCharacters);
          filteredFiles.push(newFile);
          continue;
        }

        filteredFiles.push(files[i]);
      }

      if (filteredFiles.length > 0) {
        filteredFiles = await prepareFilesForSubmission(filteredFiles);

        const [filesToUpload, filesToRemove, filesToNotUpload] = generateFileArraysForUpload(
          uploadedFiles || [],
          filteredFiles
        );

        filesToRemove.forEach((file) => {
          onRemoveFile(uploadedFiles.findIndex((uploadedFile) => uploadedFile === file));
          errorMessages.push({ fileName: file.fileName, message: errors.fileReplaced });
        });

        // This is so the user can re-select a file they have just removed
        inputRef.current.value = null;

        filesToNotUpload.forEach((file) => {
          errorMessages.push({ fileName: file.fileName, message: errors.duplicateFile });
        });

        onUploadFiles(filesToUpload);
      }

      setErrorMessages(errorMessages);
    }
  };

  const handleRemoveFile = useCallback(
    (index) => {
      onRemoveFile(index);

      // Clear the errorMessages
      setErrorMessages([]);

      // This is so the user can re-select a file they have just removed
      inputRef.current.value = null;
    },
    [inputRef, onRemoveFile, setErrorMessages]
  );

  const InvalidFiles = ({ errorMessages }) =>
    errorMessages.map((errorMessage, index) => (
      <p key={index}>
        <strong>"{errorMessage.fileName}"</strong> - {errorMessage.message}
      </p>
    ));

  const SelectedFiles = ({ uploadedFiles, onRemoveFileClick }) => {
    const uploadFilesExist = uploadedFiles !== undefined;
    return uploadFilesExist ? (
      uploadedFiles.map((file, index) => (
        <div className="file-input-cyc__uploaded-file row col-xs-12 col-md-6" key={index}>
          <div className="col-xs-12 col-sm-4">
            <img className="img img-fluid" src={'data:image/png;base64,' + file.fileContent} alt={file.fileName} />
          </div>
          <div className="col-xs-12 col-sm-8 word-break pull-right">
            <p>{file.fileName}</p>

            <button type="button" onClick={() => onRemoveFileClick(index)}>
              Remove
            </button>
          </div>
        </div>
      ))
    ) : (
      <React.Fragment />
    );
  };

  return (
    <div className="file-input-cyc">
      {<InvalidFiles errorMessages={errorMessages} />}
      <div>
        <label className="file-input-cyc__upload-file-button">
          Choose file
          <input
            id="uploadImg"
            className=""
            type="file"
            onChange={handleFileChange}
            accept={acceptedFileTypes.map((x) => x.suffix).toString()}
            ref={inputRef}
            multiple
          />
        </label>
        <div className="row">
          <SelectedFiles uploadedFiles={uploadedFiles} onRemoveFileClick={handleRemoveFile} />
        </div>
        <HelpTextComponent>{uploadedFiles.length === 0 ? helpText.noFiles : helpText.withFiles}</HelpTextComponent>
      </div>
    </div>
  );
};

FileInputComponent.propTypes = {
  onUploadFiles: PropTypes.func.isRequired,
  onRemoveFile: PropTypes.func.isRequired,
  uploadedFiles: PropTypes.array,
  helpText: PropTypes.object,
  maxFileUploadCount: PropTypes.number,
  maxFileUploadSize: PropTypes.number,
  acceptedFileTypes: PropTypes.array,
};

export default FileInputComponent;
