import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Form, withFormik } from 'formik';
import * as Yup from 'yup';
import { ErrorBoxComponent, ErrorLabelHandlerComponent, setInitialPosition } from '../../../common';
import {
  RadiobuttonInputComponent,
  InputErrorComponent,
  ButtonComponent,
  ButtonClasses,
} from 'cyc-react-ui-components';

const ProblemWithPageForm = (props) => {
  let errorBoxRef = React.createRef();

  useEffect(() => {
    if (!props.isSubmitting && !props.isValid && Object.keys(props.errors).length > 0) {
      const errorBox = ReactDOM.findDOMNode(errorBoxRef.current);
      errorBox.scrollIntoView();
    }
  }, [props.isSubmitting, errorBoxRef, props.errors, props.isValid]);

  useEffect(() => {
    setInitialPosition();
  }, []);

  return (
    <div className="page" id="typeOfTheProblem">
      <Form>
        <div ref={errorBoxRef}></div>
        {Object.keys(props.errors).length > 0 && props.touched.problemWithId && (
          <ErrorBoxComponent errors={props.errors}></ErrorBoxComponent>
        )}

        <h2>About the problem</h2>

        {props.types !== undefined && props.types.length > 0 ? (
          <div className="row">
            <span id="WhatIsTheProblemWith">
              <ErrorLabelHandlerComponent
                touched={props.touched}
                errors={props.errors}
                tag="p"
                text="What is the problem with?"
                id="problemWithId"
                submitCount={props.submitCount}
              />
              <RadiobuttonInputComponent
                name="problemWithId"
                value={props.values.problemWithId}
                onChange={(e) => {
                  props.setFieldValue('problemWithId', e);
                  props.onProblemWithChange(e);
                }}
                error={props.errors.problemWithId && props.touched.problemWithId}
                options={props.types.map((type) => ({
                  label: type.displayName,
                  value: type.id,
                }))}
              />
              {props.errors.problemWithId && props.touched.problemWithId && (
                <InputErrorComponent>{props.errors.problemWithId}</InputErrorComponent>
              )}
            </span>
          </div>
        ) : undefined}

        <ButtonComponent type="submit" disabled={props.isSubmitting} className={ButtonClasses.primary + ' float-right'}>
          Next
        </ButtonComponent>
        <ButtonComponent type="button" onClick={props.goToPreviousPage}>
          Previous
        </ButtonComponent>
      </Form>
    </div>
  );
};

const ProblemWithPageComponent = withFormik({
  mapPropsToValues({ problemWithId, goToNextPage }) {
    return {
      problemWithId: problemWithId,
      goToNextPage: goToNextPage,
    };
  },
  validationSchema: Yup.object().shape({
    problemWithId: Yup.string().required("Please supply details for 'What is the problem with?'."),
  }),
  handleSubmit(bag) {
    bag.goToNextPage();
  },
})(ProblemWithPageForm);

ProblemWithPageComponent.propTypes = {
  problemWithId: PropTypes.string,
  onProblemWithChange: PropTypes.func.isRequired,
  goToNextPage: PropTypes.func.isRequired,
  goToPreviousPage: PropTypes.func.isRequired,
  types: PropTypes.array.isRequired,
};

export default ProblemWithPageComponent;
export { ProblemWithPageForm };
